var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// ../../../node_modules/validator/lib/util/assertString.js
var require_assertString = __commonJS({
  "../../../node_modules/validator/lib/util/assertString.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = assertString;
    function _typeof(obj) {
      "@babel/helpers - typeof";
      if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
        _typeof = function _typeof2(obj2) {
          return typeof obj2;
        };
      } else {
        _typeof = function _typeof2(obj2) {
          return obj2 && typeof Symbol === "function" && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
        };
      }
      return _typeof(obj);
    }
    function assertString(input) {
      var isString2 = typeof input === "string" || input instanceof String;
      if (!isString2) {
        var invalidType = _typeof(input);
        if (input === null)
          invalidType = "null";
        else if (invalidType === "object")
          invalidType = input.constructor.name;
        throw new TypeError("Expected a string but received a ".concat(invalidType));
      }
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/alpha.js
var require_alpha = __commonJS({
  "../../../node_modules/validator/lib/alpha.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.commaDecimal = exports.dotDecimal = exports.bengaliLocales = exports.farsiLocales = exports.arabicLocales = exports.englishLocales = exports.decimal = exports.alphanumeric = exports.alpha = void 0;
    var alpha = {
      "en-US": /^[A-Z]+$/i,
      "az-AZ": /^[A-VXYZÇƏĞİıÖŞÜ]+$/i,
      "bg-BG": /^[А-Я]+$/i,
      "cs-CZ": /^[A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]+$/i,
      "da-DK": /^[A-ZÆØÅ]+$/i,
      "de-DE": /^[A-ZÄÖÜß]+$/i,
      "el-GR": /^[Α-ώ]+$/i,
      "es-ES": /^[A-ZÁÉÍÑÓÚÜ]+$/i,
      "fa-IR": /^[ابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهی]+$/i,
      "fi-FI": /^[A-ZÅÄÖ]+$/i,
      "fr-FR": /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]+$/i,
      "it-IT": /^[A-ZÀÉÈÌÎÓÒÙ]+$/i,
      "ja-JP": /^[ぁ-んァ-ヶｦ-ﾟ一-龠ー・。、]+$/i,
      "nb-NO": /^[A-ZÆØÅ]+$/i,
      "nl-NL": /^[A-ZÁÉËÏÓÖÜÚ]+$/i,
      "nn-NO": /^[A-ZÆØÅ]+$/i,
      "hu-HU": /^[A-ZÁÉÍÓÖŐÚÜŰ]+$/i,
      "pl-PL": /^[A-ZĄĆĘŚŁŃÓŻŹ]+$/i,
      "pt-PT": /^[A-ZÃÁÀÂÄÇÉÊËÍÏÕÓÔÖÚÜ]+$/i,
      "ru-RU": /^[А-ЯЁ]+$/i,
      "sl-SI": /^[A-ZČĆĐŠŽ]+$/i,
      "sk-SK": /^[A-ZÁČĎÉÍŇÓŠŤÚÝŽĹŔĽÄÔ]+$/i,
      "sr-RS@latin": /^[A-ZČĆŽŠĐ]+$/i,
      "sr-RS": /^[А-ЯЂЈЉЊЋЏ]+$/i,
      "sv-SE": /^[A-ZÅÄÖ]+$/i,
      "th-TH": /^[ก-๐\s]+$/i,
      "tr-TR": /^[A-ZÇĞİıÖŞÜ]+$/i,
      "uk-UA": /^[А-ЩЬЮЯЄIЇҐі]+$/i,
      "vi-VN": /^[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴĐÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸ]+$/i,
      "ko-KR": /^[ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
      "ku-IQ": /^[ئابپتجچحخدرڕزژسشعغفڤقکگلڵمنوۆھەیێيطؤثآإأكضصةظذ]+$/i,
      ar: /^[ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]+$/,
      he: /^[א-ת]+$/,
      fa: /^['آاءأؤئبپتثجچحخدذرزژسشصضطظعغفقکگلمنوهةی']+$/i,
      bn: /^['ঀঁংঃঅআইঈউঊঋঌএঐওঔকখগঘঙচছজঝঞটঠডঢণতথদধনপফবভমযরলশষসহ়ঽািীুূৃৄেৈোৌ্ৎৗড়ঢ়য়ৠৡৢৣৰৱ৲৳৴৵৶৷৸৹৺৻']+$/,
      "hi-IN": /^[\u0900-\u0961]+[\u0972-\u097F]*$/i,
      "si-LK": /^[\u0D80-\u0DFF]+$/
    };
    exports.alpha = alpha;
    var alphanumeric = {
      "en-US": /^[0-9A-Z]+$/i,
      "az-AZ": /^[0-9A-VXYZÇƏĞİıÖŞÜ]+$/i,
      "bg-BG": /^[0-9А-Я]+$/i,
      "cs-CZ": /^[0-9A-ZÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]+$/i,
      "da-DK": /^[0-9A-ZÆØÅ]+$/i,
      "de-DE": /^[0-9A-ZÄÖÜß]+$/i,
      "el-GR": /^[0-9Α-ω]+$/i,
      "es-ES": /^[0-9A-ZÁÉÍÑÓÚÜ]+$/i,
      "fi-FI": /^[0-9A-ZÅÄÖ]+$/i,
      "fr-FR": /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]+$/i,
      "it-IT": /^[0-9A-ZÀÉÈÌÎÓÒÙ]+$/i,
      "ja-JP": /^[0-9０-９ぁ-んァ-ヶｦ-ﾟ一-龠ー・。、]+$/i,
      "hu-HU": /^[0-9A-ZÁÉÍÓÖŐÚÜŰ]+$/i,
      "nb-NO": /^[0-9A-ZÆØÅ]+$/i,
      "nl-NL": /^[0-9A-ZÁÉËÏÓÖÜÚ]+$/i,
      "nn-NO": /^[0-9A-ZÆØÅ]+$/i,
      "pl-PL": /^[0-9A-ZĄĆĘŚŁŃÓŻŹ]+$/i,
      "pt-PT": /^[0-9A-ZÃÁÀÂÄÇÉÊËÍÏÕÓÔÖÚÜ]+$/i,
      "ru-RU": /^[0-9А-ЯЁ]+$/i,
      "sl-SI": /^[0-9A-ZČĆĐŠŽ]+$/i,
      "sk-SK": /^[0-9A-ZÁČĎÉÍŇÓŠŤÚÝŽĹŔĽÄÔ]+$/i,
      "sr-RS@latin": /^[0-9A-ZČĆŽŠĐ]+$/i,
      "sr-RS": /^[0-9А-ЯЂЈЉЊЋЏ]+$/i,
      "sv-SE": /^[0-9A-ZÅÄÖ]+$/i,
      "th-TH": /^[ก-๙\s]+$/i,
      "tr-TR": /^[0-9A-ZÇĞİıÖŞÜ]+$/i,
      "uk-UA": /^[0-9А-ЩЬЮЯЄIЇҐі]+$/i,
      "ko-KR": /^[0-9ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
      "ku-IQ": /^[٠١٢٣٤٥٦٧٨٩0-9ئابپتجچحخدرڕزژسشعغفڤقکگلڵمنوۆھەیێيطؤثآإأكضصةظذ]+$/i,
      "vi-VN": /^[0-9A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴĐÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸ]+$/i,
      ar: /^[٠١٢٣٤٥٦٧٨٩0-9ءآأؤإئابةتثجحخدذرزسشصضطظعغفقكلمنهوىيًٌٍَُِّْٰ]+$/,
      he: /^[0-9א-ת]+$/,
      fa: /^['0-9آاءأؤئبپتثجچحخدذرزژسشصضطظعغفقکگلمنوهةی۱۲۳۴۵۶۷۸۹۰']+$/i,
      bn: /^['ঀঁংঃঅআইঈউঊঋঌএঐওঔকখগঘঙচছজঝঞটঠডঢণতথদধনপফবভমযরলশষসহ়ঽািীুূৃৄেৈোৌ্ৎৗড়ঢ়য়ৠৡৢৣ০১২৩৪৫৬৭৮৯ৰৱ৲৳৴৵৶৷৸৹৺৻']+$/,
      "hi-IN": /^[\u0900-\u0963]+[\u0966-\u097F]*$/i,
      "si-LK": /^[0-9\u0D80-\u0DFF]+$/
    };
    exports.alphanumeric = alphanumeric;
    var decimal = {
      "en-US": ".",
      ar: "\u066B"
    };
    exports.decimal = decimal;
    var englishLocales = ["AU", "GB", "HK", "IN", "NZ", "ZA", "ZM"];
    exports.englishLocales = englishLocales;
    for (i = 0; i < englishLocales.length; i++) {
      locale = "en-".concat(englishLocales[i]);
      alpha[locale] = alpha["en-US"];
      alphanumeric[locale] = alphanumeric["en-US"];
      decimal[locale] = decimal["en-US"];
    }
    var locale;
    var i;
    var arabicLocales = ["AE", "BH", "DZ", "EG", "IQ", "JO", "KW", "LB", "LY", "MA", "QM", "QA", "SA", "SD", "SY", "TN", "YE"];
    exports.arabicLocales = arabicLocales;
    for (_i = 0; _i < arabicLocales.length; _i++) {
      _locale = "ar-".concat(arabicLocales[_i]);
      alpha[_locale] = alpha.ar;
      alphanumeric[_locale] = alphanumeric.ar;
      decimal[_locale] = decimal.ar;
    }
    var _locale;
    var _i;
    var farsiLocales = ["IR", "AF"];
    exports.farsiLocales = farsiLocales;
    for (_i2 = 0; _i2 < farsiLocales.length; _i2++) {
      _locale2 = "fa-".concat(farsiLocales[_i2]);
      alphanumeric[_locale2] = alphanumeric.fa;
      decimal[_locale2] = decimal.ar;
    }
    var _locale2;
    var _i2;
    var bengaliLocales = ["BD", "IN"];
    exports.bengaliLocales = bengaliLocales;
    for (_i3 = 0; _i3 < bengaliLocales.length; _i3++) {
      _locale3 = "bn-".concat(bengaliLocales[_i3]);
      alpha[_locale3] = alpha.bn;
      alphanumeric[_locale3] = alphanumeric.bn;
      decimal[_locale3] = decimal["en-US"];
    }
    var _locale3;
    var _i3;
    var dotDecimal = ["ar-EG", "ar-LB", "ar-LY"];
    exports.dotDecimal = dotDecimal;
    var commaDecimal = ["bg-BG", "cs-CZ", "da-DK", "de-DE", "el-GR", "en-ZM", "es-ES", "fr-CA", "fr-FR", "id-ID", "it-IT", "ku-IQ", "hi-IN", "hu-HU", "nb-NO", "nn-NO", "nl-NL", "pl-PL", "pt-PT", "ru-RU", "si-LK", "sl-SI", "sr-RS@latin", "sr-RS", "sv-SE", "tr-TR", "uk-UA", "vi-VN"];
    exports.commaDecimal = commaDecimal;
    for (_i4 = 0; _i4 < dotDecimal.length; _i4++) {
      decimal[dotDecimal[_i4]] = decimal["en-US"];
    }
    var _i4;
    for (_i5 = 0; _i5 < commaDecimal.length; _i5++) {
      decimal[commaDecimal[_i5]] = ",";
    }
    var _i5;
    alpha["fr-CA"] = alpha["fr-FR"];
    alphanumeric["fr-CA"] = alphanumeric["fr-FR"];
    alpha["pt-BR"] = alpha["pt-PT"];
    alphanumeric["pt-BR"] = alphanumeric["pt-PT"];
    decimal["pt-BR"] = decimal["pt-PT"];
    alpha["pl-Pl"] = alpha["pl-PL"];
    alphanumeric["pl-Pl"] = alphanumeric["pl-PL"];
    decimal["pl-Pl"] = decimal["pl-PL"];
    alpha["fa-AF"] = alpha.fa;
  }
});

// ../../../node_modules/validator/lib/isAlphanumeric.js
var require_isAlphanumeric = __commonJS({
  "../../../node_modules/validator/lib/isAlphanumeric.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isAlphanumeric2;
    exports.locales = void 0;
    var _assertString = _interopRequireDefault(require_assertString());
    var _alpha = require_alpha();
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    function isAlphanumeric2(_str) {
      var locale = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "en-US";
      var options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
      (0, _assertString.default)(_str);
      var str = _str;
      var ignore = options.ignore;
      if (ignore) {
        if (ignore instanceof RegExp) {
          str = str.replace(ignore, "");
        } else if (typeof ignore === "string") {
          str = str.replace(new RegExp("[".concat(ignore.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, "\\$&"), "]"), "g"), "");
        } else {
          throw new Error("ignore should be instance of a String or RegExp");
        }
      }
      if (locale in _alpha.alphanumeric) {
        return _alpha.alphanumeric[locale].test(str);
      }
      throw new Error("Invalid locale '".concat(locale, "'"));
    }
    var locales = Object.keys(_alpha.alphanumeric);
    exports.locales = locales;
  }
});

// ../../../node_modules/validator/lib/isIP.js
var require_isIP = __commonJS({
  "../../../node_modules/validator/lib/isIP.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isIP2;
    var _assertString = _interopRequireDefault(require_assertString());
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    var IPv4SegmentFormat = "(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])";
    var IPv4AddressFormat = "(".concat(IPv4SegmentFormat, "[.]){3}").concat(IPv4SegmentFormat);
    var IPv4AddressRegExp = new RegExp("^".concat(IPv4AddressFormat, "$"));
    var IPv6SegmentFormat = "(?:[0-9a-fA-F]{1,4})";
    var IPv6AddressRegExp = new RegExp("^(" + "(?:".concat(IPv6SegmentFormat, ":){7}(?:").concat(IPv6SegmentFormat, "|:)|") + "(?:".concat(IPv6SegmentFormat, ":){6}(?:").concat(IPv4AddressFormat, "|:").concat(IPv6SegmentFormat, "|:)|") + "(?:".concat(IPv6SegmentFormat, ":){5}(?::").concat(IPv4AddressFormat, "|(:").concat(IPv6SegmentFormat, "){1,2}|:)|") + "(?:".concat(IPv6SegmentFormat, ":){4}(?:(:").concat(IPv6SegmentFormat, "){0,1}:").concat(IPv4AddressFormat, "|(:").concat(IPv6SegmentFormat, "){1,3}|:)|") + "(?:".concat(IPv6SegmentFormat, ":){3}(?:(:").concat(IPv6SegmentFormat, "){0,2}:").concat(IPv4AddressFormat, "|(:").concat(IPv6SegmentFormat, "){1,4}|:)|") + "(?:".concat(IPv6SegmentFormat, ":){2}(?:(:").concat(IPv6SegmentFormat, "){0,3}:").concat(IPv4AddressFormat, "|(:").concat(IPv6SegmentFormat, "){1,5}|:)|") + "(?:".concat(IPv6SegmentFormat, ":){1}(?:(:").concat(IPv6SegmentFormat, "){0,4}:").concat(IPv4AddressFormat, "|(:").concat(IPv6SegmentFormat, "){1,6}|:)|") + "(?::((?::".concat(IPv6SegmentFormat, "){0,5}:").concat(IPv4AddressFormat, "|(?::").concat(IPv6SegmentFormat, "){1,7}|:))") + ")(%[0-9a-zA-Z-.:]{1,})?$");
    function isIP2(str) {
      var version = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "";
      (0, _assertString.default)(str);
      version = String(version);
      if (!version) {
        return isIP2(str, 4) || isIP2(str, 6);
      }
      if (version === "4") {
        return IPv4AddressRegExp.test(str);
      }
      if (version === "6") {
        return IPv6AddressRegExp.test(str);
      }
      return false;
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/isISO8601.js
var require_isISO8601 = __commonJS({
  "../../../node_modules/validator/lib/isISO8601.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isISO86012;
    var _assertString = _interopRequireDefault(require_assertString());
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    var iso8601 = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-3])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
    var iso8601StrictSeparator = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-3])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
    var isValidDate = function isValidDate2(str) {
      var ordinalMatch = str.match(/^(\d{4})-?(\d{3})([ T]{1}\.*|$)/);
      if (ordinalMatch) {
        var oYear = Number(ordinalMatch[1]);
        var oDay = Number(ordinalMatch[2]);
        if (oYear % 4 === 0 && oYear % 100 !== 0 || oYear % 400 === 0)
          return oDay <= 366;
        return oDay <= 365;
      }
      var match = str.match(/(\d{4})-?(\d{0,2})-?(\d*)/).map(Number);
      var year = match[1];
      var month = match[2];
      var day = match[3];
      var monthString = month ? "0".concat(month).slice(-2) : month;
      var dayString = day ? "0".concat(day).slice(-2) : day;
      var d = new Date("".concat(year, "-").concat(monthString || "01", "-").concat(dayString || "01"));
      if (month && day) {
        return d.getUTCFullYear() === year && d.getUTCMonth() + 1 === month && d.getUTCDate() === day;
      }
      return true;
    };
    function isISO86012(str) {
      var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      (0, _assertString.default)(str);
      var check = options.strictSeparator ? iso8601StrictSeparator.test(str) : iso8601.test(str);
      if (check && options.strict)
        return isValidDate(str);
      return check;
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/util/merge.js
var require_merge = __commonJS({
  "../../../node_modules/validator/lib/util/merge.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = merge;
    function merge() {
      var obj = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
      var defaults = arguments.length > 1 ? arguments[1] : void 0;
      for (var key in defaults) {
        if (typeof obj[key] === "undefined") {
          obj[key] = defaults[key];
        }
      }
      return obj;
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/isJSON.js
var require_isJSON = __commonJS({
  "../../../node_modules/validator/lib/isJSON.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isJSON2;
    var _assertString = _interopRequireDefault(require_assertString());
    var _merge = _interopRequireDefault(require_merge());
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    function _typeof(obj) {
      "@babel/helpers - typeof";
      if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
        _typeof = function _typeof2(obj2) {
          return typeof obj2;
        };
      } else {
        _typeof = function _typeof2(obj2) {
          return obj2 && typeof Symbol === "function" && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
        };
      }
      return _typeof(obj);
    }
    var default_json_options = {
      allow_primitives: false
    };
    function isJSON2(str, options) {
      (0, _assertString.default)(str);
      try {
        options = (0, _merge.default)(options, default_json_options);
        var primitives = [];
        if (options.allow_primitives) {
          primitives = [null, false, true];
        }
        var obj = JSON.parse(str);
        return primitives.includes(obj) || !!obj && _typeof(obj) === "object";
      } catch (e) {
      }
      return false;
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/isLength.js
var require_isLength = __commonJS({
  "../../../node_modules/validator/lib/isLength.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isLength;
    var _assertString = _interopRequireDefault(require_assertString());
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    function _typeof(obj) {
      "@babel/helpers - typeof";
      if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
        _typeof = function _typeof2(obj2) {
          return typeof obj2;
        };
      } else {
        _typeof = function _typeof2(obj2) {
          return obj2 && typeof Symbol === "function" && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
        };
      }
      return _typeof(obj);
    }
    function isLength(str, options) {
      (0, _assertString.default)(str);
      var min2;
      var max2;
      if (_typeof(options) === "object") {
        min2 = options.min || 0;
        max2 = options.max;
      } else {
        min2 = arguments[1] || 0;
        max2 = arguments[2];
      }
      var presentationSequences = str.match(/(\uFE0F|\uFE0E)/g) || [];
      var surrogatePairs = str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
      var len = str.length - presentationSequences.length - surrogatePairs.length;
      return len >= min2 && (typeof max2 === "undefined" || len <= max2);
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// ../../../node_modules/validator/lib/isNumeric.js
var require_isNumeric = __commonJS({
  "../../../node_modules/validator/lib/isNumeric.js"(exports, module) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = isNumeric;
    var _assertString = _interopRequireDefault(require_assertString());
    var _alpha = require_alpha();
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : { default: obj };
    }
    var numericNoSymbols = /^[0-9]+$/;
    function isNumeric(str, options) {
      (0, _assertString.default)(str);
      if (options && options.no_symbols) {
        return numericNoSymbols.test(str);
      }
      return new RegExp("^[+-]?([0-9]*[".concat((options || {}).locale ? _alpha.decimal[options.locale] : ".", "])?[0-9]+$")).test(str);
    }
    module.exports = exports.default;
    module.exports.default = exports.default;
  }
});

// src/constants/colors.ts
var etRedLight = "#feefee";
var etAmaranth = "#dc3545";
var etRed = "#ee220c";
var etAbsoluteRed = "#ff0000";
var etRedDark = "#a12618";
var etRedFresh = "#f60561";
var etSanMarino = "#414BB2";
var sdgPartnerships = "#19486a";
var etLiBlueWaterDark = "#006fa6";
var etBlueDark = "#0071b3";
var sdgPeace = "#00689d";
var etBlueDefault = "#007bff";
var sdgWater = "#0a97d9";
var etLightBlue = "#00A2FF";
var etBlue = "#00a2ff";
var etTwBlueWater = "#00acee";
var etBlueLowLight = "#00c7FB";
var etBrightLightBlue = "#32c5ff";
var etBrightLightBlueOpacity = "#32c5ff26";
var etBrightTurquoise = "#00ccfa";
var etBlueLight = "#A1DAFF";
var etBlueOnahau = "#d1f5fe";
var etBlueZiggurat = "#b2d7e0";
var etSunglow = "#FFC532";
var etBlueFaded = "rgba(50, 197, 255, 0.1)";
var etBluePattens = "#D5F3FF";
var etBlueLighter = "#D3E6F1";
var etOrangeLight = "#fff7ed";
var etOrange = "#ff9300";
var etOrangeDark = "#b37012";
var etYellowLight = "#fffce9";
var etYellow = "#fae232";
var etYellowDark = "#ad9f34";
var etYellowDeck = "#E6D02C";
var etGreenLight = "#f4fcf1";
var etGreenSquare = "#48a128";
var etGreen = "#61d836";
var etGreenDark = "#498c31";
var etNiagara = "#0DA888";
var etPinkLight = "#f4eefc";
var etPink = "#6108d8";
var etPinkDark = "#48148c";
var etPinkHighLight = "#f6ebf7";
var etLicoriceBlue = "#293540";
var etHeatherBlue = "#b1b9c1";
var etWhiteLight = "#f7f9fa";
var etWhite = "#fff";
var etGhostWhite = "#f3f3f4";
var etWhiteOpacity = "#ffffffaa";
var etWhiteDark = "#f4f5f5";
var etSilverLight = "#d8d8d8";
var etGrayLight = "#d4d7d9";
var etGray = "#7f868e";
var etGrayBlue = "#6a7179";
var etGhostGray = "#ced4da";
var etGrayBold = "#949a9f";
var etGrayTuna = "#495057";
var etGrayDark = "#2a3642";
var etRegentGray = "#949a9f";
var etDark = "#293540";
var etDarkWithOpacity = "#2935403d";
var etGrayExtraDark = "#161c22";
var etIronGray = "#d6dadc";
var etBlack = "#000000";
var etTundora = "#494949";
var etConcrete = "#F2F2F2";
var etShadow = "rgba(0, 0, 0, 0.5)";
var etShadowWhite = "rgba(225, 225, 225, 0.5)";
var etShadowLight = "rgba(0, 0, 0, 0.3)";
var etShadowLighter = "rgba(0, 0, 0, 0.1)";
var etShadowBrighter = "rgba(0, 0, 0, 0.05)";
var etShadowDarkBlueFaded = "rgba(42, 54, 66, 0.2)";
var etFbPurple = "#4267b2";
var etWaGreen = "#25D366";
var etGreenBold = "#18360E";
var etPiRed = "#BD091D";
var etYTDark = "#35383C";
var etCeruleanFrost = "#7591cc";
var etSolitude = "#E0E5EA";
var etSolitudeLight = "#EBEEF0";
var etPattensBlue = "#DEE2E6";
var etIceBlue = "#ebeef0";
var etScarletRed = "#ee220c";
var etSolitudeBlue = "#ebeef0";
var sdgPoverty = "#e5243b";
var sdgHunger = "#dda63a";
var sdgHealth = "#4c9f38";
var sdgEducation = "#c5192d";
var sdgEquality = "#ff3a21";
var sdgSanitation = "#26bde2";
var sdgEnergy = "#fcc30b";
var sdgGrowth = "#a21942";
var sdgInnovation = "#fd6925";
var sdgInequality = "#dd1367";
var sdgCommunities = "#fd9d24";
var sdgConsumption = "#bf8b2e";
var sdgClimate = "#3f7e44";
var sdgLand = "#56c02b";
var etRedBlood = "#E5233B";
var etBlueSky = "#edf8ff";
var etGrayDarkWithOpacity = "rgba(42, 54, 66, 0.05)";
var etVoteGreen = "#6dd400";
var etVoteRed = "#e02020";
var etPurple = "#531B93";
var etPurpleUonCard = "#a63cff";
var etPurpleLogo = "#531A93";
var etHeliotrope = "#A659FF";
var etNevadaGrey = "#697179";
var etStormGray = "#727377";
var etGallery = "#ebebeb";
var etVeryMarshmallow = "#ebeef0";
var etGrayIconMenu = "#7F8674";
var etDustyGray = "#959595";
var etFrenchGray = "#cdcdcd";
var etPaleSky = "#6C757D";
var etSonicSilver = "#757575";

// src/constants/regexes.ts
var VIMEO_REGEX = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com/;
var YOUTUBE_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|.*[&?]v=))((\w|-){11})/m;

// ../../../node_modules/@earthtoday/contract/dist/index.js
import { ApiProperty } from "@nestjs/swagger";
import { HttpError } from "routing-controllers";
import { HttpException, HttpStatus } from "@nestjs/common";
import { ApiProperty as ApiProperty2 } from "@nestjs/swagger";
import { HttpException as HttpException2, HttpStatus as HttpStatus2 } from "@nestjs/common";
import { ApiProperty as ApiProperty3 } from "@nestjs/swagger";
import { HttpException as HttpException3, HttpStatus as HttpStatus3 } from "@nestjs/common";
import { ApiProperty as ApiProperty4 } from "@nestjs/swagger";
import { HttpException as HttpException4, HttpStatus as HttpStatus4 } from "@nestjs/common";
import { ApiProperty as ApiProperty5 } from "@nestjs/swagger";
import { HttpException as HttpException5, HttpStatus as HttpStatus5 } from "@nestjs/common";
import { ApiProperty as ApiProperty6 } from "@nestjs/swagger";
import { HttpException as HttpException6, HttpStatus as HttpStatus6 } from "@nestjs/common";
import { ApiProperty as ApiProperty7 } from "@nestjs/swagger";
import { HttpException as HttpException7, HttpStatus as HttpStatus7 } from "@nestjs/common";
import { ApiProperty as ApiProperty8 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty9 } from "@nestjs/swagger";
import { HttpError as HttpError2 } from "routing-controllers";
import { ApiProperty as ApiProperty10 } from "@nestjs/swagger";

// ../../../node_modules/class-validator/esm5/metadata/ValidationMetadata.js
var ValidationMetadata = (
  /** @class */
  function() {
    function ValidationMetadata2(args) {
      this.groups = [];
      this.each = false;
      this.context = void 0;
      this.type = args.type;
      this.name = args.name;
      this.target = args.target;
      this.propertyName = args.propertyName;
      this.constraints = args === null || args === void 0 ? void 0 : args.constraints;
      this.constraintCls = args.constraintCls;
      this.validationTypeOptions = args.validationTypeOptions;
      if (args.validationOptions) {
        this.message = args.validationOptions.message;
        this.groups = args.validationOptions.groups;
        this.always = args.validationOptions.always;
        this.each = args.validationOptions.each;
        this.context = args.validationOptions.context;
      }
    }
    return ValidationMetadata2;
  }()
);

// ../../../node_modules/class-validator/esm5/validation-schema/ValidationSchemaToMetadataTransformer.js
var ValidationSchemaToMetadataTransformer = (
  /** @class */
  function() {
    function ValidationSchemaToMetadataTransformer2() {
    }
    ValidationSchemaToMetadataTransformer2.prototype.transform = function(schema) {
      var metadatas = [];
      Object.keys(schema.properties).forEach(function(property) {
        schema.properties[property].forEach(function(validation) {
          var validationOptions = {
            message: validation.message,
            groups: validation.groups,
            always: validation.always,
            each: validation.each
          };
          var args = {
            type: validation.type,
            name: validation.name,
            target: schema.name,
            propertyName: property,
            constraints: validation.constraints,
            validationTypeOptions: validation.options,
            validationOptions
          };
          metadatas.push(new ValidationMetadata(args));
        });
      });
      return metadatas;
    };
    return ValidationSchemaToMetadataTransformer2;
  }()
);

// ../../../node_modules/class-validator/esm5/utils/get-global.util.js
function getGlobal() {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof self !== "undefined") {
    return self;
  }
}

// ../../../node_modules/class-validator/esm5/metadata/MetadataStorage.js
var __values = function(o) {
  var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
  if (m)
    return m.call(o);
  if (o && typeof o.length === "number")
    return {
      next: function() {
        if (o && i >= o.length)
          o = void 0;
        return { value: o && o[i++], done: !o };
      }
    };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = function(o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m)
    return o;
  var i = m.call(o), r, ar = [], e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
      ar.push(r.value);
  } catch (error) {
    e = { error };
  } finally {
    try {
      if (r && !r.done && (m = i["return"]))
        m.call(i);
    } finally {
      if (e)
        throw e.error;
    }
  }
  return ar;
};
var __spreadArray = function(to, from, pack) {
  if (pack || arguments.length === 2)
    for (var i = 0, l = from.length, ar; i < l; i++) {
      if (ar || !(i in from)) {
        if (!ar)
          ar = Array.prototype.slice.call(from, 0, i);
        ar[i] = from[i];
      }
    }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var MetadataStorage = (
  /** @class */
  function() {
    function MetadataStorage2() {
      this.validationMetadatas = /* @__PURE__ */ new Map();
      this.constraintMetadatas = /* @__PURE__ */ new Map();
    }
    Object.defineProperty(MetadataStorage2.prototype, "hasValidationMetaData", {
      get: function() {
        return !!this.validationMetadatas.size;
      },
      enumerable: false,
      configurable: true
    });
    MetadataStorage2.prototype.addValidationSchema = function(schema) {
      var _this = this;
      var validationMetadatas = new ValidationSchemaToMetadataTransformer().transform(schema);
      validationMetadatas.forEach(function(validationMetadata) {
        return _this.addValidationMetadata(validationMetadata);
      });
    };
    MetadataStorage2.prototype.addValidationMetadata = function(metadata) {
      var existingMetadata = this.validationMetadatas.get(metadata.target);
      if (existingMetadata) {
        existingMetadata.push(metadata);
      } else {
        this.validationMetadatas.set(metadata.target, [metadata]);
      }
    };
    MetadataStorage2.prototype.addConstraintMetadata = function(metadata) {
      var existingMetadata = this.constraintMetadatas.get(metadata.target);
      if (existingMetadata) {
        existingMetadata.push(metadata);
      } else {
        this.constraintMetadatas.set(metadata.target, [metadata]);
      }
    };
    MetadataStorage2.prototype.groupByPropertyName = function(metadata) {
      var grouped = {};
      metadata.forEach(function(metadata2) {
        if (!grouped[metadata2.propertyName])
          grouped[metadata2.propertyName] = [];
        grouped[metadata2.propertyName].push(metadata2);
      });
      return grouped;
    };
    MetadataStorage2.prototype.getTargetValidationMetadatas = function(targetConstructor, targetSchema, always, strictGroups, groups) {
      var e_1, _a;
      var includeMetadataBecauseOfAlwaysOption = function(metadata) {
        if (typeof metadata.always !== "undefined")
          return metadata.always;
        if (metadata.groups && metadata.groups.length)
          return false;
        return always;
      };
      var excludeMetadataBecauseOfStrictGroupsOption = function(metadata) {
        if (strictGroups) {
          if (!groups || !groups.length) {
            if (metadata.groups && metadata.groups.length)
              return true;
          }
        }
        return false;
      };
      var filteredForOriginalMetadatasSearch = this.validationMetadatas.get(targetConstructor) || [];
      var originalMetadatas = filteredForOriginalMetadatasSearch.filter(function(metadata) {
        if (metadata.target !== targetConstructor && metadata.target !== targetSchema)
          return false;
        if (includeMetadataBecauseOfAlwaysOption(metadata))
          return true;
        if (excludeMetadataBecauseOfStrictGroupsOption(metadata))
          return false;
        if (groups && groups.length > 0)
          return metadata.groups && !!metadata.groups.find(function(group) {
            return groups.indexOf(group) !== -1;
          });
        return true;
      });
      var filteredForInheritedMetadatasSearch = [];
      try {
        for (var _b = __values(this.validationMetadatas.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
          var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
          if (targetConstructor.prototype instanceof key) {
            filteredForInheritedMetadatasSearch.push.apply(filteredForInheritedMetadatasSearch, __spreadArray([], __read(value), false));
          }
        }
      } catch (e_1_1) {
        e_1 = { error: e_1_1 };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return))
            _a.call(_b);
        } finally {
          if (e_1)
            throw e_1.error;
        }
      }
      var inheritedMetadatas = filteredForInheritedMetadatasSearch.filter(function(metadata) {
        if (typeof metadata.target === "string")
          return false;
        if (metadata.target === targetConstructor)
          return false;
        if (metadata.target instanceof Function && !(targetConstructor.prototype instanceof metadata.target))
          return false;
        if (includeMetadataBecauseOfAlwaysOption(metadata))
          return true;
        if (excludeMetadataBecauseOfStrictGroupsOption(metadata))
          return false;
        if (groups && groups.length > 0)
          return metadata.groups && !!metadata.groups.find(function(group) {
            return groups.indexOf(group) !== -1;
          });
        return true;
      });
      var uniqueInheritedMetadatas = inheritedMetadatas.filter(function(inheritedMetadata) {
        return !originalMetadatas.find(function(originalMetadata) {
          return originalMetadata.propertyName === inheritedMetadata.propertyName && originalMetadata.type === inheritedMetadata.type;
        });
      });
      return originalMetadatas.concat(uniqueInheritedMetadatas);
    };
    MetadataStorage2.prototype.getTargetValidatorConstraints = function(target) {
      return this.constraintMetadatas.get(target) || [];
    };
    return MetadataStorage2;
  }()
);
function getMetadataStorage() {
  var global2 = getGlobal();
  if (!global2.classValidatorMetadataStorage) {
    global2.classValidatorMetadataStorage = new MetadataStorage();
  }
  return global2.classValidatorMetadataStorage;
}

// ../../../node_modules/class-validator/esm5/validation/ValidationTypes.js
var ValidationTypes = (
  /** @class */
  function() {
    function ValidationTypes2() {
    }
    ValidationTypes2.isValid = function(type) {
      var _this = this;
      return type !== "isValid" && type !== "getMessage" && Object.keys(this).map(function(key) {
        return _this[key];
      }).indexOf(type) !== -1;
    };
    ValidationTypes2.CUSTOM_VALIDATION = "customValidation";
    ValidationTypes2.NESTED_VALIDATION = "nestedValidation";
    ValidationTypes2.PROMISE_VALIDATION = "promiseValidation";
    ValidationTypes2.CONDITIONAL_VALIDATION = "conditionalValidation";
    ValidationTypes2.WHITELIST = "whitelistValidation";
    ValidationTypes2.IS_DEFINED = "isDefined";
    return ValidationTypes2;
  }()
);

// ../../../node_modules/class-validator/esm5/container.js
var defaultContainer = new /** @class */
(function() {
  function class_1() {
    this.instances = [];
  }
  class_1.prototype.get = function(someClass) {
    var instance = this.instances.find(function(instance2) {
      return instance2.type === someClass;
    });
    if (!instance) {
      instance = { type: someClass, object: new someClass() };
      this.instances.push(instance);
    }
    return instance.object;
  };
  return class_1;
}())();
var userContainer;
var userContainerOptions;
function getFromContainer(someClass) {
  if (userContainer) {
    try {
      var instance = userContainer.get(someClass);
      if (instance)
        return instance;
      if (!userContainerOptions || !userContainerOptions.fallback)
        return instance;
    } catch (error) {
      if (!userContainerOptions || !userContainerOptions.fallbackOnErrors)
        throw error;
    }
  }
  return defaultContainer.get(someClass);
}

// ../../../node_modules/class-validator/esm5/metadata/ConstraintMetadata.js
var ConstraintMetadata = (
  /** @class */
  function() {
    function ConstraintMetadata2(target, name, async) {
      if (async === void 0) {
        async = false;
      }
      this.target = target;
      this.name = name;
      this.async = async;
    }
    Object.defineProperty(ConstraintMetadata2.prototype, "instance", {
      // -------------------------------------------------------------------------
      // Accessors
      // -------------------------------------------------------------------------
      /**
       * Instance of the target custom validation class which performs validation.
       */
      get: function() {
        return getFromContainer(this.target);
      },
      enumerable: false,
      configurable: true
    });
    return ConstraintMetadata2;
  }()
);

// ../../../node_modules/class-validator/esm5/register-decorator.js
function registerDecorator(options) {
  var constraintCls;
  if (options.validator instanceof Function) {
    constraintCls = options.validator;
    var constraintClasses = getFromContainer(MetadataStorage).getTargetValidatorConstraints(options.validator);
    if (constraintClasses.length > 1) {
      throw "More than one implementation of ValidatorConstraintInterface found for validator on: ".concat(options.target.name, ":").concat(options.propertyName);
    }
  } else {
    var validator_1 = options.validator;
    constraintCls = /** @class */
    function() {
      function CustomConstraint() {
      }
      CustomConstraint.prototype.validate = function(value, validationArguments) {
        return validator_1.validate(value, validationArguments);
      };
      CustomConstraint.prototype.defaultMessage = function(validationArguments) {
        if (validator_1.defaultMessage) {
          return validator_1.defaultMessage(validationArguments);
        }
        return "";
      };
      return CustomConstraint;
    }();
    getMetadataStorage().addConstraintMetadata(new ConstraintMetadata(constraintCls, options.name, options.async));
  }
  var validationMetadataArgs = {
    type: options.name && ValidationTypes.isValid(options.name) ? options.name : ValidationTypes.CUSTOM_VALIDATION,
    name: options.name,
    target: options.target,
    propertyName: options.propertyName,
    validationOptions: options.options,
    constraintCls,
    constraints: options.constraints
  };
  getMetadataStorage().addValidationMetadata(new ValidationMetadata(validationMetadataArgs));
}

// ../../../node_modules/class-validator/esm5/decorator/common/ValidateBy.js
function buildMessage(impl, validationOptions) {
  return function(validationArguments) {
    var eachPrefix = validationOptions && validationOptions.each ? "each value in " : "";
    return impl(eachPrefix, validationArguments);
  };
}
function ValidateBy(options, validationOptions) {
  return function(object, propertyName) {
    registerDecorator({
      name: options.name,
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: options.constraints,
      validator: options.validator
    });
  };
}

// ../../../node_modules/class-validator/esm5/decorator/common/IsDefined.js
var IS_DEFINED = ValidationTypes.IS_DEFINED;
function isDefined(value) {
  return value !== void 0 && value !== null;
}
function IsDefined(validationOptions) {
  return ValidateBy({
    name: IS_DEFINED,
    validator: {
      validate: function(value) {
        return isDefined(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property should not be null or undefined";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/common/IsOptional.js
function IsOptional(validationOptions) {
  return function(object, propertyName) {
    var args = {
      type: ValidationTypes.CONDITIONAL_VALIDATION,
      target: object.constructor,
      propertyName,
      constraints: [
        function(object2, value) {
          return object2[propertyName] !== null && object2[propertyName] !== void 0;
        }
      ],
      validationOptions
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}

// ../../../node_modules/class-validator/esm5/decorator/common/Validate.js
function ValidatorConstraint(options) {
  return function(target) {
    var isAsync2 = options && options.async;
    var name = options && options.name ? options.name : "";
    if (!name) {
      name = target.name;
      if (!name)
        name = name.replace(/\.?([A-Z]+)/g, function(x, y) {
          return "_" + y.toLowerCase();
        }).replace(/^_/, "");
    }
    var metadata = new ConstraintMetadata(target, name, isAsync2);
    getMetadataStorage().addConstraintMetadata(metadata);
  };
}
function Validate(constraintClass, constraintsOrValidationOptions, maybeValidationOptions) {
  return function(object, propertyName) {
    var args = {
      type: ValidationTypes.CUSTOM_VALIDATION,
      target: object.constructor,
      propertyName,
      constraintCls: constraintClass,
      constraints: Array.isArray(constraintsOrValidationOptions) ? constraintsOrValidationOptions : void 0,
      validationOptions: !Array.isArray(constraintsOrValidationOptions) ? constraintsOrValidationOptions : maybeValidationOptions
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}

// ../../../node_modules/class-validator/esm5/decorator/common/ValidateIf.js
function ValidateIf(condition, validationOptions) {
  return function(object, propertyName) {
    var args = {
      type: ValidationTypes.CONDITIONAL_VALIDATION,
      target: object.constructor,
      propertyName,
      constraints: [condition],
      validationOptions
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}

// ../../../node_modules/class-validator/esm5/decorator/common/ValidateNested.js
var __assign = function() {
  __assign = Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p))
          t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
function ValidateNested(validationOptions) {
  var opts = __assign({}, validationOptions);
  var eachPrefix = opts.each ? "each value in " : "";
  opts.message = opts.message || eachPrefix + "nested property $property must be either object or array";
  return function(object, propertyName) {
    var args = {
      type: ValidationTypes.NESTED_VALIDATION,
      target: object.constructor,
      propertyName,
      validationOptions: opts
    };
    getMetadataStorage().addValidationMetadata(new ValidationMetadata(args));
  };
}

// ../../../node_modules/class-validator/esm5/decorator/common/IsNotEmpty.js
var IS_NOT_EMPTY = "isNotEmpty";
function isNotEmpty(value) {
  return value !== "" && value !== null && value !== void 0;
}
function IsNotEmpty(validationOptions) {
  return ValidateBy({
    name: IS_NOT_EMPTY,
    validator: {
      validate: function(value, args) {
        return isNotEmpty(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property should not be empty";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/common/IsIn.js
var IS_IN = "isIn";
function isIn(value, possibleValues) {
  return !Array.isArray(possibleValues) || possibleValues.some(function(possibleValue) {
    return possibleValue === value;
  });
}
function IsIn(values, validationOptions) {
  return ValidateBy({
    name: IS_IN,
    constraints: [values],
    validator: {
      validate: function(value, args) {
        return isIn(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be one of the following values: $constraint1";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/number/Max.js
var MAX = "max";
function max(num, max2) {
  return typeof num === "number" && typeof max2 === "number" && num <= max2;
}
function Max(maxValue, validationOptions) {
  return ValidateBy({
    name: MAX,
    constraints: [maxValue],
    validator: {
      validate: function(value, args) {
        return max(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must not be greater than $constraint1";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/number/Min.js
var MIN = "min";
function min(num, min2) {
  return typeof num === "number" && typeof min2 === "number" && num >= min2;
}
function Min(minValue, validationOptions) {
  return ValidateBy({
    name: MIN,
    constraints: [minValue],
    validator: {
      validate: function(value, args) {
        return min(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must not be less than $constraint1";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsAlphanumeric.js
var import_isAlphanumeric = __toESM(require_isAlphanumeric());
var IS_ALPHANUMERIC = "isAlphanumeric";
function isAlphanumeric(value, locale) {
  return typeof value === "string" && (0, import_isAlphanumeric.default)(value, locale);
}
function IsAlphanumeric(locale, validationOptions) {
  return ValidateBy({
    name: IS_ALPHANUMERIC,
    constraints: [locale],
    validator: {
      validate: function(value, args) {
        return isAlphanumeric(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must contain only letters and numbers";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsIP.js
var import_isIP = __toESM(require_isIP());
var IS_IP = "isIp";
function isIP(value, version) {
  var versionStr = version ? "".concat(version) : void 0;
  return typeof value === "string" && (0, import_isIP.default)(value, versionStr);
}
function IsIP(version, validationOptions) {
  return ValidateBy({
    name: IS_IP,
    constraints: [version],
    validator: {
      validate: function(value, args) {
        return isIP(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be an ip address";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsISO8601.js
var import_isISO8601 = __toESM(require_isISO8601());
function isISO8601(value, options) {
  return typeof value === "string" && (0, import_isISO8601.default)(value, options);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsJSON.js
var import_isJSON = __toESM(require_isJSON());
var IS_JSON = "isJson";
function isJSON(value) {
  return typeof value === "string" && (0, import_isJSON.default)(value);
}
function IsJSON(validationOptions) {
  return ValidateBy({
    name: IS_JSON,
    validator: {
      validate: function(value, args) {
        return isJSON(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a json string";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/MaxLength.js
var import_isLength = __toESM(require_isLength());
var MAX_LENGTH = "maxLength";
function maxLength(value, max2) {
  return typeof value === "string" && (0, import_isLength.default)(value, { min: 0, max: max2 });
}
function MaxLength(max2, validationOptions) {
  return ValidateBy({
    name: MAX_LENGTH,
    constraints: [max2],
    validator: {
      validate: function(value, args) {
        return maxLength(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be shorter than or equal to $constraint1 characters";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsDateString.js
var IS_DATE_STRING = "isDateString";
function isDateString(value, options) {
  return isISO8601(value, options);
}
function IsDateString(options, validationOptions) {
  return ValidateBy({
    name: IS_DATE_STRING,
    constraints: [options],
    validator: {
      validate: function(value) {
        return isDateString(value, options);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a valid ISO 8601 date string";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/string/IsNumberString.js
var import_isNumeric = __toESM(require_isNumeric());
var IS_NUMBER_STRING = "isNumberString";
function isNumberString(value, options) {
  return typeof value === "string" && (0, import_isNumeric.default)(value, options);
}
function IsNumberString(options, validationOptions) {
  return ValidateBy({
    name: IS_NUMBER_STRING,
    constraints: [options],
    validator: {
      validate: function(value, args) {
        return isNumberString(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a number string";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsBoolean.js
var IS_BOOLEAN = "isBoolean";
function isBoolean(value) {
  return value instanceof Boolean || typeof value === "boolean";
}
function IsBoolean(validationOptions) {
  return ValidateBy({
    name: IS_BOOLEAN,
    validator: {
      validate: function(value, args) {
        return isBoolean(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a boolean value";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsNumber.js
var IS_NUMBER = "isNumber";
function isNumber(value, options) {
  if (options === void 0) {
    options = {};
  }
  if (typeof value !== "number") {
    return false;
  }
  if (value === Infinity || value === -Infinity) {
    return !!options.allowInfinity;
  }
  if (Number.isNaN(value)) {
    return !!options.allowNaN;
  }
  if (options.maxDecimalPlaces !== void 0) {
    var decimalPlaces = 0;
    if (value % 1 !== 0) {
      decimalPlaces = value.toString().split(".")[1].length;
    }
    if (decimalPlaces > options.maxDecimalPlaces) {
      return false;
    }
  }
  return Number.isFinite(value);
}
function IsNumber(options, validationOptions) {
  if (options === void 0) {
    options = {};
  }
  return ValidateBy({
    name: IS_NUMBER,
    constraints: [options],
    validator: {
      validate: function(value, args) {
        return isNumber(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a number conforming to the specified constraints";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsEnum.js
var __read2 = function(o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m)
    return o;
  var i = m.call(o), r, ar = [], e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
      ar.push(r.value);
  } catch (error) {
    e = { error };
  } finally {
    try {
      if (r && !r.done && (m = i["return"]))
        m.call(i);
    } finally {
      if (e)
        throw e.error;
    }
  }
  return ar;
};
var IS_ENUM = "isEnum";
function isEnum(value, entity) {
  var enumValues = Object.keys(entity).map(function(k) {
    return entity[k];
  });
  return enumValues.includes(value);
}
function validEnumValues(entity) {
  return Object.entries(entity).filter(function(_a) {
    var _b = __read2(_a, 2), key = _b[0], value = _b[1];
    return isNaN(parseInt(key));
  }).map(function(_a) {
    var _b = __read2(_a, 2), key = _b[0], value = _b[1];
    return value;
  });
}
function IsEnum(entity, validationOptions) {
  return ValidateBy({
    name: IS_ENUM,
    constraints: [entity, validEnumValues(entity)],
    validator: {
      validate: function(value, args) {
        return isEnum(value, args === null || args === void 0 ? void 0 : args.constraints[0]);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be one of the following values: $constraint2";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsInt.js
var IS_INT = "isInt";
function isInt(val) {
  return typeof val === "number" && Number.isInteger(val);
}
function IsInt(validationOptions) {
  return ValidateBy({
    name: IS_INT,
    validator: {
      validate: function(value, args) {
        return isInt(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be an integer number";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsString.js
var IS_STRING = "isString";
function isString(value) {
  return value instanceof String || typeof value === "string";
}
function IsString(validationOptions) {
  return ValidateBy({
    name: IS_STRING,
    validator: {
      validate: function(value, args) {
        return isString(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be a string";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/typechecker/IsArray.js
var IS_ARRAY = "isArray";
function isArray(value) {
  return Array.isArray(value);
}
function IsArray(validationOptions) {
  return ValidateBy({
    name: IS_ARRAY,
    validator: {
      validate: function(value, args) {
        return isArray(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property must be an array";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/class-validator/esm5/decorator/array/ArrayNotEmpty.js
var ARRAY_NOT_EMPTY = "arrayNotEmpty";
function arrayNotEmpty(array) {
  return Array.isArray(array) && array.length > 0;
}
function ArrayNotEmpty(validationOptions) {
  return ValidateBy({
    name: ARRAY_NOT_EMPTY,
    validator: {
      validate: function(value, args) {
        return arrayNotEmpty(value);
      },
      defaultMessage: buildMessage(function(eachPrefix) {
        return eachPrefix + "$property should not be empty";
      }, validationOptions)
    }
  }, validationOptions);
}

// ../../../node_modules/@earthtoday/contract/dist/index.js
import { ApiProperty as ApiProperty11 } from "@nestjs/swagger";

// ../../../node_modules/@ts-rest/core/index.mjs
var isZodObjectStrict = (obj) => {
  return typeof (obj === null || obj === void 0 ? void 0 : obj.passthrough) === "function";
};
var zodMerge = (objectA, objectB) => {
  if (isZodObjectStrict(objectA)) {
    if (isZodObjectStrict(objectB)) {
      return objectA.merge(objectB);
    }
    return objectA;
  }
  if (isZodObjectStrict(objectB)) {
    return objectB;
  }
  return Object.assign({}, objectA, objectB);
};
var isAppRoute = (obj) => {
  return "method" in obj && "path" in obj;
};
var recursivelyApplyOptions = (router, options) => {
  return Object.fromEntries(Object.entries(router).map(([key, value]) => {
    var _a;
    if (isAppRoute(value)) {
      return [
        key,
        __spreadProps(__spreadValues({}, value), {
          path: (options === null || options === void 0 ? void 0 : options.pathPrefix) ? options.pathPrefix + value.path : value.path,
          headers: zodMerge(options === null || options === void 0 ? void 0 : options.baseHeaders, value.headers),
          strictStatusCodes: (_a = value.strictStatusCodes) !== null && _a !== void 0 ? _a : options === null || options === void 0 ? void 0 : options.strictStatusCodes
        })
      ];
    } else {
      return [key, recursivelyApplyOptions(value, options)];
    }
  }));
};
var ContractPlainTypeRuntimeSymbol = Symbol("ContractPlainType");
var initContract = () => {
  return {
    router: (endpoints, options) => recursivelyApplyOptions(endpoints, options),
    query: (args) => args,
    mutation: (args) => args,
    response: () => ContractPlainTypeRuntimeSymbol,
    body: () => ContractPlainTypeRuntimeSymbol,
    type: () => ContractPlainTypeRuntimeSymbol,
    otherResponse: ({ contentType, body }) => ({
      contentType,
      body
    })
  };
};

// ../../../node_modules/@earthtoday/contract/dist/index.js
import { ApiProperty as ApiProperty12 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty13 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty14 } from "@nestjs/swagger";

// ../../../node_modules/zod/lib/index.mjs
var util;
(function(util2) {
  util2.assertEqual = (val) => val;
  function assertIs(_arg) {
  }
  util2.assertIs = assertIs;
  function assertNever(_x) {
    throw new Error();
  }
  util2.assertNever = assertNever;
  util2.arrayToEnum = (items) => {
    const obj = {};
    for (const item of items) {
      obj[item] = item;
    }
    return obj;
  };
  util2.getValidEnumValues = (obj) => {
    const validKeys = util2.objectKeys(obj).filter((k) => typeof obj[obj[k]] !== "number");
    const filtered = {};
    for (const k of validKeys) {
      filtered[k] = obj[k];
    }
    return util2.objectValues(filtered);
  };
  util2.objectValues = (obj) => {
    return util2.objectKeys(obj).map(function(e) {
      return obj[e];
    });
  };
  util2.objectKeys = typeof Object.keys === "function" ? (obj) => Object.keys(obj) : (object) => {
    const keys = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
  util2.find = (arr, checker) => {
    for (const item of arr) {
      if (checker(item))
        return item;
    }
    return void 0;
  };
  util2.isInteger = typeof Number.isInteger === "function" ? (val) => Number.isInteger(val) : (val) => typeof val === "number" && isFinite(val) && Math.floor(val) === val;
  function joinValues(array, separator = " | ") {
    return array.map((val) => typeof val === "string" ? `'${val}'` : val).join(separator);
  }
  util2.joinValues = joinValues;
  util2.jsonStringifyReplacer = (_, value) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };
})(util || (util = {}));
var objectUtil;
(function(objectUtil2) {
  objectUtil2.mergeShapes = (first, second) => {
    return __spreadValues(__spreadValues({}, first), second);
  };
})(objectUtil || (objectUtil = {}));
var ZodParsedType = util.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]);
var getParsedType = (data) => {
  const t = typeof data;
  switch (t) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return isNaN(data) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "symbol":
      return ZodParsedType.symbol;
    case "object":
      if (Array.isArray(data)) {
        return ZodParsedType.array;
      }
      if (data === null) {
        return ZodParsedType.null;
      }
      if (data.then && typeof data.then === "function" && data.catch && typeof data.catch === "function") {
        return ZodParsedType.promise;
      }
      if (typeof Map !== "undefined" && data instanceof Map) {
        return ZodParsedType.map;
      }
      if (typeof Set !== "undefined" && data instanceof Set) {
        return ZodParsedType.set;
      }
      if (typeof Date !== "undefined" && data instanceof Date) {
        return ZodParsedType.date;
      }
      return ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
};
var ZodIssueCode = util.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]);
var quotelessJson = (obj) => {
  const json = JSON.stringify(obj, null, 2);
  return json.replace(/"([^"]+)":/g, "$1:");
};
var ZodError = class extends Error {
  constructor(issues) {
    super();
    this.issues = [];
    this.addIssue = (sub) => {
      this.issues = [...this.issues, sub];
    };
    this.addIssues = (subs = []) => {
      this.issues = [...this.issues, ...subs];
    };
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
    this.name = "ZodError";
    this.issues = issues;
  }
  get errors() {
    return this.issues;
  }
  format(_mapper) {
    const mapper = _mapper || function(issue) {
      return issue.message;
    };
    const fieldErrors = { _errors: [] };
    const processError = (error) => {
      for (const issue of error.issues) {
        if (issue.code === "invalid_union") {
          issue.unionErrors.map(processError);
        } else if (issue.code === "invalid_return_type") {
          processError(issue.returnTypeError);
        } else if (issue.code === "invalid_arguments") {
          processError(issue.argumentsError);
        } else if (issue.path.length === 0) {
          fieldErrors._errors.push(mapper(issue));
        } else {
          let curr = fieldErrors;
          let i = 0;
          while (i < issue.path.length) {
            const el = issue.path[i];
            const terminal = i === issue.path.length - 1;
            if (!terminal) {
              curr[el] = curr[el] || { _errors: [] };
            } else {
              curr[el] = curr[el] || { _errors: [] };
              curr[el]._errors.push(mapper(issue));
            }
            curr = curr[el];
            i++;
          }
        }
      }
    };
    processError(this);
    return fieldErrors;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, util.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(mapper = (issue) => issue.message) {
    const fieldErrors = {};
    const formErrors = [];
    for (const sub of this.issues) {
      if (sub.path.length > 0) {
        fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
        fieldErrors[sub.path[0]].push(mapper(sub));
      } else {
        formErrors.push(mapper(sub));
      }
    }
    return { formErrors, fieldErrors };
  }
  get formErrors() {
    return this.flatten();
  }
};
ZodError.create = (issues) => {
  const error = new ZodError(issues);
  return error;
};
var errorMap = (issue, _ctx) => {
  let message;
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = "Required";
      } else {
        message = `Expected ${issue.expected}, received ${issue.received}`;
      }
      break;
    case ZodIssueCode.invalid_literal:
      message = `Invalid literal value, expected ${JSON.stringify(issue.expected, util.jsonStringifyReplacer)}`;
      break;
    case ZodIssueCode.unrecognized_keys:
      message = `Unrecognized key(s) in object: ${util.joinValues(issue.keys, ", ")}`;
      break;
    case ZodIssueCode.invalid_union:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_union_discriminator:
      message = `Invalid discriminator value. Expected ${util.joinValues(issue.options)}`;
      break;
    case ZodIssueCode.invalid_enum_value:
      message = `Invalid enum value. Expected ${util.joinValues(issue.options)}, received '${issue.received}'`;
      break;
    case ZodIssueCode.invalid_arguments:
      message = `Invalid function arguments`;
      break;
    case ZodIssueCode.invalid_return_type:
      message = `Invalid function return type`;
      break;
    case ZodIssueCode.invalid_date:
      message = `Invalid date`;
      break;
    case ZodIssueCode.invalid_string:
      if (typeof issue.validation === "object") {
        if ("includes" in issue.validation) {
          message = `Invalid input: must include "${issue.validation.includes}"`;
          if (typeof issue.validation.position === "number") {
            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
          }
        } else if ("startsWith" in issue.validation) {
          message = `Invalid input: must start with "${issue.validation.startsWith}"`;
        } else if ("endsWith" in issue.validation) {
          message = `Invalid input: must end with "${issue.validation.endsWith}"`;
        } else {
          util.assertNever(issue.validation);
        }
      } else if (issue.validation !== "regex") {
        message = `Invalid ${issue.validation}`;
      } else {
        message = "Invalid";
      }
      break;
    case ZodIssueCode.too_small:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${issue.minimum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly equal to ` : issue.inclusive ? `greater than or equal to ` : `greater than `}${new Date(Number(issue.minimum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.too_big:
      if (issue.type === "array")
        message = `Array must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
      else if (issue.type === "string")
        message = `String must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
      else if (issue.type === "number")
        message = `Number must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "bigint")
        message = `BigInt must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
      else if (issue.type === "date")
        message = `Date must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
      else
        message = "Invalid input";
      break;
    case ZodIssueCode.custom:
      message = `Invalid input`;
      break;
    case ZodIssueCode.invalid_intersection_types:
      message = `Intersection results could not be merged`;
      break;
    case ZodIssueCode.not_multiple_of:
      message = `Number must be a multiple of ${issue.multipleOf}`;
      break;
    case ZodIssueCode.not_finite:
      message = "Number must be finite";
      break;
    default:
      message = _ctx.defaultError;
      util.assertNever(issue);
  }
  return { message };
};
var overrideErrorMap = errorMap;
function setErrorMap(map) {
  overrideErrorMap = map;
}
function getErrorMap() {
  return overrideErrorMap;
}
var makeIssue = (params) => {
  const { data, path, errorMaps, issueData } = params;
  const fullPath = [...path, ...issueData.path || []];
  const fullIssue = __spreadProps(__spreadValues({}, issueData), {
    path: fullPath
  });
  let errorMessage = "";
  const maps = errorMaps.filter((m) => !!m).slice().reverse();
  for (const map of maps) {
    errorMessage = map(fullIssue, { data, defaultError: errorMessage }).message;
  }
  return __spreadProps(__spreadValues({}, issueData), {
    path: fullPath,
    message: issueData.message || errorMessage
  });
};
var EMPTY_PATH = [];
function addIssueToContext(ctx, issueData) {
  const issue = makeIssue({
    issueData,
    data: ctx.data,
    path: ctx.path,
    errorMaps: [
      ctx.common.contextualErrorMap,
      ctx.schemaErrorMap,
      getErrorMap(),
      errorMap
      // then global default map
    ].filter((x) => !!x)
  });
  ctx.common.issues.push(issue);
}
var ParseStatus = class _ParseStatus {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    if (this.value === "valid")
      this.value = "dirty";
  }
  abort() {
    if (this.value !== "aborted")
      this.value = "aborted";
  }
  static mergeArray(status, results) {
    const arrayValue = [];
    for (const s of results) {
      if (s.status === "aborted")
        return INVALID;
      if (s.status === "dirty")
        status.dirty();
      arrayValue.push(s.value);
    }
    return { status: status.value, value: arrayValue };
  }
  static mergeObjectAsync(status, pairs) {
    return __async(this, null, function* () {
      const syncPairs = [];
      for (const pair of pairs) {
        syncPairs.push({
          key: yield pair.key,
          value: yield pair.value
        });
      }
      return _ParseStatus.mergeObjectSync(status, syncPairs);
    });
  }
  static mergeObjectSync(status, pairs) {
    const finalObject = {};
    for (const pair of pairs) {
      const { key, value } = pair;
      if (key.status === "aborted")
        return INVALID;
      if (value.status === "aborted")
        return INVALID;
      if (key.status === "dirty")
        status.dirty();
      if (value.status === "dirty")
        status.dirty();
      if (key.value !== "__proto__" && (typeof value.value !== "undefined" || pair.alwaysSet)) {
        finalObject[key.value] = value.value;
      }
    }
    return { status: status.value, value: finalObject };
  }
};
var INVALID = Object.freeze({
  status: "aborted"
});
var DIRTY = (value) => ({ status: "dirty", value });
var OK = (value) => ({ status: "valid", value });
var isAborted = (x) => x.status === "aborted";
var isDirty = (x) => x.status === "dirty";
var isValid = (x) => x.status === "valid";
var isAsync = (x) => typeof Promise !== "undefined" && x instanceof Promise;
var errorUtil;
(function(errorUtil2) {
  errorUtil2.errToObj = (message) => typeof message === "string" ? { message } : message || {};
  errorUtil2.toString = (message) => typeof message === "string" ? message : message === null || message === void 0 ? void 0 : message.message;
})(errorUtil || (errorUtil = {}));
var ParseInputLazyPath = class {
  constructor(parent, value, path, key) {
    this._cachedPath = [];
    this.parent = parent;
    this.data = value;
    this._path = path;
    this._key = key;
  }
  get path() {
    if (!this._cachedPath.length) {
      if (this._key instanceof Array) {
        this._cachedPath.push(...this._path, ...this._key);
      } else {
        this._cachedPath.push(...this._path, this._key);
      }
    }
    return this._cachedPath;
  }
};
var handleResult = (ctx, result) => {
  if (isValid(result)) {
    return { success: true, data: result.value };
  } else {
    if (!ctx.common.issues.length) {
      throw new Error("Validation failed but no issues detected.");
    }
    return {
      success: false,
      get error() {
        if (this._error)
          return this._error;
        const error = new ZodError(ctx.common.issues);
        this._error = error;
        return this._error;
      }
    };
  }
};
function processCreateParams(params) {
  if (!params)
    return {};
  const { errorMap: errorMap2, invalid_type_error, required_error, description } = params;
  if (errorMap2 && (invalid_type_error || required_error)) {
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  }
  if (errorMap2)
    return { errorMap: errorMap2, description };
  const customMap = (iss, ctx) => {
    if (iss.code !== "invalid_type")
      return { message: ctx.defaultError };
    if (typeof ctx.data === "undefined") {
      return { message: required_error !== null && required_error !== void 0 ? required_error : ctx.defaultError };
    }
    return { message: invalid_type_error !== null && invalid_type_error !== void 0 ? invalid_type_error : ctx.defaultError };
  };
  return { errorMap: customMap, description };
}
var ZodType = class {
  constructor(def) {
    this.spa = this.safeParseAsync;
    this._def = def;
    this.parse = this.parse.bind(this);
    this.safeParse = this.safeParse.bind(this);
    this.parseAsync = this.parseAsync.bind(this);
    this.safeParseAsync = this.safeParseAsync.bind(this);
    this.spa = this.spa.bind(this);
    this.refine = this.refine.bind(this);
    this.refinement = this.refinement.bind(this);
    this.superRefine = this.superRefine.bind(this);
    this.optional = this.optional.bind(this);
    this.nullable = this.nullable.bind(this);
    this.nullish = this.nullish.bind(this);
    this.array = this.array.bind(this);
    this.promise = this.promise.bind(this);
    this.or = this.or.bind(this);
    this.and = this.and.bind(this);
    this.transform = this.transform.bind(this);
    this.brand = this.brand.bind(this);
    this.default = this.default.bind(this);
    this.catch = this.catch.bind(this);
    this.describe = this.describe.bind(this);
    this.pipe = this.pipe.bind(this);
    this.readonly = this.readonly.bind(this);
    this.isNullable = this.isNullable.bind(this);
    this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(input) {
    return getParsedType(input.data);
  }
  _getOrReturnCtx(input, ctx) {
    return ctx || {
      common: input.parent.common,
      data: input.data,
      parsedType: getParsedType(input.data),
      schemaErrorMap: this._def.errorMap,
      path: input.path,
      parent: input.parent
    };
  }
  _processInputParams(input) {
    return {
      status: new ParseStatus(),
      ctx: {
        common: input.parent.common,
        data: input.data,
        parsedType: getParsedType(input.data),
        schemaErrorMap: this._def.errorMap,
        path: input.path,
        parent: input.parent
      }
    };
  }
  _parseSync(input) {
    const result = this._parse(input);
    if (isAsync(result)) {
      throw new Error("Synchronous parse encountered promise.");
    }
    return result;
  }
  _parseAsync(input) {
    const result = this._parse(input);
    return Promise.resolve(result);
  }
  parse(data, params) {
    const result = this.safeParse(data, params);
    if (result.success)
      return result.data;
    throw result.error;
  }
  safeParse(data, params) {
    var _a;
    const ctx = {
      common: {
        issues: [],
        async: (_a = params === null || params === void 0 ? void 0 : params.async) !== null && _a !== void 0 ? _a : false,
        contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap
      },
      path: (params === null || params === void 0 ? void 0 : params.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data,
      parsedType: getParsedType(data)
    };
    const result = this._parseSync({ data, path: ctx.path, parent: ctx });
    return handleResult(ctx, result);
  }
  parseAsync(data, params) {
    return __async(this, null, function* () {
      const result = yield this.safeParseAsync(data, params);
      if (result.success)
        return result.data;
      throw result.error;
    });
  }
  safeParseAsync(data, params) {
    return __async(this, null, function* () {
      const ctx = {
        common: {
          issues: [],
          contextualErrorMap: params === null || params === void 0 ? void 0 : params.errorMap,
          async: true
        },
        path: (params === null || params === void 0 ? void 0 : params.path) || [],
        schemaErrorMap: this._def.errorMap,
        parent: null,
        data,
        parsedType: getParsedType(data)
      };
      const maybeAsyncResult = this._parse({ data, path: ctx.path, parent: ctx });
      const result = yield isAsync(maybeAsyncResult) ? maybeAsyncResult : Promise.resolve(maybeAsyncResult);
      return handleResult(ctx, result);
    });
  }
  refine(check, message) {
    const getIssueProperties = (val) => {
      if (typeof message === "string" || typeof message === "undefined") {
        return { message };
      } else if (typeof message === "function") {
        return message(val);
      } else {
        return message;
      }
    };
    return this._refinement((val, ctx) => {
      const result = check(val);
      const setError = () => ctx.addIssue(__spreadValues({
        code: ZodIssueCode.custom
      }, getIssueProperties(val)));
      if (typeof Promise !== "undefined" && result instanceof Promise) {
        return result.then((data) => {
          if (!data) {
            setError();
            return false;
          } else {
            return true;
          }
        });
      }
      if (!result) {
        setError();
        return false;
      } else {
        return true;
      }
    });
  }
  refinement(check, refinementData) {
    return this._refinement((val, ctx) => {
      if (!check(val)) {
        ctx.addIssue(typeof refinementData === "function" ? refinementData(val, ctx) : refinementData);
        return false;
      } else {
        return true;
      }
    });
  }
  _refinement(refinement) {
    return new ZodEffects({
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "refinement", refinement }
    });
  }
  superRefine(refinement) {
    return this._refinement(refinement);
  }
  optional() {
    return ZodOptional.create(this, this._def);
  }
  nullable() {
    return ZodNullable.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return ZodArray.create(this, this._def);
  }
  promise() {
    return ZodPromise.create(this, this._def);
  }
  or(option) {
    return ZodUnion.create([this, option], this._def);
  }
  and(incoming) {
    return ZodIntersection.create(this, incoming, this._def);
  }
  transform(transform) {
    return new ZodEffects(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      schema: this,
      typeName: ZodFirstPartyTypeKind.ZodEffects,
      effect: { type: "transform", transform }
    }));
  }
  default(def) {
    const defaultValueFunc = typeof def === "function" ? def : () => def;
    return new ZodDefault(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      defaultValue: defaultValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodDefault
    }));
  }
  brand() {
    return new ZodBranded(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodBranded,
      type: this
    }, processCreateParams(this._def)));
  }
  catch(def) {
    const catchValueFunc = typeof def === "function" ? def : () => def;
    return new ZodCatch(__spreadProps(__spreadValues({}, processCreateParams(this._def)), {
      innerType: this,
      catchValue: catchValueFunc,
      typeName: ZodFirstPartyTypeKind.ZodCatch
    }));
  }
  describe(description) {
    const This = this.constructor;
    return new This(__spreadProps(__spreadValues({}, this._def), {
      description
    }));
  }
  pipe(target) {
    return ZodPipeline.create(this, target);
  }
  readonly() {
    return ZodReadonly.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
};
var cuidRegex = /^c[^\s-]{8,}$/i;
var cuid2Regex = /^[a-z][a-z0-9]*$/;
var ulidRegex = /[0-9A-HJKMNP-TV-Z]{26}/;
var uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
var emailRegex = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i;
var emojiRegex = new RegExp("^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$", "u");
var ipv4Regex = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/;
var ipv6Regex = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/;
var datetimeRegex = (args) => {
  if (args.precision) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${args.precision}}Z$`);
    }
  } else if (args.precision === 0) {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$`);
    }
  } else {
    if (args.offset) {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$`);
    } else {
      return new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$`);
    }
  }
};
function isValidIP(ip, version) {
  if ((version === "v4" || !version) && ipv4Regex.test(ip)) {
    return true;
  }
  if ((version === "v6" || !version) && ipv6Regex.test(ip)) {
    return true;
  }
  return false;
}
var ZodString = class _ZodString extends ZodType {
  constructor() {
    super(...arguments);
    this._regex = (regex, validation, message) => this.refinement((data) => regex.test(data), __spreadValues({
      validation,
      code: ZodIssueCode.invalid_string
    }, errorUtil.errToObj(message)));
    this.nonempty = (message) => this.min(1, errorUtil.errToObj(message));
    this.trim = () => new _ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "trim" }]
    }));
    this.toLowerCase = () => new _ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    }));
    this.toUpperCase = () => new _ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    }));
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = String(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.string) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(
        ctx2,
        {
          code: ZodIssueCode.invalid_type,
          expected: ZodParsedType.string,
          received: ctx2.parsedType
        }
        //
      );
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.length < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.length > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "string",
            inclusive: true,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "length") {
        const tooBig = input.data.length > check.value;
        const tooSmall = input.data.length < check.value;
        if (tooBig || tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          if (tooBig) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_big,
              maximum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          } else if (tooSmall) {
            addIssueToContext(ctx, {
              code: ZodIssueCode.too_small,
              minimum: check.value,
              type: "string",
              inclusive: true,
              exact: true,
              message: check.message
            });
          }
          status.dirty();
        }
      } else if (check.kind === "email") {
        if (!emailRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "email",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "emoji") {
        if (!emojiRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "emoji",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "uuid") {
        if (!uuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "uuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid") {
        if (!cuidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "cuid2") {
        if (!cuid2Regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "cuid2",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ulid") {
        if (!ulidRegex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ulid",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "url") {
        try {
          new URL(input.data);
        } catch (_a) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "url",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "regex") {
        check.regex.lastIndex = 0;
        const testResult = check.regex.test(input.data);
        if (!testResult) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "regex",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "trim") {
        input.data = input.data.trim();
      } else if (check.kind === "includes") {
        if (!input.data.includes(check.value, check.position)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { includes: check.value, position: check.position },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "toLowerCase") {
        input.data = input.data.toLowerCase();
      } else if (check.kind === "toUpperCase") {
        input.data = input.data.toUpperCase();
      } else if (check.kind === "startsWith") {
        if (!input.data.startsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { startsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "endsWith") {
        if (!input.data.endsWith(check.value)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: { endsWith: check.value },
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "datetime") {
        const regex = datetimeRegex(check);
        if (!regex.test(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_string,
            validation: "datetime",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "ip") {
        if (!isValidIP(input.data, check.version)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            validation: "ip",
            code: ZodIssueCode.invalid_string,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  _addCheck(check) {
    return new _ZodString(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  email(message) {
    return this._addCheck(__spreadValues({ kind: "email" }, errorUtil.errToObj(message)));
  }
  url(message) {
    return this._addCheck(__spreadValues({ kind: "url" }, errorUtil.errToObj(message)));
  }
  emoji(message) {
    return this._addCheck(__spreadValues({ kind: "emoji" }, errorUtil.errToObj(message)));
  }
  uuid(message) {
    return this._addCheck(__spreadValues({ kind: "uuid" }, errorUtil.errToObj(message)));
  }
  cuid(message) {
    return this._addCheck(__spreadValues({ kind: "cuid" }, errorUtil.errToObj(message)));
  }
  cuid2(message) {
    return this._addCheck(__spreadValues({ kind: "cuid2" }, errorUtil.errToObj(message)));
  }
  ulid(message) {
    return this._addCheck(__spreadValues({ kind: "ulid" }, errorUtil.errToObj(message)));
  }
  ip(options) {
    return this._addCheck(__spreadValues({ kind: "ip" }, errorUtil.errToObj(options)));
  }
  datetime(options) {
    var _a;
    if (typeof options === "string") {
      return this._addCheck({
        kind: "datetime",
        precision: null,
        offset: false,
        message: options
      });
    }
    return this._addCheck(__spreadValues({
      kind: "datetime",
      precision: typeof (options === null || options === void 0 ? void 0 : options.precision) === "undefined" ? null : options === null || options === void 0 ? void 0 : options.precision,
      offset: (_a = options === null || options === void 0 ? void 0 : options.offset) !== null && _a !== void 0 ? _a : false
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  regex(regex, message) {
    return this._addCheck(__spreadValues({
      kind: "regex",
      regex
    }, errorUtil.errToObj(message)));
  }
  includes(value, options) {
    return this._addCheck(__spreadValues({
      kind: "includes",
      value,
      position: options === null || options === void 0 ? void 0 : options.position
    }, errorUtil.errToObj(options === null || options === void 0 ? void 0 : options.message)));
  }
  startsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "startsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  endsWith(value, message) {
    return this._addCheck(__spreadValues({
      kind: "endsWith",
      value
    }, errorUtil.errToObj(message)));
  }
  min(minLength, message) {
    return this._addCheck(__spreadValues({
      kind: "min",
      value: minLength
    }, errorUtil.errToObj(message)));
  }
  max(maxLength2, message) {
    return this._addCheck(__spreadValues({
      kind: "max",
      value: maxLength2
    }, errorUtil.errToObj(message)));
  }
  length(len, message) {
    return this._addCheck(__spreadValues({
      kind: "length",
      value: len
    }, errorUtil.errToObj(message)));
  }
  get isDatetime() {
    return !!this._def.checks.find((ch) => ch.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((ch) => ch.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((ch) => ch.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((ch) => ch.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((ch) => ch.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((ch) => ch.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((ch) => ch.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((ch) => ch.kind === "ip");
  }
  get minLength() {
    let min2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min2 === null || ch.value > min2)
          min2 = ch.value;
      }
    }
    return min2;
  }
  get maxLength() {
    let max2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max2 === null || ch.value < max2)
          max2 = ch.value;
      }
    }
    return max2;
  }
};
ZodString.create = (params) => {
  var _a;
  return new ZodString(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodString,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
function floatSafeRemainder(val, step) {
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return valInt % stepInt / Math.pow(10, decCount);
}
var ZodNumber = class _ZodNumber extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
    this.step = this.multipleOf;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = Number(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.number) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.number,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "int") {
        if (!util.isInteger(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.invalid_type,
            expected: "integer",
            received: "float",
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            minimum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            maximum: check.value,
            type: "number",
            inclusive: check.inclusive,
            exact: false,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (floatSafeRemainder(input.data, check.value) !== 0) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "finite") {
        if (!Number.isFinite(input.data)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_finite,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new _ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new _ZodNumber(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  int(message) {
    return this._addCheck({
      kind: "int",
      message: errorUtil.toString(message)
    });
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  finite(message) {
    return this._addCheck({
      kind: "finite",
      message: errorUtil.toString(message)
    });
  }
  safe(message) {
    return this._addCheck({
      kind: "min",
      inclusive: true,
      value: Number.MIN_SAFE_INTEGER,
      message: errorUtil.toString(message)
    })._addCheck({
      kind: "max",
      inclusive: true,
      value: Number.MAX_SAFE_INTEGER,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min2 === null || ch.value > min2)
          min2 = ch.value;
      }
    }
    return min2;
  }
  get maxValue() {
    let max2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max2 === null || ch.value < max2)
          max2 = ch.value;
      }
    }
    return max2;
  }
  get isInt() {
    return !!this._def.checks.find((ch) => ch.kind === "int" || ch.kind === "multipleOf" && util.isInteger(ch.value));
  }
  get isFinite() {
    let max2 = null, min2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "finite" || ch.kind === "int" || ch.kind === "multipleOf") {
        return true;
      } else if (ch.kind === "min") {
        if (min2 === null || ch.value > min2)
          min2 = ch.value;
      } else if (ch.kind === "max") {
        if (max2 === null || ch.value < max2)
          max2 = ch.value;
      }
    }
    return Number.isFinite(min2) && Number.isFinite(max2);
  }
};
ZodNumber.create = (params) => {
  return new ZodNumber(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodNumber,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodBigInt = class _ZodBigInt extends ZodType {
  constructor() {
    super(...arguments);
    this.min = this.gte;
    this.max = this.lte;
  }
  _parse(input) {
    if (this._def.coerce) {
      input.data = BigInt(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.bigint) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.bigint,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    let ctx = void 0;
    const status = new ParseStatus();
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        const tooSmall = check.inclusive ? input.data < check.value : input.data <= check.value;
        if (tooSmall) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            type: "bigint",
            minimum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        const tooBig = check.inclusive ? input.data > check.value : input.data >= check.value;
        if (tooBig) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            type: "bigint",
            maximum: check.value,
            inclusive: check.inclusive,
            message: check.message
          });
          status.dirty();
        }
      } else if (check.kind === "multipleOf") {
        if (input.data % check.value !== BigInt(0)) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.not_multiple_of,
            multipleOf: check.value,
            message: check.message
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return { status: status.value, value: input.data };
  }
  gte(value, message) {
    return this.setLimit("min", value, true, errorUtil.toString(message));
  }
  gt(value, message) {
    return this.setLimit("min", value, false, errorUtil.toString(message));
  }
  lte(value, message) {
    return this.setLimit("max", value, true, errorUtil.toString(message));
  }
  lt(value, message) {
    return this.setLimit("max", value, false, errorUtil.toString(message));
  }
  setLimit(kind, value, inclusive, message) {
    return new _ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [
        ...this._def.checks,
        {
          kind,
          value,
          inclusive,
          message: errorUtil.toString(message)
        }
      ]
    }));
  }
  _addCheck(check) {
    return new _ZodBigInt(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  positive(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  negative(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: false,
      message: errorUtil.toString(message)
    });
  }
  nonpositive(message) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  nonnegative(message) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: true,
      message: errorUtil.toString(message)
    });
  }
  multipleOf(value, message) {
    return this._addCheck({
      kind: "multipleOf",
      value,
      message: errorUtil.toString(message)
    });
  }
  get minValue() {
    let min2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min2 === null || ch.value > min2)
          min2 = ch.value;
      }
    }
    return min2;
  }
  get maxValue() {
    let max2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max2 === null || ch.value < max2)
          max2 = ch.value;
      }
    }
    return max2;
  }
};
ZodBigInt.create = (params) => {
  var _a;
  return new ZodBigInt(__spreadValues({
    checks: [],
    typeName: ZodFirstPartyTypeKind.ZodBigInt,
    coerce: (_a = params === null || params === void 0 ? void 0 : params.coerce) !== null && _a !== void 0 ? _a : false
  }, processCreateParams(params)));
};
var ZodBoolean = class extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = Boolean(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.boolean) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.boolean,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodBoolean.create = (params) => {
  return new ZodBoolean(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodBoolean,
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false
  }, processCreateParams(params)));
};
var ZodDate = class _ZodDate extends ZodType {
  _parse(input) {
    if (this._def.coerce) {
      input.data = new Date(input.data);
    }
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.date) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.date,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    if (isNaN(input.data.getTime())) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_date
      });
      return INVALID;
    }
    const status = new ParseStatus();
    let ctx = void 0;
    for (const check of this._def.checks) {
      if (check.kind === "min") {
        if (input.data.getTime() < check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_small,
            message: check.message,
            inclusive: true,
            exact: false,
            minimum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else if (check.kind === "max") {
        if (input.data.getTime() > check.value) {
          ctx = this._getOrReturnCtx(input, ctx);
          addIssueToContext(ctx, {
            code: ZodIssueCode.too_big,
            message: check.message,
            inclusive: true,
            exact: false,
            maximum: check.value,
            type: "date"
          });
          status.dirty();
        }
      } else {
        util.assertNever(check);
      }
    }
    return {
      status: status.value,
      value: new Date(input.data.getTime())
    };
  }
  _addCheck(check) {
    return new _ZodDate(__spreadProps(__spreadValues({}, this._def), {
      checks: [...this._def.checks, check]
    }));
  }
  min(minDate, message) {
    return this._addCheck({
      kind: "min",
      value: minDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  max(maxDate, message) {
    return this._addCheck({
      kind: "max",
      value: maxDate.getTime(),
      message: errorUtil.toString(message)
    });
  }
  get minDate() {
    let min2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "min") {
        if (min2 === null || ch.value > min2)
          min2 = ch.value;
      }
    }
    return min2 != null ? new Date(min2) : null;
  }
  get maxDate() {
    let max2 = null;
    for (const ch of this._def.checks) {
      if (ch.kind === "max") {
        if (max2 === null || ch.value < max2)
          max2 = ch.value;
      }
    }
    return max2 != null ? new Date(max2) : null;
  }
};
ZodDate.create = (params) => {
  return new ZodDate(__spreadValues({
    checks: [],
    coerce: (params === null || params === void 0 ? void 0 : params.coerce) || false,
    typeName: ZodFirstPartyTypeKind.ZodDate
  }, processCreateParams(params)));
};
var ZodSymbol = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.symbol) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.symbol,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodSymbol.create = (params) => {
  return new ZodSymbol(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodSymbol
  }, processCreateParams(params)));
};
var ZodUndefined = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.undefined,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodUndefined.create = (params) => {
  return new ZodUndefined(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUndefined
  }, processCreateParams(params)));
};
var ZodNull = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.null) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.null,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodNull.create = (params) => {
  return new ZodNull(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNull
  }, processCreateParams(params)));
};
var ZodAny = class extends ZodType {
  constructor() {
    super(...arguments);
    this._any = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodAny.create = (params) => {
  return new ZodAny(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodAny
  }, processCreateParams(params)));
};
var ZodUnknown = class extends ZodType {
  constructor() {
    super(...arguments);
    this._unknown = true;
  }
  _parse(input) {
    return OK(input.data);
  }
};
ZodUnknown.create = (params) => {
  return new ZodUnknown(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodUnknown
  }, processCreateParams(params)));
};
var ZodNever = class extends ZodType {
  _parse(input) {
    const ctx = this._getOrReturnCtx(input);
    addIssueToContext(ctx, {
      code: ZodIssueCode.invalid_type,
      expected: ZodParsedType.never,
      received: ctx.parsedType
    });
    return INVALID;
  }
};
ZodNever.create = (params) => {
  return new ZodNever(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNever
  }, processCreateParams(params)));
};
var ZodVoid = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.undefined) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.void,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return OK(input.data);
  }
};
ZodVoid.create = (params) => {
  return new ZodVoid(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodVoid
  }, processCreateParams(params)));
};
var ZodArray = class _ZodArray extends ZodType {
  _parse(input) {
    const { ctx, status } = this._processInputParams(input);
    const def = this._def;
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (def.exactLength !== null) {
      const tooBig = ctx.data.length > def.exactLength.value;
      const tooSmall = ctx.data.length < def.exactLength.value;
      if (tooBig || tooSmall) {
        addIssueToContext(ctx, {
          code: tooBig ? ZodIssueCode.too_big : ZodIssueCode.too_small,
          minimum: tooSmall ? def.exactLength.value : void 0,
          maximum: tooBig ? def.exactLength.value : void 0,
          type: "array",
          inclusive: true,
          exact: true,
          message: def.exactLength.message
        });
        status.dirty();
      }
    }
    if (def.minLength !== null) {
      if (ctx.data.length < def.minLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.minLength.message
        });
        status.dirty();
      }
    }
    if (def.maxLength !== null) {
      if (ctx.data.length > def.maxLength.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxLength.value,
          type: "array",
          inclusive: true,
          exact: false,
          message: def.maxLength.message
        });
        status.dirty();
      }
    }
    if (ctx.common.async) {
      return Promise.all([...ctx.data].map((item, i) => {
        return def.type._parseAsync(new ParseInputLazyPath(ctx, item, ctx.path, i));
      })).then((result2) => {
        return ParseStatus.mergeArray(status, result2);
      });
    }
    const result = [...ctx.data].map((item, i) => {
      return def.type._parseSync(new ParseInputLazyPath(ctx, item, ctx.path, i));
    });
    return ParseStatus.mergeArray(status, result);
  }
  get element() {
    return this._def.type;
  }
  min(minLength, message) {
    return new _ZodArray(__spreadProps(__spreadValues({}, this._def), {
      minLength: { value: minLength, message: errorUtil.toString(message) }
    }));
  }
  max(maxLength2, message) {
    return new _ZodArray(__spreadProps(__spreadValues({}, this._def), {
      maxLength: { value: maxLength2, message: errorUtil.toString(message) }
    }));
  }
  length(len, message) {
    return new _ZodArray(__spreadProps(__spreadValues({}, this._def), {
      exactLength: { value: len, message: errorUtil.toString(message) }
    }));
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodArray.create = (schema, params) => {
  return new ZodArray(__spreadValues({
    type: schema,
    minLength: null,
    maxLength: null,
    exactLength: null,
    typeName: ZodFirstPartyTypeKind.ZodArray
  }, processCreateParams(params)));
};
function deepPartialify(schema) {
  if (schema instanceof ZodObject) {
    const newShape = {};
    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = ZodOptional.create(deepPartialify(fieldSchema));
    }
    return new ZodObject(__spreadProps(__spreadValues({}, schema._def), {
      shape: () => newShape
    }));
  } else if (schema instanceof ZodArray) {
    return new ZodArray(__spreadProps(__spreadValues({}, schema._def), {
      type: deepPartialify(schema.element)
    }));
  } else if (schema instanceof ZodOptional) {
    return ZodOptional.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodNullable) {
    return ZodNullable.create(deepPartialify(schema.unwrap()));
  } else if (schema instanceof ZodTuple) {
    return ZodTuple.create(schema.items.map((item) => deepPartialify(item)));
  } else {
    return schema;
  }
}
var ZodObject = class _ZodObject extends ZodType {
  constructor() {
    super(...arguments);
    this._cached = null;
    this.nonstrict = this.passthrough;
    this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const shape = this._def.shape();
    const keys = util.objectKeys(shape);
    return this._cached = { shape, keys };
  }
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.object) {
      const ctx2 = this._getOrReturnCtx(input);
      addIssueToContext(ctx2, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx2.parsedType
      });
      return INVALID;
    }
    const { status, ctx } = this._processInputParams(input);
    const { shape, keys: shapeKeys } = this._getCached();
    const extraKeys = [];
    if (!(this._def.catchall instanceof ZodNever && this._def.unknownKeys === "strip")) {
      for (const key in ctx.data) {
        if (!shapeKeys.includes(key)) {
          extraKeys.push(key);
        }
      }
    }
    const pairs = [];
    for (const key of shapeKeys) {
      const keyValidator = shape[key];
      const value = ctx.data[key];
      pairs.push({
        key: { status: "valid", value: key },
        value: keyValidator._parse(new ParseInputLazyPath(ctx, value, ctx.path, key)),
        alwaysSet: key in ctx.data
      });
    }
    if (this._def.catchall instanceof ZodNever) {
      const unknownKeys = this._def.unknownKeys;
      if (unknownKeys === "passthrough") {
        for (const key of extraKeys) {
          pairs.push({
            key: { status: "valid", value: key },
            value: { status: "valid", value: ctx.data[key] }
          });
        }
      } else if (unknownKeys === "strict") {
        if (extraKeys.length > 0) {
          addIssueToContext(ctx, {
            code: ZodIssueCode.unrecognized_keys,
            keys: extraKeys
          });
          status.dirty();
        }
      } else if (unknownKeys === "strip")
        ;
      else {
        throw new Error(`Internal ZodObject error: invalid unknownKeys value.`);
      }
    } else {
      const catchall = this._def.catchall;
      for (const key of extraKeys) {
        const value = ctx.data[key];
        pairs.push({
          key: { status: "valid", value: key },
          value: catchall._parse(
            new ParseInputLazyPath(ctx, value, ctx.path, key)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: key in ctx.data
        });
      }
    }
    if (ctx.common.async) {
      return Promise.resolve().then(() => __async(this, null, function* () {
        const syncPairs = [];
        for (const pair of pairs) {
          const key = yield pair.key;
          syncPairs.push({
            key,
            value: yield pair.value,
            alwaysSet: pair.alwaysSet
          });
        }
        return syncPairs;
      })).then((syncPairs) => {
        return ParseStatus.mergeObjectSync(status, syncPairs);
      });
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get shape() {
    return this._def.shape();
  }
  strict(message) {
    errorUtil.errToObj;
    return new _ZodObject(__spreadValues(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strict"
    }), message !== void 0 ? {
      errorMap: (issue, ctx) => {
        var _a, _b, _c, _d;
        const defaultError = (_c = (_b = (_a = this._def).errorMap) === null || _b === void 0 ? void 0 : _b.call(_a, issue, ctx).message) !== null && _c !== void 0 ? _c : ctx.defaultError;
        if (issue.code === "unrecognized_keys")
          return {
            message: (_d = errorUtil.errToObj(message).message) !== null && _d !== void 0 ? _d : defaultError
          };
        return {
          message: defaultError
        };
      }
    } : {}));
  }
  strip() {
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "strip"
    }));
  }
  passthrough() {
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      unknownKeys: "passthrough"
    }));
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(augmentation) {
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), augmentation)
    }));
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(merging) {
    const merged = new _ZodObject({
      unknownKeys: merging._def.unknownKeys,
      catchall: merging._def.catchall,
      shape: () => __spreadValues(__spreadValues({}, this._def.shape()), merging._def.shape()),
      typeName: ZodFirstPartyTypeKind.ZodObject
    });
    return merged;
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(key, schema) {
    return this.augment({ [key]: schema });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(index) {
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      catchall: index
    }));
  }
  pick(mask) {
    const shape = {};
    util.objectKeys(mask).forEach((key) => {
      if (mask[key] && this.shape[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  omit(mask) {
    const shape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (!mask[key]) {
        shape[key] = this.shape[key];
      }
    });
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => shape
    }));
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return deepPartialify(this);
  }
  partial(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      const fieldSchema = this.shape[key];
      if (mask && !mask[key]) {
        newShape[key] = fieldSchema;
      } else {
        newShape[key] = fieldSchema.optional();
      }
    });
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  required(mask) {
    const newShape = {};
    util.objectKeys(this.shape).forEach((key) => {
      if (mask && !mask[key]) {
        newShape[key] = this.shape[key];
      } else {
        const fieldSchema = this.shape[key];
        let newField = fieldSchema;
        while (newField instanceof ZodOptional) {
          newField = newField._def.innerType;
        }
        newShape[key] = newField;
      }
    });
    return new _ZodObject(__spreadProps(__spreadValues({}, this._def), {
      shape: () => newShape
    }));
  }
  keyof() {
    return createZodEnum(util.objectKeys(this.shape));
  }
};
ZodObject.create = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.strictCreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape: () => shape,
    unknownKeys: "strict",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
ZodObject.lazycreate = (shape, params) => {
  return new ZodObject(__spreadValues({
    shape,
    unknownKeys: "strip",
    catchall: ZodNever.create(),
    typeName: ZodFirstPartyTypeKind.ZodObject
  }, processCreateParams(params)));
};
var ZodUnion = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const options = this._def.options;
    function handleResults(results) {
      for (const result of results) {
        if (result.result.status === "valid") {
          return result.result;
        }
      }
      for (const result of results) {
        if (result.result.status === "dirty") {
          ctx.common.issues.push(...result.ctx.common.issues);
          return result.result;
        }
      }
      const unionErrors = results.map((result) => new ZodError(result.ctx.common.issues));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return Promise.all(options.map((option) => __async(this, null, function* () {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        return {
          result: yield option._parseAsync({
            data: ctx.data,
            path: ctx.path,
            parent: childCtx
          }),
          ctx: childCtx
        };
      }))).then(handleResults);
    } else {
      let dirty = void 0;
      const issues = [];
      for (const option of options) {
        const childCtx = __spreadProps(__spreadValues({}, ctx), {
          common: __spreadProps(__spreadValues({}, ctx.common), {
            issues: []
          }),
          parent: null
        });
        const result = option._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: childCtx
        });
        if (result.status === "valid") {
          return result;
        } else if (result.status === "dirty" && !dirty) {
          dirty = { result, ctx: childCtx };
        }
        if (childCtx.common.issues.length) {
          issues.push(childCtx.common.issues);
        }
      }
      if (dirty) {
        ctx.common.issues.push(...dirty.ctx.common.issues);
        return dirty.result;
      }
      const unionErrors = issues.map((issues2) => new ZodError(issues2));
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union,
        unionErrors
      });
      return INVALID;
    }
  }
  get options() {
    return this._def.options;
  }
};
ZodUnion.create = (types, params) => {
  return new ZodUnion(__spreadValues({
    options: types,
    typeName: ZodFirstPartyTypeKind.ZodUnion
  }, processCreateParams(params)));
};
var getDiscriminator = (type) => {
  if (type instanceof ZodLazy) {
    return getDiscriminator(type.schema);
  } else if (type instanceof ZodEffects) {
    return getDiscriminator(type.innerType());
  } else if (type instanceof ZodLiteral) {
    return [type.value];
  } else if (type instanceof ZodEnum) {
    return type.options;
  } else if (type instanceof ZodNativeEnum) {
    return Object.keys(type.enum);
  } else if (type instanceof ZodDefault) {
    return getDiscriminator(type._def.innerType);
  } else if (type instanceof ZodUndefined) {
    return [void 0];
  } else if (type instanceof ZodNull) {
    return [null];
  } else {
    return null;
  }
};
var ZodDiscriminatedUnion = class _ZodDiscriminatedUnion extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const discriminator = this.discriminator;
    const discriminatorValue = ctx.data[discriminator];
    const option = this.optionsMap.get(discriminatorValue);
    if (!option) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_union_discriminator,
        options: Array.from(this.optionsMap.keys()),
        path: [discriminator]
      });
      return INVALID;
    }
    if (ctx.common.async) {
      return option._parseAsync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    } else {
      return option._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
    }
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(discriminator, options, params) {
    const optionsMap = /* @__PURE__ */ new Map();
    for (const type of options) {
      const discriminatorValues = getDiscriminator(type.shape[discriminator]);
      if (!discriminatorValues) {
        throw new Error(`A discriminator value for key \`${discriminator}\` could not be extracted from all schema options`);
      }
      for (const value of discriminatorValues) {
        if (optionsMap.has(value)) {
          throw new Error(`Discriminator property ${String(discriminator)} has duplicate value ${String(value)}`);
        }
        optionsMap.set(value, type);
      }
    }
    return new _ZodDiscriminatedUnion(__spreadValues({
      typeName: ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
      discriminator,
      options,
      optionsMap
    }, processCreateParams(params)));
  }
};
function mergeValues(a, b) {
  const aType = getParsedType(a);
  const bType = getParsedType(b);
  if (a === b) {
    return { valid: true, data: a };
  } else if (aType === ZodParsedType.object && bType === ZodParsedType.object) {
    const bKeys = util.objectKeys(b);
    const sharedKeys = util.objectKeys(a).filter((key) => bKeys.indexOf(key) !== -1);
    const newObj = __spreadValues(__spreadValues({}, a), b);
    for (const key of sharedKeys) {
      const sharedValue = mergeValues(a[key], b[key]);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newObj[key] = sharedValue.data;
    }
    return { valid: true, data: newObj };
  } else if (aType === ZodParsedType.array && bType === ZodParsedType.array) {
    if (a.length !== b.length) {
      return { valid: false };
    }
    const newArray = [];
    for (let index = 0; index < a.length; index++) {
      const itemA = a[index];
      const itemB = b[index];
      const sharedValue = mergeValues(itemA, itemB);
      if (!sharedValue.valid) {
        return { valid: false };
      }
      newArray.push(sharedValue.data);
    }
    return { valid: true, data: newArray };
  } else if (aType === ZodParsedType.date && bType === ZodParsedType.date && +a === +b) {
    return { valid: true, data: a };
  } else {
    return { valid: false };
  }
}
var ZodIntersection = class extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const handleParsed = (parsedLeft, parsedRight) => {
      if (isAborted(parsedLeft) || isAborted(parsedRight)) {
        return INVALID;
      }
      const merged = mergeValues(parsedLeft.value, parsedRight.value);
      if (!merged.valid) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.invalid_intersection_types
        });
        return INVALID;
      }
      if (isDirty(parsedLeft) || isDirty(parsedRight)) {
        status.dirty();
      }
      return { status: status.value, value: merged.data };
    };
    if (ctx.common.async) {
      return Promise.all([
        this._def.left._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        }),
        this._def.right._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        })
      ]).then(([left, right]) => handleParsed(left, right));
    } else {
      return handleParsed(this._def.left._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }), this._def.right._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      }));
    }
  }
};
ZodIntersection.create = (left, right, params) => {
  return new ZodIntersection(__spreadValues({
    left,
    right,
    typeName: ZodFirstPartyTypeKind.ZodIntersection
  }, processCreateParams(params)));
};
var ZodTuple = class _ZodTuple extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.array) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.array,
        received: ctx.parsedType
      });
      return INVALID;
    }
    if (ctx.data.length < this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_small,
        minimum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      return INVALID;
    }
    const rest = this._def.rest;
    if (!rest && ctx.data.length > this._def.items.length) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.too_big,
        maximum: this._def.items.length,
        inclusive: true,
        exact: false,
        type: "array"
      });
      status.dirty();
    }
    const items = [...ctx.data].map((item, itemIndex) => {
      const schema = this._def.items[itemIndex] || this._def.rest;
      if (!schema)
        return null;
      return schema._parse(new ParseInputLazyPath(ctx, item, ctx.path, itemIndex));
    }).filter((x) => !!x);
    if (ctx.common.async) {
      return Promise.all(items).then((results) => {
        return ParseStatus.mergeArray(status, results);
      });
    } else {
      return ParseStatus.mergeArray(status, items);
    }
  }
  get items() {
    return this._def.items;
  }
  rest(rest) {
    return new _ZodTuple(__spreadProps(__spreadValues({}, this._def), {
      rest
    }));
  }
};
ZodTuple.create = (schemas, params) => {
  if (!Array.isArray(schemas)) {
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  }
  return new ZodTuple(__spreadValues({
    items: schemas,
    typeName: ZodFirstPartyTypeKind.ZodTuple,
    rest: null
  }, processCreateParams(params)));
};
var ZodRecord = class _ZodRecord extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.object) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.object,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const pairs = [];
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    for (const key in ctx.data) {
      pairs.push({
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, key)),
        value: valueType._parse(new ParseInputLazyPath(ctx, ctx.data[key], ctx.path, key))
      });
    }
    if (ctx.common.async) {
      return ParseStatus.mergeObjectAsync(status, pairs);
    } else {
      return ParseStatus.mergeObjectSync(status, pairs);
    }
  }
  get element() {
    return this._def.valueType;
  }
  static create(first, second, third) {
    if (second instanceof ZodType) {
      return new _ZodRecord(__spreadValues({
        keyType: first,
        valueType: second,
        typeName: ZodFirstPartyTypeKind.ZodRecord
      }, processCreateParams(third)));
    }
    return new _ZodRecord(__spreadValues({
      keyType: ZodString.create(),
      valueType: first,
      typeName: ZodFirstPartyTypeKind.ZodRecord
    }, processCreateParams(second)));
  }
};
var ZodMap = class extends ZodType {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.map) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.map,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const keyType = this._def.keyType;
    const valueType = this._def.valueType;
    const pairs = [...ctx.data.entries()].map(([key, value], index) => {
      return {
        key: keyType._parse(new ParseInputLazyPath(ctx, key, ctx.path, [index, "key"])),
        value: valueType._parse(new ParseInputLazyPath(ctx, value, ctx.path, [index, "value"]))
      };
    });
    if (ctx.common.async) {
      const finalMap = /* @__PURE__ */ new Map();
      return Promise.resolve().then(() => __async(this, null, function* () {
        for (const pair of pairs) {
          const key = yield pair.key;
          const value = yield pair.value;
          if (key.status === "aborted" || value.status === "aborted") {
            return INVALID;
          }
          if (key.status === "dirty" || value.status === "dirty") {
            status.dirty();
          }
          finalMap.set(key.value, value.value);
        }
        return { status: status.value, value: finalMap };
      }));
    } else {
      const finalMap = /* @__PURE__ */ new Map();
      for (const pair of pairs) {
        const key = pair.key;
        const value = pair.value;
        if (key.status === "aborted" || value.status === "aborted") {
          return INVALID;
        }
        if (key.status === "dirty" || value.status === "dirty") {
          status.dirty();
        }
        finalMap.set(key.value, value.value);
      }
      return { status: status.value, value: finalMap };
    }
  }
};
ZodMap.create = (keyType, valueType, params) => {
  return new ZodMap(__spreadValues({
    valueType,
    keyType,
    typeName: ZodFirstPartyTypeKind.ZodMap
  }, processCreateParams(params)));
};
var ZodSet = class _ZodSet extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.set) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.set,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const def = this._def;
    if (def.minSize !== null) {
      if (ctx.data.size < def.minSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_small,
          minimum: def.minSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.minSize.message
        });
        status.dirty();
      }
    }
    if (def.maxSize !== null) {
      if (ctx.data.size > def.maxSize.value) {
        addIssueToContext(ctx, {
          code: ZodIssueCode.too_big,
          maximum: def.maxSize.value,
          type: "set",
          inclusive: true,
          exact: false,
          message: def.maxSize.message
        });
        status.dirty();
      }
    }
    const valueType = this._def.valueType;
    function finalizeSet(elements2) {
      const parsedSet = /* @__PURE__ */ new Set();
      for (const element of elements2) {
        if (element.status === "aborted")
          return INVALID;
        if (element.status === "dirty")
          status.dirty();
        parsedSet.add(element.value);
      }
      return { status: status.value, value: parsedSet };
    }
    const elements = [...ctx.data.values()].map((item, i) => valueType._parse(new ParseInputLazyPath(ctx, item, ctx.path, i)));
    if (ctx.common.async) {
      return Promise.all(elements).then((elements2) => finalizeSet(elements2));
    } else {
      return finalizeSet(elements);
    }
  }
  min(minSize, message) {
    return new _ZodSet(__spreadProps(__spreadValues({}, this._def), {
      minSize: { value: minSize, message: errorUtil.toString(message) }
    }));
  }
  max(maxSize, message) {
    return new _ZodSet(__spreadProps(__spreadValues({}, this._def), {
      maxSize: { value: maxSize, message: errorUtil.toString(message) }
    }));
  }
  size(size, message) {
    return this.min(size, message).max(size, message);
  }
  nonempty(message) {
    return this.min(1, message);
  }
};
ZodSet.create = (valueType, params) => {
  return new ZodSet(__spreadValues({
    valueType,
    minSize: null,
    maxSize: null,
    typeName: ZodFirstPartyTypeKind.ZodSet
  }, processCreateParams(params)));
};
var ZodFunction = class _ZodFunction extends ZodType {
  constructor() {
    super(...arguments);
    this.validate = this.implement;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.function) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.function,
        received: ctx.parsedType
      });
      return INVALID;
    }
    function makeArgsIssue(args, error) {
      return makeIssue({
        data: args,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_arguments,
          argumentsError: error
        }
      });
    }
    function makeReturnsIssue(returns, error) {
      return makeIssue({
        data: returns,
        path: ctx.path,
        errorMaps: [
          ctx.common.contextualErrorMap,
          ctx.schemaErrorMap,
          getErrorMap(),
          errorMap
        ].filter((x) => !!x),
        issueData: {
          code: ZodIssueCode.invalid_return_type,
          returnTypeError: error
        }
      });
    }
    const params = { errorMap: ctx.common.contextualErrorMap };
    const fn = ctx.data;
    if (this._def.returns instanceof ZodPromise) {
      const me = this;
      return OK(function(...args) {
        return __async(this, null, function* () {
          const error = new ZodError([]);
          const parsedArgs = yield me._def.args.parseAsync(args, params).catch((e) => {
            error.addIssue(makeArgsIssue(args, e));
            throw error;
          });
          const result = yield Reflect.apply(fn, this, parsedArgs);
          const parsedReturns = yield me._def.returns._def.type.parseAsync(result, params).catch((e) => {
            error.addIssue(makeReturnsIssue(result, e));
            throw error;
          });
          return parsedReturns;
        });
      });
    } else {
      const me = this;
      return OK(function(...args) {
        const parsedArgs = me._def.args.safeParse(args, params);
        if (!parsedArgs.success) {
          throw new ZodError([makeArgsIssue(args, parsedArgs.error)]);
        }
        const result = Reflect.apply(fn, this, parsedArgs.data);
        const parsedReturns = me._def.returns.safeParse(result, params);
        if (!parsedReturns.success) {
          throw new ZodError([makeReturnsIssue(result, parsedReturns.error)]);
        }
        return parsedReturns.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...items) {
    return new _ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      args: ZodTuple.create(items).rest(ZodUnknown.create())
    }));
  }
  returns(returnType) {
    return new _ZodFunction(__spreadProps(__spreadValues({}, this._def), {
      returns: returnType
    }));
  }
  implement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  strictImplement(func) {
    const validatedFunc = this.parse(func);
    return validatedFunc;
  }
  static create(args, returns, params) {
    return new _ZodFunction(__spreadValues({
      args: args ? args : ZodTuple.create([]).rest(ZodUnknown.create()),
      returns: returns || ZodUnknown.create(),
      typeName: ZodFirstPartyTypeKind.ZodFunction
    }, processCreateParams(params)));
  }
};
var ZodLazy = class extends ZodType {
  get schema() {
    return this._def.getter();
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const lazySchema = this._def.getter();
    return lazySchema._parse({ data: ctx.data, path: ctx.path, parent: ctx });
  }
};
ZodLazy.create = (getter, params) => {
  return new ZodLazy(__spreadValues({
    getter,
    typeName: ZodFirstPartyTypeKind.ZodLazy
  }, processCreateParams(params)));
};
var ZodLiteral = class extends ZodType {
  _parse(input) {
    if (input.data !== this._def.value) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_literal,
        expected: this._def.value
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
  get value() {
    return this._def.value;
  }
};
ZodLiteral.create = (value, params) => {
  return new ZodLiteral(__spreadValues({
    value,
    typeName: ZodFirstPartyTypeKind.ZodLiteral
  }, processCreateParams(params)));
};
function createZodEnum(values, params) {
  return new ZodEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodEnum
  }, processCreateParams(params)));
}
var ZodEnum = class _ZodEnum extends ZodType {
  _parse(input) {
    if (typeof input.data !== "string") {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (this._def.values.indexOf(input.data) === -1) {
      const ctx = this._getOrReturnCtx(input);
      const expectedValues = this._def.values;
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Values() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  get Enum() {
    const enumValues = {};
    for (const val of this._def.values) {
      enumValues[val] = val;
    }
    return enumValues;
  }
  extract(values) {
    return _ZodEnum.create(values);
  }
  exclude(values) {
    return _ZodEnum.create(this.options.filter((opt) => !values.includes(opt)));
  }
};
ZodEnum.create = createZodEnum;
var ZodNativeEnum = class extends ZodType {
  _parse(input) {
    const nativeEnumValues = util.getValidEnumValues(this._def.values);
    const ctx = this._getOrReturnCtx(input);
    if (ctx.parsedType !== ZodParsedType.string && ctx.parsedType !== ZodParsedType.number) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        expected: util.joinValues(expectedValues),
        received: ctx.parsedType,
        code: ZodIssueCode.invalid_type
      });
      return INVALID;
    }
    if (nativeEnumValues.indexOf(input.data) === -1) {
      const expectedValues = util.objectValues(nativeEnumValues);
      addIssueToContext(ctx, {
        received: ctx.data,
        code: ZodIssueCode.invalid_enum_value,
        options: expectedValues
      });
      return INVALID;
    }
    return OK(input.data);
  }
  get enum() {
    return this._def.values;
  }
};
ZodNativeEnum.create = (values, params) => {
  return new ZodNativeEnum(__spreadValues({
    values,
    typeName: ZodFirstPartyTypeKind.ZodNativeEnum
  }, processCreateParams(params)));
};
var ZodPromise = class extends ZodType {
  unwrap() {
    return this._def.type;
  }
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    if (ctx.parsedType !== ZodParsedType.promise && ctx.common.async === false) {
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.promise,
        received: ctx.parsedType
      });
      return INVALID;
    }
    const promisified = ctx.parsedType === ZodParsedType.promise ? ctx.data : Promise.resolve(ctx.data);
    return OK(promisified.then((data) => {
      return this._def.type.parseAsync(data, {
        path: ctx.path,
        errorMap: ctx.common.contextualErrorMap
      });
    }));
  }
};
ZodPromise.create = (schema, params) => {
  return new ZodPromise(__spreadValues({
    type: schema,
    typeName: ZodFirstPartyTypeKind.ZodPromise
  }, processCreateParams(params)));
};
var ZodEffects = class extends ZodType {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === ZodFirstPartyTypeKind.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    const effect = this._def.effect || null;
    const checkCtx = {
      addIssue: (arg) => {
        addIssueToContext(ctx, arg);
        if (arg.fatal) {
          status.abort();
        } else {
          status.dirty();
        }
      },
      get path() {
        return ctx.path;
      }
    };
    checkCtx.addIssue = checkCtx.addIssue.bind(checkCtx);
    if (effect.type === "preprocess") {
      const processed = effect.transform(ctx.data, checkCtx);
      if (ctx.common.issues.length) {
        return {
          status: "dirty",
          value: ctx.data
        };
      }
      if (ctx.common.async) {
        return Promise.resolve(processed).then((processed2) => {
          return this._def.schema._parseAsync({
            data: processed2,
            path: ctx.path,
            parent: ctx
          });
        });
      } else {
        return this._def.schema._parseSync({
          data: processed,
          path: ctx.path,
          parent: ctx
        });
      }
    }
    if (effect.type === "refinement") {
      const executeRefinement = (acc) => {
        const result = effect.refinement(acc, checkCtx);
        if (ctx.common.async) {
          return Promise.resolve(result);
        }
        if (result instanceof Promise) {
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        }
        return acc;
      };
      if (ctx.common.async === false) {
        const inner = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inner.status === "aborted")
          return INVALID;
        if (inner.status === "dirty")
          status.dirty();
        executeRefinement(inner.value);
        return { status: status.value, value: inner.value };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((inner) => {
          if (inner.status === "aborted")
            return INVALID;
          if (inner.status === "dirty")
            status.dirty();
          return executeRefinement(inner.value).then(() => {
            return { status: status.value, value: inner.value };
          });
        });
      }
    }
    if (effect.type === "transform") {
      if (ctx.common.async === false) {
        const base = this._def.schema._parseSync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (!isValid(base))
          return base;
        const result = effect.transform(base.value, checkCtx);
        if (result instanceof Promise) {
          throw new Error(`Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.`);
        }
        return { status: status.value, value: result };
      } else {
        return this._def.schema._parseAsync({ data: ctx.data, path: ctx.path, parent: ctx }).then((base) => {
          if (!isValid(base))
            return base;
          return Promise.resolve(effect.transform(base.value, checkCtx)).then((result) => ({ status: status.value, value: result }));
        });
      }
    }
    util.assertNever(effect);
  }
};
ZodEffects.create = (schema, effect, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    typeName: ZodFirstPartyTypeKind.ZodEffects,
    effect
  }, processCreateParams(params)));
};
ZodEffects.createWithPreprocess = (preprocess, schema, params) => {
  return new ZodEffects(__spreadValues({
    schema,
    effect: { type: "preprocess", transform: preprocess },
    typeName: ZodFirstPartyTypeKind.ZodEffects
  }, processCreateParams(params)));
};
var ZodOptional = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.undefined) {
      return OK(void 0);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodOptional.create = (type, params) => {
  return new ZodOptional(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodOptional
  }, processCreateParams(params)));
};
var ZodNullable = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType === ZodParsedType.null) {
      return OK(null);
    }
    return this._def.innerType._parse(input);
  }
  unwrap() {
    return this._def.innerType;
  }
};
ZodNullable.create = (type, params) => {
  return new ZodNullable(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodNullable
  }, processCreateParams(params)));
};
var ZodDefault = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    let data = ctx.data;
    if (ctx.parsedType === ZodParsedType.undefined) {
      data = this._def.defaultValue();
    }
    return this._def.innerType._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
};
ZodDefault.create = (type, params) => {
  return new ZodDefault(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodDefault,
    defaultValue: typeof params.default === "function" ? params.default : () => params.default
  }, processCreateParams(params)));
};
var ZodCatch = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const newCtx = __spreadProps(__spreadValues({}, ctx), {
      common: __spreadProps(__spreadValues({}, ctx.common), {
        issues: []
      })
    });
    const result = this._def.innerType._parse({
      data: newCtx.data,
      path: newCtx.path,
      parent: __spreadValues({}, newCtx)
    });
    if (isAsync(result)) {
      return result.then((result2) => {
        return {
          status: "valid",
          value: result2.status === "valid" ? result2.value : this._def.catchValue({
            get error() {
              return new ZodError(newCtx.common.issues);
            },
            input: newCtx.data
          })
        };
      });
    } else {
      return {
        status: "valid",
        value: result.status === "valid" ? result.value : this._def.catchValue({
          get error() {
            return new ZodError(newCtx.common.issues);
          },
          input: newCtx.data
        })
      };
    }
  }
  removeCatch() {
    return this._def.innerType;
  }
};
ZodCatch.create = (type, params) => {
  return new ZodCatch(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodCatch,
    catchValue: typeof params.catch === "function" ? params.catch : () => params.catch
  }, processCreateParams(params)));
};
var ZodNaN = class extends ZodType {
  _parse(input) {
    const parsedType = this._getType(input);
    if (parsedType !== ZodParsedType.nan) {
      const ctx = this._getOrReturnCtx(input);
      addIssueToContext(ctx, {
        code: ZodIssueCode.invalid_type,
        expected: ZodParsedType.nan,
        received: ctx.parsedType
      });
      return INVALID;
    }
    return { status: "valid", value: input.data };
  }
};
ZodNaN.create = (params) => {
  return new ZodNaN(__spreadValues({
    typeName: ZodFirstPartyTypeKind.ZodNaN
  }, processCreateParams(params)));
};
var BRAND = Symbol("zod_brand");
var ZodBranded = class extends ZodType {
  _parse(input) {
    const { ctx } = this._processInputParams(input);
    const data = ctx.data;
    return this._def.type._parse({
      data,
      path: ctx.path,
      parent: ctx
    });
  }
  unwrap() {
    return this._def.type;
  }
};
var ZodPipeline = class _ZodPipeline extends ZodType {
  _parse(input) {
    const { status, ctx } = this._processInputParams(input);
    if (ctx.common.async) {
      const handleAsync = () => __async(this, null, function* () {
        const inResult = yield this._def.in._parseAsync({
          data: ctx.data,
          path: ctx.path,
          parent: ctx
        });
        if (inResult.status === "aborted")
          return INVALID;
        if (inResult.status === "dirty") {
          status.dirty();
          return DIRTY(inResult.value);
        } else {
          return this._def.out._parseAsync({
            data: inResult.value,
            path: ctx.path,
            parent: ctx
          });
        }
      });
      return handleAsync();
    } else {
      const inResult = this._def.in._parseSync({
        data: ctx.data,
        path: ctx.path,
        parent: ctx
      });
      if (inResult.status === "aborted")
        return INVALID;
      if (inResult.status === "dirty") {
        status.dirty();
        return {
          status: "dirty",
          value: inResult.value
        };
      } else {
        return this._def.out._parseSync({
          data: inResult.value,
          path: ctx.path,
          parent: ctx
        });
      }
    }
  }
  static create(a, b) {
    return new _ZodPipeline({
      in: a,
      out: b,
      typeName: ZodFirstPartyTypeKind.ZodPipeline
    });
  }
};
var ZodReadonly = class extends ZodType {
  _parse(input) {
    const result = this._def.innerType._parse(input);
    if (isValid(result)) {
      result.value = Object.freeze(result.value);
    }
    return result;
  }
};
ZodReadonly.create = (type, params) => {
  return new ZodReadonly(__spreadValues({
    innerType: type,
    typeName: ZodFirstPartyTypeKind.ZodReadonly
  }, processCreateParams(params)));
};
var custom = (check, params = {}, fatal) => {
  if (check)
    return ZodAny.create().superRefine((data, ctx) => {
      var _a, _b;
      if (!check(data)) {
        const p = typeof params === "function" ? params(data) : typeof params === "string" ? { message: params } : params;
        const _fatal = (_b = (_a = p.fatal) !== null && _a !== void 0 ? _a : fatal) !== null && _b !== void 0 ? _b : true;
        const p2 = typeof p === "string" ? { message: p } : p;
        ctx.addIssue(__spreadProps(__spreadValues({ code: "custom" }, p2), { fatal: _fatal }));
      }
    });
  return ZodAny.create();
};
var late = {
  object: ZodObject.lazycreate
};
var ZodFirstPartyTypeKind;
(function(ZodFirstPartyTypeKind2) {
  ZodFirstPartyTypeKind2["ZodString"] = "ZodString";
  ZodFirstPartyTypeKind2["ZodNumber"] = "ZodNumber";
  ZodFirstPartyTypeKind2["ZodNaN"] = "ZodNaN";
  ZodFirstPartyTypeKind2["ZodBigInt"] = "ZodBigInt";
  ZodFirstPartyTypeKind2["ZodBoolean"] = "ZodBoolean";
  ZodFirstPartyTypeKind2["ZodDate"] = "ZodDate";
  ZodFirstPartyTypeKind2["ZodSymbol"] = "ZodSymbol";
  ZodFirstPartyTypeKind2["ZodUndefined"] = "ZodUndefined";
  ZodFirstPartyTypeKind2["ZodNull"] = "ZodNull";
  ZodFirstPartyTypeKind2["ZodAny"] = "ZodAny";
  ZodFirstPartyTypeKind2["ZodUnknown"] = "ZodUnknown";
  ZodFirstPartyTypeKind2["ZodNever"] = "ZodNever";
  ZodFirstPartyTypeKind2["ZodVoid"] = "ZodVoid";
  ZodFirstPartyTypeKind2["ZodArray"] = "ZodArray";
  ZodFirstPartyTypeKind2["ZodObject"] = "ZodObject";
  ZodFirstPartyTypeKind2["ZodUnion"] = "ZodUnion";
  ZodFirstPartyTypeKind2["ZodDiscriminatedUnion"] = "ZodDiscriminatedUnion";
  ZodFirstPartyTypeKind2["ZodIntersection"] = "ZodIntersection";
  ZodFirstPartyTypeKind2["ZodTuple"] = "ZodTuple";
  ZodFirstPartyTypeKind2["ZodRecord"] = "ZodRecord";
  ZodFirstPartyTypeKind2["ZodMap"] = "ZodMap";
  ZodFirstPartyTypeKind2["ZodSet"] = "ZodSet";
  ZodFirstPartyTypeKind2["ZodFunction"] = "ZodFunction";
  ZodFirstPartyTypeKind2["ZodLazy"] = "ZodLazy";
  ZodFirstPartyTypeKind2["ZodLiteral"] = "ZodLiteral";
  ZodFirstPartyTypeKind2["ZodEnum"] = "ZodEnum";
  ZodFirstPartyTypeKind2["ZodEffects"] = "ZodEffects";
  ZodFirstPartyTypeKind2["ZodNativeEnum"] = "ZodNativeEnum";
  ZodFirstPartyTypeKind2["ZodOptional"] = "ZodOptional";
  ZodFirstPartyTypeKind2["ZodNullable"] = "ZodNullable";
  ZodFirstPartyTypeKind2["ZodDefault"] = "ZodDefault";
  ZodFirstPartyTypeKind2["ZodCatch"] = "ZodCatch";
  ZodFirstPartyTypeKind2["ZodPromise"] = "ZodPromise";
  ZodFirstPartyTypeKind2["ZodBranded"] = "ZodBranded";
  ZodFirstPartyTypeKind2["ZodPipeline"] = "ZodPipeline";
  ZodFirstPartyTypeKind2["ZodReadonly"] = "ZodReadonly";
})(ZodFirstPartyTypeKind || (ZodFirstPartyTypeKind = {}));
var instanceOfType = (cls, params = {
  message: `Input not instance of ${cls.name}`
}) => custom((data) => data instanceof cls, params);
var stringType = ZodString.create;
var numberType = ZodNumber.create;
var nanType = ZodNaN.create;
var bigIntType = ZodBigInt.create;
var booleanType = ZodBoolean.create;
var dateType = ZodDate.create;
var symbolType = ZodSymbol.create;
var undefinedType = ZodUndefined.create;
var nullType = ZodNull.create;
var anyType = ZodAny.create;
var unknownType = ZodUnknown.create;
var neverType = ZodNever.create;
var voidType = ZodVoid.create;
var arrayType = ZodArray.create;
var objectType = ZodObject.create;
var strictObjectType = ZodObject.strictCreate;
var unionType = ZodUnion.create;
var discriminatedUnionType = ZodDiscriminatedUnion.create;
var intersectionType = ZodIntersection.create;
var tupleType = ZodTuple.create;
var recordType = ZodRecord.create;
var mapType = ZodMap.create;
var setType = ZodSet.create;
var functionType = ZodFunction.create;
var lazyType = ZodLazy.create;
var literalType = ZodLiteral.create;
var enumType = ZodEnum.create;
var nativeEnumType = ZodNativeEnum.create;
var promiseType = ZodPromise.create;
var effectsType = ZodEffects.create;
var optionalType = ZodOptional.create;
var nullableType = ZodNullable.create;
var preprocessType = ZodEffects.createWithPreprocess;
var pipelineType = ZodPipeline.create;
var ostring = () => stringType().optional();
var onumber = () => numberType().optional();
var oboolean = () => booleanType().optional();
var coerce = {
  string: (arg) => ZodString.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  number: (arg) => ZodNumber.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  boolean: (arg) => ZodBoolean.create(__spreadProps(__spreadValues({}, arg), {
    coerce: true
  })),
  bigint: (arg) => ZodBigInt.create(__spreadProps(__spreadValues({}, arg), { coerce: true })),
  date: (arg) => ZodDate.create(__spreadProps(__spreadValues({}, arg), { coerce: true }))
};
var NEVER = INVALID;
var z = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: errorMap,
  setErrorMap,
  getErrorMap,
  makeIssue,
  EMPTY_PATH,
  addIssueToContext,
  ParseStatus,
  INVALID,
  DIRTY,
  OK,
  isAborted,
  isDirty,
  isValid,
  isAsync,
  get util() {
    return util;
  },
  get objectUtil() {
    return objectUtil;
  },
  ZodParsedType,
  getParsedType,
  ZodType,
  ZodString,
  ZodNumber,
  ZodBigInt,
  ZodBoolean,
  ZodDate,
  ZodSymbol,
  ZodUndefined,
  ZodNull,
  ZodAny,
  ZodUnknown,
  ZodNever,
  ZodVoid,
  ZodArray,
  ZodObject,
  ZodUnion,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodTuple,
  ZodRecord,
  ZodMap,
  ZodSet,
  ZodFunction,
  ZodLazy,
  ZodLiteral,
  ZodEnum,
  ZodNativeEnum,
  ZodPromise,
  ZodEffects,
  ZodTransformer: ZodEffects,
  ZodOptional,
  ZodNullable,
  ZodDefault,
  ZodCatch,
  ZodNaN,
  BRAND,
  ZodBranded,
  ZodPipeline,
  ZodReadonly,
  custom,
  Schema: ZodType,
  ZodSchema: ZodType,
  late,
  get ZodFirstPartyTypeKind() {
    return ZodFirstPartyTypeKind;
  },
  coerce,
  any: anyType,
  array: arrayType,
  bigint: bigIntType,
  boolean: booleanType,
  date: dateType,
  discriminatedUnion: discriminatedUnionType,
  effect: effectsType,
  "enum": enumType,
  "function": functionType,
  "instanceof": instanceOfType,
  intersection: intersectionType,
  lazy: lazyType,
  literal: literalType,
  map: mapType,
  nan: nanType,
  nativeEnum: nativeEnumType,
  never: neverType,
  "null": nullType,
  nullable: nullableType,
  number: numberType,
  object: objectType,
  oboolean,
  onumber,
  optional: optionalType,
  ostring,
  pipeline: pipelineType,
  preprocess: preprocessType,
  promise: promiseType,
  record: recordType,
  set: setType,
  strictObject: strictObjectType,
  string: stringType,
  symbol: symbolType,
  transformer: effectsType,
  tuple: tupleType,
  "undefined": undefinedType,
  union: unionType,
  unknown: unknownType,
  "void": voidType,
  NEVER,
  ZodIssueCode,
  quotelessJson,
  ZodError
});

// ../../../node_modules/@earthtoday/contract/dist/index.js
import { ApiProperty as ApiProperty15 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty19 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty16 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty17 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty18 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels5, ApiProperty as ApiProperty45, getSchemaPath as getSchemaPath5 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels4, ApiProperty as ApiProperty39, getSchemaPath as getSchemaPath4 } from "@nestjs/swagger";
import { Type as Type3 } from "class-transformer";
import { ApiProperty as ApiProperty20 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty28 } from "@nestjs/swagger";
import { ApiExtraModels, ApiProperty as ApiProperty24, getSchemaPath } from "@nestjs/swagger";
import { ApiProperty as ApiProperty21 } from "@nestjs/swagger";
import { Type as Type2 } from "class-transformer";
import { Type } from "class-transformer";
import { ApiProperty as ApiProperty23 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty22 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty26 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty25 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty27 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty38, getSchemaPath as getSchemaPath3 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels2, ApiProperty as ApiProperty30, getSchemaPath as getSchemaPath2 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty29 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty32 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty31 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty34 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty33 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty35 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty36 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty37 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty41 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty40 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty42 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty43 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty44 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels6, ApiProperty as ApiProperty46, getSchemaPath as getSchemaPath6 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty47, getSchemaPath as getSchemaPath7 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels7, ApiProperty as ApiProperty48, getSchemaPath as getSchemaPath8 } from "@nestjs/swagger";
import { Type as Type4 } from "class-transformer";
import { ApiProperty as ApiProperty50, getSchemaPath as getSchemaPath10 } from "@nestjs/swagger";
import { Type as Type6 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels8, ApiProperty as ApiProperty49, getSchemaPath as getSchemaPath9 } from "@nestjs/swagger";
import { Type as Type5 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels9, ApiProperty as ApiProperty52, getSchemaPath as getSchemaPath11 } from "@nestjs/swagger";
import { Type as Type7 } from "class-transformer";
import { ApiProperty as ApiProperty51 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty64 } from "@nestjs/swagger";
import { Type as Type11 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels11, ApiProperty as ApiProperty63, getSchemaPath as getSchemaPath14 } from "@nestjs/swagger";
import { Type as Type10 } from "class-transformer";
import { ApiProperty as ApiProperty54 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty53 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty55, getSchemaPath as getSchemaPath12 } from "@nestjs/swagger";
import { Type as Type8 } from "class-transformer";
import { ApiProperty as ApiProperty56 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels10, ApiProperty as ApiProperty57, getSchemaPath as getSchemaPath13 } from "@nestjs/swagger";
import { Type as Type9 } from "class-transformer";
import { ApiProperty as ApiProperty58 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty59 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty60 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty61 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty62 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty65 } from "@nestjs/swagger";
import { Type as Type12 } from "class-transformer";
import { ApiProperty as ApiProperty66 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels12, ApiProperty as ApiProperty67, getSchemaPath as getSchemaPath15 } from "@nestjs/swagger";
import { Type as Type13 } from "class-transformer";
import { ApiProperty as ApiProperty68, getSchemaPath as getSchemaPath16 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty69, getSchemaPath as getSchemaPath17 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty70 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty71, getSchemaPath as getSchemaPath18 } from "@nestjs/swagger";
import { Type as Type14 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels13, ApiProperty as ApiProperty72, getSchemaPath as getSchemaPath19 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty73, getSchemaPath as getSchemaPath20 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels14, ApiProperty as ApiProperty74, getSchemaPath as getSchemaPath21 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty75, getSchemaPath as getSchemaPath22 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty76 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty77 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty80 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels15, ApiProperty as ApiProperty79, getSchemaPath as getSchemaPath23 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty78 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty81 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty83 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty82 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels16, ApiProperty as ApiProperty84, getSchemaPath as getSchemaPath24 } from "@nestjs/swagger";
import { Type as Type15 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels17, ApiProperty as ApiProperty85, getSchemaPath as getSchemaPath25 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty86 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty87 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty89 } from "@nestjs/swagger";
import { Type as Type16 } from "class-transformer";
import { ApiProperty as ApiProperty88 } from "@nestjs/swagger";
import { Transform } from "class-transformer";
import { ApiProperty as ApiProperty90 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty91 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty92 } from "@nestjs/swagger";
import { Type as Type17 } from "class-transformer";
import { ApiProperty as ApiProperty93 } from "@nestjs/swagger";
import { Type as Type18 } from "class-transformer";
import { ApiProperty as ApiProperty94 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty95 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty96 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty97 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty98 } from "@nestjs/swagger";
import { Type as Type19 } from "class-transformer";
import { ApiProperty as ApiProperty99 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels18, ApiProperty as ApiProperty100, getSchemaPath as getSchemaPath26 } from "@nestjs/swagger";
import { Type as Type20 } from "class-transformer";
import { ApiProperty as ApiProperty101 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty102 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty103 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty104 } from "@nestjs/swagger";
import { Type as Type21 } from "class-transformer";
import { ApiProperty as ApiProperty105 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty106 } from "@nestjs/swagger";
import { Type as Type22 } from "class-transformer";
import { ApiProperty as ApiProperty109 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels19, ApiProperty as ApiProperty108, getSchemaPath as getSchemaPath28 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty107, getSchemaPath as getSchemaPath27 } from "@nestjs/swagger";
import { Type as Type23 } from "class-transformer";
import { ApiProperty as ApiProperty111, getSchemaPath as getSchemaPath29 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty110 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty112 } from "@nestjs/swagger";
import {
  plainToInstance,
  Transform as Transform2
} from "class-transformer";
import { ApiProperty as ApiProperty114 } from "@nestjs/swagger";
import { Type as Type24 } from "class-transformer";
import { ApiProperty as ApiProperty113 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels20, ApiProperty as ApiProperty115, getSchemaPath as getSchemaPath30 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels21, ApiProperty as ApiProperty116, getSchemaPath as getSchemaPath31 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty117 } from "@nestjs/swagger";
import { Type as Type25 } from "class-transformer";
import { ApiProperty as ApiProperty118 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty119 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty120 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels22, ApiProperty as ApiProperty121, getSchemaPath as getSchemaPath32 } from "@nestjs/swagger";
import { Type as Type26 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels23, ApiProperty as ApiProperty122, getSchemaPath as getSchemaPath33 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty123, getSchemaPath as getSchemaPath34 } from "@nestjs/swagger";
import { ApiExtraModels as ApiExtraModels24, ApiProperty as ApiProperty124, getSchemaPath as getSchemaPath35 } from "@nestjs/swagger";
import { Type as Type27 } from "class-transformer";
import { ApiExtraModels as ApiExtraModels25, ApiProperty as ApiProperty125, getSchemaPath as getSchemaPath36 } from "@nestjs/swagger";
import { Type as Type28 } from "class-transformer";
import { ApiProperty as ApiProperty126 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty127 } from "@nestjs/swagger";
import { ApiProperty as ApiProperty128, getSchemaPath as getSchemaPath37 } from "@nestjs/swagger";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc2 = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc2(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp2(target, key, result);
  return result;
};
var CardStatus = {
  DRAFTING: "DRAFTING",
  PUBLISHED: "PUBLISHED",
  MODERATION: "MODERATION",
  ARCHIVED: "ARCHIVED"
};
var ImageTarget = {
  USER_PROFILE: "USER_PROFILE",
  USER_PROFILE_PROMOTION: "USER_PROFILE_PROMOTION",
  UON_PROMOTION: "UON_PROMOTION",
  USER_COVER: "USER_COVER",
  CAMPAIGN_ASSET: "CAMPAIGN_ASSET",
  RESERVE_IMAGE: "RESERVE_IMAGE",
  LINK: "LINK",
  CAMPAIGN_HEADER: "CAMPAIGN_HEADER",
  CAMPAIGN_PREVIEW_IMAGE: "CAMPAIGN_PREVIEW_IMAGE",
  CARD_ACTION_BACKGROUND: "CARD_ACTION_BACKGROUND",
  CARD_INFO_BACKGROUND: "CARD_INFO_BACKGROUND",
  CARD_INFO_HTML_BUNDLE: "CARD_INFO_HTML_BUNDLE",
  DECK_BACKGROUND: "DECK_BACKGROUND"
};
var CardSuggestionAlgorithm = /* @__PURE__ */ ((CardSuggestionAlgorithm2) => {
  CardSuggestionAlgorithm2["SAME_DECK"] = "SAME_DECK";
  CardSuggestionAlgorithm2["SAME_THEN_REPOSTS_DECK"] = "SAME_THEN_REPOSTS_DECK";
  CardSuggestionAlgorithm2["SAME_DECK_THEN_RANDOM"] = "SAME_DECK_THEN_RANDOM";
  CardSuggestionAlgorithm2["RANDOM"] = "RANDOM";
  CardSuggestionAlgorithm2["REPOSTS_DECKS"] = "REPOSTS_DECKS";
  CardSuggestionAlgorithm2["REPOSTS_THEN_SAME_DECK"] = "REPOSTS_THEN_SAME_DECK";
  CardSuggestionAlgorithm2["SAME_DECK_AND_SAME_CONTENT_TYPE"] = "SAME_DECK_AND_SAME_CONTENT_TYPE";
  return CardSuggestionAlgorithm2;
})(CardSuggestionAlgorithm || {});
var CardType = {
  LINK: "LINK",
  LINK_REPOST: "LINK_REPOST",
  DECK_REPOST: "DECK_REPOST",
  AUTOPLAY: "AUTOPLAY",
  CAMPAIGN: "CAMPAIGN",
  REMOVEDCONTENT: "REMOVEDCONTENT",
  PROFILE_REPOST: "PROFILE_REPOST",
  AUTOPLAY_FOLLOWING_PERSONALIZATION: "AUTOPLAY_FOLLOWING_PERSONALIZATION",
  ACTION: "ACTION",
  INFO: "INFO",
  INFO_REPOST: "INFO_REPOST",
  PROMOTION_REPOST: "PROMOTION_REPOST",
  EDITABLE: "EDITABLE",
  EDITABLE_REPOST: "EDITABLE_REPOST"
};
var CardCreationType = {
  LINK: "LINK",
  LINK_REPOST: "LINK_REPOST",
  DECK_REPOST: "DECK_REPOST",
  AUTOPLAY: "AUTOPLAY",
  PROFILE_REPOST: "PROFILE_REPOST",
  ACTION: "ACTION",
  INFO: "INFO",
  INFO_REPOST: "INFO_REPOST",
  PROMOTION_REPOST_BY_PROMOTION: "PROMOTION_REPOST_BY_PROMOTION",
  PROMOTION_REPOST_BY_SOURCE_CARD: "PROMOTION_REPOST_BY_SOURCE_CARD",
  EDITABLE: "EDITABLE",
  EDITABLE_REPOST: "EDITABLE_REPOST"
};
var CardLayoutType = {
  EDITORIAL: "EDITORIAL",
  PHOTO: "PHOTO",
  TEXT: "TEXT"
};
var PhotoState = {
  SOCIAL: "SOCIAL",
  INITIALS: "INITIALS",
  CUSTOM: "CUSTOM"
};
var UserType = {
  BRAND: "BRAND",
  CONSUMER: "CONSUMER",
  NPO: "NPO",
  CHARITY: "CHARITY",
  UNCLASSIFIED_ORG: "UNCLASSIFIED_ORG",
  GROUP_ADAPTER: "GROUP_ADAPTER"
};
var TextAlignment = {
  LEFT: "LEFT",
  CENTER: "CENTER",
  RIGHT: "RIGHT"
};
var TextColor = {
  WHITE: "WHITE",
  BLACK: "BLACK"
};
var CardSize = {
  SINGLE: "SINGLE",
  DOUBLE: "DOUBLE",
  TRIPLE: "TRIPLE"
};
var PrincipleName = {
  PEOPLE: "PEOPLE",
  CREATION: "CREATION",
  WORLD: "WORLD",
  NATURE: "NATURE",
  KEYS: "KEYS",
  SPIRIT: "SPIRIT",
  JOKER: "JOKER"
};
var GroupMemberRole = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  CURATOR: "CURATOR",
  LEGAL_REP: "LEGAL_REP"
};
var ProfileType = {
  UNCLASSIFIED_ORG: "UNCLASSIFIED_ORG",
  BRAND: "BRAND",
  NPO: "NPO",
  CHARITY: "CHARITY",
  COMMUNITY: "COMMUNITY",
  PARTNER: "PARTNER"
};
var RoleRequest = {
  BRAND: "BRAND",
  NPO: "NPO",
  CHARITY: "CHARITY"
};
var CampaignStatus = {
  STATE1: "STATE1",
  STATE2: "STATE2"
};
var ImageSource = {
  RESERVE_IMAGE: "RESERVE_IMAGE",
  UON_IMAGE: "UON_IMAGE"
};
var TransientPaymentSourceType = {
  PROTECT: "PROTECT",
  CHARITY: "CHARITY",
  CAMPAIGN: "CAMPAIGN",
  CLAIM: "CLAIM",
  GROUP_PUBLISH: "GROUP_PUBLISH",
  NPO: "NPO",
  RESERVE: "RESERVE"
};
var PromotionType = {
  PROFILE: "PROFILE",
  UON: "UON"
};
var PromotionImageShape = {
  ROUND: "ROUND",
  SQUARE: "SQUARE"
};
var PromotionSeriesType = {
  LIMITED: "LIMITED",
  UNLIMITED: "UNLIMITED"
};
var UonTransactionType = {
  DONATE: "DONATE",
  CLAIM: "CLAIM",
  PRE_ALLOCATION: "PRE_ALLOCATION",
  GIFT_CODE: "GIFT_CODE",
  WRAPPED_CLAIM_CODE: "WRAPPED_CLAIM_CODE",
  WHOLESALE_COLLECT_CODE: "WHOLESALE_COLLECT_CODE"
};
var InvalidUonStatus = {
  MISSING: "MISSING",
  OVERAGE: "OVERAGE"
};
var UonHealthStatus = {
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
  DONE: "DONE"
};
var SubscribeStatus = {
  NEW: "NEW",
  SUBSCRIBED: "SUBSCRIBED",
  UNSUBSCRIBED: "UNSUBSCRIBED",
  SUBSCRIBING: "SUBSCRIBING",
  COMPLETED: "COMPLETED",
  VISITED: "VISITED"
};
var COLLECTED_SUBCRIBE_STATUSES = [
  SubscribeStatus.SUBSCRIBED,
  SubscribeStatus.UNSUBSCRIBED,
  SubscribeStatus.COMPLETED
];
var CategoryName = {
  PEOPLE: "PEOPLE",
  CREATION: "CREATION",
  WORLD: "WORLD",
  NATURE: "NATURE",
  KEYS: "KEYS",
  UNITY: "UNITY",
  POVERTY: "POVERTY",
  HUNGER: "HUNGER",
  HEALTH: "HEALTH",
  EDUCATION: "EDUCATION",
  EQUALITY: "EQUALITY",
  SANITATION: "SANITATION",
  ENERGY: "ENERGY",
  GROWTH: "GROWTH",
  INNOVATION: "INNOVATION",
  INEQUALITY: "INEQUALITY",
  COMMUNITIES: "COMMUNITIES",
  CONSUMPTION: "CONSUMPTION",
  CLIMATE: "CLIMATE",
  WATER: "WATER",
  LAND: "LAND",
  PEACE: "PEACE",
  PARTNERSHIPS: "PARTNERSHIPS",
  ZERO_HUNGER: "ZERO_HUNGER",
  GOOD_HEALTH_WELLBEING: "GOOD_HEALTH_WELLBEING",
  INCLUSION_EQUALITY: "INCLUSION_EQUALITY",
  VIBRANT_COMMUNITIES: "VIBRANT_COMMUNITIES",
  FREEDOM_OF_EXPRESSION: "FREEDOM_OF_EXPRESSION",
  ECOLOGICAL_DESIGN: "ECOLOGICAL_DESIGN",
  OPEN_SOURCE_COLLABORATION: "OPEN_SOURCE_COLLABORATION",
  HUMAN_RIGHTS_NO_POVERTY: "HUMAN_RIGHTS_NO_POVERTY",
  DECENT_WORK_FAIR_PLAY: "DECENT_WORK_FAIR_PLAY",
  TRANSPARENCY_DEMOCRACY: "TRANSPARENCY_DEMOCRACY",
  RESPONSIBLE_PRODUCTION: "RESPONSIBLE_PRODUCTION",
  NON_VIOLENCE_JUSTICE: "NON_VIOLENCE_JUSTICE",
  CLEAN_AIR: "CLEAN_AIR",
  PURE_WATER: "PURE_WATER",
  BIODIVERSITY_NATURE: "BIODIVERSITY_NATURE",
  ABUNDANT_ENERGY: "ABUNDANT_ENERGY",
  QUALITY_EDUCATION: "QUALITY_EDUCATION",
  MINDFULL_INNOVATION: "MINDFULL_INNOVATION",
  UNIFIED_SCIENCE: "UNIFIED_SCIENCE",
  YOUTH_EMPOWERMENT: "YOUTH_EMPOWERMENT",
  KNOWLEDGE_MEANING: "KNOWLEDGE_MEANING",
  LOVE_PEACE_JOY: "LOVE_PEACE_JOY",
  SPIRITUAL_AWAKENING: "SPIRITUAL_AWAKENING",
  WORLD_UNITY: "WORLD_UNITY",
  NUTRITION_FOOD: "NUTRITION_FOOD",
  HEALTH_WELLBEING: "HEALTH_WELLBEING",
  LIFESTYLE_LEISURE: "LIFESTYLE_LEISURE",
  RELATIONSHIPS_LOVE: "RELATIONSHIPS_LOVE",
  ARTS_CRAFTS: "ARTS_CRAFTS",
  CULTURE_RITUALS: "CULTURE_RITUALS",
  DESIGN_ARCHITECTURE: "DESIGN_ARCHITECTURE",
  MEDIA_MUSIC_GAMES: "MEDIA_MUSIC_GAMES",
  BUSINESS_ECONOMY: "BUSINESS_ECONOMY",
  FINANCE_WORK: "FINANCE_WORK",
  GOVERNANCE_JUSTICE: "GOVERNANCE_JUSTICE",
  SPORT_EVENTS: "SPORT_EVENTS",
  OCEAN_SEALIFE: "OCEAN_SEALIFE",
  PLANTS_ANIMALS: "PLANTS_ANIMALS",
  UNIVERSE_PLANETS: "UNIVERSE_PLANETS",
  WEALTHER_CLIMATE: "WEALTHER_CLIMATE",
  EDUCATION_PHILOSOPHY: "EDUCATION_PHILOSOPHY",
  INNOVATION_TECHNOLOGY: "INNOVATION_TECHNOLOGY",
  KNOWLEDGE_SCIENCE: "KNOWLEDGE_SCIENCE",
  QUANTUM_THEORIES: "QUANTUM_THEORIES",
  TEACHINGS_WISDOM: "TEACHINGS_WISDOM",
  VISION_CONSCIOUSNESS: "VISION_CONSCIOUSNESS",
  YOGA_MINDFULNESS: "YOGA_MINDFULNESS",
  ZODIAC_SYMBOLS: "ZODIAC_SYMBOLS",
  JOKER: "JOKER",
  X_FILES: "X_FILES"
};
var FeedStatus = {
  BREAKING: "breaking",
  NOTABLE: "notable",
  BORING: "boring"
};
var PaymentRegion = {
  EU: "EU",
  US: "US"
};
var PaymentRegionChangeSource = { PAGE_LOAD: "PAGE_LOAD", USER_SETTINGS: "USER_SETTINGS" };
var PromotionImageMutationType = {
  UPLOAD_NEW: "UPLOAD_NEW",
  EXISTING_IMAGE: "EXISTING_IMAGE"
};
var NotificationType = {
  CARD: "CARD",
  DECK: "DECK",
  GROUP: "GROUP",
  UON: "UON"
};
var NotificationEventType = {
  STAR: "STAR",
  UNSTAR: "UNSTAR",
  FOLLOW: "FOLLOW",
  UNFOLLOW: "UNFOLLOW",
  REPOSTED: "REPOSTED",
  CONTENT_DELETED: "CONTENT_DELETED",
  GROUP_MEMBER_INVITED: "GROUP_MEMBER_INVITED",
  GIFT_CODE_COLLECTED: "GIFT_CODE_COLLECTED",
  AUTOPLAYED: "AUTOPLAYED"
};
var MobileSystemDeckTextAlignment = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  CENTER: "CENTER"
};
var MobileSystemDeckStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
};
var DeckBackgroundImageType = {
  BACKGROUND_IMAGE: "BACKGROUND_IMAGE",
  PREVIEW_IMAGES: "PREVIEW_IMAGES"
};
var ShortcutResponseType = {
  WEB_APP: "WEB_APP",
  MOBILE_APP: "MOBILE_APP"
};
var SearchType = {
  ALL: "all",
  DECK: "deck",
  USER: "user"
};
var AccessDeniedErrorApiProperties = class {
};
__decorateClass([
  ApiProperty({
    example: "ERROR"
  })
], AccessDeniedErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty({
    example: "AccessDeniedError"
  })
], AccessDeniedErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty({
    example: "You do not have access to this resource"
  })
], AccessDeniedErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], AccessDeniedErrorApiProperties.prototype, "correlationId", 2);
var AuthorizationErrorApiProperties = class {
};
__decorateClass([
  ApiProperty2({
    example: "ERROR"
  })
], AuthorizationErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty2({
    example: "AuthorizationError"
  })
], AuthorizationErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty2({
    example: "The provided api-key is invalid"
  })
], AuthorizationErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty2({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], AuthorizationErrorApiProperties.prototype, "correlationId", 2);
var BadRequestErrorApiProperties = class {
};
__decorateClass([
  ApiProperty3({
    example: "ERROR"
  })
], BadRequestErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty3({
    example: "BadRequestError"
  })
], BadRequestErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty3({
    example: "This type is not supported"
  })
], BadRequestErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty3({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], BadRequestErrorApiProperties.prototype, "correlationId", 2);
var CollectionNameAlreadyExistsErrorApiProperties = class {
};
__decorateClass([
  ApiProperty4({
    example: "ERROR"
  })
], CollectionNameAlreadyExistsErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty4({
    example: "CollectionNameAlreadyExistsError"
  })
], CollectionNameAlreadyExistsErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty4({
    example: "The collection name already exists"
  })
], CollectionNameAlreadyExistsErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty4({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], CollectionNameAlreadyExistsErrorApiProperties.prototype, "correlationId", 2);
var MobileUserShortcutItemAlreadyExistApiProperties = class {
};
__decorateClass([
  ApiProperty5({
    example: "ERROR"
  })
], MobileUserShortcutItemAlreadyExistApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty5({
    example: "MobileUserShortcutItemAlreadyExistError"
  })
], MobileUserShortcutItemAlreadyExistApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty5({
    example: "The DeckSystemId already exists with the user"
  })
], MobileUserShortcutItemAlreadyExistApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty5({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], MobileUserShortcutItemAlreadyExistApiProperties.prototype, "correlationId", 2);
var NotFoundErrorResourceType = /* @__PURE__ */ ((NotFoundErrorResourceType2) => {
  NotFoundErrorResourceType2["Distribution"] = "Distribution";
  NotFoundErrorResourceType2["User"] = "User";
  NotFoundErrorResourceType2["Deck"] = "Deck";
  NotFoundErrorResourceType2["Promotion"] = "Promotion";
  NotFoundErrorResourceType2["Image"] = "Image";
  NotFoundErrorResourceType2["Card"] = "Card";
  NotFoundErrorResourceType2["MobileBundleVersion"] = "MobileBundleVersion";
  return NotFoundErrorResourceType2;
})(NotFoundErrorResourceType || {});
var NotFoundErrorApiProperties = class {
};
__decorateClass([
  ApiProperty6({
    example: "ERROR"
  })
], NotFoundErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty6({
    example: "NotFoundError"
  })
], NotFoundErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty6({
    example: "The resource cannot be found in the system"
  })
], NotFoundErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty6({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], NotFoundErrorApiProperties.prototype, "correlationId", 2);
__decorateClass([
  ApiProperty6({
    example: "<ResourceType>",
    type: "string",
    enum: NotFoundErrorResourceType
  })
], NotFoundErrorApiProperties.prototype, "resourceType", 2);
var PromotionAlreadyHasTransactionErrorApiProperties = class {
};
__decorateClass([
  ApiProperty7({
    example: "ERROR"
  })
], PromotionAlreadyHasTransactionErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty7({
    example: "PromotionAlreadyHasTransactionError"
  })
], PromotionAlreadyHasTransactionErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty7({
    example: "The promotion already has transaction"
  })
], PromotionAlreadyHasTransactionErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty7({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], PromotionAlreadyHasTransactionErrorApiProperties.prototype, "correlationId", 2);
var VanityNameAlreadyExistApiProperties = class {
};
__decorateClass([
  ApiProperty8({
    example: "ERROR"
  })
], VanityNameAlreadyExistApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty8({
    example: "ValidationError"
  })
], VanityNameAlreadyExistApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty8({
    example: "Vanity name already exists"
  })
], VanityNameAlreadyExistApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty8({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], VanityNameAlreadyExistApiProperties.prototype, "correlationId", 2);
var VanityNameNotAllowedErrorApiProperties = class {
};
__decorateClass([
  ApiProperty9({
    example: "ERROR"
  })
], VanityNameNotAllowedErrorApiProperties.prototype, "status", 2);
__decorateClass([
  ApiProperty9({
    example: "VanityNameNotAllowed"
  })
], VanityNameNotAllowedErrorApiProperties.prototype, "type", 2);
__decorateClass([
  ApiProperty9({
    example: "Vanity name is not allowed."
  })
], VanityNameNotAllowedErrorApiProperties.prototype, "detail", 2);
__decorateClass([
  ApiProperty9({
    example: "53bffb246fb16f60613abf31a95102a3"
  })
], VanityNameNotAllowedErrorApiProperties.prototype, "correlationId", 2);
var CARD_INFO_HTML_BUNDLE_MINE_TYPES = [
  "application/octet-stream",
  "application/zip",
  "application/x-zip-compressed",
  "multipart/x-zip"
];
var ALLOWED_IMAGE_MINE_TYPES = ["image/jpeg", "image/png", "image/gif"];
function IsWhitelistedUploadContentType(validationOptions) {
  return function(object, propertyName) {
    registerDecorator({
      name: "isWhitelistedUploadContentType",
      target: object.constructor,
      propertyName,
      constraints: ["target"],
      options: validationOptions,
      validator: {
        validate(value, arguments_) {
          const [targetPropertyName] = arguments_.constraints;
          const target = arguments_.object[targetPropertyName];
          if (target === ImageTarget.CARD_INFO_HTML_BUNDLE) {
            return isIn(value, CARD_INFO_HTML_BUNDLE_MINE_TYPES);
          }
          return isIn(value, ALLOWED_IMAGE_MINE_TYPES);
        }
      }
    });
  };
}
function whitelistedUploadContentTypeMessage(arguments_) {
  if (arguments_.object.target === ImageTarget.CARD_INFO_HTML_BUNDLE) {
    return "$property must be one of the following values: " + CARD_INFO_HTML_BUNDLE_MINE_TYPES.join(", ");
  }
  return "$property must be one of the following values: " + ALLOWED_IMAGE_MINE_TYPES.join(", ");
}
var ImageDimensions = class {
};
__decorateClass([
  ApiProperty10({
    example: 100,
    description: "Image's width",
    type: Number,
    required: true
  })
], ImageDimensions.prototype, "width", 2);
__decorateClass([
  ApiProperty10({
    example: 100,
    description: "Image's height",
    type: Number,
    required: true
  })
], ImageDimensions.prototype, "height", 2);
var UploadPresignPayloadDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsIn([
    ...Object.values(ImageTarget).map((t) => t.toLowerCase()),
    ...Object.values(ImageTarget)
  ])
], UploadPresignPayloadDto.prototype, "target", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], UploadPresignPayloadDto.prototype, "fileName", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsWhitelistedUploadContentType({
    message: whitelistedUploadContentTypeMessage
  })
], UploadPresignPayloadDto.prototype, "contentType", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], UploadPresignPayloadDto.prototype, "hashedFile", 2);
__decorateClass([
  IsOptional(),
  IsString()
], UploadPresignPayloadDto.prototype, "userIdOrVanityName", 2);
__decorateClass([
  IsOptional()
], UploadPresignPayloadDto.prototype, "dimentions", 2);
var UploadPresignResponseDto = class {
};
__decorateClass([
  ApiProperty11({
    example: "",
    required: true,
    description: "Upload token",
    type: String
  })
], UploadPresignResponseDto.prototype, "uploadToken", 2);
__decorateClass([
  ApiProperty11({
    example: "",
    required: true,
    description: "Url to upload to",
    type: String
  })
], UploadPresignResponseDto.prototype, "url", 2);
var c = initContract();
var imageContract = c.router({
  presignUpload: {
    method: "POST",
    path: "/v1/uploads/presign",
    responses: {
      201: c.type(),
      400: c.type(),
      401: c.type(),
      500: c.type()
    },
    body: c.type()
  }
});
var VANITY_NAME_MAX_NUMBER_LENGTH = 10;
var IsEarthId = (message = "Not a valid id") => {
  return (object, propertyName) => {
    registerDecorator({
      name: "isEarthId",
      target: object.constructor,
      propertyName,
      options: { message },
      validator: {
        validate(value) {
          try {
            BigInt(value);
            return value.length > VANITY_NAME_MAX_NUMBER_LENGTH;
          } catch (e) {
            return false;
          }
        }
      }
    });
  };
};
var GroupRoleUpdatePayload = class {
};
__decorateClass([
  IsString(),
  IsEarthId(),
  ApiProperty12({
    example: "123123123123",
    description: "The `id` of the group",
    format: "int64",
    type: String,
    required: true
  })
], GroupRoleUpdatePayload.prototype, "groupId", 2);
__decorateClass([
  IsString(),
  IsNotEmpty(),
  IsIn([ProfileType.BRAND, ProfileType.NPO, ProfileType.CHARITY]),
  ApiProperty12({
    example: "BRAND",
    description: "The profile type of the group",
    type: String,
    enum: [ProfileType.BRAND, ProfileType.NPO, ProfileType.CHARITY],
    required: true
  })
], GroupRoleUpdatePayload.prototype, "profileType", 2);
var GroupRoleUpdateResponseDto = class {
};
__decorateClass([
  ApiProperty13({
    example: "123123123123",
    description: "The `id` of the group",
    format: "int64",
    type: String,
    required: true
  })
], GroupRoleUpdateResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty13({
    example: "123123123123",
    description: "The `id` of the uon store",
    format: "int64",
    type: String,
    required: true
  })
], GroupRoleUpdateResponseDto.prototype, "uonStoreId", 2);
var InvalidUonResponseDto = class {
};
__decorateClass([
  ApiProperty14({
    example: "12339939",
    description: "The invalid uon id",
    type: String,
    format: "int64",
    required: true
  })
], InvalidUonResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: InvalidUonStatus.MISSING,
    description: "The status of invalid uon",
    type: InvalidUonStatus,
    required: true
  })
], InvalidUonResponseDto.prototype, "status", 2);
var PromotionCardHealthResponseDtoLimited = class {
};
__decorateClass([
  ApiProperty14({
    example: "12339939",
    description: "The id of promotion card",
    type: String,
    format: "int64",
    required: true
  })
], PromotionCardHealthResponseDtoLimited.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: PromotionSeriesType.LIMITED,
    description: "The type of promotion card health. The value must be LIMITED.",
    enum: [PromotionSeriesType.LIMITED],
    required: true
  })
], PromotionCardHealthResponseDtoLimited.prototype, "type", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The max uons of limited promtion card",
    type: Number,
    required: true
  })
], PromotionCardHealthResponseDtoLimited.prototype, "maxUons", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The issued uons count of promotion card from uon-store",
    type: Number,
    required: true
  })
], PromotionCardHealthResponseDtoLimited.prototype, "issuedUonsCountUonStore", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The promo counter of promotion card",
    type: Number,
    required: true
  })
], PromotionCardHealthResponseDtoLimited.prototype, "counterET", 2);
var PromotionCardHealthResponseDtoUnlimited = class {
};
__decorateClass([
  ApiProperty14({
    example: "12339939",
    description: "The id of promotion card",
    type: String,
    format: "int64",
    required: true
  })
], PromotionCardHealthResponseDtoUnlimited.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: PromotionSeriesType.UNLIMITED,
    description: "The type of promotion card health. The value must be UNLIMITED.",
    enum: [PromotionSeriesType.UNLIMITED],
    required: true
  })
], PromotionCardHealthResponseDtoUnlimited.prototype, "type", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The issued uons count of promotion card from uon-store",
    type: Number,
    required: true
  })
], PromotionCardHealthResponseDtoUnlimited.prototype, "issuedUonsCountUonStore", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The promo counter of promotion card",
    type: Number,
    required: true
  })
], PromotionCardHealthResponseDtoUnlimited.prototype, "counterET", 2);
var UonsHealthGetResponseDtoSuccess = class {
};
__decorateClass([
  ApiProperty14({
    example: "123123n",
    description: "The id of uon health",
    type: BigInt,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: "PROCESSING",
    description: "The status of uon health",
    type: UonHealthStatus,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "status", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The number of uon images in S3 bucket",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "s3UonImagesCount", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The number of collectable uons",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "collectableUonsCount", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The number of valid collectable uons",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "validCollectableUonsCount", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The collectable uons balance percentage",
    type: Number,
    maximum: 100,
    minimum: 0,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "collectableUonsBalancePercentage", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The number of overaged uons",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "overagedUonsCount", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The number of missing uons",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "missingUonsCount", 2);
__decorateClass([
  ApiProperty14({
    example: 100,
    description: "The uon collectable references count",
    type: Number,
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "collectableUonReferencesCount", 2);
__decorateClass([
  ApiProperty14({
    description: "The invalid uons",
    type: [InvalidUonResponseDto],
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "invalidUons", 2);
__decorateClass([
  ApiProperty14({
    description: "The promotion card healths",
    type: [
      PromotionCardHealthResponseDtoLimited || PromotionCardHealthResponseDtoUnlimited
    ],
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "promotionCards", 2);
__decorateClass([
  ApiProperty14({
    description: "The started time of calling uon-health endpoint",
    example: "2021-06-24T01:25:06.630+00:00",
    type: String,
    format: "date-time",
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "startedAt", 2);
__decorateClass([
  ApiProperty14({
    description: "The finished time of calling uon-health endpoint",
    example: "2021-06-24T01:25:06.630+00:00",
    type: String,
    format: "date-time",
    required: true
  })
], UonsHealthGetResponseDtoSuccess.prototype, "finishedAt", 2);
var UonsHealthGetResponseDtoProcessing = class {
};
__decorateClass([
  ApiProperty14({
    example: "123123n",
    description: "The id of uon health",
    type: BigInt,
    required: true
  })
], UonsHealthGetResponseDtoProcessing.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: "PROCESSING",
    description: "The status of uon health",
    type: UonHealthStatus,
    required: true
  })
], UonsHealthGetResponseDtoProcessing.prototype, "status", 2);
var UonsHealthGetResponseDtoError = class {
};
__decorateClass([
  ApiProperty14({
    example: "123123n",
    description: "The id of uon health",
    type: BigInt,
    required: true
  })
], UonsHealthGetResponseDtoError.prototype, "id", 2);
__decorateClass([
  ApiProperty14({
    example: "ERROR",
    description: "The status of uon health",
    type: UonHealthStatus,
    required: true
  })
], UonsHealthGetResponseDtoError.prototype, "status", 2);
__decorateClass([
  ApiProperty14({
    example: "fail",
    description: "The reason of error uon health",
    type: String,
    required: false,
    nullable: true
  })
], UonsHealthGetResponseDtoError.prototype, "errorReason", 2);
var c2 = initContract();
var adminContract = c2.router({
  applyGroupRole: {
    method: "PUT",
    path: "/v1/administration/apply-group-role",
    responses: {
      200: c2.type(),
      400: c2.type(),
      401: c2.type(),
      500: c2.type(),
      501: c2.type()
    },
    body: c2.type()
  },
  createUonsHealth: {
    strictStatusCodes: true,
    method: "POST",
    path: "/v1/administration/uons-health",
    responses: {
      202: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    headers: z.object({
      authorization: z.string().optional()
    }),
    body: c2.type()
  },
  retrieveUonsHealth: {
    strictStatusCodes: true,
    method: "GET",
    path: "/v1/administration/uons-health/:id",
    pathParams: z.object({
      id: z.string()
    }),
    responses: {
      200: c2.type(),
      400: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    headers: z.object({
      authorization: z.string().optional()
    })
  },
  createGiftCode: {
    method: "POST",
    path: "/v1/administration/users/:targetUserId/giftCodes",
    pathParams: z.object({
      targetUserId: z.string()
    }),
    query: z.object({
      forceGiftLastUon: z.string().optional()
    }),
    body: c2.type(),
    responses: {
      200: c2.type(),
      400: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  uploadResignMobileBundleVersion: {
    method: "POST",
    path: "/v1/administration/mobile-bundle-versions/upload-presign",
    body: c2.type(),
    responses: {
      200: c2.type(),
      400: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  uploadCommitMobileBundleVersion: {
    method: "PUT",
    path: "/v1/administration/mobile-bundle-versions/:mobileBundleVersionId/upload-commit",
    pathParams: z.object({
      mobileBundleVersionId: z.string()
    }),
    body: c2.type(),
    responses: {
      200: c2.type(),
      401: c2.type(),
      404: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  saveMobileSystemDeck: {
    method: "PUT",
    path: "/v1/administration/mobile-system-decks",
    body: c2.type(),
    responses: {
      200: c2.type(),
      401: c2.type(),
      404: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  getMobileSystemDeck: {
    method: "GET",
    path: "/v1/administration/decks/:deckId/mobile-system-deck",
    pathParams: z.object({
      deckId: z.string()
    }),
    responses: {
      200: c2.type(),
      401: c2.type(),
      404: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  getWhatIsNewDeck: {
    method: "GET",
    path: "/v1/decks/administrator/what-is-new",
    responses: {
      200: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  },
  refreshExpirationAuths: {
    method: "PATCH",
    path: "/v1/administration/auths/refresh-expiration",
    body: c2.type(),
    responses: {
      204: c2.type(),
      401: c2.type(),
      500: c2.type()
    },
    strictStatusCodes: true
  }
});
var VANITY_NAME_MAX_NUMBER_LENGTH2 = 10;
var VANITY_NAME_MIN_LENGTH = 3;
var VANITY_NAME_PATTERN = new RegExp(
  "^[a-zA-Z0-9]([a-zA-Z0-9-]+[a-zA-Z0-9]|[a-zA-Z0-9])?$"
);
function excludedVanityNames() {
  return process.env.EXCLUDED_VANITY_NAMES ? process.env.EXCLUDED_VANITY_NAMES.split(",").map((name) => name.trim()) : [
    "me",
    "following",
    "about-us",
    "privacy",
    "admin",
    "administrator",
    "curator",
    "editor",
    "play",
    "protect",
    "settings",
    "claim",
    "mobile",
    "today",
    "about",
    "people",
    "creation",
    "world",
    "nature",
    "spirit",
    "keys",
    "brands",
    "topprotectors",
    "naturereserves",
    "charities",
    "system"
  ];
}
var IsValidVanityName = class {
  validate(vanityName, _arguments_) {
    if (vanityName.length < VANITY_NAME_MIN_LENGTH) {
      return false;
    }
    if (/^\d+$/.test(vanityName)) {
      return vanityName.length <= VANITY_NAME_MAX_NUMBER_LENGTH2;
    }
    return VANITY_NAME_PATTERN.test(vanityName) && !excludedVanityNames().includes(vanityName);
  }
  defaultMessage(_arguments_) {
    return "Vanity name is not valid";
  }
};
IsValidVanityName = __decorateClass([
  ValidatorConstraint({ name: "IsValidVanityName", async: false })
], IsValidVanityName);
var GroupCreatePayloadDto = class {
};
__decorateClass([
  ApiProperty15({
    example: "group name",
    description: "Name of the group",
    required: true,
    type: String
  }),
  IsNotEmpty(),
  IsString()
], GroupCreatePayloadDto.prototype, "name", 2);
__decorateClass([
  ApiProperty15({
    example: "vanity name",
    description: "Vanity name of user",
    required: true,
    type: String
  }),
  IsNotEmpty(),
  IsString(),
  IsAlphanumeric(),
  Validate(IsValidVanityName)
], GroupCreatePayloadDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty15({
    example: "Description",
    description: "Description of group",
    type: String,
    required: false
  }),
  IsOptional(),
  IsString(),
  MaxLength(180)
], GroupCreatePayloadDto.prototype, "description", 2);
var c3 = initContract();
var groupContract = c3.router({
  createGroup: {
    method: "POST",
    path: "/v1/groups",
    responses: {
      200: c3.type(),
      400: c3.type(),
      401: c3.type(),
      500: c3.type()
    },
    body: c3.type()
  }
});
var ImageResponseDto = class {
};
__decorateClass([
  ApiProperty16({
    example: "96734581241242",
    required: true,
    type: "integer",
    description: "The `Id` of the image",
    format: "int64"
  })
], ImageResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty16({
    example: 0,
    required: true,
    type: Number,
    description: "The original width of the image",
    format: "int64"
  })
], ImageResponseDto.prototype, "originalWidth", 2);
__decorateClass([
  ApiProperty16({
    example: 0,
    required: true,
    description: "The original height of the image",
    type: Number,
    format: "int64"
  })
], ImageResponseDto.prototype, "originalHeight", 2);
__decorateClass([
  ApiProperty16({
    example: "",
    required: true,
    description: "The primary color of the image",
    type: String
  })
], ImageResponseDto.prototype, "primaryColor", 2);
__decorateClass([
  ApiProperty16({
    name: "url",
    description: "The url of the image",
    example: "https://stage-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNjMwMjc2NDUxNTk4MTAwODg5Ni9saW5rcy82NzA3Nzk4MjgzOTA4ODAwNTEyL2VhYWVmNTZhLTA1NDYtNDNiMS1iMDEyLWRmMTZiMTI0ODZiNS0zMzR5MnI5LWhpZ2hyZXMtMTY3ODkyMTQ0MS01NjM0LTE2Nzg5MjE0NzQuanBnIiwiYnVja2V0IjoiZWFydGh0b2RheS1zdGFnZS1pbWFnZXMifQ==",
    required: false,
    type: String
  })
], ImageResponseDto.prototype, "url", 2);
__decorateClass([
  ApiProperty16({
    example: "/users/6302764515981008896/profile/eaaef56a-0546-43b1-b012-df16b12486b5-334y2r9-highres-1678921441-5634-1678921474.jpg",
    required: true,
    nullable: true,
    type: String
  })
], ImageResponseDto.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty16({
    example: "earthtoday-stage-images",
    description: "The aws bucket of the image",
    required: false,
    type: String
  })
], ImageResponseDto.prototype, "awsBucket", 2);
__decorateClass([
  ApiProperty16({
    required: true,
    type: Boolean,
    description: "Detect if image has transparency",
    nullable: true
  })
], ImageResponseDto.prototype, "isTransparent", 2);
var UserImageResponseDto = class extends ImageResponseDto {
};
__decorateClass([
  ApiProperty16({
    name: "url",
    description: "The url of the image",
    example: "https://stage-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNjMwMjc2NDUxNTk4MTAwODg5Ni9saW5rcy82NzA3Nzk4MjgzOTA4ODAwNTEyL2VhYWVmNTZhLTA1NDYtNDNiMS1iMDEyLWRmMTZiMTI0ODZiNS0zMzR5MnI5LWhpZ2hyZXMtMTY3ODkyMTQ0MS01NjM0LTE2Nzg5MjE0NzQuanBnIiwiYnVja2V0IjoiZWFydGh0b2RheS1zdGFnZS1pbWFnZXMifQ==",
    required: true,
    type: String
  })
], UserImageResponseDto.prototype, "url", 2);
var LoginMetaResponseDto = class {
};
__decorateClass([
  ApiProperty17({
    example: 10,
    required: true,
    type: "integer",
    description: "user's login times"
  })
], LoginMetaResponseDto.prototype, "loginTimes", 2);
__decorateClass([
  ApiProperty17({
    type: String,
    format: "date-time",
    required: false,
    example: "2023-03-17T03:51:51.582Z",
    description: "The time that user last logged in"
  })
], LoginMetaResponseDto.prototype, "lastLoginAt", 2);
var UserCountsCustomResponseDto = class {
};
__decorateClass([
  ApiProperty18({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "user id",
    format: "int64"
  })
], UserCountsCustomResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: true,
    type: "integer",
    description: "total number of user's curated decks"
  })
], UserCountsCustomResponseDto.prototype, "curatedDecks", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: true,
    type: "integer",
    description: "total number of decks that user is following"
  })
], UserCountsCustomResponseDto.prototype, "followingDecks", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: true,
    type: "integer",
    description: "total number of user's promoted cards"
  })
], UserCountsCustomResponseDto.prototype, "promoteCards", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's starred cards"
  })
], UserCountsCustomResponseDto.prototype, "starredCards", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's drafting cards"
  })
], UserCountsCustomResponseDto.prototype, "draftingCards", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: true,
    type: "integer",
    description: "total number of user's published cards"
  })
], UserCountsCustomResponseDto.prototype, "publishedCards", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's notifications"
  })
], UserCountsCustomResponseDto.prototype, "notifications", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's remaining curated decks"
  })
], UserCountsCustomResponseDto.prototype, "remainingCuratedDecksCount", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's remaining drafting cards"
  })
], UserCountsCustomResponseDto.prototype, "remainingDraftingCardsCount", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: true,
    type: "integer",
    description: "total number of user's remaining drafting cards"
  })
], UserCountsCustomResponseDto.prototype, "uonCount", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's campaign cards"
  })
], UserCountsCustomResponseDto.prototype, "campaignCards", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's followers"
  })
], UserCountsCustomResponseDto.prototype, "followerCounts", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's received uons"
  })
], UserCountsCustomResponseDto.prototype, "receivedUonCount", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's created group"
  })
], UserCountsCustomResponseDto.prototype, "groupCount", 2);
__decorateClass([
  ApiProperty18({
    example: 10,
    required: false,
    type: "integer",
    description: "total number of user's group member"
  })
], UserCountsCustomResponseDto.prototype, "groupMemberCount", 2);
var UserDetailsResponseDto = class {
};
__decorateClass([
  ApiProperty19({
    example: "John Doe",
    required: true,
    type: String,
    description: "user's name"
  })
], UserDetailsResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty19({
    example: "CONSUMER",
    required: true,
    enum: [UserType.CONSUMER, UserType.GROUP_ADAPTER],
    type: String,
    description: "user's type"
  })
], UserDetailsResponseDto.prototype, "type", 2);
__decorateClass([
  ApiProperty19({
    example: "Urbn8 is great",
    required: true,
    type: String,
    description: "user's quote"
  })
], UserDetailsResponseDto.prototype, "quote", 2);
var UserGroupResponseDto = class {
};
__decorateClass([
  ApiProperty19({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "group id",
    format: "int64"
  })
], UserGroupResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty19({
    example: "John group",
    required: true,
    type: String,
    description: "group name"
  })
], UserGroupResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "group is verified or not",
    nullable: true
  })
], UserGroupResponseDto.prototype, "isVerified", 2);
__decorateClass([
  ApiProperty19({
    example: "NPO",
    required: true,
    enum: ProfileType,
    description: "group's profile type",
    nullable: true
  })
], UserGroupResponseDto.prototype, "profileType", 2);
__decorateClass([
  ApiProperty19({
    example: "active",
    required: true,
    type: String,
    description: "group's active status"
  })
], UserGroupResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty19({
    example: "John Doe",
    required: true,
    type: String,
    description: "user who created group"
  })
], UserGroupResponseDto.prototype, "createdBy", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: false,
    type: Boolean,
    description: "group's verification pending"
  })
], UserGroupResponseDto.prototype, "isVerificationPending", 2);
__decorateClass([
  ApiProperty19({
    example: "BRAND",
    required: false,
    enum: RoleRequest,
    description: "requested role"
  })
], UserGroupResponseDto.prototype, "roleRequest", 2);
var UserResponseDto = class {
};
__decorateClass([
  ApiProperty19({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "user id",
    format: "int64"
  })
], UserResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe",
    required: true,
    type: String,
    description: "user vanity name"
  })
], UserResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty19({
    example: "john",
    required: true,
    type: String,
    description: "user firstname"
  })
], UserResponseDto.prototype, "firstName", 2);
__decorateClass([
  ApiProperty19({
    example: "doe",
    required: true,
    type: String,
    description: "user lastname"
  })
], UserResponseDto.prototype, "lastName", 2);
__decorateClass([
  ApiProperty19({
    example: "John Doe",
    required: true,
    type: String,
    description: "user fullname"
  })
], UserResponseDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe@urbn8.com",
    required: true,
    type: String,
    description: "user's email address"
  })
], UserResponseDto.prototype, "emailAddress", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe.com",
    required: true,
    type: String,
    description: "user's website link"
  })
], UserResponseDto.prototype, "website", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe@twitter.com",
    required: false,
    type: String,
    description: "user's twitter link'"
  })
], UserResponseDto.prototype, "twitterUsername", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe@facebook.com",
    required: false,
    type: String,
    description: "user's facebook link"
  })
], UserResponseDto.prototype, "facebookUsername", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe@insta.com",
    required: false,
    type: String,
    description: "user's instagram link"
  })
], UserResponseDto.prototype, "instagramUsername", 2);
__decorateClass([
  ApiProperty19({
    example: "johndoe@linkedin.com",
    required: false,
    type: String,
    description: "user's linkedin link"
  })
], UserResponseDto.prototype, "linkedInUsername", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "dark mode activated or not"
  })
], UserResponseDto.prototype, "darkMode", 2);
__decorateClass([
  ApiProperty19({
    example: "CUSTOM",
    required: true,
    enum: PhotoState,
    description: "user's photo status"
  })
], UserResponseDto.prototype, "photoState", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "is user published or not"
  })
], UserResponseDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty19({
    example: "here is the bio mate",
    required: false,
    type: String,
    description: "user's bio"
  })
], UserResponseDto.prototype, "bio", 2);
__decorateClass([
  ApiProperty19({
    example: "Title goes here",
    required: false,
    type: String,
    description: "user's description"
  })
], UserResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty19({
    required: false,
    type: UserGroupResponseDto,
    description: "user's group"
  })
], UserResponseDto.prototype, "group", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: false,
    type: Boolean,
    description: "group's member accepted"
  })
], UserResponseDto.prototype, "groupMemberAccepted", 2);
__decorateClass([
  ApiProperty19({
    required: false,
    enum: Object.values(GroupMemberRole).map((v) => v.toLowerCase()),
    example: "admin",
    description: "user's group member role"
  })
], UserResponseDto.prototype, "groupMemberRole", 2);
__decorateClass([
  ApiProperty19({
    example: "nature lover",
    required: false,
    type: String,
    description: "user's tagline"
  })
], UserResponseDto.prototype, "tagline", 2);
__decorateClass([
  ApiProperty19({
    example: "nature lover",
    required: false,
    type: String,
    description: "is profile tag overriden"
  })
], UserResponseDto.prototype, "profileTagOverride", 2);
__decorateClass([
  ApiProperty19({
    example: false,
    required: false,
    type: Boolean,
    description: "Api key functionality for user is enabled or disabled"
  })
], UserResponseDto.prototype, "isApiKeyEnabled", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "is forcedUsedTagline enabled"
  })
], UserResponseDto.prototype, "forceUseTagLine", 2);
__decorateClass([
  ApiProperty19({
    example: false,
    required: false,
    type: Boolean,
    description: "Web login functionality for user is enabled or disabled"
  })
], UserResponseDto.prototype, "isWebLoginEnabled", 2);
__decorateClass([
  ApiProperty19({
    type: String,
    required: true,
    example: "102341949485684346825",
    description: "User's provider id"
  })
], UserResponseDto.prototype, "providerId", 2);
__decorateClass([
  ApiProperty19({
    type: String,
    required: true,
    example: "google",
    description: "User's provider"
  })
], UserResponseDto.prototype, "provider", 2);
__decorateClass([
  ApiProperty19({
    example: "basic",
    required: true,
    type: String,
    description: "user's cookie"
  })
], UserResponseDto.prototype, "cookieConsent", 2);
__decorateClass([
  ApiProperty19({
    required: false,
    type: () => UserImageResponseDto,
    description: "user's image"
  })
], UserResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty19({
    required: false,
    type: () => UserImageResponseDto,
    description: "user's cover"
  })
], UserResponseDto.prototype, "cover", 2);
__decorateClass([
  ApiProperty19({
    type: UserDetailsResponseDto,
    required: true,
    description: "User's details"
  })
], UserResponseDto.prototype, "details", 2);
__decorateClass([
  ApiProperty19({
    example: "2023-01-06T09:39:04.846Z",
    required: true,
    type: Date,
    description: "date when user joined",
    format: "date-time",
    nullable: true
  })
], UserResponseDto.prototype, "joined", 2);
__decorateClass([
  ApiProperty19({
    required: true,
    type: UserCountsCustomResponseDto,
    description: "user's statistics"
  })
], UserResponseDto.prototype, "userCounts", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "user's email verified or not"
  })
], UserResponseDto.prototype, "emailAddressVerified", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "user's terms accepted or not"
  })
], UserResponseDto.prototype, "termsAccepted", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "user's terms expired or not"
  })
], UserResponseDto.prototype, "termsExpired", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "user is admin or not"
  })
], UserResponseDto.prototype, "administrator", 2);
__decorateClass([
  ApiProperty19({
    example: true,
    required: true,
    type: Boolean,
    description: "user is curator or not"
  })
], UserResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty19({
    example: "2023-01-06T09:39:04.846Z",
    required: true,
    type: String,
    description: "when user created account",
    format: "date-time"
  })
], UserResponseDto.prototype, "createdAt", 2);
__decorateClass([
  ApiProperty19({
    required: false,
    type: LoginMetaResponseDto,
    description: "user's login meta"
  })
], UserResponseDto.prototype, "meta", 2);
var FIRSTNAME_MAX_LENGTH = 18;
var LASTNAME_MAX_LENGTH = 18;
var FULLNAME_MAX_LENGTH = 36;
var BIO_MAX_LENGTH = 256;
var TWITTER_MAX_LENGTH = 20;
var LINKED_MAX_LENGTH = 50;
var INSTAGRAM_MAX_LENGTH = 30;
var FACEBOOK_MAX_LENGTH = 50;
var TITLE_MAX_LENGTH = 36;
var TAGLINE_MAX_LENGTH = 60;
var UserUpdateDetailsPayloadDto = class {
};
__decorateClass([
  IsOptional(),
  IsNotEmpty(),
  MaxLength(FIRSTNAME_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "firstName", 2);
__decorateClass([
  IsOptional(),
  IsNotEmpty(),
  MaxLength(LASTNAME_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "lastName", 2);
__decorateClass([
  IsOptional(),
  IsNotEmpty(),
  MaxLength(FULLNAME_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "fullName", 2);
__decorateClass([
  IsOptional(),
  MaxLength(BIO_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "bio", 2);
__decorateClass([
  IsOptional(),
  MaxLength(FACEBOOK_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "facebookUsername", 2);
__decorateClass([
  IsOptional(),
  MaxLength(INSTAGRAM_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "instagramUsername", 2);
__decorateClass([
  IsOptional(),
  MaxLength(LINKED_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "linkedInUsername", 2);
__decorateClass([
  IsOptional(),
  MaxLength(TWITTER_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "twitterUsername", 2);
__decorateClass([
  IsOptional()
], UserUpdateDetailsPayloadDto.prototype, "website", 2);
__decorateClass([
  IsOptional(),
  MaxLength(TITLE_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "title", 2);
__decorateClass([
  IsOptional(),
  MaxLength(TAGLINE_MAX_LENGTH)
], UserUpdateDetailsPayloadDto.prototype, "tagline", 2);
var MobileSystemDeckResponseDtoData = class {
};
__decorateClass([
  ApiProperty20({
    type: String,
    required: true,
    format: "int64",
    example: "123456788999",
    description: "The deckId of mobile system deck"
  })
], MobileSystemDeckResponseDtoData.prototype, "deckId", 2);
__decorateClass([
  ApiProperty20({
    type: Number,
    required: false,
    example: 1,
    description: "The display order of deck in mobile system decks"
  })
], MobileSystemDeckResponseDtoData.prototype, "displayOrder", 2);
__decorateClass([
  ApiProperty20({
    type: String,
    required: true,
    example: "yellow",
    description: "The text color of mobile system deck"
  })
], MobileSystemDeckResponseDtoData.prototype, "textColor", 2);
__decorateClass([
  ApiProperty20({
    type: String,
    required: true,
    enum: MobileSystemDeckTextAlignment,
    example: MobileSystemDeckTextAlignment.CENTER,
    description: "The text alignment of mobile system deck"
  })
], MobileSystemDeckResponseDtoData.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty20({
    type: String,
    required: true,
    enum: MobileSystemDeckStatus,
    example: MobileSystemDeckStatus.ACTIVE,
    description: "The status of mobile system deck"
  })
], MobileSystemDeckResponseDtoData.prototype, "status", 2);
var MobileSystemDeckResponseDto = class {
};
__decorateClass([
  ApiProperty20({
    type: MobileSystemDeckResponseDtoData,
    required: true,
    description: "The data for mobile system deck"
  })
], MobileSystemDeckResponseDto.prototype, "data", 2);
var OwnerType = /* @__PURE__ */ ((OwnerType2) => {
  OwnerType2["CONSUMER"] = "CONSUMER";
  OwnerType2["BRAND"] = "BRAND";
  OwnerType2["CHARITY"] = "CHARITY";
  OwnerType2["MEMBER"] = "MEMBER";
  OwnerType2["UNCLASSIFIED_ORG"] = "UNCLASSIFIED_ORG";
  return OwnerType2;
})(OwnerType || {});
var Owner = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], Owner.prototype, "id", 2);
__decorateClass([
  IsNotEmpty(),
  IsEnum(OwnerType)
], Owner.prototype, "type", 2);
__decorateClass([
  IsString(),
  IsOptional()
], Owner.prototype, "email", 2);
__decorateClass([
  IsString(),
  IsOptional()
], Owner.prototype, "external_id", 2);
__decorateClass([
  IsString(),
  IsOptional()
], Owner.prototype, "name", 2);
__decorateClass([
  IsString(),
  IsOptional()
], Owner.prototype, "alias", 2);
var SubscribedUonDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], SubscribedUonDto.prototype, "id", 2);
__decorateClass([
  IsNotEmpty(),
  Type(() => MeterDto)
], SubscribedUonDto.prototype, "meter", 2);
var Geometry = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], Geometry.prototype, "type", 2);
__decorateClass([
  IsNotEmpty()
], Geometry.prototype, "coordinates", 2);
var Point = class {
};
__decorateClass([
  IsNotEmpty(),
  IsNumber()
], Point.prototype, "lat", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumber()
], Point.prototype, "lng", 2);
var MeterDto = class {
  // for reponse builder
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], MeterDto.prototype, "id", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], MeterDto.prototype, "reserveImageUrl", 2);
__decorateClass([
  IsNotEmpty()
], MeterDto.prototype, "point", 2);
__decorateClass([
  IsNotEmpty()
], MeterDto.prototype, "status", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], MeterDto.prototype, "reserveId", 2);
var Area = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], Area.prototype, "id", 2);
var ReserveDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "id", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "firstAreaId", 2);
__decorateClass([
  IsNotEmpty(),
  IsArray()
], ReserveDto.prototype, "areas", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "created", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "name", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  Type2(() => Owner)
], ReserveDto.prototype, "owner", 2);
__decorateClass([
  IsNotEmpty(),
  Type2(() => Geometry)
], ReserveDto.prototype, "perimeter", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "country", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "purpose", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "description", 2);
__decorateClass([
  IsString()
], ReserveDto.prototype, "startDate", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumber()
], ReserveDto.prototype, "size", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumber()
], ReserveDto.prototype, "stock", 2);
__decorateClass([
  IsNotEmpty(),
  IsString()
], ReserveDto.prototype, "status", 2);
var ReserveBasicInfo = class {
};
__decorateClass([
  ApiProperty21({
    required: true,
    type: String,
    description: "Reserve's Id",
    example: "12345567867"
  }),
  IsNotEmpty(),
  IsString()
], ReserveBasicInfo.prototype, "id", 2);
__decorateClass([
  ApiProperty21({
    required: true,
    type: String,
    description: "Reserve's Name",
    example: "Reserve Name"
  }),
  IsNotEmpty(),
  IsString()
], ReserveBasicInfo.prototype, "name", 2);
__decorateClass([
  ApiProperty21({
    required: true,
    type: String,
    description: "Reserve's Country",
    example: "Vietnam"
  }),
  IsNotEmpty(),
  IsString()
], ReserveBasicInfo.prototype, "country", 2);
__decorateClass([
  ApiProperty21({
    required: true,
    type: String,
    description: "Reserve's State",
    example: "Peninsula"
  }),
  IsNotEmpty(),
  IsString()
], ReserveBasicInfo.prototype, "state", 2);
__decorateClass([
  ApiProperty21({
    required: true,
    type: String,
    description: "Reserve Deck's Id",
    example: "6307860332963139585"
  }),
  IsNotEmpty(),
  IsString()
], ReserveBasicInfo.prototype, "deckId", 2);
var AwsImageExtract = class {
};
__decorateClass([
  ApiProperty22({
    example: 12,
    description: "left in https://sharp.pixelplumbing.com/api-resize#extract",
    required: true,
    type: Number
  }),
  IsNumber(),
  IsDefined()
], AwsImageExtract.prototype, "left", 2);
__decorateClass([
  ApiProperty22({
    example: 12,
    description: "top in https://sharp.pixelplumbing.com/api-resize#extract",
    required: true,
    type: Number
  }),
  IsNumber(),
  IsDefined()
], AwsImageExtract.prototype, "top", 2);
__decorateClass([
  ApiProperty22({
    example: 12,
    description: "width in https://sharp.pixelplumbing.com/api-resize#extract",
    required: true,
    type: Number
  }),
  IsNumber(),
  IsDefined()
], AwsImageExtract.prototype, "width", 2);
__decorateClass([
  ApiProperty22({
    example: 12,
    description: "height in https://sharp.pixelplumbing.com/api-resize#extract",
    required: true,
    type: Number
  }),
  IsNumber(),
  IsDefined()
], AwsImageExtract.prototype, "height", 2);
var ImageResponseDto2 = class {
};
__decorateClass([
  IsString(),
  IsEarthId(),
  ApiProperty23({
    example: "123123123123",
    description: "The `id` of the image",
    format: "int64",
    type: String,
    required: true
  })
], ImageResponseDto2.prototype, "id", 2);
__decorateClass([
  ApiProperty23({
    example: "awsKey",
    description: "awsKey of the image",
    required: true,
    nullable: true,
    type: String
  })
], ImageResponseDto2.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty23({
    example: "awsBucket",
    description: "awsBucket of the image",
    required: true,
    nullable: true,
    type: String
  })
], ImageResponseDto2.prototype, "awsBucket", 2);
__decorateClass([
  ApiProperty23({
    example: "https://www.scientificamerican.com/article/the-ocean-is-running-out-of-breath-scientists-warn/",
    description: "Url of the image",
    required: true,
    type: String
  })
], ImageResponseDto2.prototype, "url", 2);
__decorateClass([
  ApiProperty23({
    example: true,
    description: "If the image is transparent. For example, a png image can has a transparent background",
    required: true,
    nullable: true,
    type: Number
  })
], ImageResponseDto2.prototype, "isTransparent", 2);
__decorateClass([
  ApiProperty23({
    required: false,
    description: "Extract object for AWS Image Handler: https://sharp.pixelplumbing.com/api-resize#extract",
    type: AwsImageExtract
  })
], ImageResponseDto2.prototype, "extract", 2);
var PromotionDtoSeriesUnlimited = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    description: "Promotion series type",
    type: String
  }),
  IsIn([PromotionSeriesType.UNLIMITED])
], PromotionDtoSeriesUnlimited.prototype, "type", 2);
var PromotionDtoSeriesLimited = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    description: "Promotion series type",
    type: String
  }),
  IsIn([PromotionSeriesType.LIMITED])
], PromotionDtoSeriesLimited.prototype, "type", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    description: "Promotion series max items",
    type: Number,
    format: "int64"
  })
], PromotionDtoSeriesLimited.prototype, "maxItems", 2);
var PromotionDto = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of promotion",
    example: "123456789"
  })
], PromotionDto.prototype, "id", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of user",
    example: "123456789"
  })
], PromotionDto.prototype, "userId", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    enum: PromotionType,
    description: "The type of promotion",
    example: PromotionType.PROFILE
  })
], PromotionDto.prototype, "type", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: () => ImageResponseDto2,
    description: "The image of promotion"
  })
], PromotionDto.prototype, "image", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    description: "Collection name",
    example: "My collection name"
  })
], PromotionDto.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    description: "Promotion's creator",
    example: "Dung Nguyen"
  })
], PromotionDto.prototype, "protectedBy", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    enum: PromotionImageShape,
    description: "The shape of image"
  })
], PromotionDto.prototype, "imageShape", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: Boolean,
    description: "The option if promotion is featured",
    example: false
  })
], PromotionDto.prototype, "isFeatured", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    description: "The created time of promotion",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], PromotionDto.prototype, "createdAt", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: Boolean,
    description: "Enable open card view",
    example: false
  })
], PromotionDto.prototype, "enableOpenCardView", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    description: "Promotion's series",
    oneOf: [
      {
        $ref: getSchemaPath(PromotionDtoSeriesLimited)
      },
      {
        $ref: getSchemaPath(PromotionDtoSeriesUnlimited)
      }
    ],
    type: () => PromotionDtoSeriesLimited || PromotionDtoSeriesUnlimited
  })
], PromotionDto.prototype, "series", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    description: "The Reserve this promotion is assigned as default",
    type: ReserveBasicInfo
  })
], PromotionDto.prototype, "collectibleForReserve", 2);
PromotionDto = __decorateClass([
  ApiExtraModels(PromotionDtoSeriesLimited, PromotionDtoSeriesUnlimited)
], PromotionDto);
var UnlimitedPromotionDto = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of promotion",
    example: "123456789"
  })
], UnlimitedPromotionDto.prototype, "id", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of user",
    example: "123456789"
  })
], UnlimitedPromotionDto.prototype, "userId", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    enum: PromotionType,
    description: "The type of promotion",
    example: PromotionType.PROFILE
  })
], UnlimitedPromotionDto.prototype, "type", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: () => ImageResponseDto2,
    description: "The image of promotion"
  })
], UnlimitedPromotionDto.prototype, "image", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    description: "Collection name",
    example: "My collection name"
  })
], UnlimitedPromotionDto.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    description: "Promotion's creator",
    example: "Dung Nguyen"
  })
], UnlimitedPromotionDto.prototype, "protectedBy", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    enum: PromotionImageShape,
    description: "The shape of image"
  })
], UnlimitedPromotionDto.prototype, "imageShape", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: Boolean,
    description: "The option if promotion is featured",
    example: false
  })
], UnlimitedPromotionDto.prototype, "isFeatured", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    description: "The created time of promotion",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], UnlimitedPromotionDto.prototype, "createdAt", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: Boolean,
    description: "Enable open card view",
    example: false
  })
], UnlimitedPromotionDto.prototype, "enableOpenCardView", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    description: "Promotion's series",
    type: PromotionDtoSeriesUnlimited
  })
], UnlimitedPromotionDto.prototype, "series", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    description: "The Reserve this promotion is assigned as default",
    type: ReserveBasicInfo
  })
], UnlimitedPromotionDto.prototype, "collectibleForReserve", 2);
var MetaTagsDataResponseDto = class {
};
__decorateClass([
  ApiProperty24({
    type: Number,
    required: true,
    example: 100,
    description: "The total uon transactions of this promotion"
  })
], MetaTagsDataResponseDto.prototype, "count", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    description: "Promotion's creator",
    example: "Dung Nguyen"
  })
], MetaTagsDataResponseDto.prototype, "protectedBy", 2);
__decorateClass([
  ApiProperty24({
    example: "2023-01-06T09:39:04.846Z",
    required: false,
    type: String,
    description: "The date and time when the promotion was last updated",
    format: "date-time"
  })
], MetaTagsDataResponseDto.prototype, "updatedAt", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    format: "int64",
    required: true,
    example: "1550858315508583",
    description: "The facebook app `id`"
  })
], MetaTagsDataResponseDto.prototype, "facebookAppId", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: true,
    description: "The site name",
    example: "EarthToday"
  })
], MetaTagsDataResponseDto.prototype, "ogSiteName", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: true,
    description: "The type of site",
    example: "article"
  })
], MetaTagsDataResponseDto.prototype, "ogType", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: true,
    description: "The url of site",
    example: "https://earthtoday.com/collect/panchiparam?code=W22445"
  })
], MetaTagsDataResponseDto.prototype, "ogUrl", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: true,
    description: "The title of site",
    example: "Collect your m\xB2 on EarthToday"
  })
], MetaTagsDataResponseDto.prototype, "ogTitle", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: true,
    description: "The description of site",
    example: "You have been gifted protected nature by Hoang"
  })
], MetaTagsDataResponseDto.prototype, "ogDescription", 2);
__decorateClass([
  ApiProperty24({
    type: ImageResponseDto2,
    required: true,
    description: "The image url of site",
    example: "https://cdn-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNTUwMjk4NTc1NDgyMjIzODIwOC9sa"
  })
], MetaTagsDataResponseDto.prototype, "ogImage", 2);
__decorateClass([
  ApiProperty24({
    type: String,
    required: false,
    description: "Some text of site",
    example: "I\u2019m giving you this protected m\xB2 because I know you\u2019ll love this. Together we can be the change and protect our planet. Meter x meter. \u{1F49A}"
  })
], MetaTagsDataResponseDto.prototype, "ogText", 2);
var PromotionsResponseDtoMeta = class {
};
__decorateClass([
  ApiProperty24({
    required: false,
    description: "The featured promotion",
    type: PromotionDto
  })
], PromotionsResponseDtoMeta.prototype, "featuredPromotion", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    description: "Count number of promotion",
    type: Number
  })
], PromotionsResponseDtoMeta.prototype, "count", 2);
__decorateClass([
  ApiProperty24({
    required: false,
    type: MetaTagsDataResponseDto,
    description: "Metadata for sharing collectible promo card. Returned only when the `metaPromotionName` query parameter is included."
  })
], PromotionsResponseDtoMeta.prototype, "metaTagsData", 2);
var PromotionsResponseDto = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    description: "The data of promotions",
    isArray: true,
    type: PromotionDto
  })
], PromotionsResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    description: "The meta of promotions",
    type: PromotionsResponseDtoMeta
  })
], PromotionsResponseDto.prototype, "meta", 2);
var PromotionCountDto = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of promotion",
    example: "123456789"
  })
], PromotionCountDto.prototype, "id", 2);
__decorateClass([
  ApiProperty24({
    required: true,
    type: Number,
    description: "The number of collected uons",
    example: 0
  })
], PromotionCountDto.prototype, "count", 2);
var PromotionCountsResponseDto = class {
};
__decorateClass([
  ApiProperty24({
    required: true,
    description: "The data of promotion counts",
    type: Array
  })
], PromotionCountsResponseDto.prototype, "data", 2);
var ImageResponse = class {
};
__decorateClass([
  ApiProperty25({
    description: "The unique identifier of the image",
    type: String,
    example: "1234567890",
    required: true
  })
], ImageResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty25({
    description: "Original width of the image",
    type: Number,
    example: 1920,
    required: true
  })
], ImageResponse.prototype, "originalWidth", 2);
__decorateClass([
  ApiProperty25({
    description: "Original height of the image",
    type: Number,
    example: 1080,
    required: true
  })
], ImageResponse.prototype, "originalHeight", 2);
__decorateClass([
  ApiProperty25({
    description: "Primary color of the image",
    type: String,
    example: "#FFFFFF",
    required: true
  })
], ImageResponse.prototype, "primaryColor", 2);
__decorateClass([
  ApiProperty25({
    description: "URL of the image",
    type: String,
    required: false,
    example: "https://example.com/image.jpg"
  })
], ImageResponse.prototype, "url", 2);
__decorateClass([
  ApiProperty25({
    description: "AWS S3 key of the image",
    type: String,
    nullable: true,
    required: true,
    example: "path/to/image.jpg"
  })
], ImageResponse.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty25({
    description: "AWS S3 bucket name of the image",
    type: String,
    nullable: true,
    required: true,
    example: "example-bucket"
  })
], ImageResponse.prototype, "awsBucket", 2);
__decorateClass([
  ApiProperty25({
    description: "Indicates if the image is transparent",
    type: Boolean,
    nullable: true,
    required: true,
    example: true
  })
], ImageResponse.prototype, "isTransparent", 2);
var urlProperty = {
  name: "url",
  description: "The url of the image",
  example: "https://stage-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNjMwMjc2NDUxNTk4MTAwODg5Ni9saW5rcy82NzA3Nzk4MjgzOTA4ODAwNTEyL2VhYWVmNTZhLTA1NDYtNDNiMS1iMDEyLWRmMTZiMTI0ODZiNS0zMzR5MnI5LWhpZ2hyZXMtMTY3ODkyMTQ0MS01NjM0LTE2Nzg5MjE0NzQuanBnIiwiYnVja2V0IjoiZWFydGh0b2RheS1zdGFnZS1pbWFnZXMifQ==",
  required: false,
  type: String
};
var ImageResponseDto3 = class {
};
__decorateClass([
  ApiProperty25({
    example: "96734581241242",
    required: true,
    type: "integer",
    description: "The `Id` of the image",
    format: "int64"
  })
], ImageResponseDto3.prototype, "id", 2);
__decorateClass([
  ApiProperty25({
    example: 0,
    required: true,
    type: Number,
    description: "The original width of the image",
    format: "int64"
  })
], ImageResponseDto3.prototype, "originalWidth", 2);
__decorateClass([
  ApiProperty25({
    example: 0,
    required: true,
    description: "The original height of the image",
    type: Number,
    format: "int64"
  })
], ImageResponseDto3.prototype, "originalHeight", 2);
__decorateClass([
  ApiProperty25({
    example: "",
    required: true,
    description: "The primary color of the image",
    type: String
  })
], ImageResponseDto3.prototype, "primaryColor", 2);
__decorateClass([
  ApiProperty25(urlProperty)
], ImageResponseDto3.prototype, "url", 2);
__decorateClass([
  ApiProperty25({
    example: "/users/6302764515981008896/profile/eaaef56a-0546-43b1-b012-df16b12486b5-334y2r9-highres-1678921441-5634-1678921474.jpg",
    required: true,
    nullable: true,
    type: String
  })
], ImageResponseDto3.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty25({
    example: "earthtoday-stage-images",
    description: "The aws bucket of the image",
    required: false,
    type: String
  })
], ImageResponseDto3.prototype, "awsBucket", 2);
__decorateClass([
  ApiProperty25({
    required: true,
    type: Boolean,
    description: "Detect if image has transparency",
    nullable: true
  })
], ImageResponseDto3.prototype, "isTransparent", 2);
var UserImageResponseDto2 = class extends ImageResponseDto3 {
};
__decorateClass([
  ApiProperty25(__spreadProps(__spreadValues({}, urlProperty), { required: true }))
], UserImageResponseDto2.prototype, "url", 2);
var CuratorResponse = class {
};
__decorateClass([
  ApiProperty26({
    example: "1231231213123",
    type: String,
    required: true,
    description: "The `id` of the curator"
  })
], CuratorResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty26({
    example: "johndoe",
    type: String,
    required: true,
    description: "The vanity name of the curator"
  })
], CuratorResponse.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty26({
    example: false,
    type: Boolean,
    required: true,
    description: "The publish status of the curator"
  })
], CuratorResponse.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty26({
    example: false,
    type: Boolean,
    required: true,
    description: "Check if curator is token user"
  })
], CuratorResponse.prototype, "currentUser", 2);
__decorateClass([
  ApiProperty26({
    example: "johndoe",
    type: String,
    required: true,
    description: "The name of the curator"
  })
], CuratorResponse.prototype, "name", 2);
__decorateClass([
  ApiProperty26({
    required: true,
    nullable: true,
    type: () => ImageResponseDto3,
    description: "The image of the curator"
  })
], CuratorResponse.prototype, "image", 2);
var CuratorResponseDto = class {
};
__decorateClass([
  ApiProperty26({
    example: "1231231213123",
    type: String,
    required: true,
    description: "The `id` of the curator"
  })
], CuratorResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty26({
    example: "johndoe",
    type: String,
    required: true,
    description: "The vanity name of the curator"
  })
], CuratorResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty26({
    example: false,
    type: Boolean,
    required: true,
    description: "The publish status of the curator"
  })
], CuratorResponseDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty26({
    example: false,
    type: Boolean,
    required: true,
    description: "Check if curator is token user"
  })
], CuratorResponseDto.prototype, "currentUser", 2);
__decorateClass([
  ApiProperty26({
    example: "johndoe",
    type: String,
    required: true,
    description: "The name of the curator"
  })
], CuratorResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty26({
    required: true,
    nullable: true,
    type: () => ImageResponseDto3,
    description: "The image of the curator"
  })
], CuratorResponseDto.prototype, "image", 2);
var DeckForCardResponse = class {
};
__decorateClass([
  ApiProperty27({
    required: true,
    description: "The unique identifier of the deck",
    type: String,
    example: "1234567890"
  })
], DeckForCardResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty27({
    required: true,
    description: "The name of the deck",
    type: String,
    example: "Nature Conservation Deck"
  })
], DeckForCardResponse.prototype, "name", 2);
__decorateClass([
  ApiProperty27({
    required: true,
    description: "Indicates if the deck is featured on the feed",
    type: Boolean,
    example: true
  })
], DeckForCardResponse.prototype, "onFeed", 2);
__decorateClass([
  ApiProperty27({
    required: true,
    description: "Path to access the deck, represented as an array of strings",
    type: [String],
    example: ["vanityname", "deck-name"]
  })
], DeckForCardResponse.prototype, "path", 2);
var DeckForCardResponseDto = class {
};
__decorateClass([
  ApiProperty27({
    example: "6302777474501242880",
    description: "The `Id` of the deck",
    required: true,
    type: String
  })
], DeckForCardResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty27({
    example: "first",
    description: "The name of the deck",
    required: true,
    type: String
  })
], DeckForCardResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty27({
    example: true,
    description: "The feed state of the deck",
    required: true,
    type: Boolean
  })
], DeckForCardResponseDto.prototype, "onFeed", 2);
__decorateClass([
  ApiProperty27({
    example: ["johndoe", "first"],
    required: true,
    description: "The path of the deck",
    type: [String, String]
  })
], DeckForCardResponseDto.prototype, "path", 2);
var CardPromotionRepostResponseDto = class {
};
__decorateClass([
  ApiProperty28({
    required: true,
    description: "The id of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardPromotionRepostResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty28({
    required: true,
    description: "The content type of card. The value must be PROMOTION_REPOST",
    type: String,
    enum: [CardType.PROMOTION_REPOST],
    example: CardType.PROMOTION_REPOST
  })
], CardPromotionRepostResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty28({
    required: true,
    description: "Status of the card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: "DRAFTING"
  })
], CardPromotionRepostResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty28({
    required: false,
    description: "The published time of the card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardPromotionRepostResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty28({
    required: true,
    description: "The promotion of the card",
    type: PromotionDto
  })
], CardPromotionRepostResponseDto.prototype, "promotion", 2);
__decorateClass([
  ApiProperty28({
    required: false,
    description: "Deck of the card",
    type: DeckForCardResponseDto
  })
], CardPromotionRepostResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty28({
    required: true,
    description: "Curator of the card",
    type: CuratorResponseDto
  })
], CardPromotionRepostResponseDto.prototype, "curator", 2);
var MetaTagsResponseDto = class {
};
__decorateClass([
  ApiProperty29({
    type: String,
    format: "int64",
    required: true,
    example: "1550858315508583",
    description: "The facebook app `id`"
  })
], MetaTagsResponseDto.prototype, "fb:app_id", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The site name",
    example: "EarthToday"
  })
], MetaTagsResponseDto.prototype, "og:site_name", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The type of site",
    example: "article"
  })
], MetaTagsResponseDto.prototype, "og:type", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The url of site",
    example: "https://earthtoday.com/collect/panchiparam?code=W22445"
  })
], MetaTagsResponseDto.prototype, "og:url", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The title of site",
    example: "Collect your m\xB2 on EarthToday"
  })
], MetaTagsResponseDto.prototype, "og:title", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The description of site",
    example: "You have been gifted protected nature by Hoang"
  })
], MetaTagsResponseDto.prototype, "og:description", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: true,
    description: "The image url of site",
    example: "https://cdn-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNTUwMjk4NTc1NDgyMjIzODIwOC9sa"
  })
], MetaTagsResponseDto.prototype, "og:image", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: false,
    description: "Some text of site",
    example: "I\u2019m giving you this protected m\xB2 because I know you\u2019ll love this. Together we can be the change and protect our planet. Meter x meter. \u{1F49A}"
  })
], MetaTagsResponseDto.prototype, "og:text", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: false,
    description: "The width of image",
    example: "1200"
  })
], MetaTagsResponseDto.prototype, "og:image:width", 2);
__decorateClass([
  ApiProperty29({
    type: String,
    required: false,
    description: "The height of image",
    example: "630"
  })
], MetaTagsResponseDto.prototype, "og:image:height", 2);
var CardActionUrlInternalResource = class {
};
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The type of resource. The value must be URL_INTERNAL",
    type: String,
    enum: [
      "URL_INTERNAL"
      /* URL_INTERNAL */
    ],
    example: "URL_INTERNAL"
  })
], CardActionUrlInternalResource.prototype, "type", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The nextjs href path of resource",
    type: String,
    example: "/[vanityName]"
  })
], CardActionUrlInternalResource.prototype, "nextjsHrefPath", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The nextjs as path of resource",
    type: String,
    example: "/vanitynameuser"
  })
], CardActionUrlInternalResource.prototype, "nextjsAsPath", 2);
var CardActionUrlExternalResource = class {
};
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The type of resource. The value must be URL_EXTERNAL",
    type: String,
    enum: [
      "URL_EXTERNAL"
      /* URL_EXTERNAL */
    ],
    example: "URL_EXTERNAL"
  })
], CardActionUrlExternalResource.prototype, "type", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The url of resource",
    type: String,
    example: "https://www.google.com.vn/"
  })
], CardActionUrlExternalResource.prototype, "url", 2);
var CardActionResponseDto = class {
};
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The id of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardActionResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "The content type of card. The value must be ACTION",
    type: String,
    enum: [CardType.ACTION],
    example: "ACTION"
  })
], CardActionResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "Title of the card",
    type: String,
    example: "title"
  })
], CardActionResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "Subtitle of the card",
    type: String,
    example: "subtitle"
  })
], CardActionResponseDto.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "Status of the card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: "DRAFTING"
  })
], CardActionResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "Text alignment of the card",
    type: String,
    enum: TextAlignment,
    example: "LEFT"
  })
], CardActionResponseDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "text color of the card",
    type: String,
    enum: TextColor,
    example: "BLACK"
  })
], CardActionResponseDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "Size of the card",
    type: String,
    enum: CardSize,
    example: "SINGLE"
  })
], CardActionResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "The published time of the card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardActionResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "Button text of the card",
    type: String,
    example: "button text"
  })
], CardActionResponseDto.prototype, "buttonText", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "Deck of the card",
    type: DeckForCardResponseDto
  })
], CardActionResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "Curator of the card",
    type: CuratorResponseDto
  })
], CardActionResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty30({
    required: true,
    description: "Resource of the card",
    oneOf: [
      {
        $ref: getSchemaPath2(CardActionUrlInternalResource)
      },
      {
        $ref: getSchemaPath2(CardActionUrlExternalResource)
      }
    ],
    type: () => CardActionUrlInternalResource || CardActionUrlExternalResource
  })
], CardActionResponseDto.prototype, "resource", 2);
__decorateClass([
  ApiProperty30({
    required: false,
    description: "background image of the card",
    type: ImageResponseDto3
  })
], CardActionResponseDto.prototype, "backgroundImage", 2);
CardActionResponseDto = __decorateClass([
  ApiExtraModels2(CardActionUrlInternalResource, CardActionUrlExternalResource)
], CardActionResponseDto);
var CardActionWithMetaTagsResponseDto = class extends CardActionResponseDto {
};
__decorateClass([
  ApiProperty30({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardActionWithMetaTagsResponseDto.prototype, "metaTags", 2);
var ViewPort = class {
};
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Preset browser width of card content",
    type: Number,
    format: "int64",
    example: "375"
  })
], ViewPort.prototype, "presetBrowserWidth", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Detected content height of card content",
    type: Number,
    format: "int64",
    example: "375"
  })
], ViewPort.prototype, "detectedContentHeight", 2);
var HtmlBundleMetadata = class {
};
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Viewports of card content",
    type: () => [Object],
    format: "int64"
  })
], HtmlBundleMetadata.prototype, "viewports", 2);
var CardInfoResponseDto = class {
};
__decorateClass([
  ApiProperty31({
    required: true,
    description: "The `id` of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardInfoResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "The content type of card. The value must be INFO",
    type: String,
    enum: [CardType.INFO],
    example: "INFO"
  })
], CardInfoResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Title of the card",
    type: String,
    example: "title"
  })
], CardInfoResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty31({
    required: false,
    description: "Subtitle of the card",
    type: String,
    example: "subtitle"
  })
], CardInfoResponseDto.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty31({
    required: false,
    description: "Background image of the card",
    type: ImageResponseDto3
  })
], CardInfoResponseDto.prototype, "backgroundImage", 2);
__decorateClass([
  ApiProperty31({
    required: false,
    description: "Background color of the card",
    type: String,
    example: "#fff"
  })
], CardInfoResponseDto.prototype, "backgroundColor", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Enable open card info view",
    type: Boolean,
    example: true
  })
], CardInfoResponseDto.prototype, "enableOpenCardView", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Html bundle metadata of card info",
    type: () => HtmlBundleMetadata,
    example: {
      viewports: [
        {
          presetBrowserWidth: 375,
          detectedContentHeight: 375
        }
      ]
    }
  })
], CardInfoResponseDto.prototype, "htmlBundleMetadata", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Principle name of info card",
    type: String,
    enum: PrincipleName,
    example: "CREATION"
  })
], CardInfoResponseDto.prototype, "principleName", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Text alignment of info card",
    type: String,
    enum: TextAlignment,
    example: "LEFT"
  })
], CardInfoResponseDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Text color of info card",
    type: String,
    enum: TextColor,
    example: "WHITE"
  })
], CardInfoResponseDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Size of info card",
    type: String,
    enum: CardSize,
    example: "SINGLE"
  })
], CardInfoResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Status of info card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: "DRAFTING"
  })
], CardInfoResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty31({
    required: false,
    description: "Publish time of info card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardInfoResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty31({
    required: false,
    description: "Deck of info card",
    type: DeckForCardResponseDto
  })
], CardInfoResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty31({
    required: true,
    description: "Curator of info card",
    type: CuratorResponseDto
  })
], CardInfoResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty31({
    example: "2023-01-06T09:39:04.846Z",
    required: true,
    type: String,
    description: "card's latest update",
    format: "date-time"
  })
], CardInfoResponseDto.prototype, "updatedAt", 2);
var CardInfoWithMetaTagsResponseDto = class extends CardInfoResponseDto {
};
__decorateClass([
  ApiProperty31({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardInfoWithMetaTagsResponseDto.prototype, "metaTags", 2);
var CardInfoRepostResponseDto = class {
};
__decorateClass([
  ApiProperty32({
    required: true,
    description: "The id of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardInfoRepostResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty32({
    required: true,
    description: "The content type of card. The value must be INFO_REPOST",
    type: String,
    enum: [CardType.INFO_REPOST],
    example: CardType.INFO_REPOST
  })
], CardInfoRepostResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty32({
    required: true,
    description: "The root source card of the card info repost",
    type: CardInfoResponseDto
  })
], CardInfoRepostResponseDto.prototype, "rootSourceCard", 2);
__decorateClass([
  ApiProperty32({
    required: false,
    description: "Publish time of info card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardInfoRepostResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty32({
    required: true,
    description: "Size of info card",
    type: String,
    enum: CardSize,
    example: CardSize.SINGLE
  })
], CardInfoRepostResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty32({
    required: true,
    description: "Status of info card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: CardStatus.DRAFTING
  })
], CardInfoRepostResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty32({
    required: false,
    description: "Deck of the card",
    type: DeckForCardResponseDto
  })
], CardInfoRepostResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty32({
    required: true,
    description: "Curator of the card",
    type: CuratorResponseDto
  })
], CardInfoRepostResponseDto.prototype, "curator", 2);
var CardInfoRepostWithMetaTagsResponseDto = class extends CardInfoRepostResponseDto {
};
__decorateClass([
  ApiProperty32({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardInfoRepostWithMetaTagsResponseDto.prototype, "metaTags", 2);
var CardEditableResponseDtoCardOpenSection = class {
};
__decorateClass([
  ApiProperty33({
    required: true,
    description: "The `id` of card open section",
    type: String,
    format: "int64",
    example: "123"
  })
], CardEditableResponseDtoCardOpenSection.prototype, "id", 2);
__decorateClass([
  ApiProperty33({
    example: "headline",
    description: "The Headline of card open section",
    type: String,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "headline", 2);
__decorateClass([
  ApiProperty33({
    example: "headline",
    description: "The Subheadline of card open section",
    type: String,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "subheadline", 2);
__decorateClass([
  ApiProperty33({
    example: "{}",
    description: "The summary of card open section. The value must be json string",
    type: String,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "summary", 2);
__decorateClass([
  ApiProperty33({
    example: "buttonText",
    description: "The buttonText of card open section",
    type: String,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "buttonText", 2);
__decorateClass([
  ApiProperty33({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of card open section",
    type: String,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "buttonUrl", 2);
__decorateClass([
  ApiProperty33({
    example: TextAlignment.LEFT,
    description: "Text alignment of card open section",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty33({
    example: TextColor.BLACK,
    description: "the text color of card open section",
    type: String,
    enum: TextColor,
    required: false
  })
], CardEditableResponseDtoCardOpenSection.prototype, "textColor", 2);
__decorateClass([
  ApiProperty33({
    required: false,
    description: "The section background color of card open section",
    type: String,
    example: "#fff"
  })
], CardEditableResponseDtoCardOpenSection.prototype, "sectionBgColor", 2);
__decorateClass([
  ApiProperty33({
    description: "The section media image of card open section",
    required: false,
    type: () => ImageResponseDto3
  })
], CardEditableResponseDtoCardOpenSection.prototype, "sectionMediaImage", 2);
__decorateClass([
  ApiProperty33({
    required: false,
    description: "The section media link url of card open section",
    type: String,
    example: "https://earthtoday.com/images/..."
  })
], CardEditableResponseDtoCardOpenSection.prototype, "sectionMediaLinkUrl", 2);
var CardEditableResponseDto = class {
};
__decorateClass([
  ApiProperty33({
    required: true,
    description: "The `id` of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardEditableResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty33({
    example: CardType.EDITABLE,
    description: "The content type of card content. The value must be EDITABLE",
    type: String,
    enum: [CardType.EDITABLE],
    required: true
  })
], CardEditableResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty33({
    example: "headline",
    description: "The Headline of front card",
    type: String,
    required: false
  })
], CardEditableResponseDto.prototype, "headline", 2);
__decorateClass([
  ApiProperty33({
    example: "headline",
    description: "The Subheadline of front card",
    type: String,
    required: false
  })
], CardEditableResponseDto.prototype, "subheadline", 2);
__decorateClass([
  ApiProperty33({
    example: "summary",
    description: "The summary of front card",
    type: String,
    required: false
  })
], CardEditableResponseDto.prototype, "summary", 2);
__decorateClass([
  ApiProperty33({
    example: "buttonText",
    description: "The buttonText of front card",
    type: String,
    required: false
  })
], CardEditableResponseDto.prototype, "buttonText", 2);
__decorateClass([
  ApiProperty33({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of front card",
    type: String,
    required: false
  })
], CardEditableResponseDto.prototype, "buttonUrl", 2);
__decorateClass([
  ApiProperty33({
    example: TextAlignment.LEFT,
    description: "Text alignment of the front card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardEditableResponseDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty33({
    example: TextColor.BLACK,
    description: "the text color of front card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardEditableResponseDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty33({
    required: false,
    description: "The background color of front card",
    type: String,
    example: "#fff"
  })
], CardEditableResponseDto.prototype, "bgColor", 2);
__decorateClass([
  ApiProperty33({
    description: "The background image of front card",
    required: false,
    type: () => ImageResponseDto3
  })
], CardEditableResponseDto.prototype, "bgImage", 2);
__decorateClass([
  ApiProperty33({
    type: () => CardEditableResponseDtoCardOpenSection,
    description: "The card open sections of card",
    isArray: true,
    required: false
  })
], CardEditableResponseDto.prototype, "cardOpenSections", 2);
__decorateClass([
  ApiProperty33({
    required: true,
    description: "Principle name of editable card",
    type: String,
    enum: PrincipleName,
    example: "CREATION"
  })
], CardEditableResponseDto.prototype, "principleName", 2);
__decorateClass([
  ApiProperty33({
    required: true,
    description: "Size of editable card",
    type: String,
    enum: CardSize,
    example: "SINGLE"
  })
], CardEditableResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty33({
    required: true,
    description: "Status of editable card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: "DRAFTING"
  })
], CardEditableResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty33({
    required: false,
    description: "Publish time of editable card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardEditableResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty33({
    required: false,
    description: "Deck of editable card",
    type: DeckForCardResponseDto
  })
], CardEditableResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty33({
    required: true,
    description: "Curator of editable card",
    type: CuratorResponseDto
  })
], CardEditableResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty33({
    example: "2023-01-06T09:39:04.846Z",
    required: true,
    type: String,
    description: "card's latest update",
    format: "date-time"
  })
], CardEditableResponseDto.prototype, "updatedAt", 2);
var CardEditableWithMetaTagsResponseDto = class extends CardEditableResponseDto {
};
__decorateClass([
  ApiProperty33({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardEditableWithMetaTagsResponseDto.prototype, "metaTags", 2);
var CardItemEditableRepostResponseDto = class {
};
__decorateClass([
  ApiProperty34({
    required: true,
    description: "The id of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardItemEditableRepostResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "The content type of card. The value must be EDITABLE_REPOST",
    type: String,
    enum: [CardType.EDITABLE_REPOST],
    example: CardType.EDITABLE_REPOST
  })
], CardItemEditableRepostResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "The root source card of the card editable repost",
    type: CardEditableResponseDto
  })
], CardItemEditableRepostResponseDto.prototype, "rootSourceCard", 2);
__decorateClass([
  ApiProperty34({
    required: false,
    description: "Publish time of editable card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardItemEditableRepostResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "Size of editable card",
    type: String,
    enum: CardSize,
    example: CardSize.SINGLE
  })
], CardItemEditableRepostResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "Principle name of editable card repost",
    type: String,
    enum: PrincipleName,
    example: "CREATION"
  })
], CardItemEditableRepostResponseDto.prototype, "principleName", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "Status of editable card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: CardStatus.DRAFTING
  })
], CardItemEditableRepostResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty34({
    required: false,
    description: "Deck of the card",
    type: DeckForCardResponseDto
  })
], CardItemEditableRepostResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty34({
    required: true,
    description: "Curator of the card",
    type: CuratorResponseDto
  })
], CardItemEditableRepostResponseDto.prototype, "curator", 2);
var CardItemEditableRepostWithMetaTagsResponseDto = class extends CardItemEditableRepostResponseDto {
};
__decorateClass([
  ApiProperty34({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardItemEditableRepostWithMetaTagsResponseDto.prototype, "metaTags", 2);
var CardItemEditableResponseDto = class {
};
__decorateClass([
  ApiProperty35({
    required: true,
    description: "The `id` of card",
    type: String,
    format: "int64",
    example: "123"
  })
], CardItemEditableResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty35({
    example: CardType.EDITABLE,
    description: "The content type of card content. The value must be EDITABLE",
    type: String,
    enum: [CardType.EDITABLE],
    required: true
  })
], CardItemEditableResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty35({
    example: "headline",
    description: "The Headline of front card",
    type: String,
    required: false
  })
], CardItemEditableResponseDto.prototype, "headline", 2);
__decorateClass([
  ApiProperty35({
    example: "headline",
    description: "The Subheadline of front card",
    type: String,
    required: false
  })
], CardItemEditableResponseDto.prototype, "subheadline", 2);
__decorateClass([
  ApiProperty35({
    example: "summary",
    description: "The summary of front card",
    type: String,
    required: false
  })
], CardItemEditableResponseDto.prototype, "summary", 2);
__decorateClass([
  ApiProperty35({
    example: "buttonText",
    description: "The buttonText of front card",
    type: String,
    required: false
  })
], CardItemEditableResponseDto.prototype, "buttonText", 2);
__decorateClass([
  ApiProperty35({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of front card",
    type: String,
    required: false
  })
], CardItemEditableResponseDto.prototype, "buttonUrl", 2);
__decorateClass([
  ApiProperty35({
    example: TextAlignment.LEFT,
    description: "Text alignment of the front card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardItemEditableResponseDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty35({
    example: TextColor.BLACK,
    description: "the text color of front card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardItemEditableResponseDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty35({
    required: false,
    description: "The background color of front card",
    type: String,
    example: "#fff"
  })
], CardItemEditableResponseDto.prototype, "bgColor", 2);
__decorateClass([
  ApiProperty35({
    description: "The background image of front card",
    required: false,
    type: () => ImageResponseDto3
  })
], CardItemEditableResponseDto.prototype, "bgImage", 2);
__decorateClass([
  ApiProperty35({
    required: true,
    description: "Principle name of editable card",
    type: String,
    enum: PrincipleName,
    example: "CREATION"
  })
], CardItemEditableResponseDto.prototype, "principleName", 2);
__decorateClass([
  ApiProperty35({
    required: true,
    description: "Size of editable card",
    type: String,
    enum: CardSize,
    example: "SINGLE"
  })
], CardItemEditableResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty35({
    required: true,
    description: "Status of editable card",
    type: String,
    enum: [CardStatus.DRAFTING, CardStatus.PUBLISHED],
    example: "DRAFTING"
  })
], CardItemEditableResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty35({
    required: false,
    description: "Publish time of editable card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardItemEditableResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty35({
    required: false,
    description: "Deck of editable card",
    type: DeckForCardResponseDto
  })
], CardItemEditableResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty35({
    required: true,
    description: "Curator of editable card",
    type: CuratorResponseDto
  })
], CardItemEditableResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty35({
    example: "2023-01-06T09:39:04.846Z",
    required: true,
    type: String,
    description: "card's latest update",
    format: "date-time"
  })
], CardItemEditableResponseDto.prototype, "updatedAt", 2);
var FollowingResponseDto = class {
  // TODO: remove
};
__decorateClass([
  ApiProperty36({
    example: 777,
    required: true,
    type: "integer",
    description: "number of users followed this deck"
  })
], FollowingResponseDto.prototype, "count", 2);
__decorateClass([
  ApiProperty36({
    required: true,
    type: Boolean,
    description: "check token user followed this deck"
  })
], FollowingResponseDto.prototype, "following", 2);
var ReserveDeckResponseDto = class {
};
__decorateClass([
  ApiProperty37({
    example: "1302297",
    description: "The id of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty37({
    example: "somereserveintheworld",
    description: "The name of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty37({
    example: "Mwandi",
    description: "The country of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "country", 2);
__decorateClass([
  ApiProperty37({
    example: "Some state in Mwandi",
    description: "The state of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty37({
    example: "Some city in Mwandi",
    description: "The city address of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "city", 2);
__decorateClass([
  ApiProperty37({
    example: "Some address in Mwandi",
    description: "The address of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "address", 2);
__decorateClass([
  ApiProperty37({
    example: 25e5,
    description: "The size of the reserve",
    required: true,
    type: Number
  })
], ReserveDeckResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty37({
    example: "LAND",
    description: "The type of the reserve",
    required: true,
    type: Number
  })
], ReserveDeckResponseDto.prototype, "type", 2);
__decorateClass([
  ApiProperty37({
    example: "ACTIVE",
    description: "The status of the reserve",
    required: true,
    type: String
  })
], ReserveDeckResponseDto.prototype, "status", 2);
__decorateClass([
  ApiProperty37({
    example: "somereserveintheworld@earthtoday.com",
    description: "The email template of the reserve",
    required: false,
    type: String
  })
], ReserveDeckResponseDto.prototype, "emailTemplate", 2);
__decorateClass([
  ApiProperty37({
    example: "",
    description: "The map animation config of the reserve",
    required: false,
    type: String
  })
], ReserveDeckResponseDto.prototype, "mapAnimationConfig", 2);
__decorateClass([
  ApiProperty37({
    example: "",
    description: "The image details of the reserve",
    required: false,
    nullable: true,
    type: () => ImageResponseDto3
  })
], ReserveDeckResponseDto.prototype, "image", 2);
var PresentationSettingDto = class {
};
__decorateClass([
  ApiProperty38({
    example: "",
    description: "The header color of the deck",
    required: true,
    type: String
  })
], PresentationSettingDto.prototype, "headerColor", 2);
__decorateClass([
  ApiProperty38({
    example: "",
    description: "The lable title of the deck",
    required: true,
    type: String
  })
], PresentationSettingDto.prototype, "labelTitle", 2);
__decorateClass([
  ApiProperty38({
    example: "",
    description: "The lable color of the deck",
    required: true,
    type: String
  })
], PresentationSettingDto.prototype, "labelColor", 2);
__decorateClass([
  ApiProperty38({
    example: "",
    description: "The lable icon of the deck",
    required: true,
    type: String
  })
], PresentationSettingDto.prototype, "labelIcon", 2);
var DeckWithoutFollowingResponseDto = class {
};
__decorateClass([
  ApiProperty38({
    example: "6714931170118234112",
    description: "The `Id` of the deck",
    required: true,
    type: String
  })
], DeckWithoutFollowingResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty38({
    example: "this is a fake name",
    description: "The name of the deck",
    required: true,
    type: String
  })
], DeckWithoutFollowingResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty38({
    example: "",
    description: "The description of the deck",
    required: true,
    type: String
  })
], DeckWithoutFollowingResponseDto.prototype, "description", 2);
__decorateClass([
  ApiProperty38({
    example: "2023-03-17T04:08:01.494Z",
    description: "The created date of the deck",
    required: true,
    type: String,
    format: "date-time"
  })
], DeckWithoutFollowingResponseDto.prototype, "created", 2);
__decorateClass([
  ApiProperty38({
    example: ["john doe", "third"],
    description: "The path of the deck",
    required: true,
    type: [String]
  })
], DeckWithoutFollowingResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty38({
    example: 51,
    description: "The remain count of cards of the deck",
    required: true,
    type: Number
  })
], DeckWithoutFollowingResponseDto.prototype, "remainingCardsCount", 2);
__decorateClass([
  ApiProperty38({
    example: {
      headerColor: "",
      labelColor: "",
      labelIcon: "",
      labelTitle: ""
    },
    description: "The presentation of the deck",
    type: PresentationSettingDto,
    required: true
  })
], DeckWithoutFollowingResponseDto.prototype, "presentation", 2);
__decorateClass([
  ApiProperty38({
    required: true,
    description: "The curator details of the deck",
    type: CuratorResponseDto
  })
], DeckWithoutFollowingResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty38({
    required: true,
    description: "The reserve of the deck",
    type: () => [ReserveDeckResponseDto]
  })
], DeckWithoutFollowingResponseDto.prototype, "reserves", 2);
__decorateClass([
  ApiProperty38({
    required: true,
    description: "The cards of the deck",
    oneOf: [
      { $ref: getSchemaPath3(CardActionResponseDto) },
      { $ref: getSchemaPath3(CardInfoResponseDto) },
      { $ref: getSchemaPath3(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath3(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath3(CardItemEditableResponseDto) },
      { $ref: getSchemaPath3(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], DeckWithoutFollowingResponseDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty38({
    example: ["people"],
    description: "The category principle of the deck",
    required: true,
    type: [String]
  })
], DeckWithoutFollowingResponseDto.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty38({
    example: true,
    description: "The uncache status of the deck",
    required: true,
    type: Boolean
  })
], DeckWithoutFollowingResponseDto.prototype, "useCache", 2);
__decorateClass([
  ApiProperty38({
    required: false,
    description: "The following detail of the deck",
    type: FollowingResponseDto
  })
], DeckWithoutFollowingResponseDto.prototype, "following", 2);
var DeckResponseDto = class extends DeckWithoutFollowingResponseDto {
};
__decorateClass([
  ApiProperty38({
    required: true,
    description: "The following detail of the deck",
    type: FollowingResponseDto
  })
], DeckResponseDto.prototype, "following", 2);
var DeckPreviewImage = class extends ImageResponseDto3 {
};
__decorateClass([
  ApiProperty39({
    required: true,
    type: Boolean,
    description: "Detect if image is profile image"
  })
], DeckPreviewImage.prototype, "isProfileImage", 2);
var DeckBackgroundImageDto = class {
};
__decorateClass([
  ApiProperty39({
    example: "BACKGROUND_IMAGE",
    required: true,
    type: String,
    description: "The type of deck background image"
  })
], DeckBackgroundImageDto.prototype, "type", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    type: ImageResponseDto3,
    description: "The background image of deck"
  })
], DeckBackgroundImageDto.prototype, "bgImage", 2);
var DeckPreviewImagesDto = class {
};
__decorateClass([
  ApiProperty39({
    example: "PREVIEW_IMAGES",
    required: true,
    type: String,
    description: "The type of deck background image"
  })
], DeckPreviewImagesDto.prototype, "type", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    type: DeckPreviewImage,
    isArray: true,
    description: "The preview images of deck"
  })
], DeckPreviewImagesDto.prototype, "previewImages", 2);
var DeckInfoResponseDto = class extends DeckResponseDto {
};
__decorateClass([
  ApiProperty39({
    example: 0,
    required: true,
    type: "integer",
    description: "Number of followers of this deck"
  })
], DeckInfoResponseDto.prototype, "followersCount", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    type: "integer",
    description: "number of cards in this deck"
  })
], DeckInfoResponseDto.prototype, "cardsCount", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    type: [DeckPreviewImage],
    description: "preview images"
  })
], DeckInfoResponseDto.prototype, "previewImages", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    oneOf: [
      {
        $ref: getSchemaPath4(DeckPreviewImagesDto)
      },
      {
        $ref: getSchemaPath4(DeckBackgroundImageDto)
      }
    ],
    type: () => DeckPreviewImagesDto || DeckBackgroundImageDto
  }),
  ValidateNested(),
  Type3(() => DeckPreviewImagesDto || DeckBackgroundImageDto, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: DeckPreviewImagesDto,
          name: DeckBackgroundImageType.PREVIEW_IMAGES
        },
        {
          value: DeckBackgroundImageDto,
          name: DeckBackgroundImageType.BACKGROUND_IMAGE
        }
      ]
    }
  })
], DeckInfoResponseDto.prototype, "backgroundImage", 2);
DeckInfoResponseDto = __decorateClass([
  ApiExtraModels4(DeckPreviewImagesDto, DeckBackgroundImageDto)
], DeckInfoResponseDto);
var DeckDetailResponseMeta = class {
};
__decorateClass([
  ApiProperty39({
    required: false,
    type: MetaTagsResponseDto,
    description: "Information used for open graph"
  })
], DeckDetailResponseMeta.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty39({
    required: false,
    type: CardStandardResponseDto || CardPromotionRepostResponseDto,
    description: "Deck's feature card"
  })
], DeckDetailResponseMeta.prototype, "featureCard", 2);
__decorateClass([
  ApiProperty39({
    required: false,
    type: MobileSystemDeckResponseDtoData,
    description: "The mobile system override of deck"
  })
], DeckDetailResponseMeta.prototype, "mobileSystemOverride", 2);
__decorateClass([
  ApiProperty39({
    required: false,
    description: "The default promotion of reserve",
    type: UnlimitedPromotionDto
  })
], DeckDetailResponseMeta.prototype, "reserveCollectiblePromotion", 2);
var DeckDetailResponseDto = class {
};
__decorateClass([
  ApiProperty39({
    required: true,
    type: DeckInfoResponseDto,
    description: "Deck's data"
  })
], DeckDetailResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty39({
    required: true,
    type: DeckDetailResponseMeta,
    description: "Deck's meta data"
  })
], DeckDetailResponseDto.prototype, "meta", 2);
var ExternalAuthorResponseDto = class {
};
__decorateClass([
  ApiProperty40({
    example: "4009264432830484480",
    description: "The id of the author",
    required: true,
    type: String
  })
], ExternalAuthorResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty40({
    example: null,
    description: "The image details of the author",
    nullable: true,
    default: null,
    required: true,
    type: () => String
  })
], ExternalAuthorResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty40({
    example: false,
    description: "The internal state of the author",
    required: true,
    default: false,
    type: Boolean
  })
], ExternalAuthorResponseDto.prototype, "internal", 2);
__decorateClass([
  ApiProperty40({
    example: "VnExpress",
    description: "The name of the author",
    required: true,
    type: String
  })
], ExternalAuthorResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty40({
    required: true,
    nullable: true,
    description: "The source of the author",
    type: () => SourceResponseDto
  })
], ExternalAuthorResponseDto.prototype, "source", 2);
__decorateClass([
  ApiProperty40({
    example: "https://et.urbn8.io/minhtrang/marinelife/4917447324852862976",
    required: false,
    description: "The url link of the author",
    type: String
  })
], ExternalAuthorResponseDto.prototype, "url", 2);
__decorateClass([
  ApiProperty40({
    example: "johndoe",
    description: "The username of the author",
    required: false,
    type: String
  })
], ExternalAuthorResponseDto.prototype, "username", 2);
var CardContentOriginalResponseDto = class {
};
__decorateClass([
  ApiProperty41({
    example: "6714931987198013440",
    description: "`Id` of the card",
    required: true,
    type: String
  })
], CardContentOriginalResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty41({
    example: "video",
    description: "type of the card",
    required: true,
    type: String
  })
], CardContentOriginalResponseDto.prototype, "type", 2);
__decorateClass([
  ApiProperty41({
    example: "",
    description: "Font family of the card",
    required: true,
    type: String
  })
], CardContentOriginalResponseDto.prototype, "fontFamily", 2);
__decorateClass([
  ApiProperty41({
    example: "https://youtube.com/watch?v=oRAOdVkRG5E&feature=shares",
    required: true,
    description: "Url link of the card",
    type: String
  })
], CardContentOriginalResponseDto.prototype, "url", 2);
__decorateClass([
  ApiProperty41({
    example: "LaLaLa \u266B Top Hit English Love Songs \u266B Acoustic Cover Of Popular TikTok Songs",
    required: true,
    description: "Title of the card",
    type: String
  })
], CardContentOriginalResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty41({
    example: "LaLaLa \u266B Top Hit English Love Songs \u266B Acoustic Cover Of Popular TikTok Songs\u27A4 Playlist Spotify: https://magicmusic.lnk.to/theBestPop\u25BD Follow Channel: \xA9Viral ...",
    required: true,
    description: "Summary of the card",
    type: String
  })
], CardContentOriginalResponseDto.prototype, "summary", 2);
__decorateClass([
  ApiProperty41({
    example: [],
    default: [],
    required: true,
    description: "Authors of the card",
    type: [ExternalAuthorResponseDto]
  })
], CardContentOriginalResponseDto.prototype, "authors", 2);
__decorateClass([
  ApiProperty41({
    required: true,
    nullable: true,
    description: "Image of the card",
    type: () => ImageResponseDto3
  })
], CardContentOriginalResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty41({
    required: true,
    nullable: true,
    description: "Source of the card",
    type: () => SourceResponseDto
  })
], CardContentOriginalResponseDto.prototype, "source", 2);
var CardContentRemovedResponseDto = class {
};
__decorateClass([
  ApiProperty42({
    example: "2023-03-24T04:43:52.042Z",
    required: true,
    description: "The time this card has been removed",
    type: Date
  })
], CardContentRemovedResponseDto.prototype, "removed", 2);
__decorateClass([
  ApiProperty42({
    example: "article",
    required: true,
    description: "Original type of the card",
    type: String
  })
], CardContentRemovedResponseDto.prototype, "originalType", 2);
__decorateClass([
  ApiProperty42({
    example: "",
    required: true,
    description: "The reason to remove the card",
    type: String
  })
], CardContentRemovedResponseDto.prototype, "removalReason", 2);
__decorateClass([
  ApiProperty42({
    required: true,
    example: "removed",
    description: "Card has been removed or not?",
    default: "removed",
    enum: ["removed"]
  })
], CardContentRemovedResponseDto.prototype, "type", 2);
var RepostResponseDto = class {
};
__decorateClass([
  ApiProperty43({
    example: "6763300133506002944",
    required: true,
    description: "Id of the card",
    type: String
  })
], RepostResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty43({
    example: "",
    description: "Comment of the card",
    required: true,
    type: String
  })
], RepostResponseDto.prototype, "comment", 2);
__decorateClass([
  ApiProperty43({
    example: "2023-03-24T04:43:52.094Z",
    description: "The time this card has been updated",
    required: false,
    type: Date
  })
], RepostResponseDto.prototype, "updated", 2);
__decorateClass([
  ApiProperty43({
    example: "2023-03-24T04:43:52.042Z",
    description: "The time this card has been published",
    required: true,
    type: Date
  })
], RepostResponseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty43({
    required: true,
    description: "The curator of the card repost",
    type: CuratorResponseDto
  })
], RepostResponseDto.prototype, "curator", 2);
var CardContentRepostResponseDto = class {
};
__decorateClass([
  ApiProperty43({
    required: false,
    title: "CardStandardResponseDto",
    description: "The origin card",
    type: () => CardStandardResponseDto
  })
], CardContentRepostResponseDto.prototype, "original", 2);
__decorateClass([
  ApiProperty43({
    required: false,
    description: "The content of the card repost",
    type: RepostResponseDto
  })
], CardContentRepostResponseDto.prototype, "repost", 2);
__decorateClass([
  ApiProperty43({
    example: "",
    description: "The comment of the card",
    required: true,
    type: String
  })
], CardContentRepostResponseDto.prototype, "comment", 2);
__decorateClass([
  ApiProperty43({
    example: "article",
    description: "The type of the card",
    required: true,
    type: String
  })
], CardContentRepostResponseDto.prototype, "type", 2);
var CardResponseBaseDto = class {
};
__decorateClass([
  ApiProperty44({
    example: "6763300137410899968",
    description: "The `Id` of the card",
    required: true,
    type: String
  })
], CardResponseBaseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty44({
    example: "single",
    description: "The size of the card",
    required: true,
    type: String
  })
], CardResponseBaseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty44({
    example: "published",
    description: "The state of the card",
    required: true,
    type: String
  })
], CardResponseBaseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty44({
    example: "2023-03-24T04:43:52.094Z",
    description: "The update time of the card",
    required: true,
    type: Date
  })
], CardResponseBaseDto.prototype, "updated", 2);
__decorateClass([
  ApiProperty44({
    example: "2023-03-24T04:43:52.042Z",
    description: "The publish time of the card",
    required: false,
    type: String
  })
], CardResponseBaseDto.prototype, "published", 2);
__decorateClass([
  ApiProperty44({
    required: true,
    description: "The curator of the card",
    type: CuratorResponseDto
  })
], CardResponseBaseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty44({
    example: false,
    description: "Feature for profile of the card",
    required: false,
    type: Boolean
  })
], CardResponseBaseDto.prototype, "featuredForProfile", 2);
__decorateClass([
  ApiProperty44({
    example: true,
    description: "The validity of the card",
    required: true,
    type: Boolean
  })
], CardResponseBaseDto.prototype, "valid", 2);
__decorateClass([
  ApiProperty44({
    example: "",
    description: "The comment of the card",
    required: true,
    type: String
  })
], CardResponseBaseDto.prototype, "comment", 2);
__decorateClass([
  ApiProperty44({
    required: false,
    type: MetaTagsResponseDto,
    description: "The meta tags of the card"
  })
], CardResponseBaseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty44({
    example: "EDITORIAL",
    enum: CardLayoutType,
    required: true,
    description: "The layout type of the card"
  })
], CardResponseBaseDto.prototype, "layoutType", 2);
var MetadataResponse = class {
};
__decorateClass([
  ApiProperty45({
    required: true,
    description: "Number of reposts for the card",
    type: Number,
    example: 100
  })
], MetadataResponse.prototype, "repostsCount", 2);
__decorateClass([
  ApiProperty45({
    required: true,
    description: "Sum of votes received for the card",
    type: Number,
    example: 50
  })
], MetadataResponse.prototype, "votesSum", 2);
__decorateClass([
  ApiProperty45({
    required: true,
    description: "Indicates if the card is reposted by the user",
    type: Boolean,
    example: true
  })
], MetadataResponse.prototype, "reposted", 2);
__decorateClass([
  ApiProperty45({
    required: true,
    description: "Indicates if the card is starred by the user",
    type: Boolean,
    example: false
  })
], MetadataResponse.prototype, "starred", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    description: "The user's vote on the card, if any",
    type: String,
    nullable: true,
    example: "upvote"
  })
], MetadataResponse.prototype, "vote", 2);
var SourceResponseDto = class {
};
__decorateClass([
  ApiProperty45({
    example: "2460029736153223168",
    required: true,
    type: String
  })
], SourceResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty45({
    example: "YouTube",
    required: true,
    type: String
  })
], SourceResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty45({
    example: "",
    required: true,
    type: String
  })
], SourceResponseDto.prototype, "icon", 2);
__decorateClass([
  ApiProperty45({
    example: "https://www.youtube.com/",
    required: true,
    type: String
  })
], SourceResponseDto.prototype, "url", 2);
var MetadataResponseDto = class {
};
__decorateClass([
  ApiProperty45({
    example: 1,
    required: true,
    type: Number
  })
], MetadataResponseDto.prototype, "repostsCount", 2);
__decorateClass([
  ApiProperty45({
    example: 1,
    required: true,
    type: Number
  })
], MetadataResponseDto.prototype, "votesSum", 2);
__decorateClass([
  ApiProperty45({
    example: true,
    required: true,
    type: Boolean
  })
], MetadataResponseDto.prototype, "reposted", 2);
__decorateClass([
  ApiProperty45({
    example: false,
    required: true,
    type: Boolean
  })
], MetadataResponseDto.prototype, "starred", 2);
__decorateClass([
  ApiProperty45({
    example: null,
    nullable: true,
    required: true,
    type: String
  })
], MetadataResponseDto.prototype, "vote", 2);
var CardStandardResponseDto = class extends CardResponseBaseDto {
};
__decorateClass([
  ApiProperty45({
    example: ["people"],
    description: "The category principle of the deck",
    required: true,
    type: [String]
  })
], CardStandardResponseDto.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty45({
    example: "curator",
    description: "The poster type of the deck",
    required: true,
    type: String
  })
], CardStandardResponseDto.prototype, "posterType", 2);
__decorateClass([
  ApiProperty45({
    example: false,
    description: "The feature status of the deck",
    required: false,
    type: Boolean
  })
], CardStandardResponseDto.prototype, "featured", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    description: "The meta data of the deck",
    type: MetadataResponseDto
  })
], CardStandardResponseDto.prototype, "metadata", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    type: DeckForCardResponseDto
  })
], CardStandardResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    nullable: true,
    description: "The content of the card",
    title: "",
    type: () => CardContentRemovedResponseDto || CardContentRepostResponseDto || CardContentOriginalResponseDto,
    oneOf: [
      {
        $ref: getSchemaPath5(CardContentRemovedResponseDto)
      },
      {
        $ref: getSchemaPath5(CardContentRepostResponseDto)
      },
      {
        $ref: getSchemaPath5(CardContentOriginalResponseDto)
      }
    ]
  })
], CardStandardResponseDto.prototype, "content", 2);
__decorateClass([
  ApiProperty45({
    required: true,
    description: "The type of the card",
    type: String,
    enum: Object.values(CardType).filter((v) => v != "CAMPAIGN")
  })
], CardStandardResponseDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty45({
    example: "12312313123",
    description: "The `Id` of the deck repost",
    required: false,
    type: String
  })
], CardStandardResponseDto.prototype, "deckRepostId", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    description: "Deck reponse repost",
    type: () => DeckDetailResponseDto
  })
], CardStandardResponseDto.prototype, "deckRepost", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    description: "The profile of the deck repost",
    type: UserResponseDto
  })
], CardStandardResponseDto.prototype, "profileRepost", 2);
__decorateClass([
  ApiProperty45({
    example: "342342344123213",
    required: false,
    type: String,
    description: "The `id` of deck auto play"
  })
], CardStandardResponseDto.prototype, "autoplaySourceDeckId", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    type: CardStandardResponseDto,
    description: "The `id` of card auto play"
  })
], CardStandardResponseDto.prototype, "autoplaySourceCard", 2);
__decorateClass([
  ApiProperty45({
    required: false,
    type: CardStandardResponseDto,
    description: "The auto play of card following personalization"
  })
], CardStandardResponseDto.prototype, "autoplayFollowingPersonalization", 2);
CardStandardResponseDto = __decorateClass([
  ApiExtraModels5(
    CardContentRemovedResponseDto,
    CardContentRepostResponseDto,
    CardContentOriginalResponseDto
  )
], CardStandardResponseDto);
var ICampaignDto = class {
};
__decorateClass([
  ApiProperty46({
    example: "123456878965415",
    description: "`Id` of the Campaign",
    format: "int64",
    required: true,
    type: String
  })
], ICampaignDto.prototype, "id", 2);
__decorateClass([
  ApiProperty46({
    example: "fake name",
    description: "Name of the Campaign",
    required: true,
    type: String
  })
], ICampaignDto.prototype, "name", 2);
__decorateClass([
  ApiProperty46({
    example: "https://vnexpress.net/cuu-giam-doc-benh-vien-tim-ha-noi-nguyen-quang-tuan-bi-de-nghi-hon-4-nam-tu-4594662.html",
    description: "Link of the Campaign",
    required: true,
    type: String
  })
], ICampaignDto.prototype, "linkName", 2);
__decorateClass([
  ApiProperty46({
    example: 132456456,
    required: true,
    type: Number
  })
], ICampaignDto.prototype, "goal", 2);
__decorateClass([
  ApiProperty46({
    required: true,
    type: String
  })
], ICampaignDto.prototype, "pledge", 2);
__decorateClass([
  ApiProperty46({
    required: true,
    type: String
  })
], ICampaignDto.prototype, "desc", 2);
__decorateClass([
  ApiProperty46({
    example: "STATE1",
    description: "Status of the Campaign",
    required: true,
    enum: [CampaignStatus.STATE1, CampaignStatus.STATE2],
    type: String
  })
], ICampaignDto.prototype, "status", 2);
__decorateClass([
  ApiProperty46({
    required: true,
    type: String
  })
], ICampaignDto.prototype, "campaignStates", 2);
__decorateClass([
  ApiProperty46({
    required: true,
    type: String
  })
], ICampaignDto.prototype, "openPreview", 2);
var IImageCustomDto = class {
};
__decorateClass([
  ApiProperty46({
    example: "123456789",
    description: "`Id` of the image",
    required: true,
    format: "int64",
    type: String
  })
], IImageCustomDto.prototype, "id", 2);
__decorateClass([
  ApiProperty46({
    example: "awsKey",
    description: "awsKey of the image",
    required: true,
    nullable: true,
    type: String
  })
], IImageCustomDto.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty46({
    example: "awsBucket",
    description: "awsBucket of the image",
    required: true,
    nullable: true,
    type: String
  })
], IImageCustomDto.prototype, "awsBucket", 2);
__decorateClass([
  ApiProperty46({
    example: "https://vnexpress.net/cuu-giam-doc-benh-vien-tim-ha-noi-nguyen-quang-tuan-bi-de-nghi-hon-4-nam-tu-4594662.html",
    description: "Url of the image",
    required: true,
    type: String
  })
], IImageCustomDto.prototype, "url", 2);
__decorateClass([
  ApiProperty46({
    example: "",
    description: "Primary color of the image",
    required: true,
    type: String
  })
], IImageCustomDto.prototype, "primaryColor", 2);
__decorateClass([
  ApiProperty46({
    example: 12,
    description: "Original width of the image",
    required: true,
    type: Number
  })
], IImageCustomDto.prototype, "originalWidth", 2);
__decorateClass([
  ApiProperty46({
    example: 12,
    description: "Original height of the image",
    required: true,
    type: Number
  })
], IImageCustomDto.prototype, "originalHeight", 2);
__decorateClass([
  ApiProperty46({
    example: true,
    description: "Transparency of the image",
    required: true,
    nullable: true,
    type: Number
  })
], IImageCustomDto.prototype, "isTransparent", 2);
var ICampaignResponseDto = class extends ICampaignDto {
};
__decorateClass([
  ApiProperty46({
    description: "Image header of the card",
    type: IImageCustomDto,
    required: true
  })
], ICampaignResponseDto.prototype, "headerImage", 2);
__decorateClass([
  ApiProperty46({
    description: "Image of the card",
    type: IImageCustomDto,
    required: true
  })
], ICampaignResponseDto.prototype, "cardImage", 2);
__decorateClass([
  ApiProperty46({
    description: "The number of protection card",
    example: 322,
    type: Number,
    required: true
  })
], ICampaignResponseDto.prototype, "protectedCounts", 2);
var ICampaignHeadeCardResponseResponseDto = class extends ICampaignResponseDto {
};
__decorateClass([
  ApiProperty46({
    description: "`Id` of the image",
    example: "124658113212132132",
    type: String,
    format: "int64",
    required: true
  })
], ICampaignHeadeCardResponseResponseDto.prototype, "previewImage", 2);
var CardResponseCampaignDto = class extends CardResponseBaseDto {
};
__decorateClass([
  ApiProperty46({
    description: "Type of content",
    example: "CAMPAIGN",
    type: String,
    required: true
  })
], CardResponseCampaignDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty46({
    oneOf: [
      {
        $ref: getSchemaPath6(ICampaignResponseDto)
      },
      {
        $ref: getSchemaPath6(ICampaignHeadeCardResponseResponseDto)
      }
    ],
    title: "",
    required: true,
    type: () => [Object]
  })
], CardResponseCampaignDto.prototype, "campaign", 2);
CardResponseCampaignDto = __decorateClass([
  ApiExtraModels6(ICampaignResponseDto, ICampaignHeadeCardResponseResponseDto)
], CardResponseCampaignDto);
var RelatedCardsGetResponseDto = class {
};
__decorateClass([
  ApiProperty47({
    required: true,
    description: "The related cards of target card",
    oneOf: [
      { $ref: getSchemaPath7(CardStandardResponseDto) },
      { $ref: getSchemaPath7(CardActionResponseDto) },
      { $ref: getSchemaPath7(CardInfoResponseDto) },
      { $ref: getSchemaPath7(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath7(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath7(CardItemEditableResponseDto) },
      { $ref: getSchemaPath7(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], RelatedCardsGetResponseDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty47({
    example: "",
    required: true,
    description: "The single deck of related cards",
    type: Boolean
  })
], RelatedCardsGetResponseDto.prototype, "singleDeck", 2);
var BackgroundImageMutationType = /* @__PURE__ */ ((BackgroundImageMutationType2) => {
  BackgroundImageMutationType2["UPLOAD"] = "UPLOAD";
  BackgroundImageMutationType2["REMOVAL"] = "REMOVAL";
  BackgroundImageMutationType2["NOOP"] = "NOOP";
  return BackgroundImageMutationType2;
})(BackgroundImageMutationType || {});
var BackgroundImageMutationUploadDto = class {
};
__decorateClass([
  ApiProperty48({
    type: String,
    required: true,
    description: "The type of background image mutation",
    example: "UPLOAD"
    /* UPLOAD */
  }),
  IsNotEmpty(),
  IsEnum(BackgroundImageMutationType)
], BackgroundImageMutationUploadDto.prototype, "type", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: true,
    description: "The upload token of background image",
    example: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImF1dGhvcml6YXRpb24iOnRydWV9.eyJpbWFnZUlkIjoiNzQ1OTczODE2MjEyMjM1ODc4NCIsInRhcmdldCI6IlVTRVJfUFJPRklMRSIsInVzZXJJZCI6IjM3NDc3Mjg0NzUyMzI2MjQ2NDAiLCJpbWFnZUtleSI6Ii91c2Vycy8zNzQ3NzI4NDc1MjMyNjI0NjQwL3Byb2ZpbGUvOWMxYjRiNWMtZjVjYi00NjFhLWJlNzAtMWIxZjc3YmU0NmQ1LTRrLXNwaWRlci1tYW4tbWlsZXMtbW9yYWxlcy0yMDIwLXFnLmpwZyIsImlhdCI6MTY4ODM3MjE3NywiZXhwIjoxNjg4MzcyNDc3fQ.yT5erWJ-pixahg92z9oxusYCg5WKlpAQHfCV32XCjcM`
  }),
  IsNotEmpty()
], BackgroundImageMutationUploadDto.prototype, "uploadToken", 2);
var BackgroundImageMutationRemovalDto = class {
};
__decorateClass([
  ApiProperty48({
    type: String,
    required: true,
    description: "The type of background image mutation",
    example: "REMOVAL"
    /* REMOVAL */
  }),
  IsEnum(BackgroundImageMutationType),
  IsNotEmpty()
], BackgroundImageMutationRemovalDto.prototype, "type", 2);
var BackgroundImageMutationNoopDto = class {
};
__decorateClass([
  ApiProperty48({
    type: String,
    required: true,
    description: "The type of background image mutation",
    example: "NOOP"
    /* NOOP */
  }),
  IsEnum(BackgroundImageMutationType),
  IsNotEmpty()
], BackgroundImageMutationNoopDto.prototype, "type", 2);
var CardActionUpdatePayloadContentDto = class {
};
__decorateClass([
  ApiProperty48({
    type: String,
    required: true,
    description: "The type of card",
    example: CardType.ACTION
  }),
  IsIn([CardType.ACTION])
], CardActionUpdatePayloadContentDto.prototype, "type", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: false,
    description: "The title of card",
    example: "title"
  }),
  IsOptional(),
  MaxLength(82)
], CardActionUpdatePayloadContentDto.prototype, "title", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: false,
    description: "The subtitle of card",
    example: "subtitle"
  }),
  IsOptional(),
  IsString(),
  MaxLength(90)
], CardActionUpdatePayloadContentDto.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty48({
    enum: CardSize,
    required: false,
    description: "The size of card",
    example: CardSize.SINGLE
  }),
  IsOptional(),
  IsEnum(CardSize)
], CardActionUpdatePayloadContentDto.prototype, "size", 2);
__decorateClass([
  ApiProperty48({
    required: false,
    description: "The background image mutation of card",
    oneOf: [
      {
        $ref: getSchemaPath8(BackgroundImageMutationUploadDto)
      },
      {
        $ref: getSchemaPath8(BackgroundImageMutationRemovalDto)
      },
      {
        $ref: getSchemaPath8(BackgroundImageMutationNoopDto)
      }
    ],
    type: () => BackgroundImageMutationUploadDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationNoopDto
  }),
  IsOptional(),
  ValidateNested(),
  Type4(
    () => BackgroundImageMutationNoopDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationUploadDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: BackgroundImageMutationNoopDto,
            name: "NOOP"
            /* NOOP */
          },
          {
            value: BackgroundImageMutationRemovalDto,
            name: "REMOVAL"
            /* REMOVAL */
          },
          {
            value: BackgroundImageMutationUploadDto,
            name: "UPLOAD"
            /* UPLOAD */
          }
        ]
      }
    }
  )
], CardActionUpdatePayloadContentDto.prototype, "backgroundImageMutation", 2);
__decorateClass([
  ApiProperty48({
    enum: TextAlignment,
    required: false,
    description: "The text alignment of card",
    example: TextAlignment.LEFT
  }),
  IsOptional(),
  IsEnum(TextAlignment)
], CardActionUpdatePayloadContentDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty48({
    enum: TextColor,
    required: false,
    description: "The text color of card",
    example: TextColor.BLACK
  }),
  IsOptional(),
  IsEnum(TextColor)
], CardActionUpdatePayloadContentDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: false,
    description: "The button text of card",
    example: "button text"
  }),
  IsOptional(),
  IsString(),
  MaxLength(50)
], CardActionUpdatePayloadContentDto.prototype, "buttonText", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: false,
    description: "The url of card",
    example: "https://earthtoday.com"
  }),
  IsOptional(),
  IsNotEmpty(),
  IsString()
], CardActionUpdatePayloadContentDto.prototype, "url", 2);
CardActionUpdatePayloadContentDto = __decorateClass([
  ApiExtraModels7(
    BackgroundImageMutationUploadDto,
    BackgroundImageMutationRemovalDto,
    BackgroundImageMutationNoopDto
  )
], CardActionUpdatePayloadContentDto);
var CardActionUpdatePayloadDataDto = class {
};
__decorateClass([
  ApiProperty48({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  }),
  IsOptional(),
  IsString()
], CardActionUpdatePayloadDataDto.prototype, "deckId", 2);
__decorateClass([
  ApiProperty48({
    type: String,
    required: false,
    description: "The state of card",
    example: CardStatus.PUBLISHED
  }),
  IsOptional(),
  IsIn([CardStatus.PUBLISHED, CardStatus.DRAFTING])
], CardActionUpdatePayloadDataDto.prototype, "state", 2);
__decorateClass([
  ApiProperty48({
    type: CardActionUpdatePayloadContentDto,
    required: true,
    description: "The content of card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type4(() => CardActionUpdatePayloadContentDto)
], CardActionUpdatePayloadDataDto.prototype, "content", 2);
var CardActionUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty48({
    type: () => CardActionUpdatePayloadDataDto,
    required: true,
    description: "The payload data for updating card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type4(() => CardActionUpdatePayloadDataDto)
], CardActionUpdatePayloadDto.prototype, "data", 2);
var BackgroundImageMutationUpload = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([
    "UPLOAD"
    /* UPLOAD */
  ]),
  ApiProperty49({
    example: "UPLOAD",
    description: "The type of background image mutation. The value must be UPLOAD",
    type: String,
    enum: [
      "UPLOAD"
      /* UPLOAD */
    ],
    required: true
  })
], BackgroundImageMutationUpload.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  ApiProperty49({
    example: "string",
    description: "The uploadToken of background image mutation",
    type: String,
    required: true
  })
], BackgroundImageMutationUpload.prototype, "uploadToken", 2);
var BackgroundImageMutationNoop = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([
    "NOOP"
    /* NOOP */
  ]),
  ApiProperty49({
    example: "NOOP",
    description: "The type of background image mutation. The value must be NOOP",
    type: String,
    enum: [
      "UPLOAD"
      /* UPLOAD */
    ],
    required: true
  })
], BackgroundImageMutationNoop.prototype, "type", 2);
var CardActionContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardType.ACTION]),
  ApiProperty49({
    example: "ACTION",
    description: "The type of card action content. The value must be ACTION",
    type: String,
    enum: [CardType.ACTION],
    required: true
  })
], CardActionContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  ApiProperty49({
    example: "https://earthtoday.com/counter",
    description: "The url of card",
    type: String,
    required: true
  })
], CardActionContent.prototype, "url", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(82),
  ApiProperty49({
    example: "Title",
    description: "Title of the card",
    type: String,
    required: false
  })
], CardActionContent.prototype, "title", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty49({
    example: "subtitle",
    description: "Subtitle of the card",
    type: String,
    required: false
  })
], CardActionContent.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty49({
    description: "Background image mutation of the card",
    oneOf: [
      {
        $ref: getSchemaPath9(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath9(BackgroundImageMutationNoop)
      }
    ],
    required: false,
    type: () => BackgroundImageMutationUpload || BackgroundImageMutationNoop
  }),
  ValidateNested(),
  Type5((input) => {
    switch (input == null ? void 0 : input.object[input.property].type) {
      case "UPLOAD": {
        return BackgroundImageMutationUpload;
      }
      default: {
        return BackgroundImageMutationNoop;
      }
    }
  }),
  IsOptional()
], CardActionContent.prototype, "backgroundImageMutation", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsIn(Object.values(TextAlignment)),
  ApiProperty49({
    example: "LEFT",
    description: "Text alignment of the card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardActionContent.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsIn(Object.values(TextColor)),
  ApiProperty49({
    example: "BLACK",
    description: "Text color of the card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardActionContent.prototype, "textColor", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty49({
    example: "BLACK",
    description: "Button text of the card",
    type: String,
    required: false
  })
], CardActionContent.prototype, "buttonText", 2);
CardActionContent = __decorateClass([
  ApiExtraModels8(BackgroundImageMutationUpload, BackgroundImageMutationNoop)
], CardActionContent);
var CardEditableUpdatePayloadDtoDataContentSection = class {
};
__decorateClass([
  IsOptional(),
  IsNumberString(),
  ApiProperty50({
    type: String,
    format: "int64",
    required: false,
    description: `The card open section id. 
    
 If the card open section has id field, the api will update exist card open section by this id.
    
 If the card open section does not have id field the api will create new card open section in current position.
    
 Any sections that exist in the database but are not included in the update payload will be deleted by the API.`,
    example: "1234567890"
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "id", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty50({
    example: "headline",
    description: "The Headline of card open section",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "headline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty50({
    example: "headline",
    description: "The Subheadline of card open section",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "subheadline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsJSON(),
  ApiProperty50({
    example: "{}",
    description: "The summary of card open section. The value must be json string",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "summary", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(28),
  ApiProperty50({
    example: "buttonText",
    description: "The buttonText of card open section",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "buttonText", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty50({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of card open section",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "buttonUrl", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextAlignment),
  ApiProperty50({
    example: TextAlignment.LEFT,
    description: "Text alignment of card open section",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextColor),
  ApiProperty50({
    example: TextColor.BLACK,
    description: "the text color of card open section",
    type: String,
    enum: TextColor,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "textColor", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty50({
    required: false,
    description: "The section background color of card open section",
    type: String,
    example: "#fff"
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "sectionBgColor", 2);
__decorateClass([
  ValidateIf((o) => !o.sectionMediaLinkUrl),
  IsOptional(),
  ValidateNested(),
  Type6(
    () => BackgroundImageMutationUpload || BackgroundImageMutationNoop || BackgroundImageMutationRemovalDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: BackgroundImageMutationUpload,
            name: "UPLOAD"
            /* UPLOAD */
          },
          {
            value: BackgroundImageMutationNoop,
            name: "NOOP"
            /* NOOP */
          },
          {
            value: BackgroundImageMutationRemovalDto,
            name: "REMOVAL"
            /* REMOVAL */
          }
        ]
      }
    }
  ),
  ApiProperty50({
    description: "The section media image mutation of card open section",
    oneOf: [
      {
        $ref: getSchemaPath10(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath10(BackgroundImageMutationNoop)
      },
      {
        $ref: getSchemaPath10(BackgroundImageMutationRemovalDto)
      }
    ],
    required: false,
    type: () => Object
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "sectionMediaImageMutation", 2);
__decorateClass([
  ValidateIf((o) => !o.sectionMediaImageMutation),
  IsOptional(),
  IsString(),
  ApiProperty50({
    required: false,
    description: "The section media link url of card open section",
    type: String,
    example: "https://earthtoday.com/images/..."
  })
], CardEditableUpdatePayloadDtoDataContentSection.prototype, "sectionMediaLinkUrl", 2);
var CardEditableUpdatePayloadDtoDataContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardType.EDITABLE]),
  ApiProperty50({
    example: CardType.EDITABLE,
    description: "The type of card content. The value must be EDITABLE",
    type: String,
    enum: [CardType.EDITABLE],
    required: true
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "type", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty50({
    example: "headline",
    description: "The Headline of front card",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "headline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty50({
    example: "headline",
    description: "The Subheadline of front card",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "subheadline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty50({
    example: "summary",
    description: "The summary of front card",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "summary", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(28),
  ApiProperty50({
    example: "buttonText",
    description: "The buttonText of front card",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "buttonText", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty50({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of front card",
    type: String,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "buttonUrl", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextAlignment),
  ApiProperty50({
    example: TextAlignment.LEFT,
    description: "Text alignment of the front card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextColor),
  ApiProperty50({
    example: TextColor.BLACK,
    description: "the text color of front card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "textColor", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty50({
    required: false,
    description: "The background color of front card",
    type: String,
    example: "#fff"
  })
], CardEditableUpdatePayloadDtoDataContent.prototype, "bgColor", 2);
__decorateClass([
  ApiProperty50({
    description: "The background image mutation of front card",
    oneOf: [
      {
        $ref: getSchemaPath10(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath10(BackgroundImageMutationNoop)
      },
      {
        $ref: getSchemaPath10(BackgroundImageMutationRemovalDto)
      }
    ],
    required: false,
    type: () => Object
  }),
  ValidateNested(),
  Type6(
    () => BackgroundImageMutationUpload || BackgroundImageMutationNoop || BackgroundImageMutationRemovalDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: BackgroundImageMutationUpload,
            name: "UPLOAD"
            /* UPLOAD */
          },
          {
            value: BackgroundImageMutationNoop,
            name: "NOOP"
            /* NOOP */
          },
          {
            value: BackgroundImageMutationRemovalDto,
            name: "REMOVAL"
            /* REMOVAL */
          }
        ]
      }
    }
  ),
  IsOptional()
], CardEditableUpdatePayloadDtoDataContent.prototype, "bgImageMutation", 2);
__decorateClass([
  ApiProperty50({
    type: CardEditableUpdatePayloadDtoDataContentSection,
    description: "The card open sections of card",
    isArray: true,
    required: false
  }),
  ValidateNested({ each: true }),
  Type6(() => CardEditableUpdatePayloadDtoDataContentSection),
  IsNotEmpty()
], CardEditableUpdatePayloadDtoDataContent.prototype, "cardOpenSections", 2);
var CardEditableUpdatePayloadDtoData = class {
};
__decorateClass([
  ApiProperty50({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  }),
  IsOptional(),
  IsString()
], CardEditableUpdatePayloadDtoData.prototype, "deckId", 2);
__decorateClass([
  ApiProperty50({
    type: String,
    required: false,
    description: "The state of card",
    example: CardStatus.PUBLISHED
  }),
  IsOptional(),
  IsIn([CardStatus.PUBLISHED, CardStatus.DRAFTING])
], CardEditableUpdatePayloadDtoData.prototype, "state", 2);
__decorateClass([
  ApiProperty50({
    type: CardEditableUpdatePayloadDtoDataContent,
    required: true,
    description: "The content of card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type6(() => CardEditableUpdatePayloadDtoDataContent)
], CardEditableUpdatePayloadDtoData.prototype, "content", 2);
var CardEditableUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty50({
    type: () => CardEditableUpdatePayloadDtoData,
    required: true,
    description: "The payload data for updating card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type6(() => CardEditableUpdatePayloadDtoData)
], CardEditableUpdatePayloadDto.prototype, "data", 2);
var HtmlBundleMutationUploadDto = class {
};
__decorateClass([
  ApiProperty51({
    type: String,
    required: true,
    description: "The mutation type",
    example: "UPLOAD"
    /* UPLOAD */
  }),
  IsIn([
    "UPLOAD"
    /* UPLOAD */
  ]),
  IsString(),
  IsNotEmpty()
], HtmlBundleMutationUploadDto.prototype, "type", 2);
__decorateClass([
  ApiProperty51({
    type: String,
    required: true,
    description: "The upload token of zip file",
    example: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImF1dGhvcml6YXRpb24iOnRydWV9.eyJpbWFnZUlkIjoiNzQ1OTczODE2MjEyMjM1ODc4NCIsInRhcmdldCI6IlVTRVJfUFJPRklMRSIsInVzZXJJZCI6IjM3NDc3Mjg0NzUyMzI2MjQ2NDAiLCJpbWFnZUtleSI6Ii91c2Vycy8zNzQ3NzI4NDc1MjMyNjI0NjQwL3Byb2ZpbGUvOWMxYjRiNWMtZjVjYi00NjFhLWJlNzAtMWIxZjc3YmU0NmQ1LTRrLXNwaWRlci1tYW4tbWlsZXMtbW9yYWxlcy0yMDIwLXFnLmpwZyIsImlhdCI6MTY4ODM3MjE3NywiZXhwIjoxNjg4MzcyNDc3fQ.yT5erWJ-pixahg92z9oxusYCg5WKlpAQHfCV32XCjcM`
  }),
  IsString(),
  IsNotEmpty()
], HtmlBundleMutationUploadDto.prototype, "uploadToken", 2);
var HtmlBundleMutationNoopDto = class {
};
__decorateClass([
  ApiProperty51({
    type: String,
    required: true,
    description: "The mutation type",
    example: "NOOP"
    /* NOOP */
  }),
  IsIn([
    "NOOP"
    /* NOOP */
  ]),
  IsString(),
  IsNotEmpty()
], HtmlBundleMutationNoopDto.prototype, "type", 2);
var CardInfoUpdatePayloadContentDto = class {
};
__decorateClass([
  ApiProperty52({
    type: String,
    required: true,
    description: "The type of card",
    example: CardType.INFO
  }),
  IsIn([CardType.INFO])
], CardInfoUpdatePayloadContentDto.prototype, "type", 2);
__decorateClass([
  ApiProperty52({
    type: String,
    required: false,
    description: "The title of card",
    example: "title"
  }),
  IsNotEmpty(),
  MaxLength(82),
  IsString(),
  IsOptional()
], CardInfoUpdatePayloadContentDto.prototype, "title", 2);
__decorateClass([
  ApiProperty52({
    type: String,
    required: false,
    description: "The subtitle of card",
    example: "subtitle"
  }),
  IsOptional(),
  IsString(),
  MaxLength(90)
], CardInfoUpdatePayloadContentDto.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty52({
    required: false,
    description: "The background image mutation of card",
    oneOf: [
      {
        $ref: getSchemaPath11(BackgroundImageMutationUploadDto)
      },
      {
        $ref: getSchemaPath11(BackgroundImageMutationRemovalDto)
      },
      {
        $ref: getSchemaPath11(BackgroundImageMutationNoopDto)
      }
    ],
    type: () => BackgroundImageMutationUploadDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationNoopDto
  }),
  IsOptional(),
  ValidateNested(),
  Type7(
    () => BackgroundImageMutationUploadDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationNoopDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: BackgroundImageMutationUploadDto,
            name: "UPLOAD"
            /* UPLOAD */
          },
          {
            value: BackgroundImageMutationRemovalDto,
            name: "REMOVAL"
            /* REMOVAL */
          },
          {
            value: BackgroundImageMutationNoopDto,
            name: "NOOP"
            /* NOOP */
          }
        ]
      }
    }
  )
], CardInfoUpdatePayloadContentDto.prototype, "backgroundImageMutation", 2);
__decorateClass([
  ApiProperty52({
    type: String,
    required: false,
    description: "If card has no background image, the background color would be used",
    example: "#fff"
  }),
  IsOptional(),
  IsString(),
  MaxLength(100)
], CardInfoUpdatePayloadContentDto.prototype, "backgroundColor", 2);
__decorateClass([
  ApiProperty52({
    required: false,
    description: "The html bundle mutation of card",
    oneOf: [
      {
        $ref: getSchemaPath11(HtmlBundleMutationUploadDto)
      },
      {
        $ref: getSchemaPath11(HtmlBundleMutationNoopDto)
      }
    ],
    type: () => HtmlBundleMutationUploadDto || HtmlBundleMutationNoopDto
  }),
  IsOptional(),
  ValidateNested(),
  Type7(() => HtmlBundleMutationUploadDto || HtmlBundleMutationNoopDto, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: HtmlBundleMutationUploadDto,
          name: "UPLOAD"
          /* UPLOAD */
        },
        {
          value: HtmlBundleMutationNoopDto,
          name: "NOOP"
          /* NOOP */
        }
      ]
    }
  })
], CardInfoUpdatePayloadContentDto.prototype, "htmlBundleMutation", 2);
__decorateClass([
  ApiProperty52({
    type: Boolean,
    required: false,
    description: "If disabled, then the click event for the card will be disabled. This allows the Open Card view to be disabled while editing or fine tuning the card content. The Open Card view will still be accessible via the direct link of the card id",
    example: "true"
  }),
  IsOptional(),
  IsBoolean()
], CardInfoUpdatePayloadContentDto.prototype, "enableOpenCardView", 2);
__decorateClass([
  ApiProperty52({
    enum: CardSize,
    required: false,
    description: "The size of card",
    example: CardSize.SINGLE
  }),
  IsOptional(),
  IsEnum(CardSize)
], CardInfoUpdatePayloadContentDto.prototype, "size", 2);
__decorateClass([
  ApiProperty52({
    enum: TextAlignment,
    required: false,
    description: "The text alignment of card",
    example: TextAlignment.LEFT
  }),
  IsOptional(),
  IsEnum(TextAlignment)
], CardInfoUpdatePayloadContentDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty52({
    enum: TextColor,
    required: false,
    description: "The text color of card",
    example: TextColor.BLACK
  }),
  IsOptional(),
  IsEnum(TextColor)
], CardInfoUpdatePayloadContentDto.prototype, "textColor", 2);
CardInfoUpdatePayloadContentDto = __decorateClass([
  ApiExtraModels9(
    BackgroundImageMutationUploadDto,
    BackgroundImageMutationRemovalDto,
    BackgroundImageMutationNoopDto,
    HtmlBundleMutationUploadDto,
    HtmlBundleMutationNoopDto
  )
], CardInfoUpdatePayloadContentDto);
var CardInfoUpdatePayloadDataDto = class {
};
__decorateClass([
  ApiProperty52({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  }),
  IsNumberString(),
  IsOptional()
], CardInfoUpdatePayloadDataDto.prototype, "deckId", 2);
__decorateClass([
  ApiProperty52({
    type: String,
    required: false,
    description: "The state of card",
    example: CardStatus.PUBLISHED
  }),
  IsOptional(),
  IsIn([CardStatus.PUBLISHED, CardStatus.DRAFTING])
], CardInfoUpdatePayloadDataDto.prototype, "state", 2);
__decorateClass([
  ApiProperty52({
    type: CardInfoUpdatePayloadContentDto,
    required: true,
    description: "The content of card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type7(() => CardInfoUpdatePayloadContentDto)
], CardInfoUpdatePayloadDataDto.prototype, "content", 2);
var CardInfoUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty52({
    type: () => CardInfoUpdatePayloadDataDto,
    required: true,
    description: "The payload data for updating card"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type7(() => CardInfoUpdatePayloadDataDto)
], CardInfoUpdatePayloadDto.prototype, "data", 2);
var Principles = {
  PEOPLE: PrincipleName.PEOPLE,
  CREATION: PrincipleName.CREATION,
  WORLD: PrincipleName.WORLD,
  NATURE: PrincipleName.NATURE,
  KEYS: PrincipleName.KEYS,
  UNITY: CategoryName.UNITY,
  SPIRIT: PrincipleName.SPIRIT
};
var WildCardName = {
  JOKER: CategoryName.JOKER,
  XFILES: CategoryName.X_FILES
};
var categoryNames = [
  ...Object.values(Principles),
  ...Object.values(WildCardName)
];
var CardLinkContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardCreationType.LINK]),
  ApiProperty53({
    example: "LINK",
    description: "Type of card link content. The value must be LINK",
    type: String,
    enum: [CardCreationType.LINK],
    required: true
  })
], CardLinkContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  ApiProperty53({
    example: "https://www.google.com.vn",
    description: "Source url of the card",
    type: String,
    required: true
  })
], CardLinkContent.prototype, "url", 2);
__decorateClass([
  IsOptional(),
  IsIn(categoryNames),
  ApiProperty53({
    example: "PEOPLE",
    description: "Principle of the card",
    type: String,
    enum: PrincipleName,
    required: false
  })
], CardLinkContent.prototype, "principleName", 2);
__decorateClass([
  IsOptional(),
  MaxLength(520),
  ApiProperty53({
    example: "This is the fake comment!",
    description: "Comment of the card",
    type: String,
    required: false
  })
], CardLinkContent.prototype, "comment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty53({
    example: "PHOTO",
    description: "Layout type of the card",
    type: String,
    enum: CardLayoutType,
    required: false
  })
], CardLinkContent.prototype, "layoutType", 2);
var CardDeckRepostContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardCreationType.DECK_REPOST]),
  ApiProperty54({
    example: "DECK_REPOST",
    description: "Type of card deck repost content. The value must be DECK_REPOST",
    type: String,
    enum: [CardCreationType.DECK_REPOST],
    required: true
  })
], CardDeckRepostContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  ApiProperty54({
    example: "https://earthtoday.com/user/deck",
    description: "Source deck url of the card",
    type: String,
    required: true
  })
], CardDeckRepostContent.prototype, "url", 2);
__decorateClass([
  IsOptional(),
  IsIn(categoryNames),
  ApiProperty54({
    example: "PEOPLE",
    description: "Principle of the card",
    type: String,
    enum: PrincipleName,
    required: false
  })
], CardDeckRepostContent.prototype, "principleName", 2);
__decorateClass([
  IsOptional(),
  MaxLength(520),
  ApiProperty54({
    example: "This is the fake comment!",
    description: "Comment of the card",
    type: String,
    required: false
  })
], CardDeckRepostContent.prototype, "comment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty54({
    example: "PHOTO",
    description: "Layout type of the card",
    type: String,
    enum: CardLayoutType,
    required: false
  })
], CardDeckRepostContent.prototype, "layoutType", 2);
var CardOpenSection = class {
};
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty55({
    example: "headline",
    description: "The Headline of card open section",
    type: String,
    required: false
  })
], CardOpenSection.prototype, "headline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty55({
    example: "headline",
    description: "The Subheadline of card open section",
    type: String,
    required: false
  })
], CardOpenSection.prototype, "subheadline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsJSON(),
  ApiProperty55({
    example: "{}",
    description: "The summary of card open section. The value must be json string",
    type: String,
    required: false
  })
], CardOpenSection.prototype, "summary", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(28),
  ApiProperty55({
    example: "buttonText",
    description: "The buttonText of card open section",
    type: String,
    required: false
  })
], CardOpenSection.prototype, "buttonText", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty55({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of card open section",
    type: String,
    required: false
  })
], CardOpenSection.prototype, "buttonUrl", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextAlignment),
  ApiProperty55({
    example: TextAlignment.LEFT,
    description: "Text alignment of card open section",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardOpenSection.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextColor),
  ApiProperty55({
    example: TextColor.BLACK,
    description: "the text color of card open section",
    type: String,
    enum: TextColor,
    required: false
  })
], CardOpenSection.prototype, "textColor", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty55({
    required: false,
    description: "The section background color of card open section",
    type: String,
    example: "#fff"
  })
], CardOpenSection.prototype, "sectionBgColor", 2);
__decorateClass([
  ValidateIf((o) => !o.sectionMediaLinkUrl),
  IsOptional(),
  ValidateNested(),
  Type8(() => BackgroundImageMutationUpload || BackgroundImageMutationNoop, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: BackgroundImageMutationUpload,
          name: "UPLOAD"
          /* UPLOAD */
        },
        {
          value: BackgroundImageMutationNoop,
          name: "NOOP"
          /* NOOP */
        }
      ]
    }
  }),
  ApiProperty55({
    description: "The section media image mutation of card open section",
    oneOf: [
      {
        $ref: getSchemaPath12(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath12(BackgroundImageMutationNoop)
      }
    ],
    required: false,
    type: () => Object
  })
], CardOpenSection.prototype, "sectionMediaImageMutation", 2);
__decorateClass([
  ValidateIf((o) => !o.sectionMediaImageMutation),
  IsOptional(),
  IsString(),
  ApiProperty55({
    required: false,
    description: "The section media link url of card open section",
    type: String,
    example: "https://earthtoday.com/images/..."
  })
], CardOpenSection.prototype, "sectionMediaLinkUrl", 2);
var CardEditableContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardType.EDITABLE]),
  ApiProperty55({
    example: CardType.EDITABLE,
    description: "The type of card content. The value must be EDITABLE",
    type: String,
    enum: [CardType.EDITABLE],
    required: true
  })
], CardEditableContent.prototype, "type", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty55({
    example: "headline",
    description: "The Headline of front card",
    type: String,
    required: false
  })
], CardEditableContent.prototype, "headline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty55({
    example: "headline",
    description: "The Subheadline of front card",
    type: String,
    required: false
  })
], CardEditableContent.prototype, "subheadline", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty55({
    example: "summary",
    description: "The summary of front card",
    type: String,
    required: false
  })
], CardEditableContent.prototype, "summary", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(28),
  ApiProperty55({
    example: "buttonText",
    description: "The buttonText of front card",
    type: String,
    required: false
  })
], CardEditableContent.prototype, "buttonText", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty55({
    example: "https://earthtoday.com/...",
    description: "The buttonUrl of front card",
    type: String,
    required: false
  })
], CardEditableContent.prototype, "buttonUrl", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextAlignment),
  ApiProperty55({
    example: TextAlignment.LEFT,
    description: "Text alignment of the front card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardEditableContent.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextColor),
  ApiProperty55({
    example: TextColor.BLACK,
    description: "the text color of front card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardEditableContent.prototype, "textColor", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty55({
    required: false,
    description: "The background color of front card",
    type: String,
    example: "#fff"
  })
], CardEditableContent.prototype, "bgColor", 2);
__decorateClass([
  ApiProperty55({
    description: "The background image mutation of front card",
    oneOf: [
      {
        $ref: getSchemaPath12(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath12(BackgroundImageMutationNoop)
      }
    ],
    required: false,
    type: () => Object
  }),
  ValidateNested(),
  Type8(() => BackgroundImageMutationUpload || BackgroundImageMutationNoop, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: BackgroundImageMutationUpload,
          name: "UPLOAD"
          /* UPLOAD */
        },
        {
          value: BackgroundImageMutationNoop,
          name: "NOOP"
          /* NOOP */
        }
      ]
    }
  }),
  IsOptional()
], CardEditableContent.prototype, "bgImageMutation", 2);
__decorateClass([
  ApiProperty55({
    type: CardOpenSection,
    description: "The card open sections of card",
    isArray: true,
    required: false
  }),
  ValidateNested({ each: true }),
  Type8(() => CardOpenSection),
  IsNotEmpty()
], CardEditableContent.prototype, "cardOpenSections", 2);
var CardEditableRepostContent = class {
};
__decorateClass([
  IsEnum([CardCreationType.EDITABLE_REPOST]),
  IsString(),
  IsNotEmpty(),
  ApiProperty56({
    example: CardCreationType.EDITABLE_REPOST,
    description: "Type of the card editable repost content. The value must be EDITABLE_REPOST",
    type: String,
    enum: [CardCreationType.EDITABLE_REPOST],
    required: true
  })
], CardEditableRepostContent.prototype, "type", 2);
__decorateClass([
  ApiProperty56({
    example: "1234567",
    description: "The source card id of the card editable repost",
    type: String,
    format: "int64",
    required: true
  }),
  IsNotEmpty(),
  IsNumberString()
], CardEditableRepostContent.prototype, "sourceCardId", 2);
var CardInfoContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardType.INFO]),
  ApiProperty57({
    example: "INFO",
    description: "The type of card action content. The value must be INFO",
    type: String,
    enum: [CardType.INFO],
    required: true
  })
], CardInfoContent.prototype, "type", 2);
__decorateClass([
  IsString(),
  MaxLength(82),
  IsNotEmpty(),
  ApiProperty57({
    example: "Title",
    description: "Title of the card",
    type: String,
    required: true
  })
], CardInfoContent.prototype, "title", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  MaxLength(90),
  ApiProperty57({
    example: "subtitle",
    description: "Subtitle of the card",
    type: String,
    required: false
  })
], CardInfoContent.prototype, "subtitle", 2);
__decorateClass([
  IsOptional(),
  IsBoolean(),
  ApiProperty57({
    example: false,
    description: "Enable open card view of the card",
    type: Boolean,
    required: false
  })
], CardInfoContent.prototype, "enableOpenCardView", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextAlignment),
  ApiProperty57({
    example: TextAlignment.LEFT,
    description: "Text alignment of the card",
    type: String,
    enum: TextAlignment,
    required: false
  })
], CardInfoContent.prototype, "textAlignment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  IsEnum(TextColor),
  ApiProperty57({
    example: TextColor.BLACK,
    description: "Text color of the card",
    type: String,
    enum: TextColor,
    required: false
  })
], CardInfoContent.prototype, "textColor", 2);
__decorateClass([
  ApiProperty57({
    required: false,
    description: "Background color of the card",
    type: String,
    example: "#fff"
  })
], CardInfoContent.prototype, "backgroundColor", 2);
__decorateClass([
  ApiProperty57({
    description: "Background image mutation of the card",
    oneOf: [
      {
        $ref: getSchemaPath13(BackgroundImageMutationUpload)
      },
      {
        $ref: getSchemaPath13(BackgroundImageMutationNoop)
      }
    ],
    required: false,
    type: () => BackgroundImageMutationUpload || BackgroundImageMutationNoop
  }),
  ValidateNested(),
  Type9(() => BackgroundImageMutationUpload || BackgroundImageMutationNoop, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: BackgroundImageMutationUpload,
          name: "UPLOAD"
          /* UPLOAD */
        },
        {
          value: BackgroundImageMutationNoop,
          name: "NOOP"
          /* NOOP */
        }
      ]
    }
  }),
  IsOptional()
], CardInfoContent.prototype, "backgroundImageMutation", 2);
__decorateClass([
  ApiProperty57({
    description: "Html bundle mutation of the card",
    oneOf: [
      {
        $ref: getSchemaPath13(HtmlBundleMutationUploadDto)
      },
      {
        $ref: getSchemaPath13(HtmlBundleMutationNoopDto)
      }
    ],
    required: false,
    type: () => HtmlBundleMutationUploadDto || HtmlBundleMutationNoopDto
  }),
  ValidateNested(),
  Type9(() => HtmlBundleMutationUploadDto || HtmlBundleMutationNoopDto, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: HtmlBundleMutationUploadDto,
          name: "UPLOAD"
          /* UPLOAD */
        },
        {
          value: HtmlBundleMutationNoopDto,
          name: "NOOP"
          /* NOOP */
        }
      ]
    }
  }),
  IsOptional()
], CardInfoContent.prototype, "htmlBundleMutation", 2);
CardInfoContent = __decorateClass([
  ApiExtraModels10(BackgroundImageMutationUpload, BackgroundImageMutationNoop)
], CardInfoContent);
var CardInfoRepostContent = class {
};
__decorateClass([
  IsEnum([CardCreationType.INFO_REPOST]),
  IsString(),
  IsNotEmpty(),
  ApiProperty58({
    example: CardCreationType.INFO_REPOST,
    description: "Type of the card info repost content. The value must be INFO_REPOST",
    type: String,
    enum: [CardCreationType.INFO_REPOST],
    required: true
  })
], CardInfoRepostContent.prototype, "type", 2);
__decorateClass([
  ApiProperty58({
    example: "1234567",
    description: "The source card id of the card info repost",
    type: String,
    format: "int64",
    required: true
  }),
  IsNotEmpty(),
  IsNumberString()
], CardInfoRepostContent.prototype, "sourceCardId", 2);
var CardLinkRepostContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardCreationType.LINK_REPOST]),
  ApiProperty59({
    example: "LINK_REPOST",
    description: "Type of card link repost content. The value must be LINK_REPOST",
    type: String,
    enum: [CardCreationType.LINK_REPOST],
    required: true
  })
], CardLinkRepostContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumberString(),
  ApiProperty59({
    example: "1234567",
    description: "The source card id of the card",
    type: String,
    format: "int64",
    required: true
  })
], CardLinkRepostContent.prototype, "id", 2);
__decorateClass([
  IsOptional(),
  IsIn(categoryNames),
  ApiProperty59({
    example: "PEOPLE",
    description: "Principle of the card",
    type: String,
    enum: PrincipleName,
    required: false
  })
], CardLinkRepostContent.prototype, "principleName", 2);
__decorateClass([
  IsOptional(),
  MaxLength(520),
  ApiProperty59({
    example: "This is the fake comment!",
    description: "Comment of the card",
    type: String,
    required: false
  })
], CardLinkRepostContent.prototype, "comment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty59({
    example: "PHOTO",
    description: "Layout type of the card",
    type: String,
    enum: CardLayoutType,
    required: false
  })
], CardLinkRepostContent.prototype, "layoutType", 2);
var CardProfileRepostContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsIn([CardType.PROFILE_REPOST]),
  ApiProperty60({
    example: CardCreationType.PROFILE_REPOST,
    description: "Type of card profile repost content. The value must be PROFILE_REPOST",
    type: String,
    enum: [CardCreationType.PROFILE_REPOST],
    required: true
  })
], CardProfileRepostContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsString(),
  ApiProperty60({
    example: "https://earthtoday.com/user",
    description: "Source profile url of the card",
    type: String,
    required: true
  })
], CardProfileRepostContent.prototype, "url", 2);
__decorateClass([
  IsOptional(),
  IsIn(categoryNames),
  ApiProperty60({
    example: "PEOPLE",
    description: "Principle of the card",
    type: String,
    enum: PrincipleName,
    required: false
  })
], CardProfileRepostContent.prototype, "principleName", 2);
__decorateClass([
  IsOptional(),
  MaxLength(520),
  ApiProperty60({
    example: "This is the fake comment!",
    description: "Comment of the card",
    type: String,
    required: false
  })
], CardProfileRepostContent.prototype, "comment", 2);
__decorateClass([
  IsOptional(),
  IsString(),
  ApiProperty60({
    example: "PHOTO",
    description: "Layout type of the card",
    type: String,
    enum: CardLayoutType,
    required: false
  })
], CardProfileRepostContent.prototype, "layoutType", 2);
var CardPromotionRepostByPromotionContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsEnum([CardCreationType.PROMOTION_REPOST_BY_PROMOTION]),
  ApiProperty61({
    example: CardCreationType.PROMOTION_REPOST_BY_PROMOTION,
    description: "Type of card promotion repost content. The value must be PROMOTION_REPOST_BY_PROMOTION",
    type: String,
    enum: [CardCreationType.PROMOTION_REPOST_BY_PROMOTION],
    required: true
  })
], CardPromotionRepostByPromotionContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumberString(),
  ApiProperty61({
    example: "1234567",
    description: "The promotion id of the card",
    type: String,
    format: "int64",
    required: true
  })
], CardPromotionRepostByPromotionContent.prototype, "promotionId", 2);
var CardPromotionRepostBySourceCardContent = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsEnum([CardCreationType.PROMOTION_REPOST_BY_SOURCE_CARD]),
  ApiProperty62({
    example: CardCreationType.PROMOTION_REPOST_BY_SOURCE_CARD,
    description: "Type of card promotion repost content. The value must be PROMOTION_REPOST_BY_SOURCE_CARD",
    type: String,
    enum: [CardCreationType.PROMOTION_REPOST_BY_SOURCE_CARD],
    required: true
  })
], CardPromotionRepostBySourceCardContent.prototype, "type", 2);
__decorateClass([
  IsNotEmpty(),
  IsNumberString(),
  ApiProperty62({
    example: "1234567",
    description: "The source card id of the card",
    type: String,
    format: "int64",
    required: true
  })
], CardPromotionRepostBySourceCardContent.prototype, "sourceCardId", 2);
var IsCardContentType = (message = "Content type is not valid") => {
  return (object, propertyName) => {
    registerDecorator({
      name: "isCardContentType",
      target: object.constructor,
      propertyName,
      options: { message },
      validator: {
        validate(value) {
          return __async(this, null, function* () {
            if (!value || value.type === CardCreationType.LINK || value.type === CardCreationType.LINK_REPOST || value.type === CardCreationType.DECK_REPOST || value.type === CardCreationType.PROFILE_REPOST || value.type === CardCreationType.ACTION || value.type === CardCreationType.INFO_REPOST || value.type === CardCreationType.INFO || value.type === CardCreationType.PROMOTION_REPOST_BY_PROMOTION || value.type === CardCreationType.PROMOTION_REPOST_BY_SOURCE_CARD || value.type === CardCreationType.EDITABLE || value.type === CardCreationType.EDITABLE_REPOST) {
              return true;
            }
            return false;
          });
        }
      }
    });
  };
};
var cardStatuses = [CardStatus.DRAFTING, CardStatus.PUBLISHED];
var transformGroup = "transform";
var CardCreatePayloadDataDto = class {
};
__decorateClass([
  ApiProperty63({
    example: "4415536429535408147",
    description: "Deck `id` of the card",
    type: String,
    format: "int64",
    required: true
  }),
  IsNumberString(),
  IsOptional()
], CardCreatePayloadDataDto.prototype, "deckId", 2);
__decorateClass([
  IsIn(cardStatuses),
  IsString(),
  IsNotEmpty(),
  ApiProperty63({
    example: "DRAFTING",
    description: "State of the card",
    type: String,
    enum: cardStatuses,
    required: true
  })
], CardCreatePayloadDataDto.prototype, "state", 2);
__decorateClass([
  ApiProperty63({
    description: "Content of the data",
    oneOf: [
      {
        $ref: getSchemaPath14(CardInfoContent)
      },
      {
        $ref: getSchemaPath14(CardActionContent)
      },
      {
        $ref: getSchemaPath14(CardLinkContent)
      },
      {
        $ref: getSchemaPath14(CardLinkRepostContent)
      },
      {
        $ref: getSchemaPath14(CardDeckRepostContent)
      },
      {
        $ref: getSchemaPath14(CardProfileRepostContent)
      },
      {
        $ref: getSchemaPath14(CardInfoRepostContent)
      },
      {
        $ref: getSchemaPath14(CardPromotionRepostByPromotionContent)
      },
      {
        $ref: getSchemaPath14(CardPromotionRepostBySourceCardContent)
      },
      {
        $ref: getSchemaPath14(CardEditableContent)
      },
      {
        $ref: getSchemaPath14(CardEditableRepostContent)
      }
    ],
    required: true,
    type: () => Object
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type10(
    () => CardActionContent || CardLinkContent || CardLinkRepostContent || CardDeckRepostContent || CardProfileRepostContent || CardInfoContent || CardPromotionRepostByPromotionContent || CardPromotionRepostBySourceCardContent || CardEditableContent || CardEditableRepostContent,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: CardActionContent,
            name: CardCreationType.ACTION
          },
          {
            value: CardLinkContent,
            name: CardCreationType.LINK
          },
          {
            value: CardLinkRepostContent,
            name: CardCreationType.LINK_REPOST
          },
          {
            value: CardDeckRepostContent,
            name: CardCreationType.DECK_REPOST
          },
          {
            value: CardProfileRepostContent,
            name: CardCreationType.PROFILE_REPOST
          },
          {
            value: CardInfoContent,
            name: CardCreationType.INFO
          },
          {
            value: CardInfoRepostContent,
            name: CardCreationType.INFO_REPOST
          },
          {
            value: CardPromotionRepostByPromotionContent,
            name: CardCreationType.PROMOTION_REPOST_BY_PROMOTION
          },
          {
            value: CardPromotionRepostBySourceCardContent,
            name: CardCreationType.PROMOTION_REPOST_BY_SOURCE_CARD
          },
          {
            value: CardEditableContent,
            name: CardCreationType.EDITABLE
          },
          {
            value: CardEditableRepostContent,
            name: CardCreationType.EDITABLE_REPOST
          }
        ]
      }
    }
  ),
  IsCardContentType()
], CardCreatePayloadDataDto.prototype, "content", 2);
CardCreatePayloadDataDto = __decorateClass([
  ApiExtraModels11(
    CardActionContent,
    CardInfoContent,
    CardLinkContent,
    CardLinkRepostContent,
    CardDeckRepostContent,
    CardProfileRepostContent,
    CardInfoRepostContent,
    CardPromotionRepostByPromotionContent,
    CardPromotionRepostBySourceCardContent,
    CardEditableContent,
    CardEditableRepostContent
  )
], CardCreatePayloadDataDto);
var CardCreatePayloadDto = class {
};
__decorateClass([
  ApiProperty63({
    type: CardCreatePayloadDataDto,
    description: "The data of payload",
    required: false
  }),
  ValidateNested(),
  Type10(() => CardCreatePayloadDataDto),
  IsNotEmpty()
], CardCreatePayloadDto.prototype, "data", 2);
var CardPromotionUpdateStatePayloadContentDto = class {
};
__decorateClass([
  ApiProperty64({
    type: String,
    required: true,
    description: "The type of card",
    enum: [CardType.PROMOTION_REPOST],
    example: CardType.PROMOTION_REPOST
  }),
  IsNotEmpty(),
  IsEnum([CardType.PROMOTION_REPOST])
], CardPromotionUpdateStatePayloadContentDto.prototype, "type", 2);
var CardPromotionUpdateStatePayloadDataDto = class {
};
__decorateClass([
  IsNumberString(),
  IsNotEmpty(),
  ApiProperty64({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  })
], CardPromotionUpdateStatePayloadDataDto.prototype, "deckId", 2);
__decorateClass([
  IsEnum(cardStatuses),
  IsString(),
  IsNotEmpty(),
  ApiProperty64({
    example: CardStatus.DRAFTING,
    description: "State of the card",
    type: String,
    enum: cardStatuses,
    required: true
  })
], CardPromotionUpdateStatePayloadDataDto.prototype, "state", 2);
__decorateClass([
  IsNotEmpty(),
  ValidateNested(),
  Type11(() => CardPromotionUpdateStatePayloadContentDto),
  ApiProperty64({
    description: "Content of the card",
    required: true,
    type: () => CardPromotionUpdateStatePayloadContentDto
  })
], CardPromotionUpdateStatePayloadDataDto.prototype, "content", 2);
var CardPromotionUpdateStatePayloadDto = class {
};
__decorateClass([
  ApiProperty64({
    description: "The data of payload",
    type: () => CardPromotionUpdateStatePayloadDataDto,
    required: true
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type11(() => CardPromotionUpdateStatePayloadDataDto)
], CardPromotionUpdateStatePayloadDto.prototype, "data", 2);
var CardEditableRepostUpdatePayloadContentDto = class {
};
__decorateClass([
  ApiProperty65({
    type: String,
    required: true,
    description: "The type of card",
    enum: [CardType.EDITABLE_REPOST],
    example: CardType.EDITABLE_REPOST
  }),
  IsNotEmpty(),
  IsEnum([CardType.EDITABLE_REPOST])
], CardEditableRepostUpdatePayloadContentDto.prototype, "type", 2);
var CardEditableRepostUpdatePayloadDataDto = class {
};
__decorateClass([
  IsNumberString(),
  IsNotEmpty(),
  ApiProperty65({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  })
], CardEditableRepostUpdatePayloadDataDto.prototype, "deckId", 2);
__decorateClass([
  IsEnum(cardStatuses),
  IsString(),
  IsNotEmpty(),
  ApiProperty65({
    example: CardStatus.DRAFTING,
    description: "State of the card",
    type: String,
    enum: cardStatuses,
    required: true
  })
], CardEditableRepostUpdatePayloadDataDto.prototype, "state", 2);
__decorateClass([
  IsNotEmpty(),
  ValidateNested(),
  Type12(() => CardEditableRepostUpdatePayloadContentDto),
  ApiProperty65({
    description: "Content of the card",
    required: true,
    type: () => CardEditableRepostUpdatePayloadContentDto
  })
], CardEditableRepostUpdatePayloadDataDto.prototype, "content", 2);
var CardEditableRepostUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty65({
    description: "The data of payload",
    type: () => CardEditableRepostUpdatePayloadDataDto,
    required: true
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type12(() => CardEditableRepostUpdatePayloadDataDto)
], CardEditableRepostUpdatePayloadDto.prototype, "data", 2);
var categoryNames2 = [
  ...Object.values(Principles),
  ...Object.values(WildCardName),
  ...[...Object.values(Principles), ...Object.values(WildCardName)].map(
    (p) => p.toLowerCase()
  )
];
var CardStandardUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty66({
    enum: PrincipleName,
    required: false,
    description: "The principle name of card",
    example: PrincipleName.CREATION
  }),
  IsOptional(),
  IsIn(categoryNames2)
], CardStandardUpdatePayloadDto.prototype, "principleName", 2);
__decorateClass([
  ApiProperty66({
    type: String,
    required: true,
    description: "The comment of card",
    example: ""
  }),
  IsDefined(),
  MaxLength(520)
], CardStandardUpdatePayloadDto.prototype, "comment", 2);
__decorateClass([
  ApiProperty66({
    type: String,
    format: "int64",
    required: false,
    description: "The deck `id` of card",
    example: "1234567890"
  }),
  IsOptional(),
  IsString(),
  IsEarthId()
], CardStandardUpdatePayloadDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty66({
    type: String,
    required: false,
    description: "The state of card",
    example: "published"
  }),
  IsOptional(),
  IsIn(Object.values(["published", "drafting"]))
], CardStandardUpdatePayloadDto.prototype, "state", 2);
__decorateClass([
  ApiProperty66({
    type: String,
    format: "int64",
    required: false,
    description: "The principle `id` of card",
    example: "published"
  }),
  IsOptional(),
  IsString(),
  IsEarthId()
], CardStandardUpdatePayloadDto.prototype, "principleId", 2);
var CardUpdateSizePayload = class {
};
__decorateClass([
  IsNotEmpty(),
  IsIn(Object.values(CardSize).map((size) => size.toLocaleLowerCase()))
], CardUpdateSizePayload.prototype, "size", 2);
var DeckOfCardResponseDto = class {
};
__decorateClass([
  ApiProperty67({
    type: String,
    format: "int64",
    required: true,
    description: "The `id` of deck",
    example: "1234567890"
  }),
  IsDefined(),
  IsString()
], DeckOfCardResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: true,
    description: "The name of deck",
    example: "fake name"
  }),
  IsDefined(),
  IsString()
], DeckOfCardResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty67({
    type: Boolean,
    required: false,
    description: "The option if deck is on feed",
    example: false
  }),
  IsOptional(),
  IsBoolean()
], DeckOfCardResponseDto.prototype, "onFeed", 2);
__decorateClass([
  ApiProperty67({
    type: Array,
    required: true,
    description: "The path of deck",
    example: ["johndoe", "first"]
  }),
  IsDefined(),
  IsArray()
], DeckOfCardResponseDto.prototype, "path", 2);
var ResourceResponseInternalUrlDto = class {
};
__decorateClass([
  ApiProperty67({
    type: String,
    required: true,
    description: "The type of resource",
    example: "URL_INTERNAL"
    /* URL_INTERNAL */
  }),
  IsDefined()
], ResourceResponseInternalUrlDto.prototype, "type", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The nextjs href path of resource",
    example: "/fake-href"
  }),
  IsOptional()
], ResourceResponseInternalUrlDto.prototype, "nextjsHrefPath", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The nextjs as path of resource",
    example: "/[vanityName]"
  }),
  IsOptional()
], ResourceResponseInternalUrlDto.prototype, "nextjsAsPath", 2);
var ResourceResponseExternalUrlDto = class {
};
__decorateClass([
  ApiProperty67({
    type: String,
    required: true,
    description: "The type of resource",
    example: "URL_EXTERNAL"
    /* URL_EXTERNAL */
  }),
  IsDefined()
], ResourceResponseExternalUrlDto.prototype, "type", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The url of resource",
    example: "https://fake-url"
  }),
  IsOptional()
], ResourceResponseExternalUrlDto.prototype, "url", 2);
var CuratorResponseDto2 = class {
};
__decorateClass([
  ApiProperty67({
    type: String,
    format: "int64",
    required: true,
    description: "The `id` of curator",
    example: "1234567890"
  }),
  IsNotEmpty(),
  IsString()
], CuratorResponseDto2.prototype, "id", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: true,
    description: "The vanity name of curator",
    example: "johndoe"
  }),
  IsNotEmpty(),
  IsString()
], CuratorResponseDto2.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty67({
    type: Boolean,
    required: true,
    description: "The vanity name of curator",
    example: false
  }),
  IsDefined(),
  IsBoolean()
], CuratorResponseDto2.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: true,
    description: "The name of curator",
    example: "johndoe"
  }),
  IsNotEmpty(),
  IsString()
], CuratorResponseDto2.prototype, "name", 2);
__decorateClass([
  ApiProperty67({
    type: ImageResponseDto3,
    required: true,
    description: "The image of curator"
  }),
  IsDefined()
], CuratorResponseDto2.prototype, "image", 2);
var CardUpdateResponseDataDto = class {
};
__decorateClass([
  ApiProperty67({
    type: String,
    format: "int64",
    required: true,
    description: "The `id` of card",
    example: "1234567890"
  }),
  IsNotEmpty(),
  IsString()
], CardUpdateResponseDataDto.prototype, "id", 2);
__decorateClass([
  ApiProperty67({
    enum: CardType,
    required: true,
    description: "The content type of card",
    example: CardType.ACTION
  }),
  IsDefined(),
  IsEnum(CardType)
], CardUpdateResponseDataDto.prototype, "contentType", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The title of card",
    example: "This is the fake title!"
  }),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "title", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The subtitle of card",
    example: "This is the fake subtitle!"
  }),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "subtitle", 2);
__decorateClass([
  ApiProperty67({
    enum: CardStatus,
    required: true,
    description: "The state of card",
    example: CardStatus.PUBLISHED
  }),
  IsDefined(),
  IsIn([CardStatus.PUBLISHED, CardStatus.DRAFTING])
], CardUpdateResponseDataDto.prototype, "state", 2);
__decorateClass([
  ApiProperty67({
    enum: TextAlignment,
    required: true,
    description: "The text alignment of card",
    example: TextAlignment.LEFT
  }),
  IsEnum(TextAlignment)
], CardUpdateResponseDataDto.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty67({
    enum: TextColor,
    required: true,
    description: "The text color of card",
    example: TextColor.BLACK
  }),
  IsEnum(TextColor)
], CardUpdateResponseDataDto.prototype, "textColor", 2);
__decorateClass([
  ApiProperty67({
    enum: CardSize,
    required: true,
    description: "The card size of card",
    example: CardSize.SINGLE
  }),
  IsEnum(CardSize)
], CardUpdateResponseDataDto.prototype, "size", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The published date of card",
    example: "2023-03-24T04:43:52.042Z"
  }),
  IsDateString(),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "publishedAt", 2);
__decorateClass([
  ApiProperty67({
    type: DeckOfCardResponseDto,
    required: false,
    description: "The deck info of card"
  }),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty67({
    required: false,
    description: "The resource info of card",
    oneOf: [
      {
        $ref: getSchemaPath15(ResourceResponseInternalUrlDto)
      },
      {
        $ref: getSchemaPath15(ResourceResponseExternalUrlDto)
      }
    ],
    type: () => ResourceResponseInternalUrlDto || ResourceResponseExternalUrlDto
  })
], CardUpdateResponseDataDto.prototype, "resource", 2);
__decorateClass([
  ApiProperty67({
    type: CuratorResponseDto2,
    required: true,
    description: "The curator info of card"
  }),
  IsDefined()
], CardUpdateResponseDataDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty67({
    type: ImageResponseDto3,
    required: false,
    description: "The background image of card"
  }),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "backgroundImage", 2);
__decorateClass([
  ApiProperty67({
    type: String,
    required: false,
    description: "The button text of card",
    example: "button text"
  }),
  IsOptional()
], CardUpdateResponseDataDto.prototype, "buttonText", 2);
CardUpdateResponseDataDto = __decorateClass([
  ApiExtraModels12(ResourceResponseInternalUrlDto, ResourceResponseExternalUrlDto)
], CardUpdateResponseDataDto);
var CardUpdateResponseDto = class {
};
__decorateClass([
  ApiProperty67({
    required: true,
    description: "The updated card response data",
    oneOf: [
      {
        $ref: getSchemaPath15(CardUpdateResponseDataDto)
      },
      {
        $ref: getSchemaPath15(CardInfoResponseDto)
      },
      {
        $ref: getSchemaPath15(CardEditableResponseDto)
      },
      {
        $ref: getSchemaPath15(CardItemEditableRepostResponseDto)
      }
    ],
    type: () => Object
  }),
  IsDefined(),
  Type13((input) => {
    switch (input == null ? void 0 : input.object[input.property].type) {
      case CardType.INFO: {
        return CardInfoResponseDto;
      }
      case CardType.EDITABLE: {
        return CardEditableResponseDto;
      }
      case CardType.EDITABLE_REPOST: {
        return CardItemEditableRepostResponseDto;
      }
      default: {
        return CardUpdateResponseDataDto;
      }
    }
  })
], CardUpdateResponseDto.prototype, "data", 2);
CardUpdateResponseDto = __decorateClass([
  ApiExtraModels12(
    CardUpdateResponseDataDto,
    CardInfoResponseDto,
    CardItemEditableRepostResponseDto
  )
], CardUpdateResponseDto);
var PageSortDto = class {
};
__decorateClass([
  ApiProperty68({
    example: false,
    default: false,
    required: true,
    description: "The page is already sorted",
    type: Boolean
  })
], PageSortDto.prototype, "sorted", 2);
__decorateClass([
  ApiProperty68({
    example: true,
    default: true,
    required: true,
    description: "The page did not sort",
    type: Boolean
  })
], PageSortDto.prototype, "unsorted", 2);
var PageableDto = class {
};
__decorateClass([
  ApiProperty68({
    example: 0,
    default: 0,
    required: true,
    description: "The offset of the page",
    type: Number
  })
], PageableDto.prototype, "offset", 2);
__decorateClass([
  ApiProperty68({
    example: 52,
    default: 52,
    required: true,
    description: "The page size of the page",
    type: Number
  })
], PageableDto.prototype, "pageSize", 2);
__decorateClass([
  ApiProperty68({
    example: true,
    default: true,
    required: true,
    type: Boolean
  })
], PageableDto.prototype, "paged", 2);
__decorateClass([
  ApiProperty68({
    required: true,
    description: "The sorting status of the page",
    type: PageSortDto
  })
], PageableDto.prototype, "sort", 2);
__decorateClass([
  ApiProperty68({
    example: false,
    default: false,
    required: true,
    type: Boolean
  })
], PageableDto.prototype, "unpaged", 2);
__decorateClass([
  ApiProperty68({
    example: 0,
    default: 0,
    required: true,
    description: "The page number of the page",
    type: Number
  })
], PageableDto.prototype, "pageNumber", 2);
var PageCardResponseDto = class {
};
__decorateClass([
  ApiProperty68({
    required: true,
    description: "The content of the page card",
    type: () => [Object],
    oneOf: [
      { $ref: getSchemaPath16(CardStandardResponseDto) },
      { $ref: getSchemaPath16(CardActionResponseDto) },
      { $ref: getSchemaPath16(CardInfoResponseDto) },
      { $ref: getSchemaPath16(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath16(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath16(CardItemEditableResponseDto) },
      { $ref: getSchemaPath16(CardItemEditableRepostResponseDto) }
    ]
  })
], PageCardResponseDto.prototype, "content", 2);
__decorateClass([
  ApiProperty68({
    example: true,
    default: true,
    required: true,
    type: Boolean
  })
], PageCardResponseDto.prototype, "first", 2);
__decorateClass([
  ApiProperty68({
    example: true,
    default: true,
    required: true,
    type: Boolean
  })
], PageCardResponseDto.prototype, "last", 2);
__decorateClass([
  ApiProperty68({
    example: 0,
    default: 0,
    required: true,
    type: Number
  })
], PageCardResponseDto.prototype, "number", 2);
__decorateClass([
  ApiProperty68({
    example: 2,
    required: true,
    description: "The number of element",
    type: Number
  })
], PageCardResponseDto.prototype, "numberOfElements", 2);
__decorateClass([
  ApiProperty68({
    required: true,
    title: "",
    type: PageableDto
  })
], PageCardResponseDto.prototype, "pageable", 2);
__decorateClass([
  ApiProperty68({
    example: 52,
    default: 52,
    required: true,
    description: "The size of the page card response",
    type: Number
  })
], PageCardResponseDto.prototype, "size", 2);
__decorateClass([
  ApiProperty68({
    required: true,
    description: "The sort status of the page card response",
    type: PageSortDto
  })
], PageCardResponseDto.prototype, "sort", 2);
__decorateClass([
  ApiProperty68({
    example: 1,
    required: true,
    description: "The total elements of the page card response",
    type: Number
  })
], PageCardResponseDto.prototype, "totalElements", 2);
__decorateClass([
  ApiProperty68({
    example: 1,
    default: 1,
    required: true,
    description: "The total page of the page card response",
    type: Number
  })
], PageCardResponseDto.prototype, "totalPages", 2);
var CardsPublishedGetResponseDto = class extends PageCardResponseDto {
};
__decorateClass([
  ApiProperty68({
    required: true,
    description: "The cache status of getting cards response",
    type: Boolean
  })
], CardsPublishedGetResponseDto.prototype, "useCache", 2);
var CardsDraftingResponse = class {
};
__decorateClass([
  ApiProperty69({
    required: true,
    description: "The content of the card",
    oneOf: [
      { $ref: getSchemaPath17(CardStandardResponseDto) },
      { $ref: getSchemaPath17(CardActionResponseDto) },
      { $ref: getSchemaPath17(CardInfoResponseDto) },
      { $ref: getSchemaPath17(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath17(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath17(CardItemEditableResponseDto) },
      { $ref: getSchemaPath17(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], CardsDraftingResponse.prototype, "content", 2);
__decorateClass([
  ApiProperty69({
    example: true,
    default: true,
    required: true,
    type: Boolean
  })
], CardsDraftingResponse.prototype, "first", 2);
__decorateClass([
  ApiProperty69({
    example: true,
    default: true,
    required: true,
    type: Boolean
  })
], CardsDraftingResponse.prototype, "last", 2);
__decorateClass([
  ApiProperty69({
    example: 0,
    default: 0,
    required: true,
    type: Number
  })
], CardsDraftingResponse.prototype, "number", 2);
__decorateClass([
  ApiProperty69({
    example: 2,
    required: true,
    description: "The number of element",
    type: Number
  })
], CardsDraftingResponse.prototype, "numberOfElements", 2);
__decorateClass([
  ApiProperty69({
    required: true,
    title: "",
    type: PageableDto
  })
], CardsDraftingResponse.prototype, "pageable", 2);
__decorateClass([
  ApiProperty69({
    example: 52,
    default: 52,
    required: true,
    description: "The size of the page card response",
    type: Number
  })
], CardsDraftingResponse.prototype, "size", 2);
__decorateClass([
  ApiProperty69({
    required: true,
    description: "The sort status of the page card response",
    type: PageSortDto
  })
], CardsDraftingResponse.prototype, "sort", 2);
__decorateClass([
  ApiProperty69({
    example: 1,
    required: true,
    description: "The total elements of the page card response",
    type: Number
  })
], CardsDraftingResponse.prototype, "totalElements", 2);
__decorateClass([
  ApiProperty69({
    example: 1,
    default: 1,
    required: true,
    description: "The total page of the page card response",
    type: Number
  })
], CardsDraftingResponse.prototype, "totalPages", 2);
var CardsDraftingGetResponseDto = class extends CardsDraftingResponse {
};
__decorateClass([
  ApiProperty69({
    required: true,
    description: "The cache status of getting cards response",
    type: Boolean
  })
], CardsDraftingGetResponseDto.prototype, "useCache", 2);
var c4 = initContract();
var cardContract = c4.router({
  getRelatedCards: {
    method: "GET",
    path: "/v1/cards/:cardId/related",
    query: z.object({
      algorithm: z.nativeEnum(CardSuggestionAlgorithm).optional(),
      count: z.string().optional()
    }),
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      500: c4.type(),
      501: c4.type()
    }
  },
  retrieveCard: {
    method: "GET",
    path: "/v1/users/:userIdOrVanityName/cards/:cardId",
    query: z.object({
      metaTags: z.string().optional()
    }),
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type(),
      501: c4.type()
    }
  },
  createCard: {
    method: "POST",
    path: "/v1/cards",
    body: c4.type(),
    responses: {
      201: c4.type(),
      400: c4.type(),
      401: c4.type(),
      500: c4.type(),
      501: c4.type()
    }
  },
  changeSortOfCardInDeck: {
    method: "PUT",
    path: "/v1/users/:userIdOrVanityName/decks/:deckId/cards/:cardId/change-sort",
    body: c4.type(),
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type()
    }
  },
  updateCard: {
    method: "PUT",
    path: "/v1/cards/:cardId",
    body: c4.type(),
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type()
    }
  },
  updateCardSize: {
    method: "PUT",
    path: "/v1/cards/:cardId/size",
    pathParams: z.object({
      cardId: z.string()
    }),
    body: c4.type(),
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type()
    },
    strictStatusCodes: true
  },
  getMyPublishedCards: {
    method: "GET",
    path: "/v1/users/me/cards/published",
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type()
    },
    strictStatusCodes: true
  },
  getMyDraftingCards: {
    method: "GET",
    path: "/v1/users/me/cards/drafting",
    responses: {
      200: c4.type(),
      400: c4.type(),
      401: c4.type(),
      404: c4.type(),
      500: c4.type()
    },
    headers: z.object({
      "x-apicache-bypass": z.string().optional(),
      "cache-control": z.string().optional()
    }),
    strictStatusCodes: true
  }
});
var DecksResponseDto = class {
};
__decorateClass([
  ApiProperty70({
    required: true,
    description: "The decks details",
    type: [DeckWithoutFollowingResponseDto]
  })
], DecksResponseDto.prototype, "decks", 2);
__decorateClass([
  ApiProperty70({
    required: false,
    description: "The meta tags of the deck",
    type: MetaTagsResponseDto
  })
], DecksResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty70({
    required: false,
    description: "The deck uncache status",
    type: Boolean
  })
], DecksResponseDto.prototype, "useCache", 2);
var DeckFollowingUpdateRequestPayload = class {
};
__decorateClass([
  IsBoolean(),
  IsNotEmpty()
], DeckFollowingUpdateRequestPayload.prototype, "following", 2);
var c5 = initContract();
var deckContract = c5.router({
  retrieveDeckByIdAndLinkName: {
    method: "GET",
    path: "/v1/decks/:earthIdOrVanityName/:linkName",
    query: z.object({
      metaTags: z.string().optional()
    }),
    pathParams: z.object({
      earthIdOrVanityName: z.string().optional(),
      linkName: z.string().optional()
    }),
    responses: {
      200: c5.type(),
      400: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "max-age": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  featureCard: {
    method: "PUT",
    path: "/v1/decks/:earthId/featured",
    pathParams: z.object({
      earthId: z.string()
    }),
    body: c5.type(),
    responses: {
      200: c5.type(),
      400: c5.type(),
      401: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  },
  updateDeck: {
    method: "PUT",
    path: "/v1/decks/:earthId",
    pathParams: z.object({
      earthId: z.string()
    }),
    body: c5.type(),
    responses: {
      200: c5.type(),
      400: c5.type(),
      401: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  },
  updateFollowingStateForDeck: {
    method: "PUT",
    path: "/v1/decks/:deckId/follows",
    pathParams: z.object({
      deckId: z.string()
    }),
    body: c5.type(),
    responses: {
      200: c5.type(),
      400: c5.type(),
      401: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  },
  getDecksCuratedByAUser: {
    method: "GET",
    path: "/v1/users/:earthIdOrVanityName/decks/curating",
    pathParams: z.object({
      earthIdOrVanityName: z.string()
    }),
    query: z.object({
      metaTags: z.string().optional(),
      allCards: z.string().optional(),
      autoplayable: z.string().optional(),
      numberOfCards: z.string().optional(),
      forceIncludeDeckIdsOrLinkNames: z.string().optional(),
      responseType: z.string().optional()
    }),
    responses: {
      200: c5.type(),
      400: c5.type(),
      401: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  },
  getDecksFollowedByAUser: {
    method: "GET",
    path: "/v1/users/:earthIdOrVanityName/decks/following",
    pathParams: z.object({
      earthIdOrVanityName: z.string()
    }),
    query: z.object({
      limit: z.string().optional(),
      cursorId: z.string().optional(),
      metaTags: z.string().optional(),
      responseType: z.string().optional()
    }),
    responses: {
      200: c5.type(),
      400: c5.type(),
      401: c5.type(),
      404: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  },
  getSystemDecks: {
    method: "GET",
    path: "/v1/mobile-system-decks/decks",
    query: z.object({
      responseType: z.string().optional()
    }),
    responses: {
      200: c5.type(),
      500: c5.type()
    },
    strictStatusCodes: true
  }
});
var DeckFeaturedCardUpdatePayloadDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString(),
  IsEarthId()
], DeckFeaturedCardUpdatePayloadDto.prototype, "cardId", 2);
__decorateClass([
  IsNotEmpty(),
  IsBoolean()
], DeckFeaturedCardUpdatePayloadDto.prototype, "featured", 2);
var DeckUpdatePayloadDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], DeckUpdatePayloadDto.prototype, "name", 2);
__decorateClass([
  IsOptional(),
  IsString()
], DeckUpdatePayloadDto.prototype, "description", 2);
__decorateClass([
  ApiProperty71({
    required: false,
    description: "The background image mutation of card",
    oneOf: [
      {
        $ref: getSchemaPath18(BackgroundImageMutationUploadDto)
      },
      {
        $ref: getSchemaPath18(BackgroundImageMutationRemovalDto)
      },
      {
        $ref: getSchemaPath18(BackgroundImageMutationNoopDto)
      }
    ],
    type: () => BackgroundImageMutationNoopDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationUploadDto
  }),
  IsOptional(),
  ValidateNested(),
  Type14(
    () => BackgroundImageMutationNoopDto || BackgroundImageMutationRemovalDto || BackgroundImageMutationUploadDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: BackgroundImageMutationNoopDto,
            name: "NOOP"
            /* NOOP */
          },
          {
            value: BackgroundImageMutationRemovalDto,
            name: "REMOVAL"
            /* REMOVAL */
          },
          {
            value: BackgroundImageMutationUploadDto,
            name: "UPLOAD"
            /* UPLOAD */
          }
        ]
      }
    }
  )
], DeckUpdatePayloadDto.prototype, "backgroundImageMutation", 2);
var FeedDeckResponseDto = class {
};
__decorateClass([
  ApiProperty72({
    example: "6714931170118234112",
    description: "The `Id` of the deck",
    required: true,
    type: String
  })
], FeedDeckResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty72({
    example: "this is a fake name",
    description: "The name of the deck",
    required: true,
    type: String
  })
], FeedDeckResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty72({
    example: "",
    description: "The description of the deck",
    required: true,
    type: String
  })
], FeedDeckResponseDto.prototype, "description", 2);
__decorateClass([
  ApiProperty72({
    example: "2023-03-17T04:08:01.494Z",
    description: "The created date of the deck",
    required: true,
    type: String,
    format: "date-time"
  })
], FeedDeckResponseDto.prototype, "created", 2);
__decorateClass([
  ApiProperty72({
    example: ["john doe", "third"],
    description: "The path of the deck",
    required: true,
    type: [String]
  })
], FeedDeckResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty72({
    example: 51,
    description: "The remain count of cards of the deck",
    required: true,
    type: Number
  })
], FeedDeckResponseDto.prototype, "remainingCardsCount", 2);
__decorateClass([
  ApiProperty72({
    example: {
      headerColor: "",
      labelColor: "",
      labelIcon: "",
      labelTitle: ""
    },
    description: "The presentation of the deck",
    type: PresentationSettingDto,
    required: true
  })
], FeedDeckResponseDto.prototype, "presentation", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    description: "The curator details of the deck",
    type: CuratorResponseDto
  })
], FeedDeckResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    description: "The reserve of the deck",
    type: () => [ReserveDeckResponseDto]
  })
], FeedDeckResponseDto.prototype, "reserves", 2);
__decorateClass([
  ApiProperty72({
    example: ["people"],
    description: "The category principle of the deck",
    required: true,
    type: [String]
  })
], FeedDeckResponseDto.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty72({
    example: true,
    description: "The uncache status of the deck",
    required: true,
    type: Boolean
  })
], FeedDeckResponseDto.prototype, "useCache", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    description: "The following detail of the deck",
    type: FollowingResponseDto
  })
], FeedDeckResponseDto.prototype, "following", 2);
__decorateClass([
  ApiProperty72({
    example: 0,
    required: true,
    type: "integer",
    description: "Number of followers of this deck"
  })
], FeedDeckResponseDto.prototype, "followersCount", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    type: "integer",
    description: "number of cards in this deck"
  })
], FeedDeckResponseDto.prototype, "cardsCount", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    type: [DeckPreviewImage],
    description: "preview images"
  })
], FeedDeckResponseDto.prototype, "previewImages", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    oneOf: [
      {
        $ref: getSchemaPath19(DeckPreviewImagesDto)
      },
      {
        $ref: getSchemaPath19(DeckBackgroundImageDto)
      }
    ],
    type: () => DeckPreviewImagesDto || DeckBackgroundImageDto
  })
], FeedDeckResponseDto.prototype, "backgroundImage", 2);
var FeedWebResponseDto = class {
};
__decorateClass([
  ApiProperty72({
    required: true,
    enum: FeedStatus,
    description: "The status of feed"
  })
], FeedWebResponseDto.prototype, "status", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    description: "The cards of feed",
    oneOf: [
      { $ref: getSchemaPath19(CardStandardResponseDto) },
      { $ref: getSchemaPath19(CardActionResponseDto) },
      { $ref: getSchemaPath19(CardInfoResponseDto) },
      { $ref: getSchemaPath19(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath19(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath19(CardItemEditableResponseDto) },
      { $ref: getSchemaPath19(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], FeedWebResponseDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    type: () => UserResponseDto,
    description: "The profile user of feed"
  })
], FeedWebResponseDto.prototype, "profile", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    type: () => FeedDeckResponseDto,
    description: "The response get from cache"
  })
], FeedWebResponseDto.prototype, "deck", 2);
__decorateClass([
  ApiProperty72({
    required: false,
    type: "object",
    description: "Meta tags of feed",
    additionalProperties: {
      type: "string"
    }
  })
], FeedWebResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty72({
    required: true,
    type: Boolean,
    description: "The response get from cache"
  })
], FeedWebResponseDto.prototype, "useCache", 2);
FeedWebResponseDto = __decorateClass([
  ApiExtraModels13(CardItemEditableResponseDto)
], FeedWebResponseDto);
var FeedMeResponseDto = class {
};
__decorateClass([
  ApiProperty73({
    required: true,
    enum: FeedStatus,
    description: "The status of feed"
  })
], FeedMeResponseDto.prototype, "status", 2);
__decorateClass([
  ApiProperty73({
    required: true,
    description: "The cards of feed",
    oneOf: [
      { $ref: getSchemaPath20(CardStandardResponseDto) },
      { $ref: getSchemaPath20(CardActionResponseDto) },
      { $ref: getSchemaPath20(CardInfoResponseDto) },
      { $ref: getSchemaPath20(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath20(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath20(CardItemEditableResponseDto) },
      { $ref: getSchemaPath20(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], FeedMeResponseDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty73({
    example: true,
    description: "The uncache status of the feed response",
    required: false,
    type: Boolean
  })
], FeedMeResponseDto.prototype, "useCache", 2);
var c6 = initContract();
var feedContract = c6.router({
  retrieveFeed: {
    method: "GET",
    path: "/v1/feed",
    query: z.object({
      metaTags: z.string().optional(),
      responseType: z.string().optional()
    }),
    responses: {
      200: c6.type(),
      404: c6.type(),
      500: c6.type(),
      501: c6.type()
    },
    strictStatusCodes: true
  },
  retrieveFeedMe: {
    method: "GET",
    path: "/v1/feed/me",
    responses: {
      200: c6.type(),
      401: c6.type(),
      404: c6.type(),
      500: c6.type()
    },
    strictStatusCodes: true
  },
  retrieveFeedUserProfileDeck: {
    method: "GET",
    path: "/v1/users/:userId/feed",
    pathParams: z.object({
      userId: z.string()
    }),
    responses: {
      200: c6.type(),
      404: c6.type(),
      500: c6.type()
    },
    strictStatusCodes: true
  }
});
var CardCreateResponseDto = class {
};
__decorateClass([
  ApiProperty74({
    required: true,
    description: "Data of the card",
    oneOf: [
      {
        $ref: getSchemaPath21(CardPromotionRepostResponseDto)
      },
      {
        $ref: getSchemaPath21(CardInfoResponseDto)
      },
      {
        $ref: getSchemaPath21(CardActionResponseDto)
      },
      {
        $ref: getSchemaPath21(CardStandardResponseDto)
      },
      {
        $ref: getSchemaPath21(CardInfoRepostResponseDto)
      },
      {
        $ref: getSchemaPath21(CardEditableResponseDto)
      },
      {
        $ref: getSchemaPath21(CardItemEditableRepostResponseDto)
      }
    ],
    type: () => Object
  })
], CardCreateResponseDto.prototype, "data", 2);
CardCreateResponseDto = __decorateClass([
  ApiExtraModels14(
    CardPromotionRepostResponseDto,
    CardInfoRepostResponseDto,
    CardEditableResponseDto,
    CardItemEditableRepostResponseDto
  )
], CardCreateResponseDto);
var PresentationSetting = class {
};
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Header color for presentation",
    type: String,
    example: "#FFFFFF"
  })
], PresentationSetting.prototype, "headerColor", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Title of the label in the presentation",
    type: String,
    example: "Featured"
  })
], PresentationSetting.prototype, "labelTitle", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Color of the label in the presentation",
    type: String,
    example: "#FF5733"
  })
], PresentationSetting.prototype, "labelColor", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Icon of the label in the presentation",
    type: String,
    example: "star"
  })
], PresentationSetting.prototype, "labelIcon", 2);
var CardResponseBase = class {
};
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The unique identifier of the card",
    type: String,
    example: "1234567890"
  })
], CardResponseBase.prototype, "id", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Size of the card",
    type: String,
    example: "large"
  })
], CardResponseBase.prototype, "size", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "State of the card",
    type: String,
    example: "published"
  })
], CardResponseBase.prototype, "state", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Last updated timestamp of the card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardResponseBase.prototype, "updated", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Publish timestamp of the card",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], CardResponseBase.prototype, "published", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Details of the curator associated with the card",
    type: () => CuratorResponse
  })
], CardResponseBase.prototype, "curator", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Indicates if the card is featured for profile",
    type: Boolean,
    example: true
  })
], CardResponseBase.prototype, "featuredForProfile", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Indicates if the card is valid",
    type: Boolean,
    example: true
  })
], CardResponseBase.prototype, "valid", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Comment associated with the card",
    type: String,
    example: "This is a comment."
  })
], CardResponseBase.prototype, "comment", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Meta tags associated with the card",
    type: Object,
    example: {
      "og:title": "Example Title",
      "og:description": "Example Description"
    }
  })
], CardResponseBase.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Layout type of the card",
    enum: CardLayoutType,
    example: CardLayoutType.EDITORIAL
  })
], CardResponseBase.prototype, "layoutType", 2);
var linkRepostType = CardType.LINK_REPOST.toLowerCase();
var ReserveResponse = class {
};
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The unique identifier of the reserve",
    type: String,
    example: "1234567890"
  })
], ReserveResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The name of the reserve",
    type: String,
    example: "Amazon Rainforest Reserve"
  })
], ReserveResponse.prototype, "name", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The country where the reserve is located",
    type: String,
    example: "Brazil"
  })
], ReserveResponse.prototype, "country", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The state or region of the reserve",
    type: String,
    example: "Amazonas"
  })
], ReserveResponse.prototype, "state", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The city or locality of the reserve",
    type: String,
    example: "Manaus"
  })
], ReserveResponse.prototype, "city", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The full address of the reserve",
    type: String,
    example: "123 Rainforest Avenue, Manaus, Amazonas, Brazil"
  })
], ReserveResponse.prototype, "address", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The size of the reserve in hectares",
    type: Number,
    example: 5e4
  })
], ReserveResponse.prototype, "size", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The type of reserve (e.g., National Park, Private Reserve)",
    type: String,
    example: "National Park"
  })
], ReserveResponse.prototype, "type", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The current status of the reserve",
    type: String,
    example: "Protected"
  })
], ReserveResponse.prototype, "status", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Email template associated with the reserve",
    type: String,
    example: "<html><body>Welcome to our reserve!</body></html>"
  })
], ReserveResponse.prototype, "emailTemplate", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Image details for the reserve",
    type: () => ImageResponse
  })
], ReserveResponse.prototype, "image", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Configuration for map animation",
    type: String,
    example: '{"zoom": 5, "center": {"lat": -3.10719, "lng": -60.0261}}'
  })
], ReserveResponse.prototype, "mapAnimationConfig", 2);
var FollowingResponse = class {
};
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The total number of followers or following count",
    type: Number,
    example: 123
  })
], FollowingResponse.prototype, "count", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Indicates whether the current user is following",
    type: Boolean,
    example: true
  })
], FollowingResponse.prototype, "following", 2);
var DeckResponse = class {
};
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The unique identifier of the deck",
    type: String,
    example: "1234567890"
  })
], DeckResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The name of the deck",
    type: String,
    example: "Wildlife Conservation Deck"
  })
], DeckResponse.prototype, "name", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The description of the deck",
    type: String,
    example: "A deck focused on wildlife conservation awareness."
  })
], DeckResponse.prototype, "description", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The creation date of the deck",
    type: String,
    format: "date-time",
    example: "2025-02-12T10:15:30Z"
  })
], DeckResponse.prototype, "created", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The URL path for the deck",
    type: [String],
    example: ["vanityname", "wildlife-conservation"]
  })
], DeckResponse.prototype, "path", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "The remaining number of cards in the deck",
    type: Number,
    example: 10
  })
], DeckResponse.prototype, "remainingCardsCount", 2);
__decorateClass([
  ApiProperty75({
    description: "Presentation settings of the deck",
    required: false,
    type: () => PresentationSetting
  })
], DeckResponse.prototype, "presentation", 2);
__decorateClass([
  ApiProperty75({
    description: "Curator details of the deck",
    required: false,
    type: () => CuratorResponse
  })
], DeckResponse.prototype, "curator", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Following information of the deck",
    type: () => FollowingResponse
  })
], DeckResponse.prototype, "following", 2);
__decorateClass([
  ApiProperty75({
    required: false,
    description: "Array of reserves related to the deck",
    type: () => [ReserveResponse]
  })
], DeckResponse.prototype, "reserves", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Cards present in the deck",
    oneOf: [
      { $ref: getSchemaPath22(CardStandardResponseDto) },
      { $ref: getSchemaPath22(CardActionResponseDto) },
      { $ref: getSchemaPath22(CardInfoResponseDto) },
      { $ref: getSchemaPath22(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath22(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath22(CardItemEditableResponseDto) },
      { $ref: getSchemaPath22(CardItemEditableRepostResponseDto) }
    ],
    type: () => [Object]
  })
], DeckResponse.prototype, "cards", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Category principles associated with the deck",
    type: [String],
    example: ["JOKER", "CREATION"]
  })
], DeckResponse.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty75({
    required: true,
    description: "Indicates if cache is used",
    type: Boolean,
    example: false
  })
], DeckResponse.prototype, "useCache", 2);
var CollectionCreator = class {
};
__decorateClass([
  ApiProperty76({
    example: "12339939",
    description: "Collection creator's earth ID",
    type: String,
    format: "int64",
    required: true
  })
], CollectionCreator.prototype, "id", 2);
__decorateClass([
  ApiProperty76({
    required: true,
    description: "Collection creator's name",
    type: String,
    example: "Dung Nguyen"
  })
], CollectionCreator.prototype, "name", 2);
__decorateClass([
  ApiProperty76({
    required: true,
    description: "Collection creator's vanity name",
    type: String,
    example: "dungnguyen"
  })
], CollectionCreator.prototype, "vanityName", 2);
var UonTransactionResponseCollectable = class {
};
var UonTransactionResponseDefault = class {
};
var SalesTypeDto = /* @__PURE__ */ ((SalesTypeDto2) => {
  SalesTypeDto2["RETAIL"] = "RETAIL";
  SalesTypeDto2["WHOLESALE"] = "WHOLESALE";
  SalesTypeDto2["PREPAID"] = "PREPAID";
  return SalesTypeDto2;
})(SalesTypeDto || {});
var UonBaseDto = class {
};
var Npo = class {
};
var Meter = class {
};
var Consumer = class {
};
var LatLng = class {
};
var Brand = class {
};
var UonBase = class {
};
__decorateClass([
  ApiProperty77({
    example: "12345678",
    description: "The id of uon",
    type: String,
    format: "int64",
    required: true
  })
], UonBase.prototype, "id", 2);
__decorateClass([
  ApiProperty77({
    description: "The meter of uon",
    type: Meter,
    required: true
  })
], UonBase.prototype, "meter", 2);
__decorateClass([
  ApiProperty77({
    example: "",
    description: "The createdAt of uon",
    type: String,
    format: "date-time",
    required: true
  })
], UonBase.prototype, "createdAt", 2);
__decorateClass([
  ApiProperty77({
    description: "The owner of uon",
    type: () => Consumer,
    required: false
  })
], UonBase.prototype, "owner", 2);
__decorateClass([
  ApiProperty77({
    example: "https/uon-store/api/member/reserves/121/images/QRWGru9hXLjW",
    description: "The reserveImageUrl of uon",
    type: String,
    required: false
  })
], UonBase.prototype, "reserveImageUrl", 2);
__decorateClass([
  ApiProperty77({
    description: "The point of uon",
    type: LatLng,
    required: false
  })
], UonBase.prototype, "point", 2);
__decorateClass([
  ApiProperty77({
    description: "The donatorConsumer of uon",
    type: Consumer,
    required: false
  })
], UonBase.prototype, "donatorConsumer", 2);
__decorateClass([
  ApiProperty77({
    description: "The donatorBrand of uon",
    type: Brand,
    required: false
  })
], UonBase.prototype, "donatorBrand", 2);
__decorateClass([
  ApiProperty77({
    description: "The donatedNpo of uon",
    type: Npo,
    required: false
  })
], UonBase.prototype, "donatedNpo", 2);
__decorateClass([
  ApiProperty77({
    example: 12,
    description: "The count of uon",
    type: Number,
    required: false
  })
], UonBase.prototype, "count", 2);
__decorateClass([
  ApiProperty77({
    example: UonTransactionType.CLAIM,
    description: "The type of uon",
    enum: UonTransactionType,
    required: false
  })
], UonBase.prototype, "type", 2);
__decorateClass([
  ApiProperty77({
    example: "PREPAID",
    description: "The salesType of uon",
    enum: SalesTypeDto,
    required: false
  })
], UonBase.prototype, "salesType", 2);
__decorateClass([
  ApiProperty77({
    example: "code",
    description: "The code of uon",
    type: String,
    required: false
  })
], UonBase.prototype, "code", 2);
__decorateClass([
  ApiProperty77({
    example: "2024-07-07...",
    description: "The giftExpiredAt of uon",
    type: String,
    required: false
  })
], UonBase.prototype, "giftExpiredAt", 2);
__decorateClass([
  ApiProperty77({
    example: "https://image.ok/asset.png",
    description: "The previewImage of uon",
    type: String,
    required: false
  })
], UonBase.prototype, "previewImage", 2);
var ReserveImage = class {
};
__decorateClass([
  ApiProperty77({
    example: "reserves/1693082249/original-9dcd3697-7984-4f31-9ecb-57fd4ef337ad.png",
    description: "The key of reserve image",
    type: String,
    required: true
  })
], ReserveImage.prototype, "key", 2);
__decorateClass([
  ApiProperty77({
    example: "https://i-uon.urbn8.io",
    description: "The base url of reserve image",
    type: String,
    required: true
  })
], ReserveImage.prototype, "baseUrl", 2);
__decorateClass([
  ApiProperty77({
    example: "uon-stage-collectable-images",
    description: "The aws bucket of reserve image",
    type: String,
    required: true
  })
], ReserveImage.prototype, "bucket", 2);
var UonStandard = class extends UonBase {
};
__decorateClass([
  ApiProperty77({
    example: ImageSource.RESERVE_IMAGE,
    description: "The image source of uon. The value must be RESERVE_IMAGE",
    enum: [ImageSource.RESERVE_IMAGE],
    required: true
  })
], UonStandard.prototype, "imageSource", 2);
__decorateClass([
  ApiProperty77({
    description: "The reserve image of uon",
    type: () => ReserveImage,
    required: true
  })
], UonStandard.prototype, "reserveImage", 2);
var UonCollectableUnlimitedSeriesDto = class {
};
__decorateClass([
  ApiProperty78({
    example: PromotionSeriesType.UNLIMITED,
    description: "The type of series. The value must be UNLIMITED",
    type: String,
    enum: [PromotionSeriesType.UNLIMITED],
    required: true
  })
], UonCollectableUnlimitedSeriesDto.prototype, "type", 2);
var UonCollectableLimitedSeriesDto = class {
};
__decorateClass([
  ApiProperty78({
    example: PromotionSeriesType.LIMITED,
    description: "The type of of series. The value must be LIMITED",
    type: String,
    enum: [PromotionSeriesType.LIMITED],
    required: true
  })
], UonCollectableLimitedSeriesDto.prototype, "type", 2);
__decorateClass([
  ApiProperty78({
    example: 10,
    description: "The max items of limited series",
    type: Number,
    required: true
  })
], UonCollectableLimitedSeriesDto.prototype, "maxItems", 2);
__decorateClass([
  ApiProperty78({
    example: 10,
    description: "The serial number of limited series",
    type: Number,
    required: true
  })
], UonCollectableLimitedSeriesDto.prototype, "serialNumber", 2);
var UonImageExtract = class {
};
__decorateClass([
  ApiProperty79({
    example: 100,
    description: "The zero-indexed offset from left edge",
    type: Number,
    required: true
  })
], UonImageExtract.prototype, "left", 2);
__decorateClass([
  ApiProperty79({
    example: 100,
    description: "The zero-indexed offset from top edge",
    type: Number,
    required: true
  })
], UonImageExtract.prototype, "top", 2);
__decorateClass([
  ApiProperty79({
    example: 100,
    description: "The width of region to extract",
    type: Number,
    required: true
  })
], UonImageExtract.prototype, "width", 2);
__decorateClass([
  ApiProperty79({
    example: 100,
    description: "The height of region to extract",
    type: Number,
    required: true
  })
], UonImageExtract.prototype, "height", 2);
var UonImage = class {
};
__decorateClass([
  ApiProperty79({
    example: "uons/1693082249/original-9dcd3697-7984-4f31-9ecb-57fd4ef337ad.png",
    description: "The key of uon image",
    type: String,
    required: true
  })
], UonImage.prototype, "key", 2);
__decorateClass([
  ApiProperty79({
    example: "https://i-uon.urbn8.io",
    description: "The base url of uon image",
    type: String,
    required: true
  })
], UonImage.prototype, "baseUrl", 2);
__decorateClass([
  ApiProperty79({
    example: "uon-stage-collectable-images",
    description: "The aws bucket of uon image",
    type: String,
    required: true
  })
], UonImage.prototype, "bucket", 2);
__decorateClass([
  ApiProperty79({
    example: true,
    description: "The is transparent of uon image",
    type: Boolean,
    required: false
  })
], UonImage.prototype, "isTransparent", 2);
__decorateClass([
  ApiProperty79({
    description: "The extract of uon image",
    type: UonImageExtract,
    required: false
  })
], UonImage.prototype, "extract", 2);
var UonCollectable = class extends UonBase {
};
__decorateClass([
  ApiProperty79({
    example: ImageSource.UON_IMAGE,
    description: "The image source of uon. The value must be UON_IMAGE",
    required: true,
    enum: [ImageSource.UON_IMAGE]
  })
], UonCollectable.prototype, "imageSource", 2);
__decorateClass([
  ApiProperty79({
    description: "The uon image",
    required: true,
    type: UonImage
  })
], UonCollectable.prototype, "uonImage", 2);
__decorateClass([
  ApiProperty79({
    description: "The series of uon image",
    oneOf: [
      {
        $ref: getSchemaPath23(UonCollectableLimitedSeriesDto)
      },
      {
        $ref: getSchemaPath23(UonCollectableUnlimitedSeriesDto)
      }
    ],
    type: () => Object,
    required: true
  })
], UonCollectable.prototype, "series", 2);
__decorateClass([
  ApiProperty79({
    example: "name",
    description: "The collection name of uon image",
    type: String,
    required: true
  })
], UonCollectable.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty79({
    example: PromotionImageShape.ROUND,
    description: "The uonImageShape of uon image",
    enum: PromotionImageShape,
    required: true
  })
], UonCollectable.prototype, "uonImageShape", 2);
__decorateClass([
  ApiProperty79({
    description: "The collectionCreator of uon image",
    type: CollectionCreator,
    required: true
  })
], UonCollectable.prototype, "collectionCreator", 2);
UonCollectable = __decorateClass([
  ApiExtraModels15(
    UonCollectableLimitedSeriesDto,
    UonCollectableUnlimitedSeriesDto
  )
], UonCollectable);
var UonCollectableDto = class extends UonBaseDto {
};
__decorateClass([
  ApiProperty79({
    description: "The image source of uon. The value must be UON_IMAGE",
    required: true,
    enum: [ImageSource.UON_IMAGE]
  })
], UonCollectableDto.prototype, "imageSource", 2);
__decorateClass([
  ApiProperty79({
    description: "The uon image",
    required: true,
    type: UonImage
  })
], UonCollectableDto.prototype, "uonImage", 2);
__decorateClass([
  ApiProperty79({
    description: "The series of uon image",
    oneOf: [
      {
        $ref: getSchemaPath23(UonCollectableLimitedSeriesDto)
      },
      {
        $ref: getSchemaPath23(UonCollectableUnlimitedSeriesDto)
      }
    ],
    type: () => Object,
    required: true
  })
], UonCollectableDto.prototype, "series", 2);
__decorateClass([
  ApiProperty79({
    example: "name",
    description: "The collection name of uon image",
    type: String,
    required: true
  })
], UonCollectableDto.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty79({
    example: PromotionImageShape.ROUND,
    description: "The uonImageShape of uon image",
    enum: PromotionImageShape,
    required: true
  })
], UonCollectableDto.prototype, "uonImageShape", 2);
__decorateClass([
  ApiProperty79({
    description: "The collectionCreator of uon image",
    type: CollectionCreator,
    required: true
  })
], UonCollectableDto.prototype, "collectionCreator", 2);
var UonCardStandardResponseDto = class extends UonStandard {
};
__decorateClass([
  ApiProperty80({
    description: "Reserve Deck associated with the Uon Card",
    required: false,
    type: () => DeckResponse
  })
], UonCardStandardResponseDto.prototype, "reserveDeck", 2);
var UonCardCollectibleResponseDto = class extends UonCollectable {
};
__decorateClass([
  ApiProperty80({
    description: "Reserve Deck associated with the Uon Card",
    required: false,
    type: () => DeckResponse
  })
], UonCardCollectibleResponseDto.prototype, "reserveDeck", 2);
var c7 = initContract();
var uonContract = c7.router({
  getTransactions: {
    method: "GET",
    path: "/v1/users/:userIdOrVanityName/uon/transactions",
    query: z.object({
      offset: z.string().optional()
    }),
    pathParams: z.object({
      userIdOrVanityName: z.string()
    }),
    responses: {
      200: c7.type(),
      400: c7.type(),
      401: c7.type(),
      404: c7.type(),
      500: c7.type(),
      501: c7.type()
    },
    headers: z.object({
      "x-apicache-bypass": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  createGiftCode: {
    method: "POST",
    path: "/v1/uon/giftCodes",
    body: c7.type(),
    responses: {
      200: c7.type(),
      400: c7.type(),
      401: c7.type(),
      500: c7.type()
    },
    strictStatusCodes: true
  },
  redeemClaimCode: {
    method: "POST",
    path: "/v1/uon/claims",
    body: c7.type(),
    responses: {
      200: c7.type(),
      400: c7.type(),
      401: c7.type(),
      404: c7.type(),
      500: c7.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "x-apicache-bypass": z.string().optional(),
      "cache-control": z.string().optional()
    })
  }
});
var CollectCodeRedeemPayload = class {
};
__decorateClass([
  IsArray(),
  ArrayNotEmpty(),
  ApiProperty81({
    example: "WG0001-LZ6I1AGH",
    required: true,
    description: "List of codes to be collected",
    type: String
  })
], CollectCodeRedeemPayload.prototype, "codes", 2);
__decorateClass([
  IsOptional(),
  IsBoolean(),
  ApiProperty81({
    example: false,
    required: true,
    description: "The reward status",
    type: Boolean
  })
], CollectCodeRedeemPayload.prototype, "isRewardEnabled", 2);
var numberType2 = {
  type: "integer",
  required: true,
  example: 128
};
var stringBigIntType = {
  type: String,
  format: "int64",
  required: true,
  example: "1550858315508583"
};
var UserSocialImageResponse = class {
};
__decorateClass([
  ApiProperty82(__spreadProps(__spreadValues({}, stringBigIntType), {
    description: "The id of image"
  }))
], UserSocialImageResponse.prototype, "id", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "red",
    description: "The primary color of image"
  })
], UserSocialImageResponse.prototype, "primaryColor", 2);
__decorateClass([
  ApiProperty82(__spreadProps(__spreadValues({}, numberType2), {
    description: "The original width of image"
  }))
], UserSocialImageResponse.prototype, "originalWidth", 2);
__decorateClass([
  ApiProperty82(__spreadProps(__spreadValues({}, numberType2), {
    description: "The original width of image"
  }))
], UserSocialImageResponse.prototype, "originalHeight", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "https://stage-images.earthtoday.com/eyJidWNrZXQiOiJlYXJ0aHRvZGF5LXN0YWdlLWltYWdlcyIsImtleSI6Ii91c2Vycy82MjM0N",
    description: "The url of image"
  })
], UserSocialImageResponse.prototype, "url", 2);
var UserSocialDetails = class {
};
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "Default Name",
    description: "User's full name"
  })
], UserSocialDetails.prototype, "name", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    enum: UserType,
    required: true,
    example: "CONSUMER",
    description: "User's type"
  })
], UserSocialDetails.prototype, "type", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "Death like the wind, always by my side",
    description: "User's quote"
  })
], UserSocialDetails.prototype, "quote", 2);
var UserSocialCounts = class {
};
__decorateClass([
  ApiProperty82({
    type: "integer",
    required: true,
    example: 2,
    description: "The number of decks that user owns"
  })
], UserSocialCounts.prototype, "curatedDecks", 2);
__decorateClass([
  ApiProperty82({
    type: "integer",
    required: false,
    example: 33,
    description: "The number of drafting cards that user owns"
  })
], UserSocialCounts.prototype, "draftingCards", 2);
__decorateClass([
  ApiProperty82({
    type: "integer",
    required: true,
    example: 10,
    description: "The number of uons that user owns"
  })
], UserSocialCounts.prototype, "uonCount", 2);
__decorateClass([
  ApiProperty82({
    type: "integer",
    required: false,
    example: 10,
    description: "The number of followers that user owns"
  })
], UserSocialCounts.prototype, "followerCounts", 2);
var UserSocialSignInResponseDto = class {
};
__decorateClass([
  ApiProperty82(__spreadProps(__spreadValues({}, stringBigIntType), {
    example: "1550858321152427",
    description: "User's Id"
  }))
], UserSocialSignInResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "earthtoday",
    description: "User's vanity name"
  })
], UserSocialSignInResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "Earth",
    description: "User's fisrt name"
  })
], UserSocialSignInResponseDto.prototype, "firstName", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "Today",
    description: "User's last name"
  })
], UserSocialSignInResponseDto.prototype, "lastName", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "Earth Today",
    description: "User's full name"
  })
], UserSocialSignInResponseDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: false,
    description: "User's dark mode"
  })
], UserSocialSignInResponseDto.prototype, "darkMode", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "I am poor",
    description: "User's biography"
  })
], UserSocialSignInResponseDto.prototype, "bio", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: true,
    example: "google",
    description: "User's provider"
  })
], UserSocialSignInResponseDto.prototype, "provider", 2);
__decorateClass([
  ApiProperty82(__spreadProps(__spreadValues({}, stringBigIntType), {
    example: "102341949485684346825",
    description: "User's provider id"
  }))
], UserSocialSignInResponseDto.prototype, "providerId", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    format: "email",
    required: false,
    example: "earthtoday@urbn8.com",
    description: "User's email address"
  })
], UserSocialSignInResponseDto.prototype, "emailAddress", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: true,
    description: "User's email address is verified or not"
  })
], UserSocialSignInResponseDto.prototype, "emailAddressVerified", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: false,
    description: "User is adminstrator or not"
  })
], UserSocialSignInResponseDto.prototype, "administrator", 2);
__decorateClass([
  ApiProperty82({
    type: Date,
    required: true,
    example: "2023-01-06T09:39:04.846Z",
    description: "The time that user joined",
    nullable: true
  })
], UserSocialSignInResponseDto.prototype, "joined", 2);
__decorateClass([
  ApiProperty82({
    type: () => UserImageResponseDto2,
    required: true,
    description: "User's avatar"
  })
], UserSocialSignInResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty82({
    type: UserImageResponseDto2,
    required: false,
    description: "User's cover image"
  })
], UserSocialSignInResponseDto.prototype, "cover", 2);
__decorateClass([
  ApiProperty82({
    type: UserSocialDetails,
    required: false,
    description: "User's details"
  })
], UserSocialSignInResponseDto.prototype, "details", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: true,
    description: "User is accepted the terms or not"
  })
], UserSocialSignInResponseDto.prototype, "termsAccepted", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: false,
    description: "User's terms is expired or not"
  })
], UserSocialSignInResponseDto.prototype, "termsExpired", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    enum: ["social_media"],
    required: true,
    example: "social_media",
    description: "The type of cookie consent"
  })
], UserSocialSignInResponseDto.prototype, "cookieConsent", 2);
__decorateClass([
  ApiProperty82({
    type: UserSocialCounts,
    required: true,
    description: "The set of counts such as number of decks, number of cards..."
  })
], UserSocialSignInResponseDto.prototype, "userCounts", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "earthtoday.com.vn",
    description: "User's website"
  })
], UserSocialSignInResponseDto.prototype, "website", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "Elon Musk",
    description: "User's twitter name"
  })
], UserSocialSignInResponseDto.prototype, "twitterUsername", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "Nick",
    description: "User's facebook name"
  })
], UserSocialSignInResponseDto.prototype, "facebookUsername", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "Donald Trump",
    description: "User's instagram name"
  })
], UserSocialSignInResponseDto.prototype, "instagramUsername", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    required: false,
    example: "Bill Gate",
    description: "User's liked in name"
  })
], UserSocialSignInResponseDto.prototype, "linkedInUsername", 2);
__decorateClass([
  ApiProperty82({
    type: String,
    enum: PhotoState,
    required: true,
    example: "SOCIAL",
    description: "The state of photo"
  })
], UserSocialSignInResponseDto.prototype, "photoState", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: true,
    example: true,
    description: "User is curator or not"
  })
], UserSocialSignInResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty82({
    type: Boolean,
    required: false,
    example: true,
    description: "User's account is published or not"
  })
], UserSocialSignInResponseDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty82({
    type: MetaTagsResponseDto,
    description: "returns if request provided metaTags=true",
    required: false
  })
], UserSocialSignInResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty82({
    type: Date,
    required: false,
    example: (/* @__PURE__ */ new Date(1597732578036)).toISOString(),
    description: "The time that user joined"
  })
], UserSocialSignInResponseDto.prototype, "joinedAt", 2);
__decorateClass([
  ApiProperty82({
    type: LoginMetaResponseDto,
    required: false,
    description: "User's login counts and the time of last user's login"
  })
], UserSocialSignInResponseDto.prototype, "meta", 2);
var RedeemCodeResponseDefault = class extends UonTransactionResponseDefault {
};
__decorateClass([
  ApiProperty83({
    type: Boolean,
    required: false,
    example: false,
    description: "Code is used or not"
  })
], RedeemCodeResponseDefault.prototype, "isCodeUsed", 2);
__decorateClass([
  ApiProperty83({
    type: Date,
    required: false,
    nullable: true,
    example: "2023-01-06T09:39:04.846Z",
    description: "The time when user created transaction"
  })
], RedeemCodeResponseDefault.prototype, "transactionAt", 2);
__decorateClass([
  ApiProperty83({
    type: String,
    enum: ["success"],
    description: "returns success when system is sucessfull",
    required: true
  })
], RedeemCodeResponseDefault.prototype, "status", 2);
var EnqueuedStatusResponseDto = class {
};
__decorateClass([
  ApiProperty83({
    type: String,
    enum: ["enqueued"],
    description: "returns when system busy then put redeeming request in the queue",
    required: true
  })
], EnqueuedStatusResponseDto.prototype, "status", 2);
var RedeemCodeResponseCollectable = class extends UonTransactionResponseCollectable {
};
__decorateClass([
  ApiProperty83({
    type: Boolean,
    required: false,
    example: false,
    description: "Code is used or not"
  })
], RedeemCodeResponseCollectable.prototype, "isCodeUsed", 2);
__decorateClass([
  ApiProperty83({
    type: Date,
    required: false,
    nullable: true,
    example: "2023-01-06T09:39:04.846Z",
    description: "The time when user created transaction"
  })
], RedeemCodeResponseCollectable.prototype, "transactionAt", 2);
__decorateClass([
  ApiProperty83({
    type: String,
    enum: ["success"],
    description: "returns success when system is sucessfull",
    required: true
  })
], RedeemCodeResponseCollectable.prototype, "status", 2);
var RedeemErrorDto = class {
};
__decorateClass([
  ApiProperty83({
    type: String,
    example: "IllegalState",
    required: true
  })
], RedeemErrorDto.prototype, "type", 2);
__decorateClass([
  ApiProperty83({
    type: String,
    example: "IllegalState",
    required: true
  })
], RedeemErrorDto.prototype, "debugMessage", 2);
var UserSocialSigninAndCollectSuccessfullyResponseDto = class {
};
__decorateClass([
  ApiProperty83({
    type: UserSocialSignInResponseDto,
    required: true,
    title: ""
  })
], UserSocialSigninAndCollectSuccessfullyResponseDto.prototype, "userResponse", 2);
__decorateClass([
  ApiProperty83({
    type: () => RedeemErrorDto,
    nullable: true,
    required: false,
    description: "returns if any error happened when redeeming the code",
    example: null
  })
], UserSocialSigninAndCollectSuccessfullyResponseDto.prototype, "redeemCodeError", 2);
var UserSocialSigninAndCollectEnqueuedResponseDto = class {
};
__decorateClass([
  ApiProperty83({
    type: UserSocialSignInResponseDto,
    required: true,
    title: ""
  })
], UserSocialSigninAndCollectEnqueuedResponseDto.prototype, "userResponse", 2);
__decorateClass([
  ApiProperty83({
    required: true,
    type: () => EnqueuedStatusResponseDto,
    title: ""
  })
], UserSocialSigninAndCollectEnqueuedResponseDto.prototype, "redeemCodeResponse", 2);
__decorateClass([
  ApiProperty83({
    nullable: true,
    required: false,
    description: "returns if any error happened when redeeming the code",
    example: null,
    type: () => "object"
  })
], UserSocialSigninAndCollectEnqueuedResponseDto.prototype, "redeemCodeError", 2);
var UserSocialSigninAndCollectErrorResponseDto = class {
};
__decorateClass([
  ApiProperty83({
    type: UserSocialSignInResponseDto,
    required: true,
    title: ""
  })
], UserSocialSigninAndCollectErrorResponseDto.prototype, "userResponse", 2);
__decorateClass([
  ApiProperty83({
    required: true,
    nullable: true,
    description: "returns null if any error happened when redeeming the code",
    example: null,
    type: () => "object"
  })
], UserSocialSigninAndCollectErrorResponseDto.prototype, "redeemCodeResponse", 2);
__decorateClass([
  ApiProperty83({
    type: RedeemErrorDto,
    required: false,
    description: "returns if any error happened when redeeming the code"
  })
], UserSocialSigninAndCollectErrorResponseDto.prototype, "redeemCodeError", 2);
var c8 = initContract();
var userContract = c8.router({
  commitUpload: {
    method: "POST",
    path: "/v1/users/me/uploads/commit",
    responses: {
      200: c8.type(),
      400: c8.type(),
      401: c8.type(),
      409: c8.type(),
      500: c8.type()
    },
    body: c8.type(),
    strictStatusCodes: true
  },
  deleteUser: {
    method: "DELETE",
    path: "/v1/users/:earthIdOrVanityName",
    responses: {
      204: c8.type(),
      400: c8.type(),
      401: c8.type(),
      500: c8.type()
    },
    body: c8.type(),
    pathParams: z.object({
      earthIdOrVanityName: z.string()
    })
  },
  userStatistics: {
    method: "GET",
    path: "/v1/users/:userIdOrVanityName/counts",
    pathParams: z.object({
      userIdOrVanityName: z.string()
    }),
    responses: {
      200: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    headers: z.object({
      "x-apicache-bypass": z.string().optional(),
      "cache-control": z.string().optional()
    }),
    strictStatusCodes: true
  },
  updateUserDetail: {
    method: "PUT",
    path: "/v1/users/me",
    responses: {
      200: c8.type(),
      400: c8.type(),
      401: c8.type(),
      500: c8.type()
    },
    body: c8.type(),
    strictStatusCodes: true
  },
  getShortcutsByUserV2: {
    method: "GET",
    path: "/v2/users/:userIdOrVanityName/shortcuts",
    pathParams: z.object({
      userIdOrVanityName: z.string().optional()
    }),
    query: z.object({
      responseType: z.string().optional()
    }),
    responses: {
      200: c8.type(),
      400: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "max-age": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  getUserNotificationsLegacy: {
    method: "GET",
    path: "/v1/users/me/notifications",
    responses: {
      200: c8.type(),
      400: c8.type(),
      401: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "max-age": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  getUserNotifications: {
    method: "GET",
    path: "/v2/users/me/notifications",
    query: z.object({
      status: z.string().optional()
    }),
    responses: {
      200: c8.type(),
      400: c8.type(),
      401: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "max-age": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  getUserCarousel: {
    method: "GET",
    path: "/v1/users/:idOrVanityName/carousel",
    pathParams: z.object({
      idOrVanityName: z.string()
    }),
    query: c8.type(),
    responses: {
      200: c8.type(),
      400: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  createMagicLink: {
    method: "POST",
    path: "/v1/users/auth/magic-link",
    body: c8.type(),
    responses: {
      200: c8.type(),
      400: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  verifyMagicLink: {
    method: "GET",
    path: "/v1/users/auth/magic-link/verify",
    query: z.object({
      token: z.string()
    }),
    responses: {
      307: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  getMagicLinkByAuthUuid: {
    method: "GET",
    path: "/v1/users/auth/magic-link/:authUuid",
    pathParams: z.object({
      authUuid: z.string()
    }),
    responses: {
      200: c8.type(),
      401: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  getJoinedGroups: {
    method: "GET",
    path: "/v1/users/:userIdOrVanityName/groups",
    pathParams: z.object({
      userIdOrVanityName: z.string()
    }),
    query: z.object({
      filterPublicOnly: z.enum(["true", "false"]).optional()
    }),
    responses: {
      200: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      "max-age": z.string().optional(),
      "cache-control": z.string().optional()
    })
  },
  signoutUser: {
    method: "PUT",
    path: "/v1/users/me/logins/signout",
    body: c8.type(),
    responses: {
      204: c8.type(),
      401: c8.type(),
      400: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  refreshToken: {
    method: "POST",
    path: "/v1/users/me/logins/token/refresh",
    body: c8.type(),
    responses: {
      200: c8.type(),
      401: c8.type(),
      400: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true
  },
  userRetrieveMe: {
    method: "GET",
    path: "/v1/users/me",
    query: z.object({
      metaTags: z.enum(["true", "false"]).optional()
    }),
    responses: {
      200: c8.type(),
      404: c8.type(),
      500: c8.type()
    },
    strictStatusCodes: true,
    headers: z.object({
      Authorization: z.string().optional()
    })
  }
});
var UserProfilePhotoUploadCommitPayloadDtoDefault = class {
};
__decorateClass([
  ApiProperty84({
    example: "eyJhb...bqA",
    description: "Upload token from presign endpoint",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoDefault.prototype, "uploadToken", 2);
__decorateClass([
  ApiProperty84({
    example: ImageTarget.USER_PROFILE,
    description: "target must be USER_PROFILE",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsIn([ImageTarget.USER_PROFILE])
], UserProfilePhotoUploadCommitPayloadDtoDefault.prototype, "target", 2);
__decorateClass([
  ApiProperty84({ type: AwsImageExtract, required: false }),
  ValidateNested(),
  Type15(() => AwsImageExtract)
], UserProfilePhotoUploadCommitPayloadDtoDefault.prototype, "profileExtract", 2);
var UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries = class {
};
__decorateClass([
  ApiProperty84({
    example: PromotionSeriesType.UNLIMITED,
    description: "The type of series. The value must be UNLIMITED",
    type: String,
    enum: [PromotionSeriesType.UNLIMITED],
    required: true
  }),
  IsDefined(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries.prototype, "type", 2);
var UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries = class {
};
__decorateClass([
  ApiProperty84({
    example: PromotionSeriesType.LIMITED,
    description: "The type of of series. The value must be LIMITED",
    type: String,
    enum: [PromotionSeriesType.LIMITED],
    required: true
  }),
  IsDefined(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries.prototype, "type", 2);
__decorateClass([
  ApiProperty84({
    example: 10,
    description: "The max items of limited series",
    type: Number,
    required: true
  }),
  IsDefined(),
  IsNumber()
], UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries.prototype, "maxItems", 2);
var UserProfilePhotoUploadCommitPayloadDtoProfilePromotion = class {
};
__decorateClass([
  ApiProperty84({
    example: "eyJhb...bqA",
    description: "Upload token from presign endpoint. Can be null when creating Promotion from existing profile image.",
    required: false,
    nullable: false,
    type: String
  }),
  IsOptional(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "uploadToken", 2);
__decorateClass([
  ApiProperty84({
    example: ImageTarget.USER_PROFILE_PROMOTION,
    description: "target must be USER_PROFILE_PROMOTION",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsIn([ImageTarget.USER_PROFILE_PROMOTION])
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "target", 2);
__decorateClass([
  ApiProperty84({ type: AwsImageExtract, required: false }),
  ValidateNested(),
  Type15(() => AwsImageExtract)
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "profileExtract", 2);
__decorateClass([
  ApiProperty84({
    required: true,
    description: "Also create a Promotion from the Profile Image",
    type: Boolean,
    example: true
  }),
  IsBoolean(),
  IsDefined()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "shouldCreateUonCollectable", 2);
__decorateClass([
  ApiProperty84({ type: AwsImageExtract, required: false }),
  ValidateNested(),
  Type15(() => AwsImageExtract),
  IsOptional()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "uonPromotionExtract", 2);
__decorateClass([
  ApiProperty84({
    example: "fake collection name",
    description: "Collection name of promo card",
    required: false,
    nullable: false,
    type: String
  }),
  IsDefined(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty84({
    example: "UNLIMITED",
    description: "series of promo card",
    required: false,
    nullable: false,
    oneOf: [
      {
        $ref: getSchemaPath24(
          UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries
        )
      },
      {
        $ref: getSchemaPath24(
          UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
        )
      }
    ]
  }),
  IsDefined()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "series", 2);
__decorateClass([
  ApiProperty84({
    example: true,
    description: "enable open promo card",
    required: false,
    nullable: false,
    type: Boolean
  }),
  IsBoolean(),
  IsDefined()
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion.prototype, "enableOpenCardView", 2);
UserProfilePhotoUploadCommitPayloadDtoProfilePromotion = __decorateClass([
  ApiExtraModels16(
    UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries,
    UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
  )
], UserProfilePhotoUploadCommitPayloadDtoProfilePromotion);
var UserProfilePhotoUploadCommitPayloadDtoUonPromotion = class {
};
__decorateClass([
  ApiProperty84({
    example: "eyJhb...bqA",
    description: "Upload token from presign endpoint. Can be null when creating Promotion from existing profile image.",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "uploadToken", 2);
__decorateClass([
  ApiProperty84({
    example: ImageTarget.UON_PROMOTION,
    description: "target must be UON_PROMOTION",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsIn([ImageTarget.UON_PROMOTION])
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "target", 2);
__decorateClass([
  ApiProperty84({ type: AwsImageExtract, required: false }),
  ValidateNested(),
  Type15(() => AwsImageExtract),
  IsOptional()
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "uonPromotionExtract", 2);
__decorateClass([
  ApiProperty84({
    example: "fake collection name",
    description: "Collection name of promo card",
    required: false,
    nullable: false,
    type: String
  }),
  IsDefined(),
  IsString()
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty84({
    example: "UNLIMITED",
    description: "series of promo card",
    required: false,
    nullable: false,
    oneOf: [
      {
        $ref: getSchemaPath24(
          UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries
        )
      },
      {
        $ref: getSchemaPath24(
          UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
        )
      }
    ]
  }),
  IsDefined()
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "series", 2);
__decorateClass([
  ApiProperty84({
    example: true,
    description: "enable open promo card",
    required: false,
    nullable: false,
    type: Boolean
  }),
  IsBoolean(),
  IsDefined()
], UserProfilePhotoUploadCommitPayloadDtoUonPromotion.prototype, "enableOpenCardView", 2);
var ShortcutType = /* @__PURE__ */ ((ShortcutType2) => {
  ShortcutType2["DECK"] = "DECK";
  ShortcutType2["INTERNAL_PATH"] = "INTERNAL_PATH";
  ShortcutType2["EXTERNAL_URL"] = "EXTERNAL_URL";
  return ShortcutType2;
})(ShortcutType || {});
var CurationSource = /* @__PURE__ */ ((CurationSource2) => {
  CurationSource2["PERSONAL"] = "PERSONAL";
  CurationSource2["GUEST"] = "GUEST";
  return CurationSource2;
})(CurationSource || {});
var ShortcutsResponseDtoDataBase = class {
};
__decorateClass([
  ApiProperty85({
    example: "123456789",
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of the shortcut"
  })
], ShortcutsResponseDtoDataBase.prototype, "id", 2);
__decorateClass([
  ApiProperty85({
    example: "Awesome Deck",
    required: true,
    type: String,
    description: "The name of the shortcut"
  })
], ShortcutsResponseDtoDataBase.prototype, "name", 2);
__decorateClass([
  ApiProperty85({
    example: true,
    required: true,
    type: Boolean,
    description: "The is open in new tab of shortcut"
  })
], ShortcutsResponseDtoDataBase.prototype, "isOpenInNewTab", 2);
var ShortcutsResponseDtoDataDeck = class extends ShortcutsResponseDtoDataBase {
};
__decorateClass([
  ApiProperty85({
    example: "123456789",
    required: true,
    type: String,
    format: "int64",
    description: "The `deckId` of the shortcut"
  })
], ShortcutsResponseDtoDataDeck.prototype, "deckId", 2);
__decorateClass([
  ApiProperty85({
    example: "DECK",
    required: true,
    type: String,
    enum: [
      "DECK"
      /* DECK */
    ],
    description: "The type of the shortcut"
  })
], ShortcutsResponseDtoDataDeck.prototype, "type", 2);
__decorateClass([
  ApiProperty85({
    example: ["johndoe", "awesome-deck"],
    required: true,
    isArray: true,
    type: String,
    description: "The array of string to build the path to the resource"
  })
], ShortcutsResponseDtoDataDeck.prototype, "path", 2);
__decorateClass([
  ApiProperty85({
    required: true,
    isArray: true,
    type: () => DeckPreviewImage,
    description: "The images of cards in the deck"
  })
], ShortcutsResponseDtoDataDeck.prototype, "previewImages", 2);
__decorateClass([
  ApiProperty85({
    example: "GUEST",
    required: true,
    enum: CurationSource,
    description: "The curation source of shortcut"
  })
], ShortcutsResponseDtoDataDeck.prototype, "curationSource", 2);
var ShortcutsResponseDtoDataInternalPath = class extends ShortcutsResponseDtoDataBase {
};
__decorateClass([
  ApiProperty85({
    example: "DECK",
    required: true,
    type: String,
    enum: ShortcutType,
    description: "The type of the shortcut"
  })
], ShortcutsResponseDtoDataInternalPath.prototype, "type", 2);
__decorateClass([
  ApiProperty85({
    example: ["johndoe", "awesome-deck"],
    required: true,
    type: String,
    description: "The array of string to build the path to the resource"
  })
], ShortcutsResponseDtoDataInternalPath.prototype, "path", 2);
var ShortcutsResponseDtoDataExternalUrl = class extends ShortcutsResponseDtoDataBase {
};
__decorateClass([
  ApiProperty85({
    example: "DECK",
    required: true,
    type: String,
    enum: ShortcutType,
    description: "The type of the shortcut"
  })
], ShortcutsResponseDtoDataExternalUrl.prototype, "type", 2);
__decorateClass([
  ApiProperty85({
    example: ["johndoe", "awesome-deck"],
    required: true,
    type: Array,
    description: "The array of string to build the path to the resource"
  })
], ShortcutsResponseDtoDataExternalUrl.prototype, "url", 2);
var ShortcutsResponseWebAppDto = class {
};
__decorateClass([
  ApiProperty85({
    required: true,
    description: "shortcut response data",
    oneOf: [
      {
        $ref: getSchemaPath25(ShortcutsResponseDtoDataDeck)
      },
      {
        $ref: getSchemaPath25(ShortcutsResponseDtoDataInternalPath)
      },
      {
        $ref: getSchemaPath25(ShortcutsResponseDtoDataExternalUrl)
      }
    ],
    type: () => [
      ShortcutsResponseDtoDataDeck || ShortcutsResponseDtoDataInternalPath || ShortcutsResponseDtoDataExternalUrl
    ]
  })
], ShortcutsResponseWebAppDto.prototype, "data", 2);
__decorateClass([
  ApiProperty85({
    required: true,
    description: "The shortcut response type",
    type: String,
    enum: [ShortcutResponseType.WEB_APP]
  })
], ShortcutsResponseWebAppDto.prototype, "responseType", 2);
ShortcutsResponseWebAppDto = __decorateClass([
  ApiExtraModels17(
    ShortcutsResponseDtoDataDeck,
    ShortcutsResponseDtoDataInternalPath,
    ShortcutsResponseDtoDataExternalUrl
  )
], ShortcutsResponseWebAppDto);
var ShortcutsResponseMobileAppData = class {
};
__decorateClass([
  ApiProperty85({
    example: "123456789",
    required: true,
    type: String,
    format: "int64",
    description: "The `deckId` of the shortcut"
  })
], ShortcutsResponseMobileAppData.prototype, "deckId", 2);
var ShortcutsResponseMobileAppDto = class {
};
__decorateClass([
  ApiProperty85({
    required: true,
    type: [ShortcutsResponseMobileAppData]
  })
], ShortcutsResponseMobileAppDto.prototype, "data", 2);
__decorateClass([
  ApiProperty85({
    example: ShortcutResponseType.MOBILE_APP,
    required: true,
    type: String,
    enum: [ShortcutResponseType.MOBILE_APP],
    description: "The type of the shortcut"
  })
], ShortcutsResponseMobileAppDto.prototype, "responseType", 2);
var UserProfilePhotoUploadCommitResponseDtoDefault = class extends ImageResponseDto2 {
};
__decorateClass([
  ApiProperty86({
    example: ImageTarget.USER_PROFILE,
    description: "USER_PROFILE discriminator",
    required: true,
    nullable: false,
    type: String
  })
], UserProfilePhotoUploadCommitResponseDtoDefault.prototype, "target", 2);
var UserProfilePhotoUploadCommitResponseDtoProfilePromotionData = class {
};
__decorateClass([
  ApiProperty86({
    required: true,
    description: "Profile image",
    type: ImageResponseDto2
  })
], UserProfilePhotoUploadCommitResponseDtoProfilePromotionData.prototype, "profileImage", 2);
__decorateClass([
  ApiProperty86({
    required: false,
    description: "Promotion",
    type: PromotionDto
  })
], UserProfilePhotoUploadCommitResponseDtoProfilePromotionData.prototype, "promotion", 2);
var UserProfilePhotoUploadCommitResponseDtoProfilePromotion = class {
};
__decorateClass([
  ApiProperty86({
    example: ImageTarget.USER_PROFILE_PROMOTION,
    description: "USER_PROFILE_PROMOTION discriminator",
    required: true,
    nullable: false,
    type: String
  })
], UserProfilePhotoUploadCommitResponseDtoProfilePromotion.prototype, "target", 2);
__decorateClass([
  ApiProperty86({
    required: true,
    description: "Response data",
    type: UserProfilePhotoUploadCommitResponseDtoProfilePromotionData
  })
], UserProfilePhotoUploadCommitResponseDtoProfilePromotion.prototype, "data", 2);
var UserProfilePhotoUploadCommitResponseDtoUonPromotionData = class {
};
__decorateClass([
  ApiProperty86({
    required: true,
    description: "Promotion",
    type: PromotionDto
  })
], UserProfilePhotoUploadCommitResponseDtoUonPromotionData.prototype, "promotion", 2);
var UserProfilePhotoUploadCommitResponseDtoUonPromotion = class {
};
__decorateClass([
  ApiProperty86({
    example: ImageTarget.UON_PROMOTION,
    description: "UON_PROMOTION discriminator",
    required: true,
    nullable: false,
    type: String
  })
], UserProfilePhotoUploadCommitResponseDtoUonPromotion.prototype, "target", 2);
__decorateClass([
  ApiProperty86({
    required: true,
    description: "Response data",
    type: UserProfilePhotoUploadCommitResponseDtoUonPromotionData
  })
], UserProfilePhotoUploadCommitResponseDtoUonPromotion.prototype, "data", 2);
var NotificationActorDto = class {
};
__decorateClass([
  ApiProperty87({
    description: "The `id` of notification actor",
    example: "1234567",
    format: "int64",
    type: String,
    required: true
  })
], NotificationActorDto.prototype, "id", 2);
__decorateClass([
  ApiProperty87({
    description: "The vanity name of notification actor",
    example: "johndoe",
    required: true,
    type: String
  })
], NotificationActorDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty87({
    description: "The fullname of notification actor",
    example: "John Doe",
    required: true,
    type: String
  })
], NotificationActorDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty87({
    description: "The type of notification actor",
    example: UserType.CONSUMER,
    enum: UserType,
    required: true
  })
], NotificationActorDto.prototype, "userType", 2);
__decorateClass([
  ApiProperty87({
    description: "The option if tagline is forced",
    example: true,
    required: true,
    type: Boolean
  })
], NotificationActorDto.prototype, "forceUseTagLine", 2);
__decorateClass([
  ApiProperty87({
    example: true,
    required: true,
    type: Boolean,
    description: "The state if user is published or not"
  })
], NotificationActorDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty87({
    description: "The tagline of notification actor",
    example: "nature lover",
    required: false,
    type: String
  })
], NotificationActorDto.prototype, "tagline", 2);
__decorateClass([
  ApiProperty87({
    description: "The overridden profile tag",
    example: "nature lover",
    required: false,
    type: String
  })
], NotificationActorDto.prototype, "profileTagOverride", 2);
__decorateClass([
  ApiProperty87({
    description: "The photo of notification actor",
    required: false,
    type: () => ImageResponseDto2
  })
], NotificationActorDto.prototype, "photo", 2);
__decorateClass([
  ApiProperty87({
    description: "The group infomation of notification actor",
    required: false,
    type: () => UserGroupResponseDto
  })
], NotificationActorDto.prototype, "group", 2);
var NotificationResponseDto = class {
};
__decorateClass([
  ApiProperty87({
    example: "CARD",
    enum: NotificationType,
    description: "The type of notification entity",
    required: true
  })
], NotificationResponseDto.prototype, "type", 2);
__decorateClass([
  ApiProperty87({
    description: "The type of notification action",
    example: "STAR",
    enum: NotificationEventType,
    required: true
  })
], NotificationResponseDto.prototype, "event", 2);
__decorateClass([
  ApiProperty87({
    description: "The `id` of target notification entity",
    example: "123456",
    format: "int64",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty87({
    description: "The path of notification",
    example: ["vanityname_of_user", "vanityname_of_deck"],
    type: String,
    isArray: true,
    required: true
  })
], NotificationResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty87({
    description: "The visited status of notification",
    example: false,
    type: Boolean,
    required: true
  })
], NotificationResponseDto.prototype, "visited", 2);
__decorateClass([
  ApiProperty87({
    description: "The latest time of notification action",
    example: "2022-11-16T11:35:05.850Z",
    format: "date-time",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "latestTime", 2);
__decorateClass([
  ApiProperty87({
    description: "The initial time of notification action",
    example: "2022-11-16T11:35:05.850Z",
    format: "date-time",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "initialTime", 2);
__decorateClass([
  ApiProperty87({
    description: "The description of notification",
    example: "Autoplayed your deck #Ak47 to #NewDeck",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "description", 2);
__decorateClass([
  ApiProperty87({
    description: "The secondary target type",
    type: String,
    example: "secondary target",
    required: true
  })
], NotificationResponseDto.prototype, "secondaryTarget", 2);
__decorateClass([
  ApiProperty87({
    description: "The secondary target `id` of notification",
    format: "int64",
    example: "1234",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "secondaryTargetId", 2);
__decorateClass([
  ApiProperty87({
    description: "The id of gifted uon",
    format: "int64",
    example: "1234",
    type: String
  })
], NotificationResponseDto.prototype, "firstUonId", 2);
__decorateClass([
  ApiProperty87({
    description: "The count of notification",
    example: 1,
    type: Number,
    required: true
  })
], NotificationResponseDto.prototype, "count", 2);
__decorateClass([
  ApiProperty87({
    description: "The group `id`",
    example: "123",
    format: "int64",
    type: String,
    required: true
  })
], NotificationResponseDto.prototype, "group", 2);
__decorateClass([
  ApiProperty87({
    description: "The image `id` of notification",
    example: "4614497120908697600",
    format: "int64",
    type: String,
    required: false
  })
], NotificationResponseDto.prototype, "imageId", 2);
__decorateClass([
  ApiProperty87({
    description: "The image url of notification",
    example: "https://stage-images.earthtoday.com",
    type: String,
    required: false
  })
], NotificationResponseDto.prototype, "imageUrl", 2);
__decorateClass([
  ApiProperty87({
    description: "The vanity name of acted user",
    example: ["vanityname_of_acted_user"],
    type: String,
    isArray: true,
    required: true
  })
], NotificationResponseDto.prototype, "initiators", 2);
__decorateClass([
  ApiProperty87({
    description: "The `id` of ownner notification",
    example: "1234567",
    format: "int64",
    type: String,
    required: false
  })
], NotificationResponseDto.prototype, "userId", 2);
__decorateClass([
  ApiProperty87({
    description: "The `id` of target notification",
    example: "1234567",
    format: "int64",
    type: String,
    required: false
  })
], NotificationResponseDto.prototype, "targetId", 2);
__decorateClass([
  ApiProperty87({
    description: "The actor infomation",
    type: () => NotificationActorDto,
    required: true
  })
], NotificationResponseDto.prototype, "actor", 2);
var c9 = initContract();
var oneFlowContract = c9.router({
  preparePayment: {
    method: "POST",
    path: "/v1/oneflow/:oneFlowId/payments/prepare",
    pathParams: z.object({
      oneFlowId: z.string().nonempty()
    }),
    responses: {
      200: c9.type(),
      206: c9.type(),
      400: c9.type(),
      401: c9.type(),
      500: c9.type()
    },
    body: c9.type()
  },
  oneFlowFinalize: {
    method: "POST",
    path: "/v1/oneflow/:oneFlowId/providers/:provider",
    pathParams: z.object({
      oneFlowId: z.string().nonempty(),
      provider: z.string().nonempty()
    }),
    responses: {
      200: c9.type(),
      206: c9.type(),
      400: c9.type(),
      401: c9.type(),
      500: c9.type()
    },
    body: c9.type()
  }
});
var PaymentPreparePayloadDto = class {
};
__decorateClass([
  ApiProperty88({
    type: "string",
    enum: Object.values([
      "IDEAL",
      "CREDIT_CARD"
      /* CREDIT_CARD */
    ])
  }),
  IsNotEmpty(),
  IsIn(Object.values([
    "IDEAL",
    "CREDIT_CARD"
    /* CREDIT_CARD */
  ]))
], PaymentPreparePayloadDto.prototype, "method", 2);
__decorateClass([
  ApiProperty88({ type: "number" }),
  IsInt(),
  Min(1),
  IsNotEmpty()
], PaymentPreparePayloadDto.prototype, "numberOfUon", 2);
__decorateClass([
  ApiProperty88({ type: "string", required: false })
], PaymentPreparePayloadDto.prototype, "redirectUrl", 2);
__decorateClass([
  ApiProperty88({ type: "string", required: false })
], PaymentPreparePayloadDto.prototype, "issuer", 2);
__decorateClass([
  ApiProperty88({ type: "string", required: false }),
  IsOptional(),
  IsString()
], PaymentPreparePayloadDto.prototype, "sourceId", 2);
__decorateClass([
  ApiProperty88({
    type: "string",
    enum: Object.values(TransientPaymentSourceType),
    required: false
  }),
  IsOptional(),
  Transform(({ value }) => value.toUpperCase()),
  IsIn(Object.values(TransientPaymentSourceType))
], PaymentPreparePayloadDto.prototype, "sourceType", 2);
__decorateClass([
  ApiProperty88({ type: "string", required: false }),
  IsOptional(),
  IsString()
], PaymentPreparePayloadDto.prototype, "oneFlowId", 2);
__decorateClass([
  ApiProperty88({
    example: "4415536429535408147",
    description: "The promotion Id of donation",
    type: String,
    format: "int64",
    required: true
  }),
  IsOptional(),
  IsNumberString()
], PaymentPreparePayloadDto.prototype, "promotionId", 2);
__decorateClass([
  ApiProperty88({
    type: "string",
    enum: Object.values([
      "mobile_app",
      "web"
      /* WEB */
    ])
  }),
  IsOptional(),
  IsIn(Object.values([
    "mobile_app",
    "web"
    /* WEB */
  ]))
], PaymentPreparePayloadDto.prototype, "platformType", 2);
__decorateClass([
  ApiProperty88({
    type: "object",
    additionalProperties: {
      type: "string"
    },
    required: false,
    description: "Additional parameters for the redirect URL in key-value pairs"
  }),
  IsOptional()
], PaymentPreparePayloadDto.prototype, "redirectUrlExtraParameters", 2);
var OneFlowPaymentPreparePayloadDto = class {
};
__decorateClass([
  ApiProperty89({ type: "number", required: false }),
  IsOptional(),
  IsNumber(),
  Min(1),
  Max(1e4)
], OneFlowPaymentPreparePayloadDto.prototype, "giftsCount", 2);
__decorateClass([
  ApiProperty89({ type: PaymentPreparePayloadDto }),
  ValidateNested(),
  Type16(() => PaymentPreparePayloadDto),
  IsNotEmpty()
], OneFlowPaymentPreparePayloadDto.prototype, "payment", 2);
var OneFlowFinalizePayloadDto = class {
};
__decorateClass([
  ApiProperty90({
    type: String,
    description: "The token of donation",
    required: false
  }),
  IsOptional(),
  IsString()
], OneFlowFinalizePayloadDto.prototype, "token", 2);
var c10 = initContract();
var promotionContract = c10.router({
  getPromotions: {
    method: "GET",
    path: "/v1/users/:idOrVanityName/promotions",
    pathParams: z.object({
      idOrVanityName: z.string()
    }),
    responses: {
      200: c10.type(),
      404: c10.type(),
      500: c10.type()
    },
    query: z.object({
      limit: z.string().optional(),
      metaCountOnly: z.string().optional(),
      metaPromotionName: z.string().optional()
    }),
    strictStatusCodes: true
  },
  getPromotionCounts: {
    method: "GET",
    path: "/v1/promotion-counts",
    responses: {
      200: c10.type(),
      404: c10.type(),
      500: c10.type()
    },
    query: z.object({
      promotionIds: z.string()
    }),
    strictStatusCodes: true
  },
  getCollectedPromotions: {
    method: "GET",
    path: "/v1/users/:idOrVanityName/collected-promotions",
    pathParams: z.object({
      idOrVanityName: z.string()
    }),
    responses: {
      200: c10.type(),
      404: c10.type(),
      500: c10.type()
    },
    strictStatusCodes: true
  },
  getGlobalPromotions: {
    method: "GET",
    path: "/v1/promotions",
    responses: {
      200: c10.type(),
      500: c10.type()
    },
    query: z.object({
      collectionName: z.string().optional()
    }),
    strictStatusCodes: true
  },
  deletePromotion: {
    method: "DELETE",
    path: "/v1/users/:idOrVanityName/promotions/:promotionId",
    pathParams: z.object({
      idOrVanityName: z.string(),
      promotionId: z.string()
    }),
    body: c10.type(),
    responses: {
      204: c10.type(),
      400: c10.type(),
      401: c10.type(),
      409: c10.type(),
      500: c10.type()
    },
    strictStatusCodes: true
  },
  featurePromotion: {
    method: "PATCH",
    path: "/v1/users/me/promotions/:promotionId/feature",
    pathParams: z.object({
      promotionId: z.string()
    }),
    body: c10.type(),
    responses: {
      200: c10.type(),
      400: c10.type(),
      401: c10.type(),
      404: c10.type(),
      500: c10.type()
    },
    strictStatusCodes: true
  },
  updatePromotion: {
    method: "PUT",
    path: "/v1/users/me/promotions/:promotionId",
    pathParams: z.object({
      promotionId: z.string()
    }),
    body: c10.type(),
    responses: {
      200: c10.type(),
      400: c10.type(),
      401: c10.type(),
      404: c10.type(),
      409: c10.type(),
      500: c10.type()
    },
    strictStatusCodes: true
  }
});
var GlobalPromotionDto = class {
};
__decorateClass([
  ApiProperty91({
    required: true,
    description: "The `id` of promotion",
    format: "int64",
    type: String
  }),
  IsNotEmpty(),
  IsNumberString()
], GlobalPromotionDto.prototype, "id", 2);
var GlobalPromotionsGetResponseDto = class {
};
__decorateClass([
  ApiProperty91({
    required: true,
    type: Array,
    description: "The global promotions response data"
  }),
  IsNotEmpty()
], GlobalPromotionsGetResponseDto.prototype, "data", 2);
var FeaturedPromotionUpdatePayloadDtoData = class {
};
__decorateClass([
  ApiProperty92({
    type: Boolean,
    required: true,
    example: true,
    description: "The state if promotion is featured"
  }),
  IsNotEmpty(),
  IsBoolean()
], FeaturedPromotionUpdatePayloadDtoData.prototype, "isFeatured", 2);
var FeaturedPromotionUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty92({
    type: FeaturedPromotionUpdatePayloadDtoData,
    required: true,
    description: "The data for updating feature promotion"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type17(() => FeaturedPromotionUpdatePayloadDtoData)
], FeaturedPromotionUpdatePayloadDto.prototype, "data", 2);
var FeaturedPromotionUpdateResponseDtoMeta = class {
};
__decorateClass([
  ApiProperty93({
    required: false,
    description: "The featured promotion",
    type: PromotionDto
  })
], FeaturedPromotionUpdateResponseDtoMeta.prototype, "featuredPromotion", 2);
var FeaturedPromotionUpdateResponseDto = class {
};
__decorateClass([
  ApiProperty93({
    type: PromotionDto,
    required: true,
    description: "The data of updated promotion"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type18(() => PromotionDto)
], FeaturedPromotionUpdateResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty93({
    required: true,
    description: "The featured promotion meta ",
    type: FeaturedPromotionUpdateResponseDtoMeta
  })
], FeaturedPromotionUpdateResponseDto.prototype, "meta", 2);
var UonsHealthCreateResponseDtoData = class {
};
__decorateClass([
  ApiProperty94({
    example: "123123123123",
    description: "The `id` of the uons health",
    format: "int64",
    type: String,
    required: true
  })
], UonsHealthCreateResponseDtoData.prototype, "id", 2);
var UonsHealthCreateResponseDto = class {
};
__decorateClass([
  ApiProperty94({
    description: "The data of the uons health",
    type: UonsHealthCreateResponseDtoData,
    required: true
  })
], UonsHealthCreateResponseDto.prototype, "data", 2);
var c11 = initContract();
var subscriptionContract = c11.router({
  getSubscriptions: {
    strictStatusCodes: true,
    method: "GET",
    path: "/v1/users/:userIdOrVanityName/subscriptions",
    pathParams: z.object({
      userIdOrVanityName: z.string()
    }),
    responses: {
      200: c11.type(),
      401: c11.type(),
      404: c11.type(),
      500: c11.type()
    },
    headers: z.object({
      "x-apicache-bypass": z.string().optional(),
      "cache-control": z.string().optional()
    })
  }
});
var WrappedGiftCodeCreatePayloadDto = class {
};
__decorateClass([
  ApiProperty95({
    required: true,
    type: Number,
    description: "The number of wrapped gift code uons",
    example: 0
  }),
  IsNotEmpty(),
  IsNumber()
], WrappedGiftCodeCreatePayloadDto.prototype, "wrappedSize", 2);
__decorateClass([
  ApiProperty95({
    required: true,
    type: Number,
    description: "The id of uon",
    example: 0
  }),
  IsNotEmpty(),
  IsString()
], WrappedGiftCodeCreatePayloadDto.prototype, "uonId", 2);
var c12 = initContract();
var paymentContract = c12.router({
  preparePayment: {
    method: "POST",
    path: "/v1/payments",
    responses: {
      200: c12.type(),
      400: c12.type(),
      401: c12.type(),
      500: c12.type()
    },
    body: c12.type(),
    strictStatusCodes: true
  },
  detectPaymentRegion: {
    method: "POST",
    path: "/v1/payment-regions/detect-from-page-load",
    responses: {
      200: c12.type(),
      400: c12.type(),
      500: c12.type()
    },
    body: c12.type(),
    strictStatusCodes: true
  }
});
var UserPaymentRegionResponseDto = class {
};
__decorateClass([
  ApiProperty96({
    example: PaymentRegion.EU,
    description: "The payment region of user",
    required: true,
    enum: PaymentRegion
  }),
  IsNotEmpty(),
  IsEnum(PaymentRegion)
], UserPaymentRegionResponseDto.prototype, "paymentRegion", 2);
__decorateClass([
  ApiProperty96({
    example: PaymentRegionChangeSource.PAGE_LOAD,
    description: "The source that user changed payment region from",
    required: true,
    enum: PaymentRegionChangeSource
  }),
  IsNotEmpty(),
  IsEnum(PaymentRegionChangeSource)
], UserPaymentRegionResponseDto.prototype, "paymentRegionChangeSource", 2);
__decorateClass([
  ApiProperty96({
    example: "Vietnam",
    description: "The country that user changed payment region from",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], UserPaymentRegionResponseDto.prototype, "country", 2);
var UserPaymentRegionPayloadDto = class {
};
__decorateClass([
  ApiProperty97({
    example: "127.0.0.1",
    description: "The `ip` of user",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString(),
  IsIP()
], UserPaymentRegionPayloadDto.prototype, "ip", 2);
var CardChangeSortPayloadDtoData = class {
};
__decorateClass([
  ApiProperty98({
    description: "The sort index of card",
    type: Number,
    required: true
  }),
  Min(1),
  Max(52),
  IsNumber(),
  IsDefined()
], CardChangeSortPayloadDtoData.prototype, "sortIndex", 2);
var CardChangeSortPayloadDto = class {
};
__decorateClass([
  ApiProperty98({
    description: "The data of payload",
    type: CardChangeSortPayloadDtoData,
    required: true
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type19(() => CardChangeSortPayloadDtoData)
], CardChangeSortPayloadDto.prototype, "data", 2);
var CardChangeSortResponseDtoData = class {
};
__decorateClass([
  ApiProperty99({
    description: "The id of sorted card",
    type: String,
    format: "int64",
    required: true
  }),
  IsNotEmpty()
], CardChangeSortResponseDtoData.prototype, "id", 2);
var CardChangeSortResponseDto = class {
};
__decorateClass([
  ApiProperty99({
    description: "The data of response",
    type: CardChangeSortResponseDtoData,
    isArray: true,
    required: true
  }),
  IsNotEmpty()
], CardChangeSortResponseDto.prototype, "data", 2);
var PromotionImageMutationExistImage = class {
};
__decorateClass([
  ApiProperty100({
    example: PromotionImageMutationType.EXISTING_IMAGE,
    description: "type must be EXISTING_IMAGE",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsIn([PromotionImageMutationType.EXISTING_IMAGE])
], PromotionImageMutationExistImage.prototype, "type", 2);
__decorateClass([
  ApiProperty100({ type: AwsImageExtract, required: true }),
  ValidateNested(),
  Type20(() => AwsImageExtract),
  IsDefined()
], PromotionImageMutationExistImage.prototype, "uonPromotionExtract", 2);
var PromotionImageMutationUploadNew = class {
};
__decorateClass([
  ApiProperty100({
    example: PromotionImageMutationType.UPLOAD_NEW,
    description: "type must be UPLOAD_NEW",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsIn([PromotionImageMutationType.UPLOAD_NEW])
], PromotionImageMutationUploadNew.prototype, "type", 2);
__decorateClass([
  ApiProperty100({
    example: "eyJhb...bqA",
    description: "Upload token from presign endpoint",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty(),
  IsString()
], PromotionImageMutationUploadNew.prototype, "uploadToken", 2);
__decorateClass([
  ApiProperty100({ type: AwsImageExtract, required: true }),
  ValidateNested(),
  Type20(() => AwsImageExtract),
  IsDefined()
], PromotionImageMutationUploadNew.prototype, "uonPromotionExtract", 2);
var PromotionUpdatePayloadData = class {
};
__decorateClass([
  ApiProperty100({
    example: ImageTarget,
    description: "Target of promotion's image",
    required: true,
    nullable: false,
    type: String
  }),
  IsNotEmpty()
], PromotionUpdatePayloadData.prototype, "target", 2);
__decorateClass([
  ApiProperty100({
    example: "fake collection name",
    description: "Collection name of promo card",
    required: true,
    nullable: false,
    type: String
  }),
  IsDefined(),
  IsString(),
  IsNotEmpty()
], PromotionUpdatePayloadData.prototype, "collectionName", 2);
__decorateClass([
  ApiProperty100({
    example: "UNLIMITED",
    description: "series of promo card",
    required: true,
    nullable: false,
    oneOf: [
      {
        $ref: getSchemaPath26(
          UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries
        )
      },
      {
        $ref: getSchemaPath26(
          UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
        )
      }
    ],
    type: UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries || UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
  }),
  IsDefined()
], PromotionUpdatePayloadData.prototype, "series", 2);
__decorateClass([
  ApiProperty100({
    description: "Promotion image mutation",
    required: true,
    nullable: false,
    oneOf: [
      {
        $ref: getSchemaPath26(PromotionImageMutationExistImage)
      },
      {
        $ref: getSchemaPath26(PromotionImageMutationUploadNew)
      }
    ],
    type: PromotionImageMutationExistImage || PromotionImageMutationUploadNew
  }),
  IsDefined()
], PromotionUpdatePayloadData.prototype, "imageMutation", 2);
PromotionUpdatePayloadData = __decorateClass([
  ApiExtraModels18(
    PromotionImageMutationExistImage,
    PromotionImageMutationUploadNew
  ),
  ApiExtraModels18(
    UserProfilePhotoUploadCommitPayloadDtoPromotionLimitedSeries,
    UserProfilePhotoUploadCommitPayloadDtoPromotionUnlimitedSeries
  )
], PromotionUpdatePayloadData);
var PromotionUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty100({
    type: PromotionUpdatePayloadData,
    required: true,
    description: "The data for updating promotion"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type20(() => PromotionUpdatePayloadData)
], PromotionUpdatePayloadDto.prototype, "data", 2);
var c13 = initContract();
var capacitorBundleContract = c13.router({
  checkForUpdate: {
    method: "POST",
    path: "/v1/capacitor-bundles/check-for-update",
    body: c13.type(),
    responses: {
      200: c13.type()
    }
  }
});
var CapacitorBundleCheckForUpdateResponseDtoSuccess = class {
};
__decorateClass([
  ApiProperty101({
    required: true,
    description: "The new version of application",
    type: String,
    example: "1.0.2"
  })
], CapacitorBundleCheckForUpdateResponseDtoSuccess.prototype, "version", 2);
__decorateClass([
  ApiProperty101({
    required: true,
    description: "The url of installation zip file",
    type: String,
    example: "https://apiurl.com/mybuild_102.zip"
  })
], CapacitorBundleCheckForUpdateResponseDtoSuccess.prototype, "url", 2);
var CapacitorBundleCheckForUpdateResponseDtoFail = class {
};
__decorateClass([
  ApiProperty101({
    required: true,
    description: "The message of error",
    type: String,
    example: "Version not found"
  })
], CapacitorBundleCheckForUpdateResponseDtoFail.prototype, "message", 2);
__decorateClass([
  ApiProperty101({
    required: false,
    description: "The name of error",
    type: String,
    example: "The backend crashed"
  })
], CapacitorBundleCheckForUpdateResponseDtoFail.prototype, "error", 2);
__decorateClass([
  ApiProperty101({
    required: true,
    description: "The new version of application",
    type: String,
    example: ""
  })
], CapacitorBundleCheckForUpdateResponseDtoFail.prototype, "version", 2);
__decorateClass([
  ApiProperty101({
    required: true,
    description: "The url of installation zip file",
    type: String,
    example: ""
  })
], CapacitorBundleCheckForUpdateResponseDtoFail.prototype, "url", 2);
var CapacitorBundleCheckForUpdatePayloadDto = class {
};
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The version of the web build",
    required: true,
    example: "1.2.3"
  }),
  IsNotEmpty(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "version_name", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The version of the native build",
    required: false,
    example: "1.2.0"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "version_build", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The OS version of the device",
    required: false,
    example: "16"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "version_os", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The represent of your user",
    required: false,
    example: "user_1"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "custom_id", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "Is it production",
    required: false,
    example: true
  }),
  IsOptional(),
  IsBoolean()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "is_prod", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "Is it emulator",
    required: false,
    example: true
  }),
  IsOptional(),
  IsBoolean()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "is_emulator", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The plugin version make your api behave differently with different plugins",
    required: false,
    example: "1.0.0"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "plugin_version", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The platform of device",
    required: false,
    example: "ios"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "platform", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The app identifier in the store",
    required: false,
    example: "appId"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "app_id", 2);
__decorateClass([
  ApiProperty102({
    type: String,
    description: "The unique id per app install",
    required: false,
    example: "deviceId"
  }),
  IsOptional(),
  IsString()
], CapacitorBundleCheckForUpdatePayloadDto.prototype, "device_id", 2);
var MobileBundleVersionUploadResignResponseDtoData = class {
};
__decorateClass([
  ApiProperty103({
    example: "1.1.7",
    description: "The `id` of the mobile bundle version",
    type: String,
    required: true
  })
], MobileBundleVersionUploadResignResponseDtoData.prototype, "id", 2);
__decorateClass([
  ApiProperty103({
    example: "https://s3.amazonaws.com/bucket/2022/1.1.7.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=...",
    description: "The `url` is used for uploading version zip file",
    type: String,
    required: true
  })
], MobileBundleVersionUploadResignResponseDtoData.prototype, "url", 2);
var MobileBundleVersionUploadResignResponseDto = class {
};
__decorateClass([
  ApiProperty103({
    description: "The data of the mobile bundle version",
    type: MobileBundleVersionUploadResignResponseDtoData,
    required: true
  })
], MobileBundleVersionUploadResignResponseDto.prototype, "data", 2);
var MobileBundleVersionUploadResignPayloadDtoData = class {
};
__decorateClass([
  ApiProperty104({
    type: String,
    required: true,
    example: "0.1.1",
    description: "The version name of mobile bundle version"
  }),
  IsNotEmpty(),
  IsString()
], MobileBundleVersionUploadResignPayloadDtoData.prototype, "versionName", 2);
var MobileBundleVersionUploadResignPayloadDto = class {
};
__decorateClass([
  ApiProperty104({
    type: MobileBundleVersionUploadResignPayloadDtoData,
    required: true,
    description: "The data for uploading presign mobile bundle version"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type21(() => MobileBundleVersionUploadResignPayloadDtoData)
], MobileBundleVersionUploadResignPayloadDto.prototype, "data", 2);
var MobileBundleVersionUploadCommitResponseDtoData = class {
};
__decorateClass([
  ApiProperty105({
    example: "1.1.7",
    description: "The `id` of the mobile bundle version",
    type: String,
    required: true
  })
], MobileBundleVersionUploadCommitResponseDtoData.prototype, "id", 2);
__decorateClass([
  ApiProperty105({
    example: "1.1.7.zip",
    description: "The `awsKey` of the mobile bundle version",
    type: String,
    required: true
  })
], MobileBundleVersionUploadCommitResponseDtoData.prototype, "awsKey", 2);
__decorateClass([
  ApiProperty105({
    example: "ACTIVE",
    description: "The `status` of the mobile bundle version. The value must be ACTIVE",
    enum: ["ACTIVE"],
    required: true
  })
], MobileBundleVersionUploadCommitResponseDtoData.prototype, "status", 2);
var MobileBundleVersionUploadCommitResponseDto = class {
};
__decorateClass([
  ApiProperty105({
    description: "The data of the mobile bundle version",
    type: MobileBundleVersionUploadCommitResponseDtoData,
    required: true
  })
], MobileBundleVersionUploadCommitResponseDto.prototype, "data", 2);
var MobileSystemDeckSavePayloadDtoData = class {
};
__decorateClass([
  ApiProperty106({
    type: String,
    required: true,
    format: "int64",
    example: "123456788999",
    description: "The deckId of mobile system deck"
  }),
  IsNumberString(),
  IsNotEmpty()
], MobileSystemDeckSavePayloadDtoData.prototype, "deckId", 2);
__decorateClass([
  ApiProperty106({
    type: Number,
    required: false,
    example: 1,
    minimum: 1,
    description: "The display order of deck in mobile system decks"
  }),
  Min(1),
  IsNumber(),
  IsOptional()
], MobileSystemDeckSavePayloadDtoData.prototype, "displayOrder", 2);
__decorateClass([
  ApiProperty106({
    type: String,
    required: true,
    example: "yellow",
    description: "The text color of mobile system deck"
  }),
  IsString(),
  IsNotEmpty()
], MobileSystemDeckSavePayloadDtoData.prototype, "textColor", 2);
__decorateClass([
  ApiProperty106({
    type: String,
    required: true,
    enum: MobileSystemDeckTextAlignment,
    example: MobileSystemDeckTextAlignment.CENTER,
    description: "The text alignment of mobile system deck"
  }),
  IsEnum(MobileSystemDeckTextAlignment),
  IsNotEmpty()
], MobileSystemDeckSavePayloadDtoData.prototype, "textAlignment", 2);
__decorateClass([
  ApiProperty106({
    type: String,
    required: true,
    enum: MobileSystemDeckStatus,
    example: MobileSystemDeckStatus.ACTIVE,
    description: "The status of mobile system deck"
  }),
  IsEnum(MobileSystemDeckStatus),
  IsNotEmpty()
], MobileSystemDeckSavePayloadDtoData.prototype, "status", 2);
var MobileSystemDeckSavePayloadDto = class {
};
__decorateClass([
  ApiProperty106({
    type: MobileSystemDeckSavePayloadDtoData,
    required: true,
    description: "The data for saving mobile system deck"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type22(() => MobileSystemDeckSavePayloadDtoData)
], MobileSystemDeckSavePayloadDto.prototype, "data", 2);
var DeckFollowingResponseDto = class {
};
__decorateClass([
  ApiProperty107({
    example: "6714931170118234112",
    description: "The `Id` of the deck",
    required: true,
    type: String
  })
], DeckFollowingResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty107({
    example: "this is a fake name",
    description: "The name of the deck",
    required: true,
    type: String
  })
], DeckFollowingResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty107({
    example: "",
    description: "The description of the deck",
    required: true,
    type: String
  })
], DeckFollowingResponseDto.prototype, "description", 2);
__decorateClass([
  ApiProperty107({
    example: "2023-03-17T04:08:01.494Z",
    description: "The created date of the deck",
    required: true,
    type: String,
    format: "date-time"
  })
], DeckFollowingResponseDto.prototype, "created", 2);
__decorateClass([
  ApiProperty107({
    example: ["john doe", "third"],
    description: "The path of the deck",
    required: true,
    type: [String]
  })
], DeckFollowingResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty107({
    example: 51,
    description: "The remain count of cards of the deck",
    required: true,
    type: Number
  })
], DeckFollowingResponseDto.prototype, "remainingCardsCount", 2);
__decorateClass([
  ApiProperty107({
    example: {
      headerColor: "",
      labelColor: "",
      labelIcon: "",
      labelTitle: ""
    },
    description: "The presentation of the deck",
    type: PresentationSettingDto,
    required: true
  })
], DeckFollowingResponseDto.prototype, "presentation", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The curator details of the deck",
    type: CuratorResponseDto
  })
], DeckFollowingResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The reserve of the deck",
    type: () => [ReserveDeckResponseDto]
  })
], DeckFollowingResponseDto.prototype, "reserves", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The cards of the deck",
    type: () => [CardStandardResponseDto]
  })
], DeckFollowingResponseDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty107({
    example: ["people"],
    description: "The category principle of the deck",
    required: true,
    type: [String]
  })
], DeckFollowingResponseDto.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty107({
    example: true,
    description: "The uncache status of the deck",
    required: true,
    type: Boolean
  })
], DeckFollowingResponseDto.prototype, "useCache", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The following detail of the deck",
    type: FollowingResponseDto
  })
], DeckFollowingResponseDto.prototype, "following", 2);
__decorateClass([
  ApiProperty107({
    example: 0,
    required: true,
    type: "integer",
    description: "Number of followers of this deck"
  })
], DeckFollowingResponseDto.prototype, "followersCount", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    type: "integer",
    description: "number of cards in this deck"
  })
], DeckFollowingResponseDto.prototype, "cardsCount", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    type: [DeckPreviewImage],
    description: "preview images"
  })
], DeckFollowingResponseDto.prototype, "previewImages", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    oneOf: [
      {
        $ref: getSchemaPath27(DeckPreviewImagesDto)
      },
      {
        $ref: getSchemaPath27(DeckBackgroundImageDto)
      }
    ],
    type: () => DeckPreviewImagesDto || DeckBackgroundImageDto
  }),
  ValidateNested(),
  Type23(() => DeckPreviewImagesDto || DeckBackgroundImageDto, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "type",
      subTypes: [
        {
          value: DeckPreviewImagesDto,
          name: DeckBackgroundImageType.PREVIEW_IMAGES
        },
        {
          value: DeckBackgroundImageDto,
          name: DeckBackgroundImageType.BACKGROUND_IMAGE
        }
      ]
    }
  })
], DeckFollowingResponseDto.prototype, "backgroundImage", 2);
__decorateClass([
  ApiProperty107({
    required: false,
    type: MobileSystemDeckResponseDtoData,
    description: "The mobile system override of deck"
  })
], DeckFollowingResponseDto.prototype, "mobileSystemOverride", 2);
var DecksFollowingWebResponseDto = class {
};
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The following decks details",
    type: [DeckFollowingResponseDto]
  })
], DecksFollowingWebResponseDto.prototype, "decks", 2);
__decorateClass([
  ApiProperty107({
    required: false,
    description: "The meta tags of the deck",
    type: MetaTagsResponseDto
  })
], DecksFollowingWebResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty107({
    required: false,
    description: "The deck uncache status",
    type: Boolean
  })
], DecksFollowingWebResponseDto.prototype, "useCache", 2);
var DecksFollowingMobileResponseDto = class {
};
__decorateClass([
  ApiProperty107({
    type: () => [DeckCuratingMobileResponseDto],
    required: true,
    description: "The data for the decks following"
  })
], DecksFollowingMobileResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty107({
    required: true,
    description: "The deck response type",
    type: String,
    enum: [
      "MOBILE_APP"
      /* MOBILE_APP */
    ]
  })
], DecksFollowingMobileResponseDto.prototype, "responseType", 2);
var DeckCuratingResponseDto = class extends DeckFollowingResponseDto {
};
var DeckCuratingMobileResponseDto = class {
};
__decorateClass([
  ApiProperty108({
    example: "6714931170118234112",
    description: "The `Id` of the deck",
    required: true,
    type: String
  })
], DeckCuratingMobileResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty108({
    example: "this is a fake name",
    description: "The name of the deck",
    required: true,
    type: String
  })
], DeckCuratingMobileResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty108({
    example: "",
    description: "The description of the deck",
    required: true,
    type: String
  })
], DeckCuratingMobileResponseDto.prototype, "description", 2);
__decorateClass([
  ApiProperty108({
    example: "2023-03-17T04:08:01.494Z",
    description: "The created date of the deck",
    required: true,
    type: String,
    format: "date-time"
  })
], DeckCuratingMobileResponseDto.prototype, "created", 2);
__decorateClass([
  ApiProperty108({
    example: ["john doe", "third"],
    description: "The path of the deck",
    required: true,
    type: [String]
  })
], DeckCuratingMobileResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty108({
    required: true,
    description: "The curator details of the deck",
    type: CuratorResponseDto
  })
], DeckCuratingMobileResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty108({
    example: ["people"],
    description: "The category principle of the deck",
    required: true,
    type: [String]
  })
], DeckCuratingMobileResponseDto.prototype, "categoryPrinciples", 2);
__decorateClass([
  ApiProperty108({
    required: true,
    oneOf: [
      {
        $ref: getSchemaPath28(DeckPreviewImagesDto)
      },
      {
        $ref: getSchemaPath28(DeckBackgroundImageDto)
      }
    ],
    type: () => Object
  })
], DeckCuratingMobileResponseDto.prototype, "backgroundImage", 2);
__decorateClass([
  ApiProperty108({
    type: Number,
    required: true,
    description: "The number of cards",
    example: 1
  })
], DeckCuratingMobileResponseDto.prototype, "count", 2);
DeckCuratingMobileResponseDto = __decorateClass([
  ApiExtraModels19(DeckPreviewImagesDto, DeckBackgroundImageDto)
], DeckCuratingMobileResponseDto);
var DecksCuratingResponseDto = class {
};
__decorateClass([
  ApiProperty108({
    required: true,
    description: "The curating decks details",
    type: [DeckCuratingResponseDto]
  })
], DecksCuratingResponseDto.prototype, "decks", 2);
__decorateClass([
  ApiProperty108({
    required: true,
    description: "The deck response type",
    type: String,
    enum: [
      "WEB_APP"
      /* WEB_APP */
    ]
  })
], DecksCuratingResponseDto.prototype, "responseType", 2);
__decorateClass([
  ApiProperty108({
    required: false,
    description: "The meta tags of the deck",
    type: MetaTagsResponseDto
  })
], DecksCuratingResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty108({
    required: false,
    description: "The deck uncache status",
    type: Boolean
  })
], DecksCuratingResponseDto.prototype, "useCache", 2);
var DecksCuratingMobileResponseDto = class {
};
__decorateClass([
  ApiProperty108({
    required: true,
    description: "The curating decks details",
    type: [DeckCuratingMobileResponseDto]
  })
], DecksCuratingMobileResponseDto.prototype, "decks", 2);
__decorateClass([
  ApiProperty108({
    required: true,
    description: "The deck response type",
    type: String,
    enum: [
      "MOBILE_APP"
      /* MOBILE_APP */
    ]
  })
], DecksCuratingMobileResponseDto.prototype, "responseType", 2);
__decorateClass([
  ApiProperty108({
    required: false,
    description: "The meta tags of the deck",
    type: MetaTagsResponseDto
  })
], DecksCuratingMobileResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty108({
    required: false,
    description: "The deck uncache status",
    type: Boolean
  })
], DecksCuratingMobileResponseDto.prototype, "useCache", 2);
var SystemDeckWebResponseDtoData = class {
};
__decorateClass([
  ApiProperty109({
    required: true,
    type: DeckInfoResponseDto,
    description: "Deck details"
  })
], SystemDeckWebResponseDtoData.prototype, "data", 2);
__decorateClass([
  ApiProperty109({
    required: true,
    type: DeckInfoResponseDto,
    description: "The meta of deck"
  })
], SystemDeckWebResponseDtoData.prototype, "meta", 2);
var SystemDecksWebResponseDto = class {
};
__decorateClass([
  ApiProperty109({
    type: [SystemDeckWebResponseDtoData],
    required: true,
    description: "The data for system decks"
  })
], SystemDecksWebResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty109({
    required: true,
    description: "The deck response type",
    type: String,
    enum: [
      "WEB_APP"
      /* WEB_APP */
    ]
  })
], SystemDecksWebResponseDto.prototype, "responseType", 2);
var SystemDeckMobileResponseDtoData = class {
};
__decorateClass([
  ApiProperty109({
    required: true,
    type: DeckCuratingMobileResponseDto,
    description: "The system decks details"
  })
], SystemDeckMobileResponseDtoData.prototype, "data", 2);
__decorateClass([
  ApiProperty109({
    required: true,
    type: DeckDetailResponseMeta,
    description: "The meta of deck"
  })
], SystemDeckMobileResponseDtoData.prototype, "meta", 2);
var SystemDecksMobileResponseDto = class {
};
__decorateClass([
  ApiProperty109({
    type: [SystemDeckMobileResponseDtoData],
    required: true,
    description: "The data for system decks"
  })
], SystemDecksMobileResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty109({
    required: true,
    description: "The deck response type",
    type: String,
    enum: [
      "MOBILE_APP"
      /* MOBILE_APP */
    ]
  })
], SystemDecksMobileResponseDto.prototype, "responseType", 2);
var CampaignImageDto = class extends ImageResponseDto2 {
};
__decorateClass([
  ApiProperty110({
    name: "url",
    description: "The url of the image",
    example: "https://stage-images.earthtoday.com/eyJrZXkiOiIvdXNlcnMvNjMwMjc2NDUxNTk4MTAwODg5Ni9saW5rcy82NzA3Nzk4MjgzOTA4ODAwNTEyL2VhYWVmNTZhLTA1NDYtNDNiMS1iMDEyLWRmMTZiMTI0ODZiNS0zMzR5MnI5LWhpZ2hyZXMtMTY3ODkyMTQ0MS01NjM0LTE2Nzg5MjE0NzQuanBnIiwiYnVja2V0IjoiZWFydGh0b2RheS1zdGFnZS1pbWFnZXMifQ==",
    required: true,
    type: String
  })
], CampaignImageDto.prototype, "url", 2);
var DefaultCampaignResponseDto = class {
};
__decorateClass([
  ApiProperty110({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "campaign id",
    format: "int64"
  })
], DefaultCampaignResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty110({
    example: "Join me to protect",
    required: true,
    type: String,
    description: "campaign name"
  })
], DefaultCampaignResponseDto.prototype, "name", 2);
__decorateClass([
  ApiProperty110({
    example: 10,
    required: true,
    type: Number,
    description: "total protected count",
    format: "int64"
  })
], DefaultCampaignResponseDto.prototype, "protectedCount", 2);
__decorateClass([
  ApiProperty110({
    example: "STATE1",
    required: true,
    enum: [CampaignStatus.STATE1, CampaignStatus.STATE2],
    type: String,
    description: "campaign status"
  })
], DefaultCampaignResponseDto.prototype, "state", 2);
__decorateClass([
  ApiProperty110({
    example: "here comes the link",
    required: true,
    type: String,
    description: "campaign link name"
  })
], DefaultCampaignResponseDto.prototype, "linkName", 2);
__decorateClass([
  ApiProperty110({
    required: true,
    description: "Image of the card",
    type: ImageResponseDto2
  })
], DefaultCampaignResponseDto.prototype, "coverImage", 2);
var UserMetaPublicResponseDto = class {
};
__decorateClass([
  ApiProperty110({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "user id",
    format: "int64"
  })
], UserMetaPublicResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe",
    required: true,
    type: String,
    description: "user vanity name"
  })
], UserMetaPublicResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty110({
    example: "john",
    required: true,
    type: String,
    description: "user firstname"
  })
], UserMetaPublicResponseDto.prototype, "firstName", 2);
__decorateClass([
  ApiProperty110({
    example: "doe",
    required: true,
    type: String,
    description: "user lastname"
  })
], UserMetaPublicResponseDto.prototype, "lastName", 2);
__decorateClass([
  ApiProperty110({
    example: "John Doe",
    required: true,
    type: String,
    description: "user fullname"
  })
], UserMetaPublicResponseDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: true,
    type: String,
    description: "user's description"
  })
], UserMetaPublicResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty110({
    example: "here is the bio mate",
    required: true,
    type: String,
    description: "user's bio"
  })
], UserMetaPublicResponseDto.prototype, "bio", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe.com",
    required: true,
    type: String,
    description: "user's website link"
  })
], UserMetaPublicResponseDto.prototype, "website", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "dark mode activated or not"
  })
], UserMetaPublicResponseDto.prototype, "darkMode", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@twitter.com",
    required: true,
    type: String,
    description: "user's twitter link'"
  })
], UserMetaPublicResponseDto.prototype, "twitterUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@facebook.com",
    required: true,
    type: String,
    description: "user's facebook link"
  })
], UserMetaPublicResponseDto.prototype, "facebookUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@insta.com",
    required: true,
    type: String,
    description: "user's instagram link"
  })
], UserMetaPublicResponseDto.prototype, "instagramUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@linkedin.com",
    required: true,
    type: String,
    description: "user's linkedin link"
  })
], UserMetaPublicResponseDto.prototype, "linkedInUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "2023-01-06T09:39:04.846Z",
    required: false,
    type: Date,
    description: "date when user joined",
    format: "date-time",
    nullable: true
  })
], UserMetaPublicResponseDto.prototype, "joined", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: UserImageResponseDto,
    description: "user's image"
  })
], UserMetaPublicResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: UserImageResponseDto,
    description: "user's image"
  })
], UserMetaPublicResponseDto.prototype, "cover", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "is user published or not"
  })
], UserMetaPublicResponseDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: false,
    type: Boolean,
    description: "is user published or not"
  })
], UserMetaPublicResponseDto.prototype, "isDisabledProtect", 2);
__decorateClass([
  ApiProperty110({
    example: false,
    required: false,
    type: Boolean,
    description: "Web login functionality for user is enabled or disabled"
  })
], UserMetaPublicResponseDto.prototype, "isWebLoginEnabled", 2);
__decorateClass([
  ApiProperty110({
    type: UserDetailsResponseDto,
    required: true,
    description: "User's details"
  })
], UserMetaPublicResponseDto.prototype, "details", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: UserGroupResponseDto,
    description: "user's group"
  })
], UserMetaPublicResponseDto.prototype, "group", 2);
__decorateClass([
  ApiProperty110({
    required: true,
    type: UserCountsCustomResponseDto,
    description: "user's statistics"
  })
], UserMetaPublicResponseDto.prototype, "userCounts", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: PresentationSettingDto,
    description: "user's image"
  })
], UserMetaPublicResponseDto.prototype, "presentation", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: false,
    type: String,
    description: "user tagline"
  })
], UserMetaPublicResponseDto.prototype, "tagline", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: false,
    type: String,
    description: "user profile tag override"
  })
], UserMetaPublicResponseDto.prototype, "profileTagOverride", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "is tagline forced use or not"
  })
], UserMetaPublicResponseDto.prototype, "forceUseTagLine", 2);
__decorateClass([
  ApiProperty110({
    example: null,
    required: true,
    type: Boolean,
    description: "feature source",
    nullable: true
  })
], UserMetaPublicResponseDto.prototype, "featureSource", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    description: "The meta tags of the user",
    type: MetaTagsResponseDto
  })
], UserMetaPublicResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: false,
    type: Boolean,
    description: "is use cache"
  })
], UserMetaPublicResponseDto.prototype, "useCache", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: true,
    example: false,
    description: "Enable gift last uon"
  })
], UserMetaPublicResponseDto.prototype, "enableGiftLastUon", 2);
var UserMetaPrivateResponseDto = class {
};
__decorateClass([
  ApiProperty110({
    example: "6307860332963139584",
    required: true,
    type: String,
    description: "user id",
    format: "int64"
  })
], UserMetaPrivateResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe",
    required: true,
    type: String,
    description: "user vanity name"
  })
], UserMetaPrivateResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty110({
    example: "john",
    required: true,
    type: String,
    description: "user firstname"
  })
], UserMetaPrivateResponseDto.prototype, "firstName", 2);
__decorateClass([
  ApiProperty110({
    example: "doe",
    required: true,
    type: String,
    description: "user lastname"
  })
], UserMetaPrivateResponseDto.prototype, "lastName", 2);
__decorateClass([
  ApiProperty110({
    example: "John Doe",
    required: true,
    type: String,
    description: "user fullname"
  })
], UserMetaPrivateResponseDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty110({
    type: String,
    format: "email",
    required: false,
    example: "earthtoday@urbn8.com",
    description: "User's email address"
  })
], UserMetaPrivateResponseDto.prototype, "emailAddress", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    format: "email",
    required: false,
    example: false,
    description: "is user's email public"
  })
], UserMetaPrivateResponseDto.prototype, "isEmailPublic", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: false,
    example: true,
    description: "User's email address is verified or not"
  })
], UserMetaPrivateResponseDto.prototype, "emailAddressVerified", 2);
__decorateClass([
  ApiProperty110({
    type: String,
    enum: ["social_media"],
    required: false,
    example: "social_media",
    nullable: true,
    description: "The type of cookie consent"
  })
], UserMetaPrivateResponseDto.prototype, "cookieConsent", 2);
__decorateClass([
  ApiProperty110({
    type: String,
    enum: PhotoState,
    required: false,
    example: "SOCIAL",
    description: "The state of photo"
  })
], UserMetaPrivateResponseDto.prototype, "photoState", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: true,
    type: String,
    description: "user's title"
  })
], UserMetaPrivateResponseDto.prototype, "title", 2);
__decorateClass([
  ApiProperty110({
    example: "here is the bio mate",
    required: true,
    type: String,
    description: "user's bio"
  })
], UserMetaPrivateResponseDto.prototype, "bio", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe.com",
    required: true,
    type: String,
    description: "user's website link"
  })
], UserMetaPrivateResponseDto.prototype, "website", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "dark mode activated or not"
  })
], UserMetaPrivateResponseDto.prototype, "darkMode", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@twitter.com",
    required: true,
    type: String,
    description: "user's twitter link"
  })
], UserMetaPrivateResponseDto.prototype, "twitterUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@facebook.com",
    required: true,
    type: String,
    description: "user's facebook link"
  })
], UserMetaPrivateResponseDto.prototype, "facebookUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@insta.com",
    required: true,
    type: String,
    description: "user's instagram link"
  })
], UserMetaPrivateResponseDto.prototype, "instagramUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "johndoe@linkedin.com",
    required: true,
    type: String,
    description: "user's linkedin link"
  })
], UserMetaPrivateResponseDto.prototype, "linkedInUsername", 2);
__decorateClass([
  ApiProperty110({
    example: "2023-01-06T09:39:04.846Z",
    required: false,
    type: Date,
    description: "date when user joined",
    format: "date-time",
    nullable: true
  })
], UserMetaPrivateResponseDto.prototype, "joined", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    description: "user's profile image",
    type: UserImageResponseDto
  })
], UserMetaPrivateResponseDto.prototype, "image", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    description: "user's cover image",
    type: UserImageResponseDto
  })
], UserMetaPrivateResponseDto.prototype, "cover", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "is user published or not"
  })
], UserMetaPrivateResponseDto.prototype, "isPublished", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: false,
    type: Boolean,
    description: "is user published or not"
  })
], UserMetaPrivateResponseDto.prototype, "isDisabledProtect", 2);
__decorateClass([
  ApiProperty110({
    example: false,
    required: false,
    type: Boolean,
    description: "Web login functionality for user is enabled or disabled"
  })
], UserMetaPrivateResponseDto.prototype, "isWebLoginEnabled", 2);
__decorateClass([
  ApiProperty110({
    type: UserDetailsResponseDto,
    required: true,
    description: "User's details"
  })
], UserMetaPrivateResponseDto.prototype, "details", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: UserGroupResponseDto,
    description: "user's group"
  })
], UserMetaPrivateResponseDto.prototype, "group", 2);
__decorateClass([
  ApiProperty110({
    required: true,
    type: UserCountsCustomResponseDto,
    description: "user's statistics"
  })
], UserMetaPrivateResponseDto.prototype, "userCounts", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: PresentationSettingDto,
    description: "The presentation of the deck"
  })
], UserMetaPrivateResponseDto.prototype, "presentation", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: false,
    type: String,
    description: "user tagline"
  })
], UserMetaPrivateResponseDto.prototype, "tagline", 2);
__decorateClass([
  ApiProperty110({
    example: "Nature Lover",
    required: false,
    type: String,
    description: "user profile tag override"
  })
], UserMetaPrivateResponseDto.prototype, "profileTagOverride", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: true,
    type: Boolean,
    description: "is tagline forced use or not"
  })
], UserMetaPrivateResponseDto.prototype, "forceUseTagLine", 2);
__decorateClass([
  ApiProperty110({
    example: null,
    required: true,
    type: Boolean,
    description: "feature source",
    nullable: true
  })
], UserMetaPrivateResponseDto.prototype, "featureSource", 2);
__decorateClass([
  ApiProperty110({
    enum: ["google", "facebook"],
    required: false,
    example: "google",
    description: "User's provider"
  })
], UserMetaPrivateResponseDto.prototype, "provider", 2);
__decorateClass([
  ApiProperty110({
    type: String,
    format: "int64",
    required: true,
    example: "1550858315508583",
    description: "User's provider id"
  })
], UserMetaPrivateResponseDto.prototype, "providerId", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: false,
    example: true,
    description: "User is accepted the terms or not"
  })
], UserMetaPrivateResponseDto.prototype, "termsAccepted", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: false,
    example: false,
    description: "User's terms is expired or not"
  })
], UserMetaPrivateResponseDto.prototype, "termsExpired", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: true,
    example: false,
    description: "User is adminstrator or not"
  })
], UserMetaPrivateResponseDto.prototype, "administrator", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: true,
    example: true,
    description: "User is curator or not"
  })
], UserMetaPrivateResponseDto.prototype, "curator", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    enum: Object.values(GroupMemberRole).map((v) => v.toLowerCase()),
    example: "admin",
    description: "user's group member role"
  })
], UserMetaPrivateResponseDto.prototype, "groupMemberRole", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    type: DefaultCampaignResponseDto,
    description: "user's campaign"
  })
], UserMetaPrivateResponseDto.prototype, "defaultCampaign", 2);
__decorateClass([
  ApiProperty110({
    required: false,
    description: "The meta tags of the user",
    type: MetaTagsResponseDto
  })
], UserMetaPrivateResponseDto.prototype, "metaTags", 2);
__decorateClass([
  ApiProperty110({
    example: true,
    required: false,
    type: Boolean,
    description: "is use cache"
  })
], UserMetaPrivateResponseDto.prototype, "useCache", 2);
__decorateClass([
  ApiProperty110({
    type: Boolean,
    required: true,
    example: false,
    description: "Enable gift last uon"
  })
], UserMetaPrivateResponseDto.prototype, "enableGiftLastUon", 2);
var UserCarouselResponseDtoData = class {
};
__decorateClass([
  ApiProperty111({
    required: true,
    description: "User detail",
    oneOf: [
      {
        $ref: getSchemaPath29(UserMetaPrivateResponseDto)
      },
      {
        $ref: getSchemaPath29(UserMetaPublicResponseDto)
      }
    ],
    type: () => UserMetaPrivateResponseDto || UserMetaPublicResponseDto
  })
], UserCarouselResponseDtoData.prototype, "user", 2);
__decorateClass([
  ApiProperty111({
    required: false,
    description: "The decks detail of user",
    isArray: true,
    type: DeckDetailResponseDto
  })
], UserCarouselResponseDtoData.prototype, "decks", 2);
var UserCarouselResponseDto = class {
};
__decorateClass([
  ApiProperty111({
    required: true,
    description: "Response data",
    type: UserCarouselResponseDtoData
  })
], UserCarouselResponseDto.prototype, "data", 2);
var UserCarouselQueryDtoFields = class {
};
__decorateClass([
  ApiProperty112({
    required: false,
    description: "The decks query param of user"
  })
], UserCarouselQueryDtoFields.prototype, "decks", 2);
var UserCarouselQueryDto = class {
};
__decorateClass([
  ApiProperty112({
    type: String,
    required: false,
    description: "The query param for getting user carousel",
    example: `{
      "decks": {
      }
    }`
  }),
  Transform2(
    (parameters) => {
      return plainToInstance(
        UserCarouselQueryDtoFields,
        JSON.parse(parameters.value)
      );
    },
    { groups: [transformGroup], toClassOnly: true }
  ),
  IsString(),
  IsOptional()
], UserCarouselQueryDto.prototype, "fields", 2);
var DevicePayloadDto = class {
};
__decorateClass([
  ApiProperty113({
    example: "00-1a-22-3c-dd-5e",
    description: "The id of device",
    required: true,
    type: String
  }),
  IsNotEmpty(),
  IsString()
], DevicePayloadDto.prototype, "id", 2);
__decorateClass([
  ApiProperty113({
    example: "17.0.1",
    description: "The os version of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "osVersion", 2);
__decorateClass([
  ApiProperty113({
    example: "en-US",
    description: "The language tag of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "languageTag", 2);
__decorateClass([
  ApiProperty113({
    example: "en",
    description: "The language code of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "languageCode", 2);
__decorateClass([
  ApiProperty113({
    example: true,
    description: "The is virual of device",
    required: false,
    type: Boolean
  }),
  IsOptional(),
  IsBoolean()
], DevicePayloadDto.prototype, "isVirtual", 2);
__decorateClass([
  ApiProperty113({
    example: "Apple",
    description: "The manufacturer of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "manufacturer", 2);
__decorateClass([
  ApiProperty113({
    example: "iPhone16,2",
    description: "The model of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "model", 2);
__decorateClass([
  ApiProperty113({
    example: "iPhone 15 Pro Max",
    description: "The name of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "name", 2);
__decorateClass([
  ApiProperty113({
    example: "ios",
    description: "The os of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "os", 2);
__decorateClass([
  ApiProperty113({
    example: "ios",
    description: "The platform of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "platform", 2);
__decorateClass([
  ApiProperty113({
    example: "17.0.1",
    description: "The webview version of device",
    required: false,
    type: String
  }),
  IsOptional(),
  IsString()
], DevicePayloadDto.prototype, "webviewVersion", 2);
var ReturnDomainType = /* @__PURE__ */ ((ReturnDomainType2) => {
  ReturnDomainType2["UONEARTH"] = "UONEARTH";
  ReturnDomainType2["BRANCHIO"] = "BRANCHIO";
  return ReturnDomainType2;
})(ReturnDomainType || {});
var MagicLinkCreatePayloadDtoData = class {
};
__decorateClass([
  ApiProperty114({
    example: "first name",
    description: "The first name of user",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "firstName", 2);
__decorateClass([
  ApiProperty114({
    example: "last name",
    description: "The last name of user",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "lastName", 2);
__decorateClass([
  ApiProperty114({
    example: "email@gmail.com",
    description: "The email address of user",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "emailAddress", 2);
__decorateClass([
  ApiProperty114({
    example: "0.4.30",
    description: "The version of application",
    required: false,
    type: String
  }),
  IsString(),
  IsOptional()
], MagicLinkCreatePayloadDtoData.prototype, "appVersion", 2);
__decorateClass([
  ApiProperty114({
    example: true,
    description: "Forcing sending magic link email, bypassing existing email address checking",
    required: true,
    type: Boolean
  }),
  IsBoolean(),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "force", 2);
__decorateClass([
  ApiProperty114({
    example: "0.1.1",
    description: "The version of terms, that is accepted by user",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "acceptedTermsVersion", 2);
__decorateClass([
  ApiProperty114({
    example: "protect",
    description: "The path where the user will return after logging in",
    required: true,
    type: String
  }),
  IsString(),
  IsDefined()
], MagicLinkCreatePayloadDtoData.prototype, "returnPath", 2);
__decorateClass([
  ApiProperty114({
    example: "BRANCHIO",
    description: "The return domain type of path",
    required: true,
    enum: ReturnDomainType
  }),
  IsEnum(ReturnDomainType),
  IsNotEmpty()
], MagicLinkCreatePayloadDtoData.prototype, "returnDomainType", 2);
__decorateClass([
  ApiProperty114({
    description: "The device of user",
    required: true,
    type: DevicePayloadDto
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type24(() => DevicePayloadDto)
], MagicLinkCreatePayloadDtoData.prototype, "device", 2);
__decorateClass([
  ApiProperty114({
    description: "The referring params for tracking",
    required: false,
    type: Object
  }),
  IsOptional()
], MagicLinkCreatePayloadDtoData.prototype, "referringParams", 2);
var MagicLinkCreatePayloadDto = class {
};
__decorateClass([
  ApiProperty114({
    type: MagicLinkCreatePayloadDtoData,
    required: true,
    description: "The data for creating magic link"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type24(() => MagicLinkCreatePayloadDtoData)
], MagicLinkCreatePayloadDto.prototype, "data", 2);
var MagicLinkCreateResponseDtoDataEmailNew = class {
};
__decorateClass([
  ApiProperty115({
    type: String,
    required: true,
    example: "763d59f1-71be-4b97-b147-c82f45645145",
    description: "The authUuid of magic link"
  })
], MagicLinkCreateResponseDtoDataEmailNew.prototype, "authUuid", 2);
__decorateClass([
  ApiProperty115({
    required: true,
    description: "The unverified user detail",
    type: UserResponseDto
  })
], MagicLinkCreateResponseDtoDataEmailNew.prototype, "user", 2);
__decorateClass([
  ApiProperty115({
    enum: [
      "EMAIL_NEW"
      /* EMAIL_NEW */
    ],
    required: true,
    example: "EMAIL_NEW",
    description: "The type of magic link response. That must have value as EMAIL_NEW"
  })
], MagicLinkCreateResponseDtoDataEmailNew.prototype, "type", 2);
var MagicLinkCreateResponseDtoDataMagicLinkCreated = class {
};
__decorateClass([
  ApiProperty115({
    type: String,
    required: true,
    example: "763d59f1-71be-4b97-b147-c82f45645145",
    description: "The authUuid of magic link"
  })
], MagicLinkCreateResponseDtoDataMagicLinkCreated.prototype, "authUuid", 2);
__decorateClass([
  ApiProperty115({
    enum: [
      "MAGIC_LINK_CREATED"
      /* MAGIC_LINK_CREATED */
    ],
    required: true,
    example: "MAGIC_LINK_CREATED",
    description: "The type of magic link response. That must have value as MAGIC_LINK_CREATED"
  })
], MagicLinkCreateResponseDtoDataMagicLinkCreated.prototype, "type", 2);
var MagicLinkCreateResponseDtoDataEmailExisted = class {
};
__decorateClass([
  ApiProperty115({
    enum: [
      "EMAIL_EXISTED"
      /* EMAIL_EXISTED */
    ],
    required: true,
    example: "EMAIL_EXISTED",
    description: "The type of magic link response. That must have value as EMAIL_EXISTED"
  })
], MagicLinkCreateResponseDtoDataEmailExisted.prototype, "type", 2);
var MagicLinkCreateResponseDto = class {
};
__decorateClass([
  ApiProperty115({
    required: true,
    description: "The data for magic link",
    oneOf: [
      {
        $ref: getSchemaPath30(MagicLinkCreateResponseDtoDataEmailNew)
      },
      {
        $ref: getSchemaPath30(MagicLinkCreateResponseDtoDataMagicLinkCreated)
      },
      {
        $ref: getSchemaPath30(MagicLinkCreateResponseDtoDataEmailExisted)
      }
    ],
    type: () => Object
  })
], MagicLinkCreateResponseDto.prototype, "data", 2);
MagicLinkCreateResponseDto = __decorateClass([
  ApiExtraModels20(
    MagicLinkCreateResponseDtoDataEmailExisted,
    MagicLinkCreateResponseDtoDataMagicLinkCreated,
    MagicLinkCreateResponseDtoDataEmailNew
  )
], MagicLinkCreateResponseDto);
var MagicLinkGetByAuthUuidResponseDtoDataVerified = class {
};
__decorateClass([
  ApiProperty116({
    required: true,
    description: "The verified user detail",
    type: UserResponseDto
  })
], MagicLinkGetByAuthUuidResponseDtoDataVerified.prototype, "user", 2);
__decorateClass([
  ApiProperty116({
    example: "/protect",
    description: "The path where the user will be returned",
    required: true,
    type: String
  })
], MagicLinkGetByAuthUuidResponseDtoDataVerified.prototype, "returnPath", 2);
__decorateClass([
  ApiProperty116({
    example: "BRANCHIO",
    description: "The return domain type of path",
    required: true,
    enum: ReturnDomainType
  })
], MagicLinkGetByAuthUuidResponseDtoDataVerified.prototype, "returnDomainType", 2);
__decorateClass([
  ApiProperty116({
    enum: [
      "MAGIC_LINK_VERIFIED"
      /* MAGIC_LINK_VERIFIED */
    ],
    required: true,
    example: "MAGIC_LINK_VERIFIED",
    description: "The status of magic link. That must have value as MAGIC_LINK_VERIFIED"
  })
], MagicLinkGetByAuthUuidResponseDtoDataVerified.prototype, "type", 2);
var MagicLinkGetByAuthUuidResponseDtoDataExpired = class {
};
__decorateClass([
  ApiProperty116({
    enum: [
      "MAGIC_LINK_EXPIRED"
      /* MAGIC_LINK_EXPIRED */
    ],
    required: true,
    example: "MAGIC_LINK_EXPIRED",
    description: "The status of magic link. That must have value as MAGIC_LINK_EXPIRED"
  })
], MagicLinkGetByAuthUuidResponseDtoDataExpired.prototype, "type", 2);
var MagicLinkGetByAuthUuidResponseDtoDataInvalid = class {
};
__decorateClass([
  ApiProperty116({
    enum: [
      "MAGIC_LINK_INVALID"
      /* MAGIC_LINK_INVALID */
    ],
    required: true,
    example: "MAGIC_LINK_INVALID",
    description: "The status of magic link. That must have value as MAGIC_LINK_INVALID"
  })
], MagicLinkGetByAuthUuidResponseDtoDataInvalid.prototype, "type", 2);
var MagicLinkGetByAuthUuidResponseDtoDataUnverified = class {
};
__decorateClass([
  ApiProperty116({
    enum: [
      "MAGIC_LINK_UNVERIFIED"
      /* MAGIC_LINK_UNVERIFIED */
    ],
    required: true,
    example: "MAGIC_LINK_UNVERIFIED",
    description: "The status of magic link. That must have value as MAGIC_LINK_UNVERIFIED"
  })
], MagicLinkGetByAuthUuidResponseDtoDataUnverified.prototype, "type", 2);
var MagicLinkGetByAuthUuidResponseDto = class {
};
__decorateClass([
  ApiProperty116({
    required: true,
    description: "The data of magic link",
    oneOf: [
      {
        $ref: getSchemaPath31(MagicLinkGetByAuthUuidResponseDtoDataVerified)
      },
      {
        $ref: getSchemaPath31(MagicLinkGetByAuthUuidResponseDtoDataUnverified)
      },
      {
        $ref: getSchemaPath31(MagicLinkGetByAuthUuidResponseDtoDataExpired)
      },
      {
        $ref: getSchemaPath31(MagicLinkGetByAuthUuidResponseDtoDataInvalid)
      }
    ],
    type: () => Object
  })
], MagicLinkGetByAuthUuidResponseDto.prototype, "data", 2);
MagicLinkGetByAuthUuidResponseDto = __decorateClass([
  ApiExtraModels21(
    MagicLinkGetByAuthUuidResponseDtoDataUnverified,
    MagicLinkGetByAuthUuidResponseDtoDataExpired,
    MagicLinkGetByAuthUuidResponseDtoDataInvalid,
    MagicLinkGetByAuthUuidResponseDtoDataVerified
  )
], MagicLinkGetByAuthUuidResponseDto);
var c14 = initContract();
var qrCodeContract = c14.router({
  activateQrCode: {
    method: "POST",
    path: "/v1/qr-codes/activate",
    body: c14.type(),
    responses: {
      200: c14.type(),
      400: c14.type(),
      401: c14.type(),
      404: c14.type(),
      500: c14.type()
    },
    strictStatusCodes: true
  },
  createQRCode: {
    method: "POST",
    path: "/v1/users/auth/qr-code",
    body: c14.type(),
    headers: z.object({
      "x-client-earth-location": z.string().optional(),
      "user-agent": z.string().optional(),
      "cf-ipcountry": z.string().optional()
    }),
    responses: {
      201: c14.type(),
      500: c14.type()
    },
    strictStatusCodes: true
  }
});
var QrCodeActivatePayloadDtoData = class {
};
__decorateClass([
  ApiProperty117({
    example: "550e8400-e29b-41d4-a716-446655440000",
    description: "The uuid of qr code. Which is used to authorize website login permission",
    required: true,
    type: String
  }),
  IsString(),
  IsNotEmpty()
], QrCodeActivatePayloadDtoData.prototype, "uuid", 2);
__decorateClass([
  ApiProperty117({
    example: "0.4.30",
    description: "The version of application",
    required: false,
    type: String
  }),
  IsString(),
  IsOptional()
], QrCodeActivatePayloadDtoData.prototype, "appVersion", 2);
__decorateClass([
  ApiProperty117({
    description: "The device of user",
    required: true,
    type: DevicePayloadDto
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type25(() => DevicePayloadDto)
], QrCodeActivatePayloadDtoData.prototype, "device", 2);
__decorateClass([
  ApiProperty117({
    description: "The referring params for tracking",
    required: false,
    type: Object
  }),
  IsOptional()
], QrCodeActivatePayloadDtoData.prototype, "referringParams", 2);
var QrCodeActivatePayloadDto = class {
};
__decorateClass([
  ApiProperty117({
    type: QrCodeActivatePayloadDtoData,
    required: true,
    description: "The data for activating qr code"
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type25(() => QrCodeActivatePayloadDtoData)
], QrCodeActivatePayloadDto.prototype, "data", 2);
var QRCodeActiveResponseType = /* @__PURE__ */ ((QRCodeActiveResponseType2) => {
  QRCodeActiveResponseType2["SUCCESSS"] = "SUCCESSS";
  QRCodeActiveResponseType2["EXPIRED"] = "EXPIRED";
  return QRCodeActiveResponseType2;
})(QRCodeActiveResponseType || {});
var QrCodeActivateResponseDtoData = class {
};
__decorateClass([
  ApiProperty118({
    enum: QRCodeActiveResponseType,
    required: true,
    example: "SUCCESSS",
    description: "The type of qr code response"
  })
], QrCodeActivateResponseDtoData.prototype, "type", 2);
var QrCodeActivateResponseDto = class {
};
__decorateClass([
  ApiProperty118({
    required: true,
    description: "The data for activating qr code",
    type: () => QrCodeActivateResponseDtoData
  })
], QrCodeActivateResponseDto.prototype, "data", 2);
var QrCodeCreateResponseDataUuid = class {
};
__decorateClass([
  ApiProperty119({
    example: "550e8400-e29b-41d4-a716-446655440000",
    description: "The uuid of qr code. Which is used to authorize website login permission",
    required: true,
    type: String
  })
], QrCodeCreateResponseDataUuid.prototype, "qrUuid", 2);
__decorateClass([
  ApiProperty119({
    required: true,
    enum: [
      "UUID"
      /* UUID */
    ],
    example: "UUID",
    description: "The response type of Qr code. The value must be UUID"
  })
], QrCodeCreateResponseDataUuid.prototype, "type", 2);
__decorateClass([
  ApiProperty119({
    required: true,
    description: "The Expire time of Qr Uuid",
    type: Date,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], QrCodeCreateResponseDataUuid.prototype, "expiredAt", 2);
var QrCodeCreateResponseDto = class {
};
__decorateClass([
  ApiProperty119({
    description: "The data of creating Qr code response",
    type: QrCodeCreateResponseDataUuid,
    required: true
  })
], QrCodeCreateResponseDto.prototype, "data", 2);
var c15 = initContract();
var searchContract = c15.router({
  search: {
    method: "GET",
    path: "/v1/search",
    responses: {
      200: c15.type(),
      500: c15.type()
    },
    query: z.object({
      query: z.string(),
      type: z.string().optional(),
      limit: z.string().optional()
    }),
    strictStatusCodes: true
  },
  searchUserByVanityName: {
    method: "GET",
    path: "/v1/search/users/:vanityName",
    pathParams: z.object({
      vanityName: z.string()
    }),
    responses: {
      200: c15.type(),
      500: c15.type()
    },
    strictStatusCodes: true
  }
});
var DeckMatchingPropertiesDto = class {
};
__decorateClass([
  ApiProperty120({
    required: true,
    type: Number,
    description: "The amount of cards",
    example: 52
  })
], DeckMatchingPropertiesDto.prototype, "cards", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "The created time of deck",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], DeckMatchingPropertiesDto.prototype, "created", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    type: String,
    description: "The description of deck",
    example: "something"
  })
], DeckMatchingPropertiesDto.prototype, "description", 2);
__decorateClass([
  ApiProperty120({
    required: false,
    type: () => ImageResponseDto2,
    description: "The image of deck"
  })
], DeckMatchingPropertiesDto.prototype, "image", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    type: String,
    description: "Deck name",
    example: "DeckName"
  })
], DeckMatchingPropertiesDto.prototype, "name", 2);
var DeckMatchingResponseDto = class {
};
__decorateClass([
  ApiProperty120({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of deck",
    example: "123456789"
  })
], DeckMatchingResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "Deck path",
    example: "[vanityName, linkName]",
    type: [String]
  })
], DeckMatchingResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "Deck properties",
    type: DeckMatchingPropertiesDto
  })
], DeckMatchingResponseDto.prototype, "properties", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "Search type",
    type: String,
    example: SearchType.DECK
  })
], DeckMatchingResponseDto.prototype, "type", 2);
var UserMatchingPropertiesDto = class {
};
__decorateClass([
  ApiProperty120({
    required: true,
    type: Number,
    description: "The amount of decks",
    example: 52
  })
], UserMatchingPropertiesDto.prototype, "decks", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    type: Number,
    description: "The amount of followers",
    example: 52
  })
], UserMatchingPropertiesDto.prototype, "followers", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    type: String,
    description: "User fullname",
    example: "Fullname"
  })
], UserMatchingPropertiesDto.prototype, "fullname", 2);
__decorateClass([
  ApiProperty120({
    required: false,
    type: () => ImageResponseDto2,
    description: "The image of deck"
  })
], UserMatchingPropertiesDto.prototype, "image", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "The created time of user",
    type: String,
    format: "date-time",
    example: "2023-03-24T04:43:52.042Z"
  })
], UserMatchingPropertiesDto.prototype, "joined", 2);
var UserMatchingResponseDto = class {
};
__decorateClass([
  ApiProperty120({
    required: true,
    type: String,
    format: "int64",
    description: "The `id` of user",
    example: "123456789"
  })
], UserMatchingResponseDto.prototype, "id", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "User path",
    example: "[vanityName]",
    type: [String]
  })
], UserMatchingResponseDto.prototype, "path", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "User properties",
    type: UserMatchingPropertiesDto
  })
], UserMatchingResponseDto.prototype, "properties", 2);
__decorateClass([
  ApiProperty120({
    required: true,
    description: "Search type",
    type: String,
    example: SearchType.USER
  })
], UserMatchingResponseDto.prototype, "type", 2);
var c16 = initContract();
var mobileUserShortcutItemContract = c16.router({
  createMobileUserShortcutItem: {
    method: "POST",
    path: "/v1/users/me/mobile-user-shortcut-items",
    headers: z.object({
      authorization: z.string()
    }),
    responses: {
      201: c16.type(),
      400: c16.type(),
      401: c16.type(),
      409: c16.type(),
      500: c16.type()
    },
    body: c16.type(),
    strictStatusCodes: true
  },
  getMobileUserShortcutItems: {
    method: "GET",
    path: "/v1/users/:userId/mobile-user-shortcut-items",
    pathParams: z.object({
      userId: z.string()
    }),
    headers: z.object({
      authorization: z.string()
    }),
    responses: {
      200: c16.type(),
      401: c16.type(),
      500: c16.type()
    },
    strictStatusCodes: true
  },
  removeMobileUserShortcutItem: {
    method: "DELETE",
    path: "/v1/users/:userId/mobile-user-shortcut-items/:mobileUserShortcutItemId",
    pathParams: z.object({
      userId: z.string(),
      mobileUserShortcutItemId: z.string()
    }),
    headers: z.object({
      authorization: z.string()
    }),
    body: c16.type(),
    responses: {
      204: c16.type(),
      401: c16.type(),
      404: c16.type(),
      500: c16.type()
    },
    strictStatusCodes: true
  },
  prepopulateMobileUserShortcutItem: {
    method: "POST",
    path: "/v1/administration/mobile-user-shortcut-items/pre-populate",
    query: z.object({
      userIds: z.string().optional()
    }),
    headers: z.object({
      authorization: z.string()
    }),
    body: c16.type(),
    responses: {
      201: c16.type(),
      401: c16.type(),
      500: c16.type()
    },
    strictStatusCodes: true
  }
});
var MobileUserShortcutItemLocalId = /* @__PURE__ */ ((MobileUserShortcutItemLocalId2) => {
  MobileUserShortcutItemLocalId2["HOME"] = "HOME";
  MobileUserShortcutItemLocalId2["SETTING"] = "SETTING";
  MobileUserShortcutItemLocalId2["WALLET"] = "WALLET";
  MobileUserShortcutItemLocalId2["GALLERY"] = "GALLERY";
  MobileUserShortcutItemLocalId2["GROUP"] = "GROUP";
  MobileUserShortcutItemLocalId2["MEMBER"] = "MEMBER";
  MobileUserShortcutItemLocalId2["MESSAGE"] = "MESSAGE";
  MobileUserShortcutItemLocalId2["PROFILE"] = "PROFILE";
  return MobileUserShortcutItemLocalId2;
})(MobileUserShortcutItemLocalId || {});
var MobileUserShortcutItemCreatePayloadDtoDataDeckCurating = class {
};
__decorateClass([
  ApiProperty121({
    example: "12345678388338",
    description: "The curating deck_id of user. That was created by this user.",
    required: true,
    format: "int-64",
    type: String
  }),
  IsNotEmpty(),
  IsNumberString()
], MobileUserShortcutItemCreatePayloadDtoDataDeckCurating.prototype, "deckId", 2);
__decorateClass([
  ApiProperty121({
    example: "DECK_CURATING",
    description: "Type must be DECK_CURATING",
    required: true,
    enum: [
      "DECK_CURATING"
      /* DECK_CURATING */
    ]
  }),
  IsNotEmpty(),
  IsIn([
    "DECK_CURATING"
    /* DECK_CURATING */
  ])
], MobileUserShortcutItemCreatePayloadDtoDataDeckCurating.prototype, "type", 2);
var MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing = class {
};
__decorateClass([
  ApiProperty121({
    example: "12345678388338",
    description: "The following deck_id. That was followed by this user.",
    required: true,
    format: "int-64",
    type: String
  }),
  IsNotEmpty(),
  IsNumberString()
], MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing.prototype, "deckId", 2);
__decorateClass([
  ApiProperty121({
    example: "DECK_FOLLOWING",
    description: "Type must be DECK_FOLLOWING",
    required: true,
    enum: [
      "DECK_FOLLOWING"
      /* DECK_FOLLOWING */
    ]
  }),
  IsNotEmpty(),
  IsIn([
    "DECK_FOLLOWING"
    /* DECK_FOLLOWING */
  ])
], MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing.prototype, "type", 2);
var MobileUserShortcutItemCreatePayloadDtoDataDeckSystem = class {
};
__decorateClass([
  ApiProperty121({
    example: "12345678388338",
    description: "The system deck_id. That was available to all users.",
    required: true,
    format: "int-64",
    type: String
  }),
  IsNotEmpty(),
  IsNumberString()
], MobileUserShortcutItemCreatePayloadDtoDataDeckSystem.prototype, "deckId", 2);
__decorateClass([
  ApiProperty121({
    example: "DECK_SYSTEM",
    description: "Type must be DECK_SYSTEM",
    required: true,
    enum: [
      "DECK_SYSTEM"
      /* DECK_SYSTEM */
    ]
  }),
  IsNotEmpty(),
  IsIn([
    "DECK_SYSTEM"
    /* DECK_SYSTEM */
  ])
], MobileUserShortcutItemCreatePayloadDtoDataDeckSystem.prototype, "type", 2);
var MobileUserShortcutItemCreatePayloadDtoDataLocal = class {
};
__decorateClass([
  ApiProperty121({
    example: "12345678388338",
    description: "The local id of cards. That were saved locally in the mobblie app",
    required: true,
    enum: MobileUserShortcutItemLocalId
  }),
  IsNotEmpty(),
  IsEnum(MobileUserShortcutItemLocalId)
], MobileUserShortcutItemCreatePayloadDtoDataLocal.prototype, "localId", 2);
__decorateClass([
  ApiProperty121({
    example: "LOCAL",
    description: "Type must be LOCAL",
    required: true,
    enum: [
      "LOCAL"
      /* LOCAL */
    ]
  }),
  IsNotEmpty(),
  IsIn([
    "LOCAL"
    /* LOCAL */
  ])
], MobileUserShortcutItemCreatePayloadDtoDataLocal.prototype, "type", 2);
var MobileUserShortcutItemCreatePayloadDto = class {
};
__decorateClass([
  ApiProperty121({
    required: true,
    description: "The data for creating mobile user shortcut item",
    oneOf: [
      {
        $ref: getSchemaPath32(
          MobileUserShortcutItemCreatePayloadDtoDataDeckCurating
        )
      },
      {
        $ref: getSchemaPath32(
          MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing
        )
      },
      {
        $ref: getSchemaPath32(
          MobileUserShortcutItemCreatePayloadDtoDataDeckSystem
        )
      },
      {
        $ref: getSchemaPath32(MobileUserShortcutItemCreatePayloadDtoDataLocal)
      }
    ],
    type: () => Object
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type26(
    () => MobileUserShortcutItemCreatePayloadDtoDataDeckCurating || MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing || MobileUserShortcutItemCreatePayloadDtoDataDeckSystem || MobileUserShortcutItemCreatePayloadDtoDataLocal,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "type",
        subTypes: [
          {
            value: MobileUserShortcutItemCreatePayloadDtoDataDeckCurating,
            name: "DECK_CURATING"
            /* DECK_CURATING */
          },
          {
            value: MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing,
            name: "DECK_FOLLOWING"
            /* DECK_FOLLOWING */
          },
          {
            value: MobileUserShortcutItemCreatePayloadDtoDataDeckSystem,
            name: "DECK_SYSTEM"
            /* DECK_SYSTEM */
          },
          {
            value: MobileUserShortcutItemCreatePayloadDtoDataLocal,
            name: "LOCAL"
            /* LOCAL */
          }
        ]
      }
    }
  )
], MobileUserShortcutItemCreatePayloadDto.prototype, "data", 2);
MobileUserShortcutItemCreatePayloadDto = __decorateClass([
  ApiExtraModels22(
    MobileUserShortcutItemCreatePayloadDtoDataDeckCurating,
    MobileUserShortcutItemCreatePayloadDtoDataDeckFollowing,
    MobileUserShortcutItemCreatePayloadDtoDataDeckSystem,
    MobileUserShortcutItemCreatePayloadDtoDataLocal
  )
], MobileUserShortcutItemCreatePayloadDto);
var MobileUserShortcutItemCreateResponseDtoDataDeckCurating = class {
};
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `Id` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckCurating.prototype, "id", 2);
__decorateClass([
  ApiProperty122({
    example: "DECK_CURATING",
    description: "Type must be DECK_CURATING",
    required: true,
    enum: [
      "DECK_CURATING"
      /* DECK_CURATING */
    ]
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckCurating.prototype, "type", 2);
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `deckId` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckCurating.prototype, "deckId", 2);
var MobileUserShortcutItemCreateResponseDtoDataDeckFollowing = class {
};
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `Id` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckFollowing.prototype, "id", 2);
__decorateClass([
  ApiProperty122({
    example: "DECK_FOLLOWING",
    description: "Type must be DECK_FOLLOWING",
    required: true,
    enum: [
      "DECK_FOLLOWING"
      /* DECK_FOLLOWING */
    ]
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckFollowing.prototype, "type", 2);
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `deckId` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckFollowing.prototype, "deckId", 2);
var MobileUserShortcutItemCreateResponseDtoDataDeckSystem = class {
};
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `Id` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckSystem.prototype, "id", 2);
__decorateClass([
  ApiProperty122({
    example: "DECK_SYSTEM",
    description: "Type must be DECK_SYSTEM",
    required: true,
    enum: [
      "DECK_SYSTEM"
      /* DECK_SYSTEM */
    ]
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckSystem.prototype, "type", 2);
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `deckId` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataDeckSystem.prototype, "deckId", 2);
var MobileUserShortcutItemCreateResponseDtoDataLocal = class {
};
__decorateClass([
  ApiProperty122({
    example: "6714931170118234112",
    description: "The `Id` of the MobileUserShortcutItem",
    required: true,
    format: "int-64",
    type: String
  })
], MobileUserShortcutItemCreateResponseDtoDataLocal.prototype, "id", 2);
__decorateClass([
  ApiProperty122({
    example: "LOCAL",
    description: "Type must be LOCAL",
    required: true,
    enum: [
      "LOCAL"
      /* LOCAL */
    ]
  })
], MobileUserShortcutItemCreateResponseDtoDataLocal.prototype, "type", 2);
__decorateClass([
  ApiProperty122({
    example: "12345678388338",
    description: "The local id",
    required: true,
    enum: MobileUserShortcutItemLocalId
  })
], MobileUserShortcutItemCreateResponseDtoDataLocal.prototype, "localId", 2);
var MobileUserShortcutItemCreateResponseDto = class {
};
__decorateClass([
  ApiProperty122({
    required: true,
    description: "The data for creating mobile user shortcut item",
    oneOf: [
      {
        $ref: getSchemaPath33(
          MobileUserShortcutItemCreateResponseDtoDataDeckCurating
        )
      },
      {
        $ref: getSchemaPath33(
          MobileUserShortcutItemCreateResponseDtoDataDeckFollowing
        )
      },
      {
        $ref: getSchemaPath33(
          MobileUserShortcutItemCreateResponseDtoDataDeckSystem
        )
      },
      {
        $ref: getSchemaPath33(MobileUserShortcutItemCreateResponseDtoDataLocal)
      }
    ],
    type: () => Object
  })
], MobileUserShortcutItemCreateResponseDto.prototype, "data", 2);
MobileUserShortcutItemCreateResponseDto = __decorateClass([
  ApiExtraModels23(
    MobileUserShortcutItemCreateResponseDtoDataDeckCurating,
    MobileUserShortcutItemCreateResponseDtoDataDeckFollowing,
    MobileUserShortcutItemCreateResponseDtoDataDeckSystem,
    MobileUserShortcutItemCreateResponseDtoDataLocal
  )
], MobileUserShortcutItemCreateResponseDto);
var MobileUserShortcutItemsGetResponseDto = class {
};
__decorateClass([
  ApiProperty123({
    required: true,
    description: "The data for getting mobile user shortcut items",
    items: {
      oneOf: [
        {
          $ref: getSchemaPath34(
            MobileUserShortcutItemCreateResponseDtoDataDeckCurating
          )
        },
        {
          $ref: getSchemaPath34(
            MobileUserShortcutItemCreateResponseDtoDataDeckFollowing
          )
        },
        {
          $ref: getSchemaPath34(
            MobileUserShortcutItemCreateResponseDtoDataDeckSystem
          )
        },
        {
          $ref: getSchemaPath34(MobileUserShortcutItemCreateResponseDtoDataLocal)
        }
      ]
    },
    type: "array"
  })
], MobileUserShortcutItemsGetResponseDto.prototype, "data", 2);
var UserSignoutPayloadMobileDataDto = class {
};
__decorateClass([
  ApiProperty124({
    description: "The device of user",
    required: true,
    type: DevicePayloadDto
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type27(() => DevicePayloadDto)
], UserSignoutPayloadMobileDataDto.prototype, "device", 2);
__decorateClass([
  ApiProperty124({
    required: true,
    description: "The signout request type",
    type: String,
    enum: [
      "MOBILE_APP"
      /* MOBILE_APP */
    ]
  })
], UserSignoutPayloadMobileDataDto.prototype, "requestType", 2);
var UserSignoutPayloadWebDataDto = class {
};
__decorateClass([
  ApiProperty124({
    required: true,
    description: "The signout request type",
    type: String,
    enum: [
      "WEB_APP"
      /* WEB_APP */
    ]
  })
], UserSignoutPayloadWebDataDto.prototype, "requestType", 2);
var UserSignoutPayloadDto = class {
};
__decorateClass([
  ApiProperty124({
    required: true,
    description: "The signout request data",
    oneOf: [
      {
        $ref: getSchemaPath35(UserSignoutPayloadWebDataDto)
      },
      {
        $ref: getSchemaPath35(UserSignoutPayloadMobileDataDto)
      }
    ],
    type: () => Object
  }),
  ValidateNested(),
  Type27(() => UserSignoutPayloadWebDataDto || UserSignoutPayloadMobileDataDto, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "requestType",
      subTypes: [
        {
          value: UserSignoutPayloadWebDataDto,
          name: "WEB_APP"
          /* WEB_APP */
        },
        {
          value: UserSignoutPayloadMobileDataDto,
          name: "MOBILE_APP"
          /* MOBILE_APP */
        }
      ]
    }
  })
], UserSignoutPayloadDto.prototype, "data", 2);
UserSignoutPayloadDto = __decorateClass([
  ApiExtraModels24(UserSignoutPayloadWebDataDto, UserSignoutPayloadMobileDataDto)
], UserSignoutPayloadDto);
var UserTokenRefreshPayloadDataDto = class {
};
__decorateClass([
  IsNotEmpty()
], UserTokenRefreshPayloadDataDto.prototype, "refreshToken", 2);
var UserTokenRefreshPayloadDataMobileDto = class extends UserTokenRefreshPayloadDataDto {
};
__decorateClass([
  ApiProperty125({
    required: true,
    description: "The signout request type",
    type: String,
    enum: [
      "MOBILE_APP"
      /* MOBILE_APP */
    ],
    example: "MOBILE_APP"
    /* MOBILE_APP */
  }),
  IsNotEmpty()
], UserTokenRefreshPayloadDataMobileDto.prototype, "requestType", 2);
__decorateClass([
  ApiProperty125({
    description: "The device of user",
    required: true,
    type: DevicePayloadDto
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type28(() => DevicePayloadDto)
], UserTokenRefreshPayloadDataMobileDto.prototype, "device", 2);
var UserTokenRefreshPayloadDataWebDto = class extends UserTokenRefreshPayloadDataDto {
};
__decorateClass([
  ApiProperty125({
    required: true,
    description: "The signout request type",
    type: String,
    enum: [
      "WEB_APP"
      /* WEB_APP */
    ],
    example: "WEB_APP"
    /* WEB_APP */
  }),
  IsNotEmpty()
], UserTokenRefreshPayloadDataWebDto.prototype, "requestType", 2);
var UserTokenRefreshPayloadDto = class {
};
__decorateClass([
  ApiProperty125({
    required: true,
    description: "The signout request data",
    oneOf: [
      {
        $ref: getSchemaPath36(UserTokenRefreshPayloadDataWebDto)
      },
      {
        $ref: getSchemaPath36(UserTokenRefreshPayloadDataMobileDto)
      }
    ],
    type: () => Object
  }),
  IsNotEmpty(),
  ValidateNested(),
  Type28(
    () => UserTokenRefreshPayloadDataWebDto || UserTokenRefreshPayloadDataMobileDto,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: "requestType",
        subTypes: [
          {
            value: UserTokenRefreshPayloadDataWebDto,
            name: "WEB_APP"
            /* WEB_APP */
          },
          {
            value: UserTokenRefreshPayloadDataMobileDto,
            name: "MOBILE_APP"
            /* MOBILE_APP */
          }
        ]
      }
    }
  )
], UserTokenRefreshPayloadDto.prototype, "data", 2);
UserTokenRefreshPayloadDto = __decorateClass([
  ApiExtraModels25(
    UserTokenRefreshPayloadDataWebDto,
    UserTokenRefreshPayloadDataMobileDto
  )
], UserTokenRefreshPayloadDto);
var UserGetMePrivateResponseDto = class extends UserMetaPrivateResponseDto {
};
__decorateClass([
  ApiProperty126({
    required: true,
    type: LoginMetaResponseDto,
    description: "Time informations that user loged in"
  })
], UserGetMePrivateResponseDto.prototype, "meta", 2);
__decorateClass([
  ApiProperty126({
    example: false,
    required: false,
    type: Boolean,
    description: "Api key functionality for user is enabled or disabled"
  })
], UserGetMePrivateResponseDto.prototype, "isApiKeyEnabled", 2);
var UserGetMePublicResponseDto = class extends UserMetaPublicResponseDto {
};
__decorateClass([
  ApiProperty126({
    required: true,
    type: LoginMetaResponseDto,
    description: "Time informations that user loged in"
  })
], UserGetMePublicResponseDto.prototype, "meta", 2);
__decorateClass([
  ApiProperty126({
    example: false,
    required: false,
    type: Boolean,
    description: "Api key functionality for user is enabled or disabled"
  })
], UserGetMePublicResponseDto.prototype, "isApiKeyEnabled", 2);
var c17 = initContract();
var reserveContract = c17.router({
  setCollectibleForReserve: {
    method: "PUT",
    path: "/v1/reserves/:reserveId/promotions/:promotionId",
    pathParams: z.object({
      reserveId: z.string(),
      promotionId: z.string()
    }),
    body: c17.type(),
    responses: {
      204: c17.type(),
      400: c17.type(),
      401: c17.type(),
      500: c17.type()
    },
    headers: z.object({
      authorization: z.string()
    }),
    strictStatusCodes: true
  }
});
var c18 = initContract();
var uonMarkerContract = c18.router({
  findUonMarkersByViewport: {
    method: "GET",
    path: "/v1/reserves/:reserveId/uon-markers",
    responses: {
      200: c18.type(),
      404: c18.type(),
      500: c18.type()
    },
    pathParams: z.object({
      reserveId: z.string()
    }),
    query: c18.type(),
    strictStatusCodes: true
  }
});
var Point2 = class {
};
__decorateClass([
  ApiProperty127({
    description: "The latitude of coordinates",
    example: 60.1,
    type: Number,
    required: true
  })
], Point2.prototype, "lat", 2);
__decorateClass([
  ApiProperty127({
    description: "The longtitude of coordinates",
    example: 120.7,
    type: Number,
    required: true
  })
], Point2.prototype, "lng", 2);
var OwnerResponseDto = class {
};
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The uonStoreId of Owner",
    example: "12345",
    type: String,
    format: "int-64"
  })
], OwnerResponseDto.prototype, "uonStoreId", 2);
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The name of Owner",
    example: "full name",
    type: String
  })
], OwnerResponseDto.prototype, "fullName", 2);
__decorateClass([
  ApiProperty127({
    required: false,
    description: "The vanityName of Owner",
    example: "vanityName",
    type: String
  })
], OwnerResponseDto.prototype, "vanityName", 2);
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The isPublished of Owner",
    example: false,
    type: Boolean
  })
], OwnerResponseDto.prototype, "isPublished", 2);
var UonMarkerResponseDtoData = class {
};
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The uon id of uon marker",
    example: "12345",
    type: String
  })
], UonMarkerResponseDtoData.prototype, "uonId", 2);
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The point of uon marker",
    type: () => Point2
  })
], UonMarkerResponseDtoData.prototype, "point", 2);
__decorateClass([
  ApiProperty127({
    required: true,
    description: "The owner of uon",
    type: () => OwnerResponseDto
  })
], UonMarkerResponseDtoData.prototype, "owner", 2);
var UonMarkerResponseDto = class {
};
__decorateClass([
  ApiProperty127({
    required: true,
    isArray: true,
    description: "The data of uon markers",
    type: UonMarkerResponseDtoData
  })
], UonMarkerResponseDto.prototype, "data", 2);
var UonMarkersQueryDto = class {
};
__decorateClass([
  IsNotEmpty(),
  IsString()
], UonMarkersQueryDto.prototype, "viewport", 2);
__decorateClass([
  IsOptional(),
  IsNumberString()
], UonMarkersQueryDto.prototype, "excludeUonId", 2);
var FeedUserProfileDeckResponseDtoMeta = class {
};
__decorateClass([
  ApiProperty128({
    required: false,
    description: "The featured promotion",
    type: PromotionDto
  })
], FeedUserProfileDeckResponseDtoMeta.prototype, "featuredPromotion", 2);
var FeedUserProfileDeckResponseDto = class {
};
__decorateClass([
  ApiProperty128({
    required: true,
    description: "The data of feed user profile deck",
    type: () => [Object],
    oneOf: [
      { $ref: getSchemaPath37(PromotionDto) },
      { $ref: getSchemaPath37(CardStandardResponseDto) },
      { $ref: getSchemaPath37(CardActionResponseDto) },
      { $ref: getSchemaPath37(CardInfoResponseDto) },
      { $ref: getSchemaPath37(CardInfoRepostResponseDto) },
      { $ref: getSchemaPath37(CardPromotionRepostResponseDto) },
      { $ref: getSchemaPath37(CardItemEditableResponseDto) },
      { $ref: getSchemaPath37(CardItemEditableRepostResponseDto) }
    ]
  })
], FeedUserProfileDeckResponseDto.prototype, "data", 2);
__decorateClass([
  ApiProperty128({
    required: true,
    description: "The meta of feed user profile deck",
    type: FeedUserProfileDeckResponseDtoMeta
  })
], FeedUserProfileDeckResponseDto.prototype, "meta", 2);

// src/helpers/getEventTranslationKey.ts
var getEventTranslationKey = (event) => {
  switch (event) {
    case NotificationEventType.AUTOPLAYED: {
      return "notification.event.autoplay";
    }
    case NotificationEventType.STAR: {
      return "notification.event.star";
    }
    case NotificationEventType.UNSTAR: {
      return "notification.event.unstar";
    }
    case NotificationEventType.REPOSTED: {
      return "notification.event.reposted";
    }
    case NotificationEventType.FOLLOW: {
      return "notification.event.follow";
    }
    case NotificationEventType.UNFOLLOW: {
      return "notification.event.unfollow";
    }
    case NotificationEventType.CONTENT_DELETED: {
      return "notification.event.content-deleted";
    }
    case NotificationEventType.GROUP_MEMBER_INVITED: {
      return "notification.event.group-member-invited";
    }
    default: {
      return "";
    }
  }
};

// src/helpers/getEventText.ts
var getEventText = (event, t) => {
  return t(getEventTranslationKey(event));
};

// src/helpers/parseInternalUrl/parseResourceByInternalUrl.ts
var excludedVanityNames2 = /* @__PURE__ */ new Set([
  "me",
  "following",
  "about-us",
  "privacy",
  "admin",
  "administrator",
  "curator",
  "editor",
  "play",
  "protect",
  "settings",
  "claim",
  "mobile",
  "today",
  "about",
  "people",
  "creation",
  "world",
  "nature",
  "spirit",
  "keys",
  "brands",
  "topprotectors",
  "naturereserves",
  "charities",
  "system",
  "collect",
  "m2",
  "qr",
  "policies",
  "counter"
]);
function parseResourceByInternalUrl({
  internalUrl
}) {
  const url = new URL(internalUrl);
  const pathElements = url.pathname.replace(/\/*$/, "").split("/");
  const search = url.search;
  if (pathElements.length === 0) {
    throw new Error(`Expected length of path elements greater than 0`);
  }
  if (pathElements.length === 1) {
    return {
      nextjsHrefPath: `/${search}`,
      nextjsAsPath: `/${search}`
    };
  }
  if (pathElements.length === 2 && excludedVanityNames2.has(pathElements[1])) {
    return {
      nextjsHrefPath: `/${pathElements[1]}${search}`,
      nextjsAsPath: `/${pathElements[1]}${search}`
    };
  }
  if (!excludedVanityNames2.has(pathElements[1])) {
    if (pathElements.length === 2) {
      return {
        nextjsHrefPath: `/[vanityName]${search}`,
        nextjsAsPath: `/${pathElements[1]}${search}`
      };
    }
    if (pathElements.length === 3) {
      return {
        nextjsHrefPath: `/[vanityName]/[deckName]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}${search}`
      };
    }
    if (pathElements.length === 4) {
      return {
        nextjsHrefPath: `/[vanityName]/[deckName]/[cardId]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}/${pathElements[3]}${search}`
      };
    }
  }
  if (pathElements[1] === "share") {
    if (pathElements.length !== 4) {
      throw new Error(`The wrong url of share page.`);
    }
    return {
      nextjsHrefPath: `/share/[giftType]/[uniqueId]${search}`,
      nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}/${pathElements[3]}${search}`
    };
  }
  if (pathElements.length === 3) {
    if (pathElements[1] === "collect") {
      return {
        nextjsHrefPath: `/collect/[brandName]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}${search}`
      };
    }
    if (pathElements[1] === "m2") {
      return {
        nextjsHrefPath: `/m2/[transactionId]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}${search}`
      };
    }
    if (pathElements[1] === "policies") {
      return {
        nextjsHrefPath: `/policies/[param]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}${search}`
      };
    }
    if (pathElements[1] === "qr") {
      return {
        nextjsHrefPath: `/qr/[poolName]${search}`,
        nextjsAsPath: `/${pathElements[1]}/${pathElements[2]}${search}`
      };
    }
  }
  return {
    nextjsHrefPath: `${url.pathname}${search}`,
    nextjsAsPath: `${url.pathname}${search}`
  };
}
export {
  VIMEO_REGEX,
  YOUTUBE_REGEX,
  etAbsoluteRed,
  etAmaranth,
  etBlack,
  etBlue,
  etBlueDark,
  etBlueDefault,
  etBlueFaded,
  etBlueLight,
  etBlueLighter,
  etBlueLowLight,
  etBlueOnahau,
  etBluePattens,
  etBlueSky,
  etBlueZiggurat,
  etBrightLightBlue,
  etBrightLightBlueOpacity,
  etBrightTurquoise,
  etCeruleanFrost,
  etConcrete,
  etDark,
  etDarkWithOpacity,
  etDustyGray,
  etFbPurple,
  etFrenchGray,
  etGallery,
  etGhostGray,
  etGhostWhite,
  etGray,
  etGrayBlue,
  etGrayBold,
  etGrayDark,
  etGrayDarkWithOpacity,
  etGrayExtraDark,
  etGrayIconMenu,
  etGrayLight,
  etGrayTuna,
  etGreen,
  etGreenBold,
  etGreenDark,
  etGreenLight,
  etGreenSquare,
  etHeatherBlue,
  etHeliotrope,
  etIceBlue,
  etIronGray,
  etLiBlueWaterDark,
  etLicoriceBlue,
  etLightBlue,
  etNevadaGrey,
  etNiagara,
  etOrange,
  etOrangeDark,
  etOrangeLight,
  etPaleSky,
  etPattensBlue,
  etPiRed,
  etPink,
  etPinkDark,
  etPinkHighLight,
  etPinkLight,
  etPurple,
  etPurpleLogo,
  etPurpleUonCard,
  etRed,
  etRedBlood,
  etRedDark,
  etRedFresh,
  etRedLight,
  etRegentGray,
  etSanMarino,
  etScarletRed,
  etShadow,
  etShadowBrighter,
  etShadowDarkBlueFaded,
  etShadowLight,
  etShadowLighter,
  etShadowWhite,
  etSilverLight,
  etSolitude,
  etSolitudeBlue,
  etSolitudeLight,
  etSonicSilver,
  etStormGray,
  etSunglow,
  etTundora,
  etTwBlueWater,
  etVeryMarshmallow,
  etVoteGreen,
  etVoteRed,
  etWaGreen,
  etWhite,
  etWhiteDark,
  etWhiteLight,
  etWhiteOpacity,
  etYTDark,
  etYellow,
  etYellowDark,
  etYellowDeck,
  etYellowLight,
  getEventText,
  getEventTranslationKey,
  parseResourceByInternalUrl,
  sdgClimate,
  sdgCommunities,
  sdgConsumption,
  sdgEducation,
  sdgEnergy,
  sdgEquality,
  sdgGrowth,
  sdgHealth,
  sdgHunger,
  sdgInequality,
  sdgInnovation,
  sdgLand,
  sdgPartnerships,
  sdgPeace,
  sdgPoverty,
  sdgSanitation,
  sdgWater
};
