import { SearchResponseDto } from "@earthtoday/contract";
import { ClientHintsPlugin } from "@snowplow/browser-plugin-client-hints";
import {
  ConsentPlugin,
  trackConsentGranted,
} from "@snowplow/browser-plugin-consent";
import {
  enableErrorTracking,
  ErrorTrackingPlugin,
} from "@snowplow/browser-plugin-error-tracking";
import {
  enableFormTracking,
  FormTrackingPlugin,
} from "@snowplow/browser-plugin-form-tracking";
import { GaCookiesPlugin } from "@snowplow/browser-plugin-ga-cookies";
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from "@snowplow/browser-plugin-link-click-tracking";
import { PerformanceTimingPlugin } from "@snowplow/browser-plugin-performance-timing";
import {
  SiteTrackingPlugin,
  trackSiteSearch,
} from "@snowplow/browser-plugin-site-tracking";
import { TimezonePlugin } from "@snowplow/browser-plugin-timezone";
import {
  addGlobalContexts,
  disableAnonymousTracking,
  enableActivityTracking,
  newTracker,
  setUserId,
  TrackerConfiguration,
  trackPageView,
  trackSelfDescribingEvent,
} from "@snowplow/browser-tracker";

import { appENV, snowplowCollectorEndpoint } from "./env";
import { isBrowser } from "./helpers/isBrowser";
import { isCypress } from "./helpers/isCypress";
import { CardState } from "./models/Card";
import { IOneFlowPaymentStartedData, IUonCollectedData } from "./models/Events";
import { CookieConsent, GroupProfileType, UserType } from "./models/User";

const isEnableSnowplow = snowplowCollectorEndpoint !== "";
let pageName: string | undefined;

export interface ISnowplowUser {
  userSessionId: string;
  browsingSessionId: string;
  vanityName: string;
  fullName: string;
  emailAddress: string;
  userType: UserType;
  joined: string;
  uonStoreId?: string;
  termsAccepted: boolean;
  cookieConsent: string;
  groupMemberRole?: string;
  groupId?: string;
  groupName?: string;
  isGroupVerified?: boolean;
  groupType?: GroupProfileType;
  groupState?: string;
  groupCreatedBy?: string;
  isGroupVerificationPending?: boolean;
  groupRoleRequest?: GroupProfileType;
  lastLoginAt?: string;
  loginTimes?: number;
  isNew?: boolean;
}

function generateUserContext() {
  const { snowplowUser } = window.__rootStore.userSessionStore;

  if (!snowplowUser) {
    // eslint-disable-next-line unicorn/no-useless-undefined
    return undefined;
  }

  return {
    schema: "iglu:com.earthtoday.self-desc/user_info/jsonschema/1-0-1",
    data: snowplowUser as unknown as Record<string, unknown>,
  };
}

function generatePageContext() {
  return {
    schema: "iglu:com.earthtoday.self-desc/page_info/jsonschema/1-0-0",
    data: {
      pageName,
    },
  };
}

export function setPageName(name: string | undefined) {
  pageName = name;
}

export function initSnowplow(
  userId: string | null,
  cookieConsent: CookieConsent,
) {
  if (!isBrowser() || isCypress() || !isEnableSnowplow) {
    return;
  }

  const config: TrackerConfiguration = {
    appId: `ET-${appENV}`,
    plugins: [
      LinkClickTrackingPlugin(),
      PerformanceTimingPlugin(),
      FormTrackingPlugin(),
      ErrorTrackingPlugin(),
      ClientHintsPlugin(),
      ConsentPlugin() as never,
      GaCookiesPlugin(),
      SiteTrackingPlugin(),
      TimezonePlugin(),
    ],
  };

  if (cookieConsent === CookieConsent.NONE) {
    config.eventMethod = "post";
    config.anonymousTracking = true;
    config.stateStorageStrategy = "none";
  }

  newTracker("earthtoday-ui-react", snowplowCollectorEndpoint, config);

  setUserId(userId);

  addGlobalContexts([generateUserContext, generatePageContext]);

  // Turn on page pings every 10 seconds
  enableActivityTracking({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
  });
  enableLinkClickTracking();
  enableFormTracking();
  enableErrorTracking();

  trackPageView();
}

export function snowplowHandleConsentUpdated(consent: CookieConsent) {
  if (!isEnableSnowplow) return;
  if (consent !== CookieConsent.NONE) {
    trackConsentGranted({
      id: "1",
      version: "1",
      name: "consent_document",
      description: "a document granting consent",
      expiry: "2100-01-01T00:00:00.000Z",
    });
    disableAnonymousTracking({ stateStorageStrategy: "cookieAndLocalStorage" });
  }
}

export function snowplowSetUserId(userId: string | null) {
  if (!isEnableSnowplow) return;
  setUserId(userId);
}

function snowplowTrackCyDataClick(event) {
  if (!isEnableSnowplow) return;

  const elem = (() => {
    if (event.target?.dataset?.cy) {
      return event.target;
    }

    if (event.target?.closest) {
      return event.target.closest("[data-cy]");
    }

    let el = event.target;
    while (
      (el = el.parentElement) &&
      !(el.matches || el.matchesSelector).call(el, "[data-cy]")
    );
    return el;
  })();

  if (!elem) {
    return;
  }

  const dataCy = elem.dataset.cy;
  const tag = elem.tagName;
  const href = elem.getAttribute("href") || null;

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/data_cy_click/jsonschema/1-0-0",
      data: {
        dataCy,
        tag,
        href,
      },
    },
  });
}

export function snowplowTrackSiteSearch(str: string, res: SearchResponseDto[]) {
  if (!isEnableSnowplow) return;

  trackSiteSearch({
    terms: str.split(" ").map((term) => term.trim()),
    totalResults: res.length,
    pageResults: 1,
  });
}

export function protectflowStarted(event) {
  if (!isEnableSnowplow) return;

  const elem = (() => {
    if (event.target?.dataset?.cy) {
      return event.target;
    }

    if (event.target?.closest) {
      return event.target.closest("[data-cy]");
    }
  })();

  if (!elem) {
    return;
  }

  const triggerPoint = elem.dataset.cy;
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.earthtoday.self-desc/protectflow_started/jsonschema/1-0-0",
      data: {
        triggerPoint,
      },
    },
  });
}

function trackElementClickEvent(event) {
  if (!isEnableSnowplow) return;

  const elem = (() => {
    if (event.target?.dataset?.trackId) {
      return event.target;
    }

    if (event.target?.closest) {
      return event.target.closest("[data-track-id]");
    }
  })();

  if (!elem) {
    return;
  }

  const { trackId } = elem.dataset;
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.earthtoday.self-desc/track_element_click/jsonschema/1-0-0",
      data: {
        trackId,
      },
    },
  });
}

export function snowplowHandleGlobalMousedown(event) {
  snowplowTrackCyDataClick(event);
  trackElementClickEvent(event);
}

export function snowplowCaptureDeckCreatedEvent(data: {
  deckId: string;
  deckName: string;
  linkName: string;
  category: string;
  description: string;
}) {
  if (!isEnableSnowplow) return;

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/deck_created/jsonschema/1-0-0",
      data,
    },
  });
}

export function snowplowCaptureCardCreatedEvent(data: {
  cardId: string;
  deckId: string;
  contentType: string;
  state: CardState;
  category: string;
  cardName: string;
  cardWhy: string;
}) {
  if (!isEnableSnowplow) return;

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/card_created/jsonschema/1-0-0",
      data,
    },
  });
}

export function trackSignUpWithGoogleButtonClickEvent(
  dataCy: string,
  trackId?: string,
) {
  if (!isEnableSnowplow) return;

  if (trackId) {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.earthtoday.self-desc/track_element_click/jsonschema/1-0-0",
        data: {
          trackId,
        },
      },
    });
  }

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/data_cy_click/jsonschema/1-0-0",
      data: {
        dataCy,
        tag: "",
        href: "",
      },
    },
  });
}

export function snowplowCaptureCodeIssuedEvent(data: {
  issueSource: string;
  issuer: string;
  codeType: string;
  uonValue: number;
}) {
  if (!isEnableSnowplow) return;

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/code_issued/jsonschema/1-0-0",
      data,
    },
  });
}

export function snowplowCaptureUonProtectedEvent(data: {
  uonValue: number;
  paymentMethod: string;
  wallet: string;
  protectedBy: string;
  sponsoredBy: string;
  registeredTo: string;
}) {
  if (!isEnableSnowplow) return;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/uon_protected/jsonschema/1-0-0",
      data,
    },
  });
}

export function snowplowCaptureUonCollectedEvent(data: IUonCollectedData) {
  if (!isEnableSnowplow) return;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/uon_collected/jsonschema/1-0-0",
      data,
    },
  });
}

export function snowplowOneFlowPaymentStartedEvent(
  data: IOneFlowPaymentStartedData,
) {
  if (!isEnableSnowplow) return;
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.earthtoday.self-desc/oneFlow_payment_started/jsonschema/1-0-0",
      data,
    },
  });
}

export function snowplowETPageViewEvent(data: {
  category: string;
  deckName: string;
  deckId: string;
  cardId: string;
}) {
  if (!isEnableSnowplow) return;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.earthtoday.self-desc/et_page_view/jsonschema/1-0-0",
      data,
    },
  });
}
