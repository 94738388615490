import { observer } from "mobx-react-lite";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

import { deepLinkHost } from "../../shared/env";
import { useRootStore } from "../../stores/rootStore";

export const MetaTagsStateful = observer(() => {
  const rootStore = useRootStore();
  const { metaStore: store } = rootStore;

  const router = useRouter();
  const ogUrl = `${deepLinkHost}${router.asPath}`;

  return (
    <Head>
      {store.metaTagsArray.map((meta) => (
        <meta name={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaTagsArrayWhatsApp.map((meta) => (
        <meta
          property={meta.key}
          content={meta.content}
          key={`property-${meta.key}`}
        />
      ))}

      <meta name="og:url" content={ogUrl} />
      <meta property="og:url" content={ogUrl} />
      <meta name="og:rich_attachment" content="true" />
      <meta property="og:rich_attachment" content="true" />
      {store.metaTagsTwitterArray.map((meta) => (
        <meta name={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaGoogleArray.map((meta) => (
        <meta itemProp={meta.key} content={meta.content} key={meta.key} />
      ))}
      {store.metaPinterestArray.map((meta) => (
        <meta property={meta.key} content={meta.content} key={meta.key} />
      ))}
    </Head>
  );
});
